<template>
  <div class="arena">
    <div class="arena-warp">
      <div class="pagesView">
        <div class="tabBox">
          <div class="typesTab">
            <div
              class="typeItem"
              @click="getTypeList(1)"
              :class="payType == '1' ? 'active' : ''"
            >
              <img src="../assets/img/i1@2x.30d94e4c.png" alt="" />
              官方
            </div>
            <div
              class="typeItem"
              @click="getTypeList(2)"
              :class="payType == '2' ? 'active' : ''"
            >
              <img src="../assets/img/i2@2x.84e3f6f6.png" alt="" />
              主播
            </div>
            <div
              class="typeItem"
              @click="getTypeList(3)"
              :class="payType == '3' ? 'active' : ''"
            >
              <img src="../assets/img/i3@2x.df21b2ae.png" alt="" />
              我参与的
            </div>
          </div>
          <div class="statusTab">
            <div
              class="statusItem"
              @click="getStatusList(1)"
              :class="status == '1' ? 'active' : ''"
            >
              进行中
            </div>
            <div
              class="statusItem"
              @click="getStatusList(2)"
              :class="status == '2' ? 'active' : ''"
            >
              已结束
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="boxsss">
				<button :class="payType == '0'?'el-btn-click':'el-btn'" @click="getTypeList(0)"></i>全部房间</button>
				<button :class="payType == '1'?'el-btn-click':'el-btn'" @click="getTypeList(1)"></i>官方房间</button>
				<button :class="payType == '2'?'el-btn-click':'el-btn'" @click="getTypeList(2)"></i>主播房间</button>
				<button :class="payType == '4'?'el-btn-click':'el-btn'" @click="getTypeList(4)"></i>我参与的</button>
				<button :class="payType == '3'?'el-btn-click':'el-btn'" @click="getTypeList(3)"></i>已结束</button>
			</div> -->
      <div class="tabs">
        <div class="list">
          <ul>
            <li v-for="(item, index) in list" :key="index">
              <div class="list-warp">
                <div class="front">
                  <div class="tou-warp">
                    <!-- <img :src="item.img" style="opacity: 0.3" /> -->
                    <div class="tou-warp-back"></div>
                  </div>
                  <div class="tou">
                    <img :src="item.img" />
                    <span class="type">{{
                      item.type == 1 ? "官方" : "主播"
                    }}</span>
                  </div>
                  <div class="name">{{ item.room_name }}</div>
                  <div class="time">截止时间 : {{ item.run_lottery_time }}</div>
                  <div class="num">
                    <div class="num-box">
                      <span>奖池</span>
                      <span class="num-span">{{ item.pool }}</span>
                    </div>
                    <div class="num-box">
                      <span>件数</span>
                      <span class="num-span">{{ item.count }}</span>
                    </div>
                    <div class="num-box">
                      <span>人数</span>
                      <span class="num-span">{{ item.person_num }}</span>
                    </div>
                  </div>
                  <div class="skinBox">
                    <div
                      class="skinItem bg"
                      v-for="(item1, index1) in item.skin_list"
                      :key="index1"
                      :style="{ 'background-image': `url(${skinBg})` }"
                    >
                      <img class="gun" :src="item1.imageUrl" alt="" />
                      <div class="price">
                        <img src="../assets/img/money.png" alt="" />
                        <span style="color: rgb(255, 255, 255)">{{
                          item1.price
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="btn detailBtn _btnHover"
                    @click="jionRoom(item.id)"
                  >
                    <img
                      v-if="item.status == 1"
                      class="btnBg"
                      src="../assets/img/btn1.cb5859e1.png"
                      alt=""
                    />
                    <img
                      v-if="item.status == 2"
                      class="btnBg"
                      src="../assets/img/roll/btn2.70f110c5.png"
                      alt=""
                    />
                    <img
                      v-if="item.status == 2"
                      class="chap"
                      src="../assets/img/roll/chap@2X.2de50574.png"
                      alt=""
                    />
                    查看房间
                  </div>
                  <!-- <div class="btn">
                    <div
                      class="btn-warp"
                      @click="jionRoom(item.id)"
                      v-if="item.status == 1"
                    >
                   
                      <span>加入房间</span>
                    </div>
                    <div class="btn-warp" v-if="item.status == 2">
                      <span @click="jionRoom(item.id)">已结束</span>
                    </div>
                    <div class="btn-warp" v-if="item.status == 3">
                      <span @click="jionRoom(item.id, true)">已加入</span>
                    </div>
                  </div> -->

                  <!-- <div
                    class="back1"
                    v-if="item.status == 2"
                    @click="jionRoom(item.id)"
                  ></div> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="list" v-if="payType == '4'">
					<ul>
						<li v-for="(item, index) in list1" :key="index">
							<div class="list-warp">
								<div class="tou-warp">
									<img :src="item.img" />
									<div class="tou-warp-back"></div>
								</div>
								<div class="tou">
									<img :src="item.img" />
									<span>{{ item.type == 1 ? "官方" : "主播" }}</span>
								</div>
								<div class="name">{{ item.room_name }}</div>
								<div class="num">
									<div class="num-box">
										<span>奖池</span>
										<span class="num-span">{{ item.pool }}</span>
									</div>
									<div class="num-box">
										<span>件数</span>
										<span>{{ item.count }}</span>
									</div>
									<div class="num-box">
										<span>人数</span>
										<span>{{ item.person_num }}</span>
									</div>
								</div>
								<div class="img" v-for="(item1, index1) in item.skin_list" :key="index1">
									<div class="img-box">
										<img :src="item1.imageUrl" />
										<span>{{ item1.price }}</span>
									</div>
								</div>
								<div class="clear"></div>
								<div class="btn">
									<div class="btn-warp" v-if="item.status == 1">
										<span @click="jionRoom(item.id,true)">查看房间</span>
									</div>
									<div class="btn-warp" v-if="item.status == 2">
										<span @click="jionRoom(item.id)">已结束</span>
									</div>
								</div>
								<div class="time">开奖时间 : {{ item.run_lottery_time }}</div>

								<div class="back1" v-if="item.status == 2" @click="jionRoom(item.id)"></div>
							</div>
						</li>
					</ul>
				</div> -->
      </div>
    </div>
    <div class="clear"></div>
    <myhomebot></myhomebot>
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
  </div>
</template>
<script>
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import navheader from "@/components/navheader.vue";
export default {
  components: {
    myhomebot,
    myinform,
    navheader,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      activeName: "first",
      list: [],
      list1: [],
      payType: "1",
      status: 1,
      skinBg: require("../assets/img/skinbg2.8216810b.png"),
    };
  },
  async mounted() {
    await this.getMyList();
    // this.getList();
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name == "first") {
        this.getList();
      } else {
        this.getMyList();
      }
    },
    //免费皮肤房间列表
    getList() {
      let _this = this;
      let param = {
        page: 1,
        pageSize: 50,
      };
      _this.$axios
        .post("index/Free/freeRoomList", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == 1) {
            this.list = data.data.list;
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].person_num == null) {
                this.list[i].person_num = 0;
              }
              if (this.list[i].count == null) {
                this.list[i].count = 0;
              }
              if (this.list[i].pool == null) {
                this.list[i].pool = 0;
              }
            }
            this.list.forEach((e) => {
              this.list1.forEach((es) => {
                if (e.id == es.id && e.status != 2) {
                  e.status = 3;
                }
              });
            });
          }
        });
    },
    // 官方roll
    // getTypeList(e) {
    //   let _this = this;
    //   let param = {
    //     page: 1,
    //     pageSize: 30,
    //   };
    //   this.list = [];
    //   this.list1 = [];
    //   this.payType = e;
    //   if (e == 0) {
    //     this.getList();
    //   }
    //   if (e == 1) {
    //     _this.$axios
    //       .post("index/Free/officialList", _this.$qs.stringify(param))
    //       .then((res) => {
    //         var data = res.data;
    //         if (data.status == 1) {
    //           this.list = data.data.list;
    //           for (let i = 0; i < this.list.length; i++) {
    //             if (this.list[i].person_num == null) {
    //               this.list[i].person_num = 0;
    //             }
    //             if (this.list[i].count == null) {
    //               this.list[i].count = 0;
    //             }
    //             if (this.list[i].pool == null) {
    //               this.list[i].pool = 0;
    //             }
    //           }
    //           this.list.forEach((e) => {
    //             this.list1.forEach((es) => {
    //               if (e.id == es.id && e.status != 2) {
    //                 e.status = 3;
    //               }
    //             });
    //           });
    //         }
    //       });
    //   }
    //   if (e == 2) {
    //     _this.$axios
    //       .post("index/Free/anchorList", _this.$qs.stringify(param))
    //       .then((res) => {
    //         // console.log(res.data);
    //         var data = res.data;
    //         if (data.status == 1) {
    //           this.list = data.data.list;
    //           for (let i = 0; i < this.list.length; i++) {
    //             if (this.list[i].person_num == null) {
    //               this.list[i].person_num = 0;
    //             }
    //             if (this.list[i].count == null) {
    //               this.list[i].count = 0;
    //             }
    //             if (this.list[i].pool == null) {
    //               this.list[i].pool = 0;
    //             }
    //           }
    //           this.list.forEach((e) => {
    //             this.list1.forEach((es) => {
    //               if (e.id == es.id && e.status != 2) {
    //                 e.status = 3;
    //               }
    //             });
    //           });
    //         }
    //       });
    //   }
    //   if (e == 3) {
    //     _this.$axios
    //       .post("index/Free/overList", _this.$qs.stringify(param))
    //       .then((res) => {
    //         // console.log(res.data);
    //         var data = res.data;
    //         if (data.status == 1) {
    //           this.list = data.data.list;
    //           for (let i = 0; i < this.list.length; i++) {
    //             if (this.list[i].person_num == null) {
    //               this.list[i].person_num = 0;
    //             }
    //             if (this.list[i].count == null) {
    //               this.list[i].count = 0;
    //             }
    //             if (this.list[i].pool == null) {
    //               this.list[i].pool = 0;
    //             }
    //           }
    //           this.list.forEach((e) => {
    //             this.list1.forEach((es) => {
    //               if (e.id == es.id && e.status != 2) {
    //                 e.status = 3;
    //               }
    //             });
    //           });
    //         }
    //       });
    //   }
    //   if (e == 4) {
    //     this.getMyList();
    //   }
    //   this.$forceUpdate();
    // },
    //我参与的房间列表
    // type 官方 1 主播 2 我参加的3
    // status 进行中 1 已结束2
    async getMyList() {
      let _this = this;
      let param = {
        page: 1,
        pageSize: 10,
        type: _this.payType,
        status: _this.status,
      };
      await _this.$axios
        .post("index/Free/myFreeRoom", _this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.data != undefined) {
            if (data.status == 1) {
              this.list = data.data.list;
              for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].person_num == null) {
                  this.list[i].person_num = 0;
                }
                if (this.list[i].count == null) {
                  this.list[i].count = 0;
                }
                if (this.list[i].pool == null) {
                  this.list[i].pool = 0;
                }
              }
              this.list.forEach((e) => {
                this.list1.forEach((es) => {
                  if (e.id == es.id && e.status != 2) {
                    e.status = 3;
                  }
                });
              });
            }
          } else {
            this.list = [];
          }
        });
    },
    getTypeList(num) {
      this.payType = num;
      this.getMyList();
    },
    getStatusList(num) {
      this.status = num;
      this.getMyList();
    },
    //加入房间
    jionRoom(id, status) {
      if (!this.$store.state.token) {
        this.$store.commit("getLogin", true);
        return;
      }
      this.$router.push({
        path: `/ArenaRoom`,
        query: {
          id: id,
          status: status,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.boxsss {
  width: 500px;
  padding: 0 32px;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 32px;
  border: 1px solid rgb(30, 28, 44);
  padding: 2px;
  margin-top: 32px;
}

.el-btn {
  width: 100px;
  padding: 8px 0;
  border-radius: 32px;
  border: none;
  // border: 1px solid #007aff;
  background: none;
  cursor: pointer;
  // color: #007aff;
  color: white;
  font-weight: 600;
  // font-weight: 600;
}

// .el-btn:hover {
// 	width: 100px;
// 	padding: 8px 0;
// 	border-radius: 32px;
// 	// border: 1px solid #007aff;
// 	background: #007aff;
// 	color: white;
// 	// font-weight: 600;
// }

.el-btn-click {
  width: 100px;
  padding: 8px 0;
  border-radius: 32px;
  // border: 1px solid #007aff;
  border: none;
  background: #007aff;
  color: white;
  cursor: pointer;
  // font-weight: 600;
  font-weight: 600;
}

.arena {
  --multiple: 1.4;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  // background-color: #06090f;

  // background: url("../assets/img/bg.6a2bc676.jpg") no-repeat;
  height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center center !important;
  .arena-warp {
    min-height: 100vh;
    // padding: 16px 50px;
    width: 80%;
    margin: auto;
    .pagesView {
      width: 100%;
      margin: 30px auto;
      .typesTab {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.83rem * 1.5;
        cursor: pointer;
        color: #fff;
      }
      .typesTab .typeItem {
        margin: 0 0.17em;
        height: 2.29rem * 1.5;
        border-radius: 0.17rem;
        width: 7.58rem * 1.5;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active img {
          opacity: 1;
        }
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.3;
          z-index: -1;
        }
      }
      .statusTab {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(0.75rem * var(--multiple));
        margin: 1rem 0;
        color: #fff;
      }
      .statusTab .statusItem {
        position: relative;
        padding-bottom: 0.5rem;
        margin: 0 calc(0.8rem * var(--multiple));
        cursor: pointer;
      }
      .statusTab .active {
        color: #ff31ed;
        font-weight: 700;
      }
      .statusTab .active::after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 0.13rem;
        background: #ff31ed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .extension_index_img {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // display: flex;
    /deep/.el-tabs__header {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /deep/.el-tabs__nav-wrap {
      // width: 200px;
    }

    .tabs /deep/ .el-tabs__item.is-active {
      color: #fff;
    }

    .tabs /deep/ .el-tabs__item {
      color: #fff;
      font-size: 20px;
    }

    .tabs /deep/ .el-tabs__nav-wrap::after {
      background-color: #1a1c24;
    }

    .tabs {
      .list {
        margin: 0 0;
        width: 100%;

        ul {
          margin: 0 0;
          width: 100%;
          margin: auto;
          padding: 32px 0;
          // background-color: rgb(16, 21, 27);
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: flex-start;
          // height: 1000px;

          li {
            width: 25%;
          }
          .list-warp {
            width: 15rem * 1.5;
            height: 25rem * 1.5;
            transform-style: preserve-3d;
            transition: transform 0.5s;
            background: #1d2245;
            border-radius: 0.2rem;
            margin: 0.2rem;
            cursor: pointer;
            overflow: hidden;
            // margin: 8px;
            // // padding: 16px;
            // background-color: rgba(0, 122, 255, 0.2);
            // border: 2px solid rgba(0, 122, 255, 1);
            // border-radius: 5px;
            // overflow: hidden;
            // position: relative;
            .front {
              width: 100%;
              height: 100%;
              position: absolute;
              box-sizing: border-box;
              background: url("../assets/img/titlebg@2x.52afd75f.png") no-repeat;
              background-size: 100% calc(8.5rem * var(--multiple));
              border-radius: 8px;
            }

            // .tou-warp {
            //   width: 100%;
            //   height: 130px;
            //   position: relative;

            //   img {
            //     width: 100%;
            //     height: 100%;
            //     object-fit: cover;
            //   }

            //   .tou-warp-back {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 130px;
            //     // background: linear-gradient(to bottom,
            //     // rgba(0, 122, 255, 1) 0%,
            //     // rgba(0, 122, 255, 0.2) 100%);
            //   }
            // }

            .back1 {
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              position: absolute;
              top: 0;
              left: 0;
            }

            .tou {
              width: calc(3.33rem * var(--multiple));
              height: calc(3.33rem * var(--multiple));
              border-radius: 50%;
              border: 2px solid #ff31ed;
              // padding: 0 16px;
              display: flex;
              justify-content: center;
              position: relative;
              margin: 30px auto;
              margin-bottom: 20px;
              .type {
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: 100% 100%;
                width: 3.27rem * 1.2;
                height: 1.08rem * 1.2;
                font-size: 0.5rem * 1.5;
                margin-top: -0.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: url("../assets/img/anchor.748fa088.png");
              }
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                // border: 1px solid #ae23c6;
              }

              span {
                color: #fff;
                font-size: 12px;
                padding: 0 6px;
                position: absolute;
                bottom: -3px;
                background-color: #ae23c6;
                border-radius: 3px;
              }
            }

            .name {
              position: relative;
              z-index: 66;
              padding: 0 16px;
              margin-top: 5px;
              font-size: 16px;
              color: #fff;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: calc(0.92rem * var(--multiple));
              font-weight: 700;
            }

            .num {
              position: relative;
              z-index: 66;
              padding: 0 16px;
              margin-top: 10px;
              display: flex;
              justify-content: space-around;
              align-items: center;

              .num-box {
                display: flex;
                flex-direction: column;
                align-items: center;

                span {
                  font-size: 0.67rem * 1.6;
                  font-weight: 700;
                  color: #fff;
                }

                span:last-child {
                  margin-top: 5px;
                }

                .num-span {
                  color: #ff31ed;
                }
              }
            }
            .skinBox {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              margin: 1.1rem 0;
              position: relative;
              min-height: 8.74rem;
              padding: 0.25rem calc(0.83rem * var(--multiple));
              box-sizing: border-box;
            }

            .skinBox .skinItem {
              width: calc(4.11rem * var(--multiple));
              height: calc(4.11rem * var(--multiple));
              // background-image: url("../assets/img/skinbg2.8216810b.png");
              background: hsla(0, 0%, 100%, 0.05);
              border-radius: 0.42rem;
              position: relative;
            }

            .skinBox .skinItem .gun {
              width: 80%;
              top: 40%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .skinBox .skinItem:first-child {
              width: calc(9.23rem * var(--multiple));
              height: calc(9.24rem * var(--multiple));
              margin-right: 0.3rem;
            }

            .skinBox .skinItem:first-child .price {
              font-size: calc(0.67rem * var(--multiple));
            }

            .skinBox .skinItem:first-child .price .icon {
              width: 0.67rem;
              height: 0.67rem;
              margin-right: 0.25rem;
            }

            .skinBox .skinItem:last-child {
              position: absolute;
              bottom: 0.25rem;
              right: calc(0.83rem * var(--multiple));
            }

            .skinBox .skinItem .price {
              display: flex;
              align-items: center;
              position: absolute;
              bottom: 0.2rem;
              left: 50%;
              transform: translateX(-50%);
              font-size: 0.8rem;
              z-index: 2;
            }

            .skinBox .skinItem .price img {
              width: calc(1rem * var(--multiple));
              height: calc(1rem * var(--multiple));
              margin-right: calc(0.2rem * var(--multiple));
            }

            .skinBox .skinItemCenter:last-child {
              position: absolute;
              bottom: 0;
              right: 0;
              top: 0;
              left: 0;
              margin: auto;
            }

            .skinBox .skinRight {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
            }

            .skinBox .skinRight .rightItem {
              width: 4.11rem;
              height: 4.11rem;
              background-color: #22252b;
              border-radius: 0.42rem;
            }

            .skinBox .bg {
              background-size: 130%;
              background-position: 50%;
              background-repeat: no-repeat;
            }
            .detailBtn {
              height: calc(1.99rem * var(--multiple));
              border-radius: 0.17rem;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: calc(0.67rem * var(--multiple));
              font-weight: 700;
              position: relative;
              padding: 0 calc(0.83rem * var(--multiple));
            }
            .detailBtn .btnBg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: calc(100% - 0.83rem * var(--multiple) * 2);
              height: 100%;
              z-index: -1;
            }
            ._btnHover:hover {
              animation: btnHover 1s;
            }
            .img {
              padding: 0 16px;
              margin-top: 20px;

              .img-box {
                width: 30%;
                margin: 0 2px;
                float: left;
                // background-image: url("../assets/img/beibao.png");
                background-color: rgba(0, 122, 255, 0.2);
                background-size: 100% 100%;
                position: relative;
                text-align: center;
                // margin-right: 2px;

                img {
                  width: 70%;
                  height: 45px;
                }

                span {
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  // margin-left: -25px;
                  color: #fff;
                  padding: 0 10px;
                  border-radius: 20px;
                  background-color: rgba(0, 0, 0, 0.3);
                  font-size: 10px;
                }
              }

              .img-box:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              }

              .img-box:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }

            .btn {
              padding: 0 16px;
              margin-top: 20px;
              display: flex;
              justify-content: center;

              .btn-warp {
                display: flex;
                align-items: center;
                background-color: #007aff;
                padding: 5px 25px;
                border-radius: 20px;

                img {
                  width: 16px;
                  height: 16px;
                  margin-right: 10px;
                }

                span {
                  font-size: 14px;
                  color: #fff;
                }
              }

              .btn-warp:hover {
                // cursor: pointer;
                // border: 1px solid#007aff;
                background-color: #ce9a0c;
              }

              // .btn-warp:hover span {
              // 	color: #007aff;
              // }
            }

            .time {
              padding: 0 16px;
              margin: 10px 0 16px 0;

              text-align: center;
              font-size: calc(0.58rem * var(--multiple));
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@keyframes btnHover {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  to {
    transform: scale(1);
  }
}

@media (min-width: 1300px) and (max-width: 1700px) {
  .arena-warp {
    width: 90% !important;
  }
  .arena-warp .tabs .list ul li {
    width: 33.333% !important;
  }
}
@media (min-width: 767px) and (max-width: 1300px) {
  .arena-warp .tabs .list ul li {
    width: 50% !important;
  }
}
@media screen and (max-width: 767px) {
  .pagesView {
    margin-bottom: 0px !important;
  }
  .arena-warp {
    width: 94% !important;
  }
  .boxsss {
    width: 80%;
    min-width: 280px;
    border-radius: 32px;
    border: 1px solid rgb(30, 28, 44);
    padding: 2px;
    margin: 12px auto;
  }
  .extension_index_img {
    // padding: 16px 50px;
    img {
      width: 80%;
    }
    margin: auto;
  }

  .el-btn {
    width: 20% !important;
    padding: 7px 0 !important;
    border-radius: 32px;
    border: none;
    // border: 1px solid #007aff;
    background: none;
    cursor: pointer;
    // color: #007aff;
    color: white;
    font-weight: 400 !important;
    font-size: 10px !important;
    // font-weight: 600;
  }

  // .el-btn:hover {
  // 	width: 100px;
  // 	padding: 8px 0;
  // 	border-radius: 32px;
  // 	// border: 1px solid #007aff;
  // 	background: #007aff;
  // 	color: white;
  // 	// font-weight: 600;
  // }

  .el-btn-click {
    width: 20% !important;
    padding: 7px 0 !important;
    border-radius: 32px;
    // border: 1px solid #007aff;
    border: none;
    background: #007aff;
    color: white;
    cursor: pointer;
    // font-weight: 600;
    font-weight: 400 !important;
    font-size: 10px !important;
  }

  .list-warp {
    width: 100% !important;
    margin: 8px 0 !important;
    // padding: 16px;
    // background-color: rgba(0, 122, 255, 0.2);
    // border: 2px solid rgba(0, 122, 255, 1);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  .arena-warp .tabs .list ul {
    padding-top: 0px !important;
  }
}
.detailBtn .chap {
  width: auto;
  height: 160%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}
</style>