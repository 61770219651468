<template>
  <div class="bot-right-inform" v-if="$store.state.informState">
    <div class="inform-left">
      <div>
        <div class="tz">
          <img src="../assets/img/tongzhi.png" />
        </div>
      </div>

      <div class="span">
        <span id="span">
          完成签到，对战任务，盲盒任务即可获得海量游戏币哦～（有任何问题可联系网站客服QQ：3801177361
          ）
        </span>
      </div>
    </div>
    <!-- <div class="inform-right" @click="getInformState">
			<i class="el-icon-close"></i>
		</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      informState: true,
      screenWidth: document.body.clientWidth,
    };
  },
  watch: {
    screenWidth(val) {
      console.log(val);
    },
  },
  methods: {
    //取消顶部通知
    getInformState() {
      this.$store.commit("getInform", false);
    },
    getSpanWidth() {
      var span = document.getElementById("span");
      var spanWidth = span.offsetWidth;

      // let style = document.createElement('style');
      // style.setAttribute('type', 'text/css');
      // document.head.appendChild(style);
      // let sheet = style.sheet;
      // let random = Math.floor(Math.random()*190)+11905;
      // sheet.insertRule(
      //   `@keyframes run``{
      //     0% {
      //       left: 0;
      //     }
      //     100% {
      //       left: -`+random+`px
      //     }
      // }`,0);
    },
  },
  mounted() {
    const _this = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        _this.screenWidth = window.screenWidth;
      })();
    };
    _this.getSpanWidth();
  },
};
</script>

<style lang="less" scoped>
.bot-right-inform {
  background-color: #3088ff;
  padding: 4px 12px;
  margin: 4px 4px;
  // border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .inform-left {
    max-width: 95%;
    display: flex;
    align-items: center;

    img {
      width: 14px;
      height: 14px;
    }

    .span {
      margin-left: 10px;
      overflow: hidden;
    }

    span {
      margin-left: 20px;
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      white-space: nowrap;
      //	text-overflow: ellipsis;
      // overflow: hidden;
    }

    span {
      position: relative;
      right: 0;
      animation: marquee 30s linear infinite;

      @keyframes marquee {
        0% {
          right: 0;
        }

        100% {
          right: 1400px;
        }
      }
    }
  }

  .inform-right {
    i {
      font-size: 18px;
      color: #fff;
    }
  }

  .inform-right:hover {
    cursor: pointer;
  }

  .tz {
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      // top: 50%;
      // left: 50%;
      // margin-left: -9px;
      // margin-top: -9px;
    }
  }
}
</style>
