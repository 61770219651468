<template>
  <div class="shareBox">
    <div class="share pagesView routerView">
      <div
        class="BackTop _windows"
        style="background-color: transparent"
        v-if="showNav"
        @click="$router.go(-1)"
      >
        <div class="left">
          <div class="backBox">
            <i class="el-icon-arrow-left svg-icon backSvg"></i>
            <span>返回</span>
          </div>
        </div>
        <div class="right"></div>
      </div>
      <!-- 手机端 -->
      <div
        class="BackTop _mobile"
        v-if="!showNav"
        style="background-color: transparent"
        @click="$router.go(-1)"
      >
        <div class="left">
          <div class="backBox">
            <i class="el-icon-arrow-left svg-icon backSvg"></i>
            <span>我的佣金</span>
          </div>
        </div>
        <div class="right"><!----><!----></div>
      </div>
      <div class="promote">
        <div class="right">
          <span class="promoteName">推广链接</span>
          <div class="inputBox">
            <div class="ellipsis">
              {{ valueUrl }}
            </div>
            <div
              class="copyBtn btn _btnClick1 copy"
              @click="copyText(valueUrl)"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAoCAYAAACSN4jeAAAAAXNSR0IArs4c6QAAAtFJREFUWEftmEuoTWEUx39/woSkiMhjoExIhG7cgQgDQ3UJGRhwUx55pNSVQpnoDiTKwESXIlEGjL2SLroeV3mUIpLyGFyElrNue+vYzuv7zraT7qrTGez1+LX296219hIBYmaLgHZgOjAswLSS6hvgDtAp6XlWQY04N7OhwAlgXSP6gTrfgB2SjpTbNQp2GlgVGDBUfb+kvalRXTAzWwZcDo0SoW9Am6RzbtsI2GFge0SgGJO3wDRJH+qCxXivZmNmIwC/QFuS/0qqByR1FApWTmFmG4GjwOAM3bvSRZvwG5iZudJyYC4wJMdsfS2dn0fARUlfUr9mtqcU52CFOEt/gZnZGOACMD9HoKyrF8BqSTf8QVKGngITM4r7+sEShZvA7L8IlbruA1ol3U1idwLbMnFPpmB+6/z2FSXdkuYkYCuBM5nAXSnYY7+mRVElcWZK6jGzVuBqNbAfwKCCwbyYnq0H5lW3aFkjqWsALCDt/1/GvDX4GPIkIAtZ1ZHAztJ00lLBR3TGWiTdagKq39TMhgMPgMkZX1FgryWNbxYqtTez44A37XKJAvNGO1bSpzzgzOxSMhw0DeYOvKGvl/Q+Fs7MvGhvTdpddsSKyljK4uPKq1gwYFTyq+SiKbAmmOqaDoDVTVEetzI0SIx+9KvsBjYAPZK+x0Q2s0nAbmBTXpXf57Mpkl7GAJXbmJmXCZ/xs20pKmN5V/5jyTImlwI7VdKznDLmx2JWwOE/lc78lSbYXmAzcB/wjUyM+GfZLmBt4BnbVQssBiTEptoZ88/Ixf8S2OfS4OBnsUNSXwrmPdGXc0XKCknnzWw0MA+4LuljCpCCXQMWFEnll0HSvWoxU7AlwJVG9mU5wT8EZkiq+tlYvlTxCn0op8C13PjuYqGk27WUsmuoNt8iA7mN1Zng3gHaJXkJqil/LO7MzPdi3j7G1TMOeO6jeq8kXzk1JD8B3oKPU2cW4vsAAAAASUVORK5CYII="
                alt=""
              /><span>复制</span>
            </div>
          </div>
        </div>
      </div>
      <div class="cardList">
        <div class="cardItem" style="">
          <div class="title">邀请总人数</div>
          <div class="number">
            <span>{{ people_num }}</span>
          </div>
          <img
            class="cardIcon"
            src="../assets/img/share/zongrenshu.4d0541d4.png"
            alt=""
          />
        </div>
        <div class="cardItem" style="">
          <div class="title">有效用户</div>
          <div class="number">
            <span>{{ commission }}</span>
          </div>
          <img
            class="cardIcon"
            src="../assets/img/share/youxiaoyonghu.49c0fed4.png"
            alt=""
          />
        </div>
        <div class="cardItem" style="">
          <div class="title">累计奖励</div>
          <div class="number">
            <span
              ><div class="MoneyCoin money">
                <img
                  style="width: 40px; height: 40px"
                  src="../assets/img/money.png"
                  alt=""
                />
                <span class="moneyVal">{{ commission }}</span>
              </div></span
            >
          </div>
          <img
            class="cardIcon"
            src="../assets/img/share/leijijiangli.b604c934.png"
            alt=""
          />
        </div>
      </div>
      <div class="tableContent">
        <div class="tabBox">
          <div class="tabItem _tabBtn active">
            <img src="../assets/img/share/shareVip.e043693c.png" alt="" /><span
              >等级说明</span
            >
          </div>
          <div class="tabItem _tabBtn">
            <img
              src="../assets/img/share/numberOfInvitations.286c56e9.png"
              alt=""
            /><span>邀请记录</span>
          </div>
          <div class="rule">
            <img src="../assets/img/share/Question.c91820b3.png" alt="" />
            <span>规则</span>
          </div>
        </div>
        <div class="table">
          <div class="theader">
            <div class="tr">
              <div class="td level">邀请等级</div>
              <div class="td userRecharge">被邀请用户购币</div>
              <div class="td rewardRatio1">返佣比例</div>
              <div class="td rewardIncome">推广用户比例</div>
            </div>
          </div>
          <div class="tbody">
            <div class="tr" v-for="(item, index) in level" :key="item.id">
              <div class="td level">LV{{ item.level }}</div>
              <div class="td userRecharge">
                <div class="MoneyCoin">
                  <img src="../assets/img/money.png" alt="" />
                  <span class="moneyVal">{{ item.minRecharge }}</span>
                </div>
              </div>
              <div class="td rewardRatio1">{{ item.levelRateInvite }}%</div>
              <div class="td rewardIncome">{{ item.levelRateAccept }}%</div>
            </div>
          </div>
          <!---->
        </div>
        <div class="paging" style="display: none">
          <div class="el-pagination">
            <button
              type="button"
              class="btn-prev is-first"
              disabled=""
              aria-label="Go to previous page"
              aria-disabled="true"
            >
              <i class="el-icon"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z"
                  ></path></svg
              ></i>
            </button>
            <ul class="el-pager">
              <li
                class="is-active number"
                aria-current="true"
                aria-label="page 1"
                tabindex="0"
              >
                1
              </li>
              <!--v-if--><!--v-if--><!--v-if-->
            </ul>
            <button
              type="button"
              class="btn-next is-last"
              disabled=""
              aria-label="Go to next page"
              aria-disabled="true"
            >
              <i class="el-icon"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"
                  ></path></svg
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div class="mask animate__animated hidde" style="display: none">
        <div class="popupContent" style="display: none">
          <img class="close" src="/img/close.10e4447a.png" alt="" />
          <div class="ruleContent">
            <div class="ruleTitle">返佣说明</div>
            <div class="text">
              <p>1、返利比例 (邀请者)按照单个(被邀请者)用户充值金额计算;</p>
              <p>2、满足对应等级后，再充值金额按照相应比例返利;</p>
              <p>3、返佣比例自动发放至邀请者或被邀请者余额中;</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["showNav"],
  data() {
    return {
      level: [
        {
          createTime: "2024-04-16 12:51:40",
          id: 1,
          level: 1,
          levelRateInvite: 1.5,
          levelRateAccept: 0.5,
          minRecharge: 100.0,
          maxRecharge: 999.0,
          remark: "1级返利",
          useFlag: "1",
        },
        {
          createTime: "2024-04-16 12:51:49",
          id: 3,
          level: 2,
          levelRateInvite: 2.0,
          levelRateAccept: 0.5,
          minRecharge: 500.0,
          maxRecharge: 999.0,
          remark: "2级返佣",
          useFlag: "1",
        },
        {
          createTime: "2024-04-16 12:52:09",
          id: 4,
          level: 3,
          levelRateInvite: 2.5,
          levelRateAccept: 0.5,
          minRecharge: 1000.0,
          maxRecharge: 4999.0,
          remark: "3级返佣",
          useFlag: "1",
        },
        {
          createTime: "2024-04-16 12:53:29",
          id: 5,
          level: 4,
          levelRateInvite: 3.0,
          levelRateAccept: 0.5,
          minRecharge: 5000.0,
          maxRecharge: 9999.0,
          remark: "4级返佣",
          useFlag: "1",
        },
        {
          createTime: "2024-04-16 12:54:01",
          id: 6,
          level: 5,
          levelRateInvite: 3.5,
          levelRateAccept: 0.5,
          minRecharge: 10000.0,
          maxRecharge: 49999.0,
          remark: "5级返佣",
          useFlag: "1",
        },
        {
          createTime: "2024-04-16 12:54:39",
          id: 7,
          level: 6,
          levelRateInvite: 4.0,
          levelRateAccept: 0.5,
          minRecharge: 50000.0,
          maxRecharge: 99999.0,
          remark: "6级返佣",
          useFlag: "1",
        },
      ],
      valueUrl: "",
      valueCode: "",
      people_num: 0,
      valid_user:0,
      commission:0,
      ratio: 0,
      invite_commission: 0,
    };
  },
  mounted() {
    this.getInviteLink();
  },
  methods: {
    //   获取邀请链接
    getInviteLink() {
      this.$axios
        .post("index/Invite/inviteInfo", this.$qs.stringify({}))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.valueUrl = data.data.invite_url;
            this.valueCode = data.data.invite_code;
          }
        });
      //   this.$axios
      //     .post("index/User/playerInfo", this.$qs.stringify(param))
      //     .then((res) => {
      //       let data = res.data;
      //       if (data.status == 1) {
      //         this.tuiguangren = data.myInviter;
      //         if (!this.tuiguangren) {
      //           this.tuiguangren = "官方";
      //         }
      //       }
      //     });
    },
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    // 获取我的下级
    getMySubordinate(days) {
      let param = {
        days: days,
      };
      this.$axios
        .post("index/Invite/offline", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            //console.log(data.data.people_num)
            if (data.data.people_num) {
              this.people_num = data.data.people_num;
            }
            if (data.data.ratio) {
              this.ratio = data.data.ratio;
            }
            if (data.data.invite_commission) {
              this.invite_commission = data.data.invite_commission;
            }
            this.valid_user = data.data.valid_user;
            this.commission = data.data.commission;
            // this.tableData1 = data.data.list;
            // for (let i = 0; i < this.tableData1.length; i++) {
            //   if (this.tableData1[i].status == 1) {
            //     this.tableData1[i].state = "已结算";
            //   } else {
            //     this.tableData1[i].state = "未结算";
            //   }
            // }
          }
        });
    },
  },
};
</script>
<style scoped lang="less">
@media screen and (min-width: 992px) {
  .pagesView {
    max-width: 1500px;
    padding: 0 1rem * 1.4;
    margin: 0 auto;
    min-height: calc(
      100vh - var(--window-headerNav-height) - var(--window-footer-height) -
        var(--window-swiper-height)
    );
  }
}
.shareBox {
  background: #0e1325;
  padding-top: 40px;
}
.BackTop .left .backBox {
  display: flex;
}
.BackTop .left .backBox .backSvg {
  width: 1.06rem * 1.4;
  height: 1.06rem * 1.4;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.25rem * 1.4;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 0.29rem * 1.4;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share {
  padding-top: 1rem * 1.4;
  padding-bottom: 3rem * 1.4;
  background-image: url("../assets/img/share/shareBg.9474246f.png");
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 37.088rem 20.516rem;
  color: #fff;
  padding: 0 0.6rem;
}

.share .BackTop {
  background: transparent;
}

.share .BackTop .left,
.share .BackTop .right {
  padding: 0;
}

.promote {
  width: 35.9rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.42rem * 1.4;
  margin-top: 0.42rem * 1.4;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.promote .left,
.promote .right {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem * 1.4 1rem * 1.4;
  box-sizing: border-box;
}

.promote .left .promoteName,
.promote .right .promoteName {
  width: 3rem * 1.4;
}

.promote .left .inputBox,
.promote .right .inputBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.13rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.33rem * 1.4;
  min-width: 7rem * 1.4;
  margin-left: 1rem * 1.4;
  flex: 1;
  padding: 0 0.38rem * 1.4 0 1.25rem * 1.4;
}

.promote .left .inputBox .copyBtn,
.promote .right .inputBox .copyBtn {
  // min-width: 3.13rem * 1.4;
  height: 1.43rem * 1.4;
  background: #6917ff;
  border-radius: 0.7rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.58rem * 1.4;
  font-weight: 700;
  margin-left: 1rem * 1.4;
  padding: 0 0.3rem * 1.4;
}

.promote .left .inputBox .copyBtn img,
.promote .right .inputBox .copyBtn img {
  width: 0.79rem * 1.4;
  margin-right: 0.2rem * 1.4;
}

.cardList {
  padding: 0.8rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.42rem * 1.4;
  margin: 0.42rem * 1.4 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.cardList .cardItem {
  width: 14.87rem * 1.4;
  min-width: 10rem;
  height: 5.44rem * 1.4;
  border-radius: 0.42rem * 1.4;
  padding: 1rem * 1.4;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin: 0.5rem * 1.4 0.05rem * 1.4;
}
.cardList .cardItem:nth-child(1) {
  background: linear-gradient(
    45deg,
    rgba(0, 255, 216, 0.4) 0%,
    rgba(0, 255, 216, 0.2) 100%
  );
}
.cardList .cardItem:nth-child(2) {
  background: linear-gradient(
    45deg,
    rgba(114, 0, 255, 0.4) 0%,
    rgba(114, 0, 255, 0.2) 100%
  );
}
.cardList .cardItem:nth-child(3) {
  background: linear-gradient(
    45deg,
    rgba(255, 222, 0, 0.4) 0%,
    rgba(255, 222, 0, 0.2) 100%
  );
}

.cardList .cardItem:after {
  width: 8.9rem * 1.4;
  height: 6.39rem * 1.4;
  display: block;
  content: "";
  background: url("../assets/img/share/picture@2X.b335482c.png") no-repeat 50% /
    cover;
  position: absolute;
  top: 0.63rem * 1.4;
  right: 0;
}

.cardList .cardItem .title {
  font-size: 0.67rem * 1.4;
  margin-bottom: 0.5rem * 1.4;
}

.cardList .cardItem .number {
  font-size: 1.25rem * 1.4;
  text-align: left;
  font-weight: 700;
}

.cardList .cardItem .number .money {
  justify-content: flex-start;
}

.cardList .cardItem .cardIcon {
  width: 2.08rem * 1.4;
  position: absolute;
  bottom: 0.3rem * 1.4;
  right: 0.8rem * 1.4;
}

.tableContent {
  padding: 0.67rem * 1.4 1rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.42rem * 1.4;
}

.tableContent .tabBox {
  display: flex;
  margin-bottom: 0.46rem * 1.4;
  position: relative;
}

.tableContent .tabBox .tabItem {
  width: 6.76rem * 1.4;
  height: 1.83rem * 1.4;
  padding: 0.2rem * 1.4 0.3rem * 1.4;
  box-sizing: border-box;
  margin-right: 0.17rem * 1.4;
  border-radius: 0.17rem * 1.4;
  font-size: 0.58rem * 1.4;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../assets/img/share/tittleBorder.fb5c5562.png") no-repeat;
  background-size: 100% 100%;
}

.tableContent .tabBox .tabItem img {
  margin-right: 0.33rem * 1.4;
  width: 0.96rem * 1.4;
}

.tableContent .tabBox .active {
  background: url("../assets/img/share/tittleActive.fba375fc.png") no-repeat;
  background-size: 100% 100%;
}

.tableContent .tabBox .rule {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem * 1.4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableContent .tabBox .rule img {
  width: 0.8rem * 1.4;
  margin-right: 0.33rem * 1.4;
}

.tableContent .table {
  border-radius: 0.17rem * 1.4;
  overflow: auto;
  font-size: 0.58rem * 1.4;
}

.tableContent .table .theader .tr {
  background: rgba(105, 23, 255, 0.2);
}

.tableContent .table .tr {
  height: 2.1rem * 1.4;
}

.tableContent .table .curLvl {
  position: relative;
  background: rgba(105, 23, 255, 0.4);
}

.tableContent .table .curLvl .level {
  color: #ff31ed;
}

.tableContent .table .date,
.tableContent .table .invitee,
.tableContent .table .myReward,
.tableContent .table .recharge {
  white-space: normal;
}

.tableContent .table .explain {
  flex-grow: 1;
}

@media screen and (max-width: 992px) {
  .share {
    padding-top: 1rem * 0.8;
    padding-bottom: 3rem * 0.8;
    background: url("../assets/img/share/shareBg.9474246f.png") no-repeat
      top/50rem * 0.8 27.63rem * 0.8;
  }

  .share .BackTop {
    background: transparent;
  }

  .share .BackTop .left,
  .share .BackTop .right {
    padding: 0;
  }

  .promote {
    width: 100%;
    box-sizing: border-box;
    background: hsla(0, 0%, 100%, 0.05);
    border-radius: 0.42rem * 0.8;
    margin-top: 0.42rem * 0.8;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1.13rem * 0.8;
  }

  .promote .left,
  .promote .right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .promote .left > span,
  .promote .right > span {
    min-width: 5rem * 0.8;
  }

  .promote .left .inputBox,
  .promote .right .inputBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.67rem * 0.8;
    background: hsla(0, 0%, 100%, 0.05);
    border-radius: 0.33rem * 0.8;
    min-width: 5rem * 0.8;
    margin-left: 1rem * 0.8;
    flex: 1;
    padding: 0 0 0 1.25rem * 0.8;
  }

  .promote .left .inputBox .copyBtn,
  .promote .right .inputBox .copyBtn {
    width: 2.71rem * 0.8;
    height: 2.71rem * 0.8;
    background: #6917ff;
    border-radius: 0.42rem * 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .promote .left .inputBox .copyBtn img,
  .promote .right .inputBox .copyBtn img {
    width: 1.38rem * 0.8;
    margin-right: 0;
  }

  .promote .left .inputBox .copyBtn span,
  .promote .right .inputBox .copyBtn span {
    display: none;
  }

  .promote .left .inputBox .ellipsis,
  .promote .right .inputBox .ellipsis {
    width: 80%;
  }

  .promote .left {
    border-right: 2px solid #1e222a;
    flex: 2;
    padding-right: 0;
    margin-bottom: 1.13rem * 0.8;
  }

  .promote .right {
    flex: 3;
    padding: 0;
  }

  .promote .right .ellipsis {
    margin: 0;
  }

  .cardList {
    padding: 0.8rem * 0.8;
    background: hsla(0, 0%, 100%, 0.05);
    border-radius: 0.42rem * 0.8;
    margin: 0.42rem * 0.8 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .cardList .cardItem {
    width: 49%;
    height: 7.07rem * 0.8;
    border-radius: 0.42rem * 0.8;
    padding: 1rem * 0.8;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.5rem * 0.8;
  }

  .cardList .cardItem:after {
    width: 8.9rem * 0.8;
    height: 6.39rem * 0.8;
    display: block;
    content: "";
    position: absolute;
    top: 0.8rem * 0.8;
    right: 0;
  }

  .cardList .cardItem .title {
    font-size: 1rem * 0.8;
    margin-bottom: 0.5rem * 0.8;
  }

  .cardList .cardItem .number {
    font-size: 1.75rem * 0.8;
    text-align: left;
    font-weight: 700;
  }

  .cardList .cardItem .number .money {
    justify-content: flex-start;
  }

  .cardList .cardItem .cardIcon {
    width: 2.55rem * 0.8;
    position: absolute;
    bottom: 0.3rem * 0.8;
    right: 0.8rem * 0.8;
  }

  .tableContent {
    padding: 0.67rem * 0.8 1rem * 0.8;
    background: hsla(0, 0%, 100%, 0.05);
    border-radius: 0.42rem * 0.8;
  }

  .tableContent .tabBox {
    display: flex;
    margin-bottom: 0.46rem * 0.8;
  }

  .tableContent .tabBox .tabItem {
    width: 8.7rem * 0.8;
    padding: 0.2rem * 0.8 0.3rem * 0.8;
    box-sizing: border-box;
    height: 3rem * 0.8;
    margin-right: 0.33rem * 0.8;
    border-radius: 0.17rem * 0.8;
    font-size: 1.08rem * 0.8;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tableContent .tabBox .tabItem img {
    margin-right: 0.33rem * 0.8;
    width: 1.33rem * 0.8;
  }

  .tableContent .tabBox .rule img {
    width: 1.3rem * 0.8;
    margin-right: 0.33rem * 0.8;
  }

  .tableContent .table {
    border-radius: 0.17rem * 0.8;
    font-size: 1rem * 0.8;
    align-items: stretch;
  }

  .tableContent .table .theader .tr {
    background: hsla(0, 0%, 100%, 0.1);
  }

  .tableContent .table .tr {
    height: 3.27rem * 0.8;
    box-sizing: border-box;
    display: inline-flex;
  }

  .tableContent .table .td {
    flex-shrink: 0;
    flex-grow: 0;
    box-sizing: border-box;
    width: 6.8rem;
  }
  .promote {
    width: 100%;
    box-sizing: border-box;
    background: hsla(0, 0%, 100%, 0.05);
    border-radius: 0.42rem;
    margin-top: 0.42rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1.13rem;
  }
}
.tr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 0.13rem * 1.4;
}
.td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.38rem * 1.4 1rem * 1.4;
  width: 5rem * 1.4;
}
.MoneyCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;
}
</style>