<template>
  <div class="container" v-if="!showNav">
    <div class="flexleft">
      <ul>
        <li
          v-for="(item, index) in yidongmenu"
          :key="index"
          @click="goNavMenu(item.id, item.path, item.value)"
        >
          <img
            :class="item.selState ? 'img1' : 'img2'"
            :src="item.selState ? item.img1 : item.img2"
            style="width: 24px"
          />
          <div
            :class="item.selState ? 'item-val-bian' : 'item-val'"
            style="font-size: 12px; margin-top: 3px"
          >
            {{ item.value }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showNav", "yidongmenu", "openHongbao"],
  methods: {
    //左侧导航
    goNavMenu(id, path, name) {
      if (name == "我的") {
        this.$parent.$parent.goMes();
        return;
      }
      if (name == "口令") {
        this.openHongbao();
        return;
      }
      for (let i = 0; i < this.yidongmenu.length; i++) {
        if (id == this.yidongmenu[i].id) {
          this.yidongmenu[i].selState = true;
        } else {
          this.yidongmenu[i].selState = false;
        }
      }
      if(this.$store.state.token!=''){
        this.$router.push({
          path: `/${path}`,
        });
      }else {
        return this.$store.commit("getLogin", true);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 75px;
}

.flexleft {
  height: 75px;
  // height: 50px;
  // position: fixed;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  // background-color: #10151b;
  background-image: url('../assets/img/tarbar-bg.png');
  background-size: 100% 100%;
  color: #848492;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;

  ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    height: 100%;
    overflow: auto;

    li {
      display: flex;
      flex-direction: column;
      height: 70px;
      align-items: center;
      width: 100px;
      justify-content: flex-start;
      color: hsla(0, 0%, 100%, 0.6);
      font-weight: 700;
      margin-top: 5px;
      box-sizing: border-box;
    }
  }
}

.item-val-bian {
  // color: rgba(254, 154, 10, 1);
  color: white;
}
</style>