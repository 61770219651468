<template>
  
  <div class="base-header" >
    <ul class="nav">
      <li class="select">充值</li>
    </ul>
    <div class="content">
      <div class="c-top">
        <span>充值金额</span>
        <span>1个硬币={{ rmb }}元人民币，盲盒娱乐，请理性消费 </span>
      </div>
      <!-- <div class="c-top1" v-if="is_new">
        <span v-if="ordinaryActivity.end_time"
          >活动时间 {{ ordinaryActivity.start_time }} 至
          {{ ordinaryActivity.end_time }}</span
        >单笔充值{{ ordinaryActivity.money }}(含)以上{{ ordinaryActivity.limit }}以下送{{
          ordinaryActivity.billie
        }}%
      </div> -->
      <div class="c-top1">
        <span v-if="ratioList && ratioList.end_time"
          >活动时间 {{ ratioList && ratioList.start_time }} 至 {{ ratioList && ratioList.end_time }}</span
        >
        单笔充值{{ ratioList.money }}(含)以上{{ ratioList.limit }}以下首充多送{{ ratioList.billie }}%
      </div>
      <div class="c-center">
        <div
          class="boxs"
          v-for="(item, index) in list.slice(0, list.length - 1)"
          :class="{ select: item.state }"
          @click="zhifuMoney(index)"
          v-if="!is_new"
        >
          <img src="../../../assets/img/money.png" style="width: 30px" alt="" />
          <span>{{ item.money }}</span>
          <div class="jiasong" v-if="item.money >= Number(ratioList.money)">
            多送{{ (Number(item.money) * Number(ratioList.billie)) / 100 }}
          </div>
        </div>
        <div
          class="boxs"
          v-for="(item, index) in list.slice(0, list.length - 1)"
          :class="{ select: item.state }"
          @click="zhifuMoney(index)"
          v-if="is_new"
        >
          <img src="../../../assets/img/money.png" style="width: 30px" alt="" />
          <span>{{ item.money }}</span>
          <div class="jiasong" v-if="item.money >= Number(ratioList.money)">
            首充多送{{ (Number(item.money) * Number(ratioList.billie)) / 100 }}
          </div>
        </div>
      </div>
      <div class="c-bottom">
        <div class="cb-box" v-if="!is_new">
          <div class="cb-content">
            <span>实际支付：</span>
            <span class="yellow">￥{{ list[selectlist].money * rmb }}</span>
          </div>
          <div class="cb-content">
            <span>实际到账：</span>
            <span
              ><img src="../../../assets/img/money.png" style="width: 30px" alt="" /><span>{{
                list[selectlist].money >= Number(ratioList.money)
                  ? Number(list[selectlist].money) + (Number(list[selectlist].money) * Number(ratioList.billie)) / 100
                  : list[selectlist].money
              }}</span></span
            >
          </div>
        </div>
        <div class="cb-box" v-else>
          <div class="cb-content">
            <span>实际支付：</span>
            <span class="yellow">￥{{ list[selectlist].money * rmb }}</span>
          </div>
          <div class="cb-content">
            <span>实际到账：</span>
            <span
              ><img src="../../../assets/img/money.png" style="width: 30px" alt="" /><span>{{
                list[selectlist].money >= Number(ratioList.money)
                  ? Number(list[selectlist].money) + (Number(list[selectlist].money) * Number(ratioList.billie)) / 100
                  : list[selectlist].money
              }}</span></span
            >
          </div>
        </div>
        <div class="cb-content">
          <span>支付方式：</span>
          <span
            @click="payMethod = 'alipay1'"
            class="zhifubao"
            :class="{
              active: payMethod == 'alipay1'
            }"
          >
            <img src="../../../assets/img/my/zfb.png" alt="" />
            <span>支付宝1</span>
          </span>
          <span
            @click="payMethod = 'alipay2'"
            class="zhifubao"
            :class="{
              active: payMethod == 'alipay2'
            }"
          >
            <img src="../../../assets/img/my/zfb.png" alt="" />
            <span>支付宝2</span>
          </span>

          <span
            @click="payMethod = 'wxpay1'"
            class="zhifubao"
            :class="{
              active: payMethod == 'wxpay1'
            }"
          >
            <img src="../../../assets/img/my/1702460429958.png" alt="" />
            <span>微信1</span>
          </span>
          <span
            @click="payMethod = 'wxpay2'"
            class="zhifubao"
            :class="{
              active: payMethod == 'wxpay2'
            }"
          >
            <img src="../../../assets/img/my/1702460429958.png" alt="" />
            <span>微信2</span>
          </span>
        </div>
        <div class="cb-chongzhi" @click="payInfo">确认充值</div>
      </div>
      <el-checkbox v-model="cheked">
        我已满18岁且具备完全行为能力，登录/注册即代表同意
        <strong>《用户协议》</strong>和<strong>《隐私条款》</strong>。
      </el-checkbox>
    </div>
    <el-dialog :visible.sync="payBox" width="320px" center top="5%" :before-close="handleClose">
      <div slot="title" class="dialog-title">
        <div>
          <img :src="require('../../../assets/img/' + payTitleIcon)" />
          <span class="title-text">{{ payTitle }}</span>
        </div>
      </div>
      <div style="min-height: 160px">
        <div id="qrcode" ref="qrcode" style="margin-left: 60px" />
      </div>
      <div class="save-qrcode">
        <el-button type="warning" @click="saveImg">保存二维码</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <img src="../../../assets/img/scan.svg" />
        <div>
          <p>{{ payFooter }}</p>
          <p>{{ payFooterDesc }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import PayMixin from '@/mixins/pay'
import Utils from './../../../assets/js/util.js'
export default {
  props: ['type'],
  mixins: [PayMixin],
  data() {
    return {
      show: false,
      // czlist: [29, 58, 99, 198, 475, 998, 1975, 4999],
      selectlist: 0,
      winState: false,
      winList: [],
      loading: false,
      payTitle: '',
      payTitleIcon: 'wechat.svg',
      payFooter: '',
      payFooterDesc: '',
      payBox: false,
      cdk: '',
      payType: 'alipay',
      list: [],
      payObj: {
        rmb: 0,
        money: 5,
        state: false
      },
      money: null,
      rmb: 0,
      ratioList: [],
      firstGiveValidate: false,
      ordinaryValidate: false,
      firstGive: {}, //新用户活动
      ordinaryActivity: {}, //非新用户充值活动
      is_new: false, //是否是新用户
      userInfo: {},
      payInfoData: {
        flag: false,
        type: '',
        checked2: false,
        checked1: false
      }, // 充值提示
      // cdk兑换的皮肤
      cdk_skin: {},
      cheked: false
    }
  },
  mounted() {
    this.amountList()
    this.payBox = false
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.userInfo = userInfo
    if (this.$route.query.type) {
      this.payType = this.$route.query.type
    }

    // setTimeout(() => {
    //   this.show = true
    // }, 1000)
    // console.log(this.show,'你好')
  },
  watch: {
    selectlist: {
      handler(val) {
        if (!this.list[val]) {
          this.list[val] = {}
        }
      },
      immediate: true
    }
  },
  methods: {
    //子组件修改父组件
    winexchange(msg) {
      this.winState = msg
    },
    winget(msg) {
      this.winState = msg
    },
    winX(msg) {
      this.winState = msg
    },
    //充值送多少
    // getRatio() {
    //   this.$axios.get("index/User/giveAboutRecharge").then((res) => {
    //     let data = res.data;
    //     console.log(data);
    //     if (data.status == 1) {
    //       this.ratioList = data.data;
    //     }
    //   });
    // },
    // 跳转到个人账单
    goToBill() {
      this.$router.push({
        path: `/Bill`
      })
    },
    // 联系客服
    concact() {
      // http://wpa.qq.com/msgrd?v=3&uin=2484784479&site=qq&menu=yes
      // tencent://message/?uin=1187276773&Site=&Menu=yes
      // window.open("http://wpa.qq.com/msgrd?v=3&uin=1064097081&site=qq&menu=yes");
      window.open(
        'https://qm.qq.com/cgi-bin/qm/qr?k=TFB6vBuBWEJpO1H8VADjLQZP9PpF8OUM&jump_from=webapi&authKey=tUy1AIU9X4U0Ie5WNGGDvFFi7SSs7is4vZ0qeHg8iu6KUWm5nMHMYVwly29zHVFB'
      )
    },
    //input框
    getInput(value) {
      //  /^(0\.0[1-9]|0\.[1-9]\d|[1-9]\d?(\.\d\d)?|[1-4]\d\d(\.\d\d)?|500)$/
      if (value == '') {
        this.list[6].money = ''
        this.list[6].rmb = 0
      } else {
        this.list[6].money = value
        this.list[6].rmb = (value * this.rmb).toFixed(2)
      }
      value = Number(value)

      let money = Number(this.ratioList.money)
      let limit = Number(this.ratioList.limit)
      if (money > 0 && limit > 0) {
        if (value >= money && value <= limit) {
          this.setTrue()
        } else {
          this.setFalse()
        }
      } else if (money == 0 && limit == 0) {
        this.setTrue()
      } else if (money > 0 && limit == 0) {
        console.log(value >= money)
        if (value >= money) {
          this.setTrue()
        } else {
          this.setFalse()
        }
      } else if (money == 0 && limit > 0) {
        if (value <= limit) {
          this.setTrue()
        } else {
          this.setFalse()
        }
      } else {
        this.setFalse()
      }
    },
    CDKInput(e) {
      this.CDK = e.detail.value
    },
    setTrue() {
      this.list[this.list.length - 1].is_first_give = true
      this.list[this.list.length - 1].billieState = true
      this.list[this.list.length - 1].billie = this.ratioList.billie
    },
    setFalse() {
      this.list[this.list.length - 1].is_first_give = false
      this.list[this.list.length - 1].billieState = false
      this.list[this.list.length - 1].billie = 0
    },
    //加
    addInput() {
      if (this.list[6].money < 5) {
        this.list[6].money = 5.0
        this.list[6].rmb = 5.0 * this.rmb
      } else {
        this.list[6].money = (Number(this.list[6].money) + 1).toFixed(2)
        this.list[6].rmb = (this.list[6].money * this.rmb).toFixed(2)
      }
      // console.log(11)
      for (let i = 0; i < this.ratioList.length; i++) {
        if (this.list[6].money >= Number(this.ratioList[i].money)) {
          this.list[6].billie = this.ratioList[i].billie
          this.list[6].billieState = true
        } else {
          this.list[6].billieState = false
        }
      }
    },
    //减
    remInput() {
      if (this.list[6].money <= 5) {
        return
      }
      this.list[6].money = ((this.list[6].money * 100 - 1 * 100) / 100).toFixed(2)
      this.list[6].rmb = (this.list[6].money * this.rmb).toFixed(2)
      for (let i = 0; i < this.ratioList.length; i++) {
        if (this.list[6].money >= Number(this.ratioList[i].money)) {
          this.list[6].billie = this.ratioList[i].billie
          this.list[6].billieState = true
        } else {
          this.list[6].billieState = false
        }
      }
    },
    amountList() {
      let param = {
        player_id: localStorage.getItem('id')
      }
      this.$axios.post('index/User/giveAboutRecharge', this.$qs.stringify(param)).then((res) => {
        let data = res.data
        this.is_new = data.data.new
        if (data.status == 1) {
          console.log(data.data)
          // this.ratioList = data.data.giveInfo;
          // if(data.data.firstGive){
          //   this.firstGiveValidate = true;
          //   this.firstGive = data.data.firstGive;
          // }

          // 代表是首充
          this.firstGiveValidate = data.data.new
          const type = this.firstGiveValidate ? 1 : 2
          const recharge_activity = data.data.recharge_activity
          // if (recharge_activity.length > 0) {
          //   const target = recharge_activity.find((item) => item.type == type)
          //   this.ratioList = target
          //   this.firstGive = target
          //   // data.data.recharge_activity.forEach((e) => {
          //   //   if (e.type == 1) {
          //   //     this.firstGiveValidate = true;
          //   //     this.ratioList = e;
          //   //     this.firstGive = e;
          //   //   }
          //   //   if (e.type == 2) {
          //   //     this.firstGiveValidate = false;
          //   //     this.ratioList = e;
          //   //     this.ordinaryActivity = e;
          //   //   }
          //   // });
          // }

          // 代表是首充
          if (this.is_new) {
            const recharge_activity = data.data.recharge_activity;
            if (recharge_activity.length > 0) {
              const target = recharge_activity.find((item) => item.type == 1);
              this.ratioList = target;
              this.firstGive = target;
              data.data.recharge_activity.forEach((e) => {
                if (e.type == 1) {
                  this.firstGiveValidate = true;
                  this.ratioList = e;
                  this.firstGive = e;
                }
                if (e.type == 2) {
                  this.firstGiveValidate = false;
                  this.ratioList = e;
                  this.ordinaryActivity = e;
                }
              });
            }
          } else {
            this.firstGiveValidate = false;
            if (data.data.recharge_activity.length > 0) {
              data.data.recharge_activity.forEach((e) => {
                if (e.type == 2) {
                  this.ratioList = e;
                  this.ordinaryActivity = e;
                }
              });
            }
          }
        }
        // 获取充值列表
        this.$axios.get('/index/pay/chargeInfoList').then((res) => {
          let data = res.data
          if (data.status == 1) {
            this.rmb = data.data.exchange_rate
            this.payObj.rmb = data.data.exchange_rate * 5
            let list = res.data.data.list
            list.push(this.payObj)
            list.forEach(function (item, index) {
              item.state = false
              if (index == 0) {
                item.state = true
              }
            })
            this.list = list
            // console.log(this.list);
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i] && this.ratioList) {
                let money = Number(this.list[i].money) //当前充值列表金额
                let lower_limit = Number(this.ratioList.money) //后台设定下限
                let upper_limit = Number(this.ratioList.limit) //后台设定上限
                // console.log(money,lower_limit,upper_limit);
                if (lower_limit > 0 && upper_limit > 0) {
                  if (money >= lower_limit && money <= upper_limit) {
                    this.list[i].billie = this.ratioList.billie
                    this.list[i].billieState = true
                  }
                }
                if (lower_limit == 0 && upper_limit == 0) {
                  this.list[i].billie = this.ratioList.billie
                  this.list[i].billieState = true
                }
                if (lower_limit > 0 && upper_limit == 0) {
                  if (money >= lower_limit) {
                    this.list[i].billie = this.ratioList.billie
                    this.list[i].billieState = true
                  }
                }
                if (lower_limit == 0 && upper_limit > 0) {
                  if (money <= upper_limit) {
                    this.list[i].billie = this.ratioList.billie
                    this.list[i].billieState = true
                  }
                }
              }
            }
            console.log(this.list,'充值列表')
          }
        })
        // console.log(this.ratioList);
        // console.log(this.ordinaryActivity);
        if (!this.empty(this.ordinaryActivity)) {
          this.ordinaryValidate = true
        }
      })
    },

    //领取红包
    getcdk() {
      if (!this.cdk) {
        this.$message({
          message: '请输入cdk口令',
          type: 'warning'
        })
        return
      }
      // this.hongbaoState1 = false;
      // this.hongbaoState2 = true;
      let param = {
        password: this.cdk
      }
      this.$axios.post('index/Activity/cdk', this.$qs.stringify(param)).then((res) => {
        let data = res.data

        if (data.status == 1) {
          this.$message({
            message: 'CDK成功兑换',

            type: 'success'
          })
        } else {
          this.$message({
            message: data.msg,
            type: 'warning'
          })
        }
        // 皮肤
        if (data.data && data.data[0].id) {
          this.cdk_skin = data.data[0]
          this.winList.skins_info = []
          this.winList.skins_info[0] = data.data[0]
          this.winList.skins_info[0]['background'] = require('../../../assets/img/openBox-blue.png')
          this.openBoxState = false
          this.winState = true
        } else if (data.data) {
          this.money = 1
        }
      })
    },

    empty(obj) {
      for (let key in obj) {
        return false //非空
      }
      return true //为空
    },
    epayjump() {
      this.$router.push = 'https://gdskins.com/SDK/index.php'
    },
    payInfo(t) {
      console.log(this.cheked)
      if (!this.cheked) {
        this.$message({
          //message: res.data.msg,
          message: '请阅读并勾选所有协议',
          type: 'warning'
        })
        return
      }
      let money = 0
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state) {
          money = this.list[i].money
        }
      }
      console.log(Number(money))
      this.payAction(t)
      return
      if (this.userInfo.total_amount == '0.00' || money > 199) {
        this.payInfoData.flag = true
        this.payInfoData.type = t
      } else {
        this.payAction(t)
      }
    },
    checkPayInfo() {
      if (this.payInfoData.checked1 && this.payInfoData.checked2) {
        if (this.payInfoData.type) {
          this.payInfoData.flag = false
          this.payAction(this.payInfoData.type)
        }
      } else {
        this.$message.info('请阅读并勾选所有协议')
      }
    },
    payAction(t) {
      if (this.list[6].money < 5 && this.list[6].state == true) {
        this.$message({
          message: '充值Z币不少于5个',
          type: 'warning'
        })
        return
      }

      const _this = this
      // 新版充值
      this.createOrder({
        money: this.list.filter((i) => i.state).reduce((result, curr) => result + parseFloat(curr.money), 0),
        mobile: this.userInfo.mobile,
        callback(payLink) {
          if (_this.payMethod.startsWith('wxpay')) {
            _this.loading = true
            _this.payTitle = '微信支付'
            _this.payTitleIcon = 'wechat.svg'
            _this.payFooter = '请使用微信或支付宝'
            _this.payFooterDesc = '扫描二维码完成支付'
          } else {
            _this.loading = true
            _this.payTitle = '支付宝支付'
            _this.payTitleIcon = 'alipay.svg'
            _this.payFooter = '请使用微信或支付宝'
            _this.payFooterDesc = '扫描二维码完成支付'
          }

          _this.payBox = true
          _this.loading = false
          _this.$nextTick(() => {
            const qrCode = new QRCode('qrcode', {
              width: 160,
              height: 160,
              text: payLink
            })
          })
        }
      })
      return

      if (t == 'wechat') {
        this.loading = true
        this.payTitle = '微信支付'
        this.payTitleIcon = 'wechat.svg'
        this.payFooter = '请使用微信或支付宝'
        this.payFooterDesc = '扫描二维码完成支付'
        this.pay('weixin')
        return
      }
      this.loading = true
      this.payTitle = '支付宝支付'
      this.payTitleIcon = 'alipay.svg'
      this.payFooter = '请使用微信或支付宝'
      this.payFooterDesc = '扫描二维码完成支付'
      this.pay('zhifubao')
    },
    pay(type) {
      let money = 0
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state) {
          money = this.list[i].money
        }
      }

      let _this = this
      let data = {
        mode: type,
        money: money,
        mobile: this.userInfo.mobile
      }

      _this.$axios.post('/index/Pay/recharge', data).then((res) => {
        let data = res.data
        this.loading = false
        if (data.status == 1) {
          //支付宝跳转
          if (this.isMobile()) {
            //移动端支付宝操作
            if (type == 'zhifubao') {
              window.location = res.data.data
              return
            }
          }

          //this.$refs.qrcode.innerHTML = "";
          this.payBox = true
          this.loading = false
          this.$nextTick(() => {
            const qrCode = new QRCode('qrcode', {
              width: 160,
              height: 160,
              text: res.data.data
            })
          })
        } else {
          if (type == 'zhifubao') {
            this.$message({
              message: '支付宝支付维护中，请使用微信充值',
              type: 'warning'
            })
          }
          this.$message({
            //message: res.data.msg,
            message: '微信支付维护中，请使用支付宝充值',
            type: 'warning'
          })
        }
      })
    },
    handleClose() {
      this.payBox = false
      this.$refs.qrcode.innerHTML = ''
    },
    saveImg() {
      var canvasData = this.$refs.qrcode.getElementsByTagName('canvas')
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      a.href = canvasData[0].toDataURL('image/png')
      a.download = '支付二维码'
      a.dispatchEvent(event)
    },
    weixinMoney(index) {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false
      }
      this.list[index].state = true
    },
    zhifuMoney(index) {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false
      }
      this.list[index].state = true
      this.selectChange(index)
    },
    epayMoney(index) {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false
      }
      this.list[index].state = true
    },
    isMobile() {
      let userAgentInfo = navigator.userAgent
      let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
      let getArr = Agents.filter((i) => userAgentInfo.includes(i))
      return getArr.length ? true : false
    },

    selectChange(index) {
      this.selectlist = index
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  background-color: #333542;
}

.base-header {
  font-family: '微软雅黑';

  .nav {
    height: 42px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;

    li {
      width: 110px;
      height: 48px;
      line-height: 48px;
      color: white;
      margin-right: 40px;
      text-align: center;
      padding: 0 24px;
    }

    .select {
      color: #3088ff !important;
      border-bottom: 2px solid #3088ff;
    }

    li:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .select:hover {
      background: rgba(105, 224, 255, 0.1);
    }
  }

  .content {
    width: 100%;
    min-height: 600px;
    background: rgba(71, 201, 243, 0.14);
    border-radius: 4px;
    padding: 20px 25px;
    margin-top: 32px;

    .c-top {
      span:first-child {
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        line-height: 21px;
      }

      span:last-child {
        font-size: 11px;
        font-weight: 400;
        color: #adb8ba;
        line-height: 16px;
        margin-left: 5px;
      }
    }

    .c-top1 {
      font-size: 15px;
      font-weight: 400;
      color: rgba(213, 177, 14, 1);
      line-height: 21px;
    }

    .c-center {
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;

      .boxs {
        width: 152px;
        height: 102px;
        background-image: url('../../../assets/img/my/content.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
        margin-bottom: 20px;
        border: 2px solid #142a40;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .jiasong {
          position: absolute;
          top: 0;
          height: 25px;
          line-height: 25px;
          font-weight: 400;
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          text-align: center;
        }

        span {
          margin-left: 6px;
        }
      }

      .select {
        background-image: url('../../../assets/img/my/content1.png');
      }
    }

    .c-bottom {
      color: white;
      font-size: 18px;

      .cb-box {
        display: flex;
        align-items: center;

        .cb-content:first-child {
          margin-left: 0;
        }

        .cb-content {
          margin-left: 32px;

          span {
            display: flex;
            align-items: center;
            margin-left: 0;
          }

          span span:last-child {
          }
        }
      }

      .cb-chongzhi {
        margin-top: 32px;
        background: rgb(101, 108, 157);
        width: 120px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .cb-content {
        display: flex;
        align-items: center;
        margin-top: 32px;

        span:last-child {
          // margin-left: 12px;
        }
        .active {
          text-shadow: 0 0 2px rgba(71, 201, 243, 0.55);
          box-shadow: 0 0 10px 0 rgba(52, 164, 225, 1);
          border: 1px solid rgba(52, 164, 225, 1);
        }
        .zhifubao {
          display: flex;
          align-items: center;
          width: 158px;
          height: 44px;
          background: #141b25;
          border-radius: 8px;
          border: 1px solid #adb8ba;
          font-size: 17px;
          font-weight: 500;
          margin: 0 5px;
          color: #fff;
          line-height: 24px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
          }
        }

        .yellow {
          font-size: 24px;
          font-weight: 500;
          color: #fc0;
          line-height: 33px;
        }
      }
    }

    .el-checkbox {
      margin-top: 32px;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      color: rgb(71, 201, 243);
    }

    /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
      color: rgb(255, 255, 255);
      opacity: 0.8;
    }

    /deep/.el-checkbox__label {
      color: rgb(255, 255, 255);
      opacity: 0.8;

      strong {
        font-weight: 600;
        color: rgb(133, 203, 255);
      }
    }
  }
}
</style>
