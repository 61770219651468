<template>
  <div class="comeback routerView">
    <div class="pagesView">
      <Back></Back>
      <div class="content">
        <img
          v-show="showNav"
          class="_windows"
          src="https://lucsbox.oss-cn-hangzhou.aliyuncs.com/2024/05/27/1716803518218qJFYt.jpg"
          alt=""
        /><img
          v-show="!showNav"
          class="_mobile"
          src="https://lucsbox.oss-cn-hangzhou.aliyuncs.com/2024/05/27/1716803521169SVBOb.jpg"
          alt=""
        />
        <div class="rule">
          规则：当日充值金额 - 当日掉落价值 - 账户余额后亏损部分有效；
        </div>
      </div>
      <div class="btn _btnClick1" @click="getComeBack">领取</div>
    </div>
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
  </div>
</template>
<script>
import Back from "@/components/back.vue";
import navheader from "@/components/navheader.vue";
export default {
  components: { Back, navheader },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getComeBack() {
      this.$axios.post("/index/activity/czls", {}).then((res) => {
        var data = res.data;
        if (data.status == "1") {
          this.$message({
            showClose: true,
            message: data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.pagesView {
  max-width: 1500px;
  padding: 0 1rem * 1.5;
  min-height: calc(
    100vh - var(--window-headerNav-height) - var(--window-footer-height) -
      var(--window-swiper-height)
  );
  margin: 0 auto;
}
.comeback {
  padding-bottom: 3rem * 1.5;
  background: none;
  background: #0e1325;
  color: #fff;
}

.comeback .pagesView {
  padding-top: 1.33rem * 1.5;
}

.comeback .pagesView .content {
  position: relative;
}

.comeback .pagesView .content img {
  width: 100%;
  height: auto;
}

.comeback .pagesView .content .rule {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  left: 51%;
  bottom: 2rem * 1.5;
  font-size: 0.8rem * 1.5;
  font-weight: 700;
}

.btn {
  width: 10rem * 1.5;
  height: 2rem * 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6917ff;
  font-size: 1rem * 1.5;
  font-weight: 700;
  border-radius: 0.42rem * 1.5;
  color: #fff;
  margin: 1rem * 1.5 auto;
}

.notGive {
  background: #979ca7;
  cursor: not-allowed;
}

@media screen and (max-width: 992px) {
  .comeback {
    padding-bottom: 3rem * 0.8;
    background: #080d21 url("../assets/img/detailBg.bd65f729.png") no-repeat;
    background-size: contain;
  }

  .comeback .pagesView .content img {
    width: 100%;
    height: auto;
  }

  .comeback .pagesView .content .rule {
    font-size: 1.2rem * 0.8;
    width: 100%;
    padding: 0 3rem * 0.8;
    box-sizing: border-box;
    bottom: 1rem * 0.8;
  }

  .btn {
    width: 80%;
    height: 3.5rem * 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem * 0.8;
    font-weight: 700;
    border-radius: 0.42rem * 0.8;
    margin: 1rem * 0.8 auto;
    background: #6917ff;
    color: #fff;
  }

  .notGive {
    background: #979ca7;
    cursor: not-allowed;
  }
}
</style>