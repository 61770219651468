<template>
  <div class="areroom">
    <div class="room-warp">
      <!-- 返回键 -->
      <back></back>
      <!-- 房间信息 -->
      <div class="roomInfo">
        <div class="info">
          <div class="avatar">
            <img :src="roomData.img" alt="" class="img" />
            <div class="avatar-type">
              <img src="../assets/img/anchor.748fa088.png" alt="" class="bo" />
              <!-- <img
                src="../assets/img/anchor.748fa088.png"
                alt=""
                class="bo"
                v-else-if="roomData.type == 2"
              /> -->
              <div class="type-name">
                {{ roomData.type == 1 ? "官方" : "主播" }}
              </div>
            </div>
          </div>
          <div class="num" v-if="width">
            <div class="price">
              <img src="../assets/img/icon1.28d9519f.png" alt="" />
              <span class="all">
                <span class="zi">总价值</span
                ><span>{{ roomData.pool ? roomData.pool : 0 }}</span>
              </span>
            </div>
            <div class="price" style="">
              <img src="../assets/img/icon2.bb8cbea2.png" alt="" />
              <span class="all">
                <span class="zi">物品</span
                ><span>{{ roomData.count ? roomData.count : 0 }}</span>
              </span>
            </div>
            <div class="price">
              <img src="../assets/img/icon3.92e9d743.png" alt="" />
              <span class="all">
                <span class="zi">玩家</span
                ><span>{{
                  roomData.person_num ? roomData.person_num : 0
                }}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="other">
          <div class="title">
            <span>{{ roomData.room_name }}</span>
          </div>
          <div class="row1">
            参与条件：<span>{{ roomData.desc }}</span>
          </div>
          <div class="row2">
            <div class="left">
              <img src="../assets/img/time.10db5c48.png" alt="" />
              <div>开奖倒计时</div>
            </div>
            <div class="right">
              <span class="s" v-if="dTime > 0">
                <span>{{ dTime }}</span>
                <span>D</span>
              </span>
              <span style="margin: 0 6px" v-if="dTime > 0">:</span>
              <span class="s">
                <span>{{ hTime }}</span>
                <span>H</span>
              </span>
              <span style="margin: 0 6px">:</span>
              <span class="s">
                <span>{{ mTime }}</span>
                <span>M</span>
              </span>
              <span style="margin: 0 6px">:</span>
              <span class="s">
                <span>{{ sTime }}</span>
                <span>S</span>
              </span>
            </div>
          </div>
          <div class="row4" v-if="!width">
            <div class="price">
              <span class="all">
                <img src="../assets/img/icon1.28d9519f.png" alt="" />
                <span class="zi">总价值</span>
              </span>
              <span>{{ roomData.pool ? roomData.pool : 0 }}</span>
            </div>
            <div class="price" style="">
              <span class="all">
                <img src="../assets/img/icon2.bb8cbea2.png" alt="" />
                <span class="zi">物品</span>
              </span>
              <span>{{ roomData.count ? roomData.count : 0 }}</span>
            </div>
            <div class="price">
              <span class="all">
                <img src="../assets/img/icon3.92e9d743.png" alt="" />
                <span class="zi">玩家</span>
              </span>
              <span>{{ roomData.person_num ? roomData.person_num : 0 }}</span>
            </div>
          </div>
          <!-- 状态1可以加入 2结束 -->
          <div class="row3 none" v-if="status && roomData.status != 2">
            <img src="../assets/img/roll/roll2.8c3c3b66.png" alt="" />
            <span>已参与</span>
          </div>
          <div class="row3 in-join" v-if="!status && roomData.status != 2" @click="addRoom">
            <img src="../assets/img/roll/roll.f5e106d1.png" alt="" />
            <span>加入房间</span>
          </div>
          <div class="row3 prohibit" v-if="roomData.status == 2">
            <img src="../assets/img/roll/roll2.8c3c3b66.png" alt="" />
            <span>已结束</span>
          </div>
        </div>
      </div>
      <!-- 获奖名单、 -->
      <div class="awrdLst" v-if="roomData.status == 2">
        <div class="listTitle">
          <img
            class="titleImg"
            src="../assets/img/roll/title3@2x.9a2bb515.png"
            alt=""
          />
        </div>
        <div class="list winList">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="winCard"
            :class="{ top3: index + 1 <= 3 }"
            :style="{ '--borderColor': index + 1 == 1 ? '#ca002b' : '#cfcfcf' }"
          >
            <div class="card-img">
              <div class="img-price">
                <div class="MoneyCoin">
                  <img src="../assets/img/money.png" alt="" />
                  <span class="moneyVal" style="color: rgb(255, 49, 237)">{{
                    item.price
                  }}</span>
                </div>
              </div>
              <div class="win-img">
                <img
                  class="img-go"
                  v-if="index + 1 == 1"
                  src="../assets/img/roll/circle1.56126c2c.png"
                />
                <img
                  class="img-go"
                  v-else
                  src="../assets/img/roll/circle4.80e5f285.png"
                />
                <img class="img-go-up" :src="item.skin_img" />
              </div>
              <div class="card-title">{{ item.skin_name }}</div>
              <div class="card-degree">{{ item.exteriorName }}</div>
              <div class="user-info-head">
                <div class="user-info">
                  <div
                    class="user-card-img"
                    :style="{
                      'background-image': `url(${item.player_img})`,
                    }"
                  ></div>
                  <div class="user-card-name ellipsis">
                    {{ item.player_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="roll-room-load-top">
          <div class="load-more">
            <div class="load-more-load-top">
              <div
                class="load-more-load"
                v-if="!noMoreData"
                @click="loadMore"
                :disabled="loading"
              >
                {{ loading ? "加载中..." : "加载更多" }}
                <div class="image-container">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAKCAYAAAC9vt6cAAAAAXNSR0IArs4c6QAAAItJREFUKFNjZGBgYJg7d6kjiE5Ojt4PogkBZPWM82cvdvjPwADWyMjAUJ2YGtuGz4B5s5dUMzD8b4Gqd0QxgJAhyJrhBoAY6BIMDIw1SakxrcguwaIZ7FpGmCJ8huDSDHUFwh5shkBkIX7G5kW4C3C7BGEBtkDGMAB7mOCOIawGoBuCL3pxGkBsAgMAh2xSRaHJUkYAAAAASUVORK5CYII="
                    alt=""
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAKCAYAAAC9vt6cAAAAAXNSR0IArs4c6QAAAItJREFUKFNjZGBgYJg7d6kjiE5Ojt4PogkBZPWM82cvdvjPwADWyMjAUJ2YGtuGz4B5s5dUMzD8b4Gqd0QxgJAhyJrhBoAY6BIMDIw1SakxrcguwaIZ7FpGmCJ8huDSDHUFwh5shkBkIX7G5kW4C3C7BGEBtkDGMAB7mOCOIawGoBuCL3pxGkBsAgMAh2xSRaHJUkYAAAAASUVORK5CYII="
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 获奖名单结束 -->
      <!-- 包含奖品开始 -->
      <div class="includingPrizes">
        <div class="listTitle">
          <img
            class="titleImg"
            src="../assets/img/roll/title1@2x.eb8c0099.png"
            alt=""
          />
        </div>
        <div class="list cardList">
          <div
            class="roll-room-list-card"
            v-for="(item, index) in list"
            :key="index"
          >
            <div
              class="card-img"
              :style="{
                'background-image': `url(${getBgColor(item.color)})`,
              }"
            >
              <div class="card-title">{{ item.name }}</div>
              <div class="card-degree">{{ item.exteriorName }}</div>
              <img class="img-go" :src="item.img" />
              <div class="img-price">
                <div class="MoneyCoin">
                  <img src="../assets/img/money.png" alt="" />
                  <span class="moneyVal" style="color: rgb(255, 49, 237)">{{
                    item.price
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="roll-room-list-card">
            <div
              class="card-img"
              style="background-image: url('/img/skinbg4.c3e49d82.png')"
            >
              <div class="card-title">2.88金币</div>
              <div class="card-degree"></div>
              <img
                class="img-go"
                src="https://lucsbox.oss-cn-hangzhou.aliyuncs.com/00/01/wKiLbmGMkwyAeFbYAAHfgHiWzLs177.png"
              />
              <div class="img-price">
                <div class="MoneyCoin">
                  <svg
                    data-v-0f4c779f=""
                    class="svg-icon"
                    aria-hidden="true"
                    style="color: rgb(255, 49, 237)"
                  >
                    <use data-v-0f4c779f="" xlink:href="#icon-A8"></use></svg
                  ><span class="moneyVal" style="color: rgb(255, 49, 237)"
                    >2.88</span
                  >
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="paging" style="">
          <div class="el-pagination">
            <button
              type="button"
              class="btn-prev is-first"
              disabled=""
              aria-label="Go to previous page"
              aria-disabled="true"
            >
              <i class="el-icon"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z"
                  ></path></svg
              ></i>
            </button>
            <ul class="el-pager">
              <li
                class="is-active number"
                aria-current="true"
                aria-label="page 1"
                tabindex="0"
              >
                1
              </li>
              <li
                class="number"
                aria-current="false"
                aria-label="page 2"
                tabindex="0"
              >
                2
              </li>
              <li
                class="number"
                aria-current="false"
                aria-label="page 3"
                tabindex="0"
              >
                3
              </li>
            </ul>
            <button
              type="button"
              class="btn-next is-last"
              aria-label="Go to next page"
              aria-disabled="false"
            >
              <i class="el-icon"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"
                  ></path></svg
              ></i>
            </button>
          </div>
        </div> -->
      </div>
      <!-- 包含奖品结束 -->
      <!-- 参与用户开始 -->
      <div class="participatingUsers">
        <div class="listTitle">
          <img
            class="titleImg"
            src="../assets/img/roll/title2@2x.33670725.png"
            alt=""
          />
        </div>
        <div class="list userList">
          <div
            class="roll-room-user-card"
            v-for="(item, index) in player_list"
            :key="index"
          >
            <img class="card-img" :src="item.img" alt="" />
            <div class="card-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 参与用户结束 -->
    </div>

    <div class="pass-box" v-if="passState">
      <span class="pass-hint" @click="hidePass"
        ><i class="el-icon-close"></i
      ></span>
      <div class="pass-title">输入房间口令</div>
      <div class="input">
        <input type="text" v-model="password" />
      </div>
      <div class="pass-btn">
        <el-button type="warning" @click="passAddRoom">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import back from "@/components/back.vue";
export default {
  components: {
    back,
  },
  data() {
    return {
      password: "",
      passState: false,
      id: "",
      roomData: {},
      list: [],
      player_list: [],
      price: 0,
      player_skin: [],
      player_skin_price: 0,
      status: false,
      selectType: 1,
      time: "",
      dTime: 0,
      hTime: 0,
      mTime: 0,
      sTime: 0,
      timer: 0,
      total: "",
      width: true,
      items: [],
      noData: true,
      noMoreData: false,
      limit: 12,
      offset: 0,
      loading: false,
      red: require("../assets/img/skinbg1.535a460d.png"),
      grey: require("../assets/img/skinbg4.c3e49d82.png"),
      // orange: require("../assets/img/skinbg0.cadc763a.png"),
      gold: require("../assets/img/skinbg0.cadc763a.png"),
      blue: require("../assets/img/skinbg3.14609471.png"),
      purple: require("../assets/img/skinbg2.8216810b.png"),
    };
  },
  async mounted() {
    if (document.body.clientWidth < 600) {
      this.width = false;
    }
    //console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;
    // this.getRoomList();
    await this.getRoomList();
    this.timer = setInterval(() => {
      this.getTime(this.time);
    }, 1000);
  },
  methods: {
    getTime(time) {
      let time1 = Date.parse(time);
      let date = new Date().getTime();
      let data = time1 - date;
      var days = Math.floor(data / (1000 * 60 * 60 * 24));
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((data % (1000 * 60)) / 1000);
      if (days < 0) {
        days = 0;
      }
      if (hours < 0) {
        hours = 0;
      }
      if (minutes < 0) {
        minutes = 0;
      }
      if (seconds < 0) {
        seconds = 0;
      }
      this.dTime = days;
      this.hTime = hours;
      this.mTime = minutes;
      this.sTime = seconds;
      //   console.log(hours,minutes,seconds,'timeeeee')
    },
    changeSelectType(e) {
      this.selectType = e;
    },
    //加入房间
    addRoom() {
      if (
        this.roomData.condition_type == 1 ||
        this.roomData.condition_type == 3
      ) {
        this.passState = true;
        return;
      } else {
        var param = {
          free_room_id: this.id,
        };
      }

      this.$axios
        .post("index/Free/addFreeRoom", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getRoomList();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //隐藏密码框
    hidePass() {
      this.passState = false;
    },
    //密码加入房间
    passAddRoom() {
      let param = {
        free_room_id: this.id,
        password: this.password,
      };
      this.$axios
        .post("index/Free/addFreeRoom", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getRoomList();
            this.passState = false;
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //房间数据
    getRoomList() {
      let param = {
        free_room_id: this.id,
      };
      this.$axios
        .post("index/Free/freeRoomDetail", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.player_list = data.data.player_list;
            this.player_list.forEach((e) => {
              console.log(e);
              if (e.id == JSON.parse(localStorage.getItem("userInfo")).id) {
                this.status = true;
              }
            });
            this.roomData = data.data;
            this.list = data.data.skin_list;
            this.time = data.data.run_lottery_time;

            if (data.data.status == 2) {
              this.player_skin = data.data.player_skin;
              //中奖记录总价
              for (let i = 0; i < this.player_skin.length; i++) {
                this.player_skin_price += Number(this.player_skin[i].price);
              }
              this.player_skin_price = Number(this.player_skin_price).toFixed(
                2
              );
              this.loadMore();
            }

            //奖池奖品总价
            /* for (let i = 0; i < this.list.length; i++) {
							  this.price += Number(this.list[i].price);
							}*/
            this.price = data.data.pool;
          }
        });
    },
    // 处理获奖名单
    loadMore() {
      this.loading = true;
      const start = this.offset;
      const end = Math.min(this.offset + this.limit, this.player_skin.length);
      if (start < this.player_skin.length) {
        this.items.push(...this.player_skin.slice(start, end));
        this.offset += this.limit;
        if (end === this.player_skin.length) {
          this.noMoreData = true;
        } else {
          this.noMoreData = false;
        }
      } else {
        this.noMoreData = true;
      }
      this.loading = false;
      this.noData = this.items.length === 0;
    },
    // 处理背景
    getBgColor(value) {
      // 把字母全部转成大写
      if (value == undefined) {
        return this.grey;
      }
      switch (value.toUpperCase()) {
        // 灰色
        case "#B2B2B2":
          // return `background: url("../assets/img/skinbg4.c3e49d82.png") no-repeat;`;
          return this.grey;
        // 红色
        case "#EB4B4B":
          // return ` background: url("../assets/img/skinbg1.535a460d.png") no-repeat;`;
          return this.red;
        // 橙色
        case "#CF6A32":
        case "#D32CE6":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.purple;
        // 金色
        case "#E4AE39":
        case "#FFD700":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.gold;
        // 蓝色
        case "#5E98D9":
        case "#4B69FF":
          // return `background: url("../assets/img/skinbg3.14609471.png") no-repeat;`;
          return this.blue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box-bottom {
  display: flex;
  background-color: rgba(0, 122, 255, 0.2);

  color: white;
  margin-top: 12px;
  border-radius: 16px;

  div {
    border-radius: 16px;
    width: 33%;
    text-align: center;
    padding: 6px 12px;
  }

  .select {
    background-color: rgba(0, 122, 255, 1);
    color: white;
    font-weight: 600;
  }
}

@media screen and (max-width: 767px) {
  .areroom {
    padding: 0 !important;
  }
  .boxlist1-bot {
    font-size: 14px !important;
  }
  .room-warp {
    padding: 0 10px !important;
    width: calc(100% - 32px) !important;
  }
  .boxlist-bot {
    padding: 10px 0px;

    ul {
      display: grid !important;
      grid-gap: 10px 0;
      justify-content: space-between;
      grid-template-columns: repeat(2, 1fr) !important;
      margin-bottom: 12px;
      gap: 10px;
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(160px, 160px));
      // gap: 8px;

      li {
        margin-left: 0 !important;
        margin-top: 12px;
        box-sizing: border-box;
        overflow: hidden;
        background: url("../assets/img/1700642832210.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-color: rgba(0, 122, 255, 0.2);
        border: 2px solid rgba(0, 122, 255, 1);
      }
    }
  }
  .listTitle .titleImg {
    width: 100% !important;
    height: auto !important;
  }
}

.boxlist-bot {
  padding: 10px 0px;

  ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    margin-bottom: 12px;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(160px, 160px));
    // gap: 8px;

    li {
      margin-top: 12px;
      margin-left: 12px;
      background: url("../assets/img/1700642832210.jpg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: rgba(0, 122, 255, 0.2);
      border: 2px solid rgba(0, 122, 255, 1);
      overflow: hidden;

      .boxlist-warp {
        // background-color: #24252f;
        border-radius: 5px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 20px);

        .boxlist1-top {
          padding: 0 20px;
          background-size: 100% 100%;
          text-align: center;
          // border-bottom: 3px solid #f1a920;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          // box-shadow: 0px 5px 10px #f1a920;
          img {
            width: 100%;
            margin-top: 20%;
            height: auto;
            cursor: pointer;
          }
        }

        .boxlist1-bot {
          padding: 8px;
          color: WHITE;
          font-weight: 600;
          text-overflow: ellipsis;
          text-align: center;
          overflow: hidden;
          font-size: 18px;
          white-space: nowrap;
          // word-wrap: break-word;
        }

        .boxlist1-bot1 {
          padding: 8px;
          display: flex;
          align-items: center;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }

          span {
            margin-left: 8px;
            color: #c3c3e2;
            font-size: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .list-pirce {
          padding: 0px 0 10px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .ico {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          color: white;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          padding: 0 4px 0 0;
          border-radius: 12px;

          img {
            width: 24px;
            margin-top: 0;
            border-radius: 12px;
            margin-right: 4px;
          }
        }

        .pirce-left {
          display: flex;
          align-items: center;
          padding-right: 10px;

          img {
            width: auto;
            height: 15px;
            margin-right: 5px;
          }

          & > div {
            flex: 1;
            color: #f4bd50;
            font-size: 14px;

            span:last-child {
              color: #c3c3e2;
            }
          }
        }

        .pirce-right {
          color: #848492;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.areroom {
  width: 100;
  height: 100vh;
  // overflow: hidden;
  overflow-y: auto;
  background-color: #06090f;

  .room-warp {
    width: 100%;
    max-width: 1500px;
    height: 100%;
    padding: 16px;
    margin: 0 auto;
  }

  .room-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-left {
      font-size: 16px;
      color: #848492;

      span {
        color: #c3c3e2;
      }
    }

    .title-right {
      font-size: 16px;
      color: #848492;

      i {
        margin-left: 10px;
      }

      span {
        margin-left: 5px;
      }
    }
  }

  .room-top {
    margin-top: 10px;
    background-color: rgba(0, 122, 255, 0.2);

    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .num {
      width: 100%;
      padding: 0 16px;
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .num-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        span {
          color: #848492;
          font-size: 10px;
        }

        span:last-child {
          margin-top: 5px;
        }

        .num-span {
          color: white;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .roomtop-left {
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #444659;

      .room-img {
        // padding-right: 20px;
        // border-right: 1px solid #444659;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          border: 1px solid #3088ff;
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        .span1 {
          margin-top: -13px;
          font-size: 13px;
          background-color: #3088ff;
          padding: 0 6px;
          border-radius: 4px;
        }

        .span2 {
          margin-top: 10px;
          color: #c3c3e2;
        }
      }

      .room-int {
        margin-left: 20px;
        color: white;

        h5 {
          font-size: 16px;
          font-weight: 600;
        }

        span {
          font-size: 14px;
          color: #848492;
        }
      }
    }
  }

  .roomtop-right {
    margin-top: 12px;

    .btn-warp {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #3088ff;
      color: #fff;
      border-radius: 5px;
      padding: 9px 22px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }

      span {
        font-size: 15px;
        color: #1a1c24;
        font-weight: 600;
      }
    }

    .btn-warp:hover {
      cursor: pointer;
    }

    .btn-warp1 {
      font-size: 15px;
      color: #c3c3e2;
    }
  }

  .win-top {
    display: flex;
    margin-top: 15px;
    align-items: center;

    h5 {
      font-size: 20px;
      font-weight: 400;
      color: #c3c3e2;
    }

    h6 {
      margin-left: 20px;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #848492;

      img {
        height: 16px;
        width: auto;
      }

      span {
        padding: 0 4px;
        color: #3088ff;
      }
    }
  }

  .room-win {
    margin-top: 30px;

    .win-con {
      margin-top: 20px;
      background-color: #2b2c37;
      padding: 16px;
      border-radius: 5px;

      ul {
        display: flex;
        flex-wrap: wrap;

        // justify-content: space-evenly;
        li {
          width: 12.5%;

          .win-warp {
            background-image: url("../assets/img/lucky.jpg");
            background-size: 100% 100%;
            margin: 2px;
            padding: 0 10px;
            background-color: #fff;
            position: relative;
            border-radius: 5px;

            span {
              position: absolute;
              top: 4px;
              left: 4px;
              background-color: rgba(0, 0, 0, 0.6);
              font-size: 12px;
              padding: 0 5px;
              border-radius: 10px;
              color: #fff;
            }

            img {
              width: 100%;
              height: auto;
              // height: 70px;
              // width: auto;
            }
          }
        }
      }
    }
  }

  .win-con1 {
    margin-top: 20px;
    background-color: #2b2c37;
    padding: 16px;
    border-radius: 5px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 14.28%;

        .win-warp {
          margin: 2px;
          background-color: #24252f;
          position: relative;
          border-radius: 5px;

          .win-warp1 {
            padding: 0 10px;
            background-image: url("../assets/img/lucky.jpg");
            background-size: 100% 100%;

            img {
              width: 100%;
              max-height: 70px;
            }
          }

          .win-name {
            margin-top: -25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }

            span {
              font-size: 14px;
              color: #c3c3e2;
              // margin-top: 5px;
              padding-bottom: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .span1 {
            position: absolute;
            top: 4px;
            left: 4px;
            background-color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            padding: 0 5px;
            border-radius: 10px;
            color: #fff;
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .room-num {
    margin-top: 30px;

    .num-list {
      margin-top: 20px;
      background-color: #2b2c37;
      padding: 16px;
      border-radius: 5px;

      ul {
        margin-top: -40px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        // padding-bottom: 22px;
        // display: grid !important;
        // grid-gap: 10px 0;
        justify-content: space-between;
        // grid-template-columns: repeat(auto-fit, minmax(21.5%,20%));
        // margin: auto;
        li {
          // margin-right: 20px;
          margin-top: 40px;
          width: 50px;
          height: 50px;
          text-align: center;
          margin-left: 4px;

          span {
            display: inline-block;
            width: 50px;
            text-overflow: ellipsis;
            text-align: center;
            overflow: hidden;
            font-size: 12px;
            white-space: nowrap;
            color: white;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .pass-box {
    position: fixed;
    top: 30%;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    padding: 16px;
    border-radius: 5px;
    background-color: #333542;

    .pass-hint {
      position: absolute;
      right: 0;
      top: 0;

      i {
        font-size: 20px;
        color: #c3c3e2;
      }

      i:hover {
        cursor: pointer;
      }
    }

    .pass-title {
      display: flex;
      justify-content: center;
      color: #c3c3e2;
      font-size: 20px;
    }

    .input {
      margin-top: 15px;
      width: 100%;

      input {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        color: #848492;
      }

      input:focus {
        border: none;
      }
    }

    .pass-btn {
      margin-top: 15px;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
.roomInfo {
  border-radius: 3px;
  width: 100%;
  height: 420px;
  margin-top: 10px;
  background-color: #13161b;
  background-image: url("../assets/img/roll-bj.7a2535fa.png");
  background-size: 100%;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  --multiple: 1.6;
  @media (max-width: 550px) {
    flex-direction: column;
    height: auto;
    background-color: #1d2245;
    background-image: url("../assets/img/roll/roll-bjMobile.f6ed3926.png");
  }
  .info {
    width: 32%;
    height: 100%;
    // background-color: red;
    padding: 0 1.2%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    @media (max-width: 550px) {
      width: 100%;
    }
    .avatar {
      width: 15.5vw;
      // width: 300px;
      height: 15.5vw;
      // background-color: red;
      background-image: url("../assets/img/rollRoomBg.af38f10d.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .avatar-type {
        position: absolute;
        bottom: 14%;
        left: 50%;
        transform: translateX(-50%);
        .type-name {
          position: absolute;
          top: 44%;

          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 18px;
          font-weight: 700;
        }
      }
      @media (max-width: 550px) {
        width: 35vw;
        height: 35vw;
        .img {
          width: 20vw !important;
          height: 20vw !important;
        }
      }
      .img {
        width: calc(6.13rem * var(--multiple));
        height: calc(6.13rem * var(--multiple));
        border-radius: 50%;
        border: 3px solid #ff31ed;
      }
      .bo {
        width: calc(4.9rem * var(--multiple));
        height: calc(1.62rem * var(--multiple));
        @media (max-width: 550px) {
          width: 20vw;
          height: 4vh;
          bottom: 5vw;
        }
      }
    }
    .num {
      display: flex;
      width: 100%;
      margin-top: 4%;
      justify-content: space-around;
      .price {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: calc(1.79rem * var(--multiple));
          height: calc(1.58rem * var(--multiple));
        }
        .all {
          display: flex;
          flex-direction: column;
          margin-left: 2px;
          font-size: 20px;
          color: #ff31ed;
          font-weight: 700;
          .zi {
            font-size: 14px;
            color: white;
          }
        }
      }
    }
  }
  .other {
    width: 30%;
    height: 100%;
    // background-color: yellow;
    margin-left: 100px;
    @media (max-width: 550px) {
      width: 100%;
      margin-left: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .title {
      font-size: 24px;
      font-weight: 700;
      margin-top: 12%;
      @media (max-width: 550px) {
        margin-top: 10px;
        font-size: 18px;
      }
    }
    .row1 {
      margin-top: 6%;
      @media (max-width: 550px) {
        margin-top: 10px;
        font-size: 14px;
      }
      span {
        color: #ff31ed;
        font-size: 14px;
      }
    }
    .row2 {
      margin-top: 6.5%;
      // height: 70px;
      height: 16%;
      // background-color: red;
      display: flex;
      align-items: center;
      @media (max-width: 550px) {
        height: 14vw;
      }
      .left {
        text-align: center;
        height: 100%;
        font-size: 14px;
        margin-right: 20px;
        padding-top: 10px;
        img {
          width: calc(1.46rem * var(--multiple));
          height: calc(1.67rem * var(--multiple));
        }
        @media (max-width: 550px) {
          padding-top: 5px;
          font-size: 13px;
          img {
            width: 26px;
            height: 36px;
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        height: 100%;
        .s {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          width: 55px;
          border-radius: 5px;
          background-color: #2b325b;
          padding: 4px 0;
          box-sizing: border-box;
          @media (max-width: 550px) {
            width: 45px;
          }
          span {
            font-size: 16px;
            @media (max-width: 550px) {
              font-size: 14px;
            }
            &:nth-child(1) {
              color: #ff31ed;
              font-weight: 700;
              font-size: 20px;
              @media (max-width: 550px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .row4 {
      width: 90%;
      height: 20%;
      // margin-top: 20px;
      .price {
        color: #f9c545;
        font-size: 16px;
        font-weight: 700;
        .zi {
          color: white;
          font-size: 14px;
          font-weight: auto;
        }
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .all {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .none {
      user-select: none;
      cursor: not-allowed !important;
    }
    .row3 {
      // margin-top: 11%;
      margin-top: 9%;
      width: 90%;
      height: 15%;
      background: #323640;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      @media (max-width: 550px) {
        height: 45px;
        font-size: 15px;
        margin-bottom: 20px;
        margin-top: 20px;
        img {
          width: 22px !important;
        }
      }
      img {
        margin-right: 5px;
        width: 35px;
        height: auto;
      }
    }
    .in-join {
      background: url("../assets/img/roll/btn1.cb5859e1.png") no-repeat 50% /
        cover;
    }
    .prohibit {
      background: url("../assets/img/roll/btn2.70f110c5.png") no-repeat;
      color: #6917ff;
      background-size: 100% 100%;
    }
    // .ccc {
    //   background: #ff31ed !important;
    //   color: black;
    // }
  }
}
@media (max-width: 764px) {
  .avatar-type {
    bottom: 0% !important;
  }
  .row4 .price img {
    width: 40px !important;
    margin-right: 2px;
  }
  .row4 .price span {
    color: #ff31ed;
  }
  .cardList .roll-room-list-card {
    width: 50% !important;
  }
  .cardList .roll-room-list-card .card-img {
    background-size: 100% 100% !important;
  }
  .winList .winCard {
    width: 45% !important;
  }
  .winList .winCard .card-img .user-info-head .user-info {
    width: 9.9rem * 0.8 !important;
    height: 2.75rem * 0.8 !important;
    border-radius: 1.38rem * 0.8 !important;
    display: flex !important;
    align-items: center !important;
    font-size: 1rem * 0.8 !important;
  }
  .winList .winCard .card-img .win-img .img-go-up {
    width: 8rem !important;
  }
  .winList .winCard:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: var(--borderColor);
    top: 2px;
    left: 0;
    z-index: 99;
  }
}
.listTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1.75rem * 1.4;
  align-items: center;
  font-size: 0.75rem * 1.4;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1.2rem * 1.4;
}
.listTitle .titleImg {
  width: 20.92rem * 1.4;
  height: 2.13rem * 1.4;
}
.winList {
  display: inline-flex;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-top: 1.2rem * 1.4;
  width: 100%;
}

.winList .winCard {
  width: calc((100% - 6rem) / 6);
  height: 11.42rem * 1.4;
  text-align: center;
  position: relative;
  margin-right: 0.7rem * 1.4;
  margin-bottom: 1.3rem * 1.4;
  border-radius: 0.42rem * 1.4;
  overflow: hidden;
  background: #1e2245;
}

.winList .winCard .card-img {
  border-radius: 5%;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 100%;
}

.winList .winCard .card-img .img-price {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.67rem * 1.4;
  margin-top: 0.6rem * 1.4;
}

.winList .winCard .card-img .win-img {
  position: relative;
  height: 5.04rem * 1.4;
  margin-top: 0.5rem * 1.4;
  margin-bottom: 0.5rem * 1.4;
}

.winList .winCard .card-img .win-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.winList .winCard .card-img .win-img .img-go {
  width: 4.83rem * 1.4;
  height: 5.04rem * 1.4;
}

.winList .winCard .card-img .win-img .img-go-up {
  width: 2.88rem * 1.4;
}

.winList .winCard .card-img .card-title {
  text-align: center;
  font-size: 0.58rem * 1.4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 0.33rem * 1.4;
  color: #fff;
}

.winList .winCard .card-img .card-degree {
  font-size: 0.5rem * 1.4;
  color: #cdcccc;
}

.winList .winCard .card-img .user-info-head {
  text-align: center;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.winList .winCard .card-img .user-info-head .user-info {
  width: 6.93rem * 1.4;
  height: 1.92rem * 1.4;
  background: #363d79;
  border-radius: 0.96rem * 1.4;
  display: flex;
  align-items: center;
  color: #fff;
}

.winList .winCard .card-img .user-info-head .user-info .user-card-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.2rem * 1.4;
  width: 60%;
}

.winList .winCard .card-img .user-info-head .user-info .user-card-img {
  border-radius: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 1.92rem * 1.4;
  width: 1.92rem * 1.4;
  position: relative;
  left: -0.1rem * 1.4;
  border: 2px solid #fff;
  box-sizing: border-box;
  flex-shrink: 0;
}

.winList .winCard .card-img .user-info-head .user-info > img {
  height: 2.21rem * 1.4;
}

.winList .winCard .card-img .user-info-head .no-head {
  border: 0.1rem * 1.4 solid #fff !important;
}

.winList .top3 {
  background: rgba(69, 28, 167, 0.4);
}

.winList .top3 .card-img .user-info-head .user-info {
  background: linear-gradient(-49deg, #6917ff, #9800f0);
}

.winList .top3 .card-img .user-info-head .user-info .user-card-img {
  border: 2px solid #ff31ed;
}
.MoneyCoin {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
}
.load-more .load-more-load-top {
  display: flex;
  justify-content: center;
  color: #fff;
}

.load-more .load-more-load-top .load-more-load {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-align: center;
  width: 5.24rem * 1.4;
  line-height: 1.57rem * 1.4;
  height: 1.57rem * 1.4;
  background: hsla(240, 1%, 53%, 0);
  border: 0.08px solid #36363a;
  border-radius: 0.78rem * 1.4;
  margin-left: 0.2rem * 1.4;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
}

.load-more .load-more-load-top .image-container {
  margin-left: 0.2rem * 1.4;
  margin-top: 0.1rem * 1.4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.load-more .load-more-load-top .image-container img {
  animation: shake-updown-670d6019 0.6s linear infinite;
}

.load-more .load-more-load-top .image-container img:nth-child(2) {
  transform: translateY(-0.1rem * 1.4);
  opacity: 0.4;
}

@keyframes shake-updown-670d6019 {
  0%,
  to {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-3px);
  }

  75% {
    transform: translateY(3px);
  }
}

@media screen and (max-width: 992px) {
  .load-more .load-more-load-top {
    display: flex;
    justify-content: center;
  }

  .load-more .load-more-load-top .load-more-load {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-align: center;
    line-height: 1.57rem * 0.8;
    background: hsla(240, 1%, 53%, 0);
    border: 0.08px solid #36363a;
    margin-left: 0.2rem * 0.8;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 1rem * 0.8;
    font-weight: 400;
    color: #cfcfcf;
    width: 8.69rem * 0.8;
    height: 2.6rem * 0.8;
    border-radius: 1.3rem * 0.8;
  }

  .load-more .load-more-load-top .image-container {
    margin-left: 0.2rem * 0.8;
    margin-top: 0.1rem * 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .load-more .load-more-load-top .image-container > img {
    width: 0.7rem * 0.8;
    height: 0.43rem * 0.8;
  }

  .load-more .load-more-load-top .image-container img {
    animation: shake-updown-670d6019 0.6s linear infinite;
  }

  .load-more .load-more-load-top .image-container img:nth-child(2) {
    transform: translateY(-0.1rem * 0.8);
    opacity: 0.4;
  }
}
.cardList {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
}

.cardList .roll-room-list-card {
  width: 16.666%;
  border-radius: 0.42rem * 1.4;
  position: relative;
  margin-bottom: 0.75rem * 1.4;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0 0.3rem * 1.4;
  box-sizing: border-box;
}

// @media screen and (max-width: 1600px) {
//   .cardList .roll-room-list-card {
//     width: 20% !important;
//   }
// }

// @media screen and (max-width: 1400px) {
//   .cardList .roll-room-list-card {
//     width: 25% !important;
//   }
// }

// @media screen and (max-width: 1150px) {
//   .cardList .roll-room-list-card {
//     width: 33.333% !important;
//   }
// }

.cardList .roll-room-list-card .card-img {
  width: 100%;
  height: 11.49rem * 1.4;
  background: #1c2245 no-repeat 50%/130% 130%;
  border-radius: 0.42rem * 1.4;
  overflow: hidden;
  background-size: 100%;
}

.cardList .roll-room-list-card .card-img .card-title {
  padding: 0.5rem * 1.4;
  font-size: 0.67rem * 1.4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.cardList .roll-room-list-card .card-img .card-degree {
  font-size: 0.58rem * 1.4;
  padding-top: 0.2rem * 1.4;
  color: #cdcccc;
  text-align: center;
}

.cardList .roll-room-list-card .card-img .img-go {
  width: 7.13rem * 1.4;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardList .roll-room-list-card .card-img .img-price {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.1rem * 1.4;
  height: 1.75rem * 1.4;
  font-size: 0.67rem * 1.4;
  font-weight: 700;
  color: #ffc600;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.userList {
  display: inline-flex;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 1rem * 1.4 0 4.1rem * 1.4 0;
}

.userList .roll-room-user-card {
  width: 3.73rem * 1.4;
  margin-right: 1rem * 1.4;
  text-align: center;
  margin-bottom: 0.5rem * 1.4;
}

.userList .roll-room-user-card .card-img {
  border-radius: 50%;
  width: 3.73rem * 1.4;
  height: 3.73rem * 1.4;
  box-sizing: border-box;
}

.userList .roll-room-user-card .card-name {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 0.2rem * 1.4;
  font-size: 0.58rem * 1.4;
  color: #a9abbc;
}

.userList .my .card-img {
  border: 2px solid #ff31ed;
}

.userList .my .card-name {
  color: #ff31ed;
}
</style>
