<template>
  <div class="recharge pagesView routerView">
    <div
      class="BackTop _mobile"
      style="background-color: transparent"
      v-if="false"
    >
      <div class="left">
        <div class="backBox">
          <svg data-v-0f4c779f="" class="svg-icon backSvg" aria-hidden="true">
            <use data-v-0f4c779f="" xlink:href="#icon-arrow-left"></use></svg
          ><span>返回</span>
        </div>
      </div>
      <div class="right"><!----><!----></div>
    </div>
    <div class="title _windows">
      <img
        class="titleImg"
        src="../assets/img/onlineRecharge@2X.6d9505ed.png"
        alt=""
      />
      <!-- <div class="titleTips">
        当前会员等级
        <img class="vipImg" src="../assets/img/V0@2x.510fc35b.png" alt="" />
        ,充值加成 <span class="pink">0%</span>， 您距离升级VIP1还需要充值
        <div  class="MoneyCoin">
          <img src="../assets/img/money.png" alt="" />
          <span class="moneyVal" style="color: rgb(255, 255, 255)">288.00</span>
        </div>
        ， <span class="pink">0.5%</span> 充值加成
      </div> -->
    </div>
    <div class="paymentMeth">
      <div class="payTypeTitle">选择支付方式</div>
      <div class="payList">
        <div class="itemBox">
          <div class="payItem payItemActive">
            <img
              src="https://lucsbox.oss-cn-hangzhou.aliyuncs.com/icon/ali.png"
              alt=""
            />
            支付宝
          </div>
        </div>
      </div>
    </div>
    <div class="rchgAmt">
      <div class="rchgAmtBottom">
        <div
          class="amountItemBox"
          v-for="(item, index) in list.slice(0, list.length - 1)"
          :key="item.id"
          @click="changeAmount(item)"
        >
          <div class="amountItem btn" :class="{ amountItemActive: item.state }">
            <div class="MoneyCoin">
              <!-- {{ item }} -->
              <img src="../assets/img/money.png" alt="" />
              <span class="moneyVal" style="color: rgb(255, 255, 255)">{{
                item.money
              }}</span>
            </div>
            <!-- <span class="give"
              ><span>首充送 <span class="giveMoney">10</span></span></span
            > -->
          </div>
        </div>
      </div>
      <div class="tips">
        <p class="tipsItem">
          <span
            >单笔5000以上大额充值限量加赠2%起，详情咨询专属客服QQ：
            3682097664；（点击图标跳转）</span
          >
          <span class="kefuBox" @click="showKefu">
          <img class="kefu" src="../assets/img/kefu.8a37dc48.png" alt="" />
          </span>
        </p>
      </div>
    </div>
    <div class="rechargeFooter">
      <div class="clause btn">
        <span class="circular circularActive"></span
        ><span>我已年满 18 岁并且同意条款和条件</span>
      </div>
      <div class="btn payBtn" @click="payInfo">确认支付 ￥ {{ filterMoney() }}</div>
    </div>
    <div class="mask animate__animated" v-show="showMask">
      <div class="popupContent" v-show="showMask">
        <div class="close" @click="closeMask">
          <img src="../assets/img/close.10e4447a.png" alt="" />
        </div>
        <div class="container">
          <span
            class="txtSpan _mobile"
            style="color: rgb(251, 198, 0)"
            v-show="!showNav"
            >请截屏后 读取相册图片完成支付</span
          >
          <div class="downLoadTitle" v-show="showNav">创建支付订单成功</div>
          <div class="qrcode">
            <div id="qrcode" ref="qrcode" />
          </div>
          <span class="txtSpan"> 充值金额:{{ filterMoney() }}元 </span>
        </div>
      </div>
    </div>
    <!-- <div
      data-v-5c6a804d=""
      class="mask animate__animated hidde"
      style="display: none"
    >
      <div class="popupContent" style="display: none">
        <img class="close" src="/img/close.10e4447a.png" alt="" />
        <div data-v-5c6a804d="" class="RealNamePopup">
          <div data-v-5c6a804d="" class="title">实名认证</div>
          <ul data-v-5c6a804d="" class="content">
            <li data-v-5c6a804d="" class="box">
              <div data-v-5c6a804d="" class="name">姓名</div>
              <input
                data-v-5c6a804d=""
                type="text"
                placeholder="请输入真实姓名"
                field_signature="1318412689"
                form_signature="11656020650301398145"
                alternative_form_signature="4407362010074370765"
                visibility_annotation="false"
              />
            </li>
            <li data-v-5c6a804d="" class="box">
              <div data-v-5c6a804d="" class="name">身份证号</div>
              <input
                data-v-5c6a804d=""
                type="text"
                placeholder="请输入身份证号码"
                field_signature="1318412689"
                form_signature="11656020650301398145"
                alternative_form_signature="4407362010074370765"
                visibility_annotation="false"
              />
            </li>
          </ul>
          <div data-v-5c6a804d="" class="defaultBtn _btnClick1">认证</div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script >
import PayMixin from "@/mixins/pay";
import QRCode from "qrcodejs2";
export default {
  mixins: [PayMixin],
  name: "recharge",
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      // 是否新人
      is_new: false,
      firstGiveValidate: false,
      //
      ratioList: "",
      // 非新用户充值活动
      ordinaryActivity: "",
      rmb: "",
      payObj: { rmb: 0, money: 5, state: false },
      list: [],
      ordinaryValidate: false,
      userInfo: {},
      showMask: false,
    };
  },
  mounted() {
    this.amountList();
  },
  methods: {
    amountList() {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let param = {
        player_id: this.userInfo.id,
      };
      this.$axios
        .post("index/User/giveAboutRecharge", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          this.is_new = data.data.new;
          if (data.status == 1) {
            if (this.is_new) {
              if (data.data.recharge_activity.length > 0) {
                data.data.recharge_activity.forEach((e) => {
                  if (e.type == 1) {
                    this.firstGiveValidate = true;
                    this.ratioList = e;
                    this.firstGive = e;
                  }
                  if (e.type == 2) {
                    this.firstGiveValidate = false;
                    this.ratioList = e;
                    this.ordinaryActivity = e;
                  }
                });
              }
            } else {
              this.firstGiveValidate = false;
              if (data.data.recharge_activity.length > 0) {
                data.data.recharge_activity.forEach((e) => {
                  if (e.type == 2) {
                    this.ratioList = e;
                    this.ordinaryActivity = e;
                  }
                });
              }
            }
          }
          // 获取充值列表
          this.$axios.get("/index/pay/chargeInfoList").then((res) => {
            let data = res.data;
            if (data.status == 1) {
              this.rmb = data.data.exchange_rate;
              this.payObj.rmb = data.data.exchange_rate * 5;
              let list = res.data.data.list;
              list.push(this.payObj);
              list.forEach(function (item, index) {
                item.state = false;
                if (index == 0) {
                  item.state = true;
                }
              });
              this.list = list;
              // console.log(this.list);
              for (let i = 0; i < this.list.length; i++) {
                let money = Number(this.list[i].money); //当前充值列表金额
                let lower_limit = Number(this.ratioList.money); //后台设定下限
                let upper_limit = Number(this.ratioList.limit); //后台设定上限
                // console.log(money,lower_limit,upper_limit);
                if (lower_limit > 0 && upper_limit > 0) {
                  if (money >= lower_limit && money <= upper_limit) {
                    this.list[i].billie = this.ratioList.billie;
                    this.list[i].billieState = true;
                  }
                }
                if (lower_limit == 0 && upper_limit == 0) {
                  this.list[i].billie = this.ratioList.billie;
                  this.list[i].billieState = true;
                }
                if (lower_limit > 0 && upper_limit == 0) {
                  if (money >= lower_limit) {
                    this.list[i].billie = this.ratioList.billie;
                    this.list[i].billieState = true;
                  }
                }
                if (lower_limit == 0 && upper_limit > 0) {
                  if (money <= upper_limit) {
                    this.list[i].billie = this.ratioList.billie;
                    this.list[i].billieState = true;
                  }
                }
              }
            }
          });
          // console.log(this.ratioList);
          // console.log(this.ordinaryActivity);
          if (!this.empty(this.ordinaryActivity)) {
            this.ordinaryValidate = true;
          }
        });
    },
    // 充值
    payInfo() {
      const _this = this;
      // 新版充值
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.createOrder({
        money: this.filterMoney(),
        mobile: this.userInfo.mobile,
        bd_vid:localStorage.getItem("bd_vid")?localStorage.getItem("bd_vid"):"",
        callback(payLink) {
          _this.payUrl = payLink;
          console.log(payLink, "测试");
          // if (
          //   _this.payMethod.startsWith("wx_scan") ||
          //   _this.payMethod.startsWith("wxpay1")
          // ) {
          //   _this.loading = true;
          //   _this.payTitle = "微信支付";
          //   _this.payTitleIcon = "wechat.svg";
          //   _this.payFooter = "请使用微信或支付宝";
          //   _this.payFooterDesc = "扫描二维码完成支付";
          // } else {
          //   _this.loading = true;
          //   _this.payTitle = "支付宝支付";
          //   _this.payTitleIcon = "alipay.svg";
          //   _this.payFooter = "请使用微信或支付宝";
          //   _this.payFooterDesc = "扫描二维码完成支付";
          // }
          _this.showMask = true;
          loading.close();
          _this.$nextTick(() => {
            const qrCode = new QRCode("qrcode", {
              width: 300,
              height: 300,
              text: payLink,
            });
          });
        },
      });
      return;
    },
    changeAmount(value) {
      this.list.forEach(function (item, index) {
        item.state = false;
        if (item.id == value.id) {
          item.state = true;
        }
      });
      this.list = this.list;
      this.$forceUpdate();
    },
    // 过滤金额
    filterMoney() {
      return this.list
        .filter((i) => i.state)
        .reduce((result, curr) => result + parseFloat(curr.money), 0);
    },
    closeMask(){
      this.showMask = false;
      this.$refs.qrcode.innerHTML = "";
    },
    empty(obj) {
      for (let key in obj) {
        return false; //非空
      }
      return true; //为空
    },
    showKefu(){
      window.open(
        "https://qm.qq.com/cgi-bin/qm/qr?k=QZcWH82_vqJEZ86YttZHaSCLjE2X2Qtx"
      );
    }
  },
};
</script>
<style scoped lang="less">
.recharge {
  padding-top: 1.75rem * 1.4;
  padding-bottom: 1.75rem * 1.4;
  color: #fff;
}

.recharge .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.25rem * 1.4;
  font-size: 0.67rem * 1.4;
}

.recharge .title .titleImg {
  width: 10.15rem * 1.4;
  margin-bottom: 0.33rem * 1.4;
}

.recharge .title .titleTips {
  display: flex;
  align-items: center;
  letter-spacing: 2px;
}

.recharge .title .titleTips .vipImg {
  width: 1.08rem * 1.4;
}

.recharge .title .titleTips .pink {
  color: #ff31ed;
}

.recharge .paymentMeth,
.recharge .rchgAmt {
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.42rem * 1.4;
  padding: 0.88rem * 1.4 1rem * 1.4 1rem * 1.4 1rem * 1.4;
  margin-bottom: 0.42rem * 1.4;
  font-size: 0.58rem * 1.4;
}

.recharge .paymentMeth .payTypeTitle {
  font-size: 0.8rem * 1.4;
}

.recharge .paymentMeth .payList {
  display: flex;
  margin-top: 0.67rem * 1.4;
  flex-wrap: wrap;
}

.recharge .paymentMeth .payList .itemBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.67rem * 1.4;
  margin-bottom: 0.67rem * 1.4;
}

.recharge .paymentMeth .payList .itemBox .payItem {
  height: 4.33rem * 1.4;
  width: 7rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.25rem * 1.4;
  cursor: pointer;
  font-size: 0.75rem * 1.4;
}

.recharge .paymentMeth .payList .itemBox .payItem img {
  width: 1.5rem * 1.4;
  margin-right: 0.33rem * 1.4;
}

.recharge .paymentMeth .payList .itemBox .payItemActive {
  box-sizing: border-box;
  border: 2px solid #ff31ed;
}

.recharge .rchgAmt .rchgAmtBottom {
  display: flex;
  align-items: center;
  padding-top: 1rem * 1.4;
  flex-wrap: wrap;
}

.recharge .rchgAmt .rchgAmtBottom .amountItem {
  width: 5.98rem * 1.4;
  min-height: 3.42rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 0.58rem * 1.4;
  margin-bottom: 0.58rem * 1.4;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.17rem * 1.4;
  font-size: 0.75rem * 1.4;
  position: relative;
  padding: 0.2rem * 1.4 0;
}

.recharge .rchgAmt .rchgAmtBottom .amountItem .give {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.58rem * 1.4;
  margin-top: 0.33rem * 1.4;
  white-space: nowrap;
}

.recharge .rchgAmt .rchgAmtBottom .amountItem .give .giveMoney {
  margin-left: 0.2rem * 1.4;
}

.recharge .rchgAmt .rchgAmtBottom .amountItemActive {
  box-sizing: border-box;
  border: 2px solid #ff31ed;
  background: rgba(255, 49, 237, 0.3);
}

.recharge .rchgAmt .kefu {
  width: 3rem * 1.4;
  margin-left: 1rem * 1.4;
  vertical-align: bottom;
  cursor: pointer;
}

.recharge .rechargeFooter {
  background: linear-gradient(
    90deg,
    rgba(255, 49, 237, 0.3) 0,
    rgba(255, 49, 237, 0) 80%
  );
  padding: 1rem * 1.4;
  position: relative;
  border-radius: 0.42rem * 1.4;
  overflow: hidden;
}

.recharge .rechargeFooter:after {
  content: "";
  display: block;
  background-color: hsla(0, 0%, 100%, 0.05);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.recharge .rechargeFooter:before {
  content: "";
  display: block;
  background: url("../assets/img/decoration@2X.ab6c8aef.png") no-repeat 50% /
    cover;
  width: 11.91rem * 1.4;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50%;
  z-index: -1;
}

.recharge .rechargeFooter .clause {
  display: flex;
  margin-bottom: 0.92rem * 1.4;
}

.recharge .rechargeFooter .clause .circular {
  width: 0.75rem * 1.4;
  height: 0.75rem * 1.4;
  border-radius: 50%;
  border: 2px solid #ff31ed;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  margin-right: 0.33rem * 1.4;
  margin-top: 0.1rem * 1.4;
}

.recharge .rechargeFooter .clause .circularActive:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5rem * 1.4;
  height: 0.5rem * 1.4;
  background-color: #ff31ed;
  border-radius: 50%;
}

.recharge .rechargeFooter .payBtn {
  width: 12.51rem * 1.4;
  height: 2.25rem * 1.4;
  background: #ff31ed;
  border-radius: 0.17rem * 1.4;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 0.75rem * 1.4;
}

@media screen and (max-width: 992px) {
  .recharge {
    padding-top: 0.5rem * 0.8;
    padding-bottom: 1.75rem * 0.8;
  }

  .recharge .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem * 0.8;
  }

  .recharge .paymentMeth,
  .recharge .rchgAmt {
    border-radius: 0.42rem * 0.8;
    padding: 0.88rem * 0.8 1rem * 0.8 1rem * 0.8 1rem * 0.8;
    margin-bottom: 0.42rem * 0.8;
    font-size: 1.08rem * 0.8;
  }

  .recharge .paymentMeth {
    margin-top: 0.42rem * 0.8;
  }

  .recharge .paymentMeth .payTypeTitle {
    font-size: 1.2rem * 0.8;
  }

  .recharge .paymentMeth .payList {
    display: flex;
    margin-top: 0.67rem * 0.8;
  }

  .recharge .paymentMeth .payList .itemBox {
    width: 33.33%;
    margin-right: 0;
  }

  .recharge .paymentMeth .payList .itemBox .payItem {
    height: 4rem * 0.8;
    width: 8rem * 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem * 0.8;
    margin-right: 0;
    cursor: pointer;
    font-size: 1rem * 0.8;
  }

  .recharge .rchgAmt .rchgAmtBottom {
    display: flex;
    align-items: center;
    padding-top: 1rem * 0.8;
    flex-wrap: wrap;
  }

  .recharge .rchgAmt .rchgAmtBottom .amountItemBox {
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.33rem * 0.8;
  }

  .recharge .rchgAmt .rchgAmtBottom .amountItemBox .amountItem {
    width: 10rem * 0.8;
    height: 6rem * 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.08rem * 0.8;
    border-radius: 0.17rem * 0.8;
    padding-left: 0.5rem * 0.8;
    position: relative;
  }

  .recharge .rchgAmt .rchgAmtBottom .amountItemBox .amountItem .give {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem * 0.8;
    margin-top: 0.33rem * 0.8;
    flex-wrap: wrap;
  }

  .recharge
    .rchgAmt
    .rchgAmtBottom
    .amountItemBox
    .amountItem
    .give
    .giveMoney {
    margin-left: 0.33rem * 0.8;
    font-size: 0.9rem * 0.8;
  }

  .recharge .rchgAmt .kefu {
    width: 5rem * 0.8;
    margin-left: 1rem * 0.8;
  }

  .recharge .active {
    box-sizing: border-box;
    background-color: rgba(251, 198, 0, 0.05) !important;
  }

  .recharge .rechargeFooter {
    padding: 1rem * 0.8;
    position: relative;
    border-radius: 0.42rem * 0.8;
    overflow: hidden;
  }

  .recharge .rechargeFooter:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .recharge .rechargeFooter:before {
    right: 0;
  }

  .recharge .rechargeFooter .clause {
    display: flex;
    margin-bottom: 0.92rem * 0.8;
  }

  .recharge .rechargeFooter .clause .circular {
    width: 1.2rem * 0.8;
    height: 1.2rem * 0.8;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    margin-right: 0.33rem * 0.8;
    margin-top: 0.2rem * 0.8;
  }

  .recharge .rechargeFooter .clause .circularActive:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5rem * 0.8;
    height: 0.5rem * 0.8;
    border-radius: 50%;
  }

  .recharge .rechargeFooter .payBtn {
    width: 100%;
    height: 3.35rem * 0.8;
    border-radius: 0.42rem * 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.17rem * 0.8;
  }
  .container{
    padding: 0rem !important;
  }
}
.recharge .rchgAmt .rchgAmtBottom {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  flex-wrap: wrap;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
}
.qrcode {
  height: 20rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}
</style>