<template>
	<div class="room-box-ul">
		<div v-for="(item, index) in list" :key="index" @click="goLuckyRoom(item.id)"
			:class="[item.class, `bg_${item.status}`, 'li']" :style="{
        borderTop: `5px solid ${
          ['#3088FF', '#67c23a', '#999999'][item.status - 1]
        }`,
      }">
			<!-- :style="{
        borderTop: `3px solid ${['#3088FF', '#67c23a', '#999999'][item.status - 1]}`,
        backgroundImage: `linear-gradient(${
          ['#3088FF', '#67c23a', '#999999'][item.status - 1]
        }, transparent)`,
      }" -->
			<div class="roombox-warp" :class="{
          two1: item.status == 1,
          two3: item.status == 2,
          two2: item.status == 3,
        }">
				<div :class="[`item${(item.status + 1) % 2}`, 'two']">
					<div class="two-bot">
						<div class="room-peo" v-if="item.mode == 2">
							<div class="pk-warp" v-for="(item2, index2) in item.player_info" :key="index2">
								<span class="pk-tou" :class="item2.class == 'pk-false' ? 'pk-tou-false' : ''">
									<!-- <span
                    v-if="item.status == 3"
                    :style="{
                      color: item2.id == item.winner ? 'red' : 'blue',
                    }"
                  >
                    {{ item2.id == item.winner ? "胜利" : "失败" }}
                  </span> -->

									<div v-if="item.status == 3">
										<img class="status-img" v-if="item2.id == item.winner"
											src="../../../assets/img/luck-history/7e4a13754b0f8980a74dc005a35673e.png"
											alt="" srcset="" />
										<img class="status-img" v-else
											src="../../../assets/img/luck-history/8447dfa3b35ada7bba04dd10ef70bfe.png"
											alt="" srcset="" />
									</div>

									<img style="object-fit: cover" :class="item2.class" :src="item2.img" alt="" />
								</span>
								<img class="vs" v-if="index2 + 1 != item.player_info.length" src="@/assets/new/vs.png"
									alt="" srcset="" />
							</div>
						</div>

						<div class="room-peo" v-if="item.mode == 3">
							<div class="pk-warp" v-for="(item3, index3) in [...item.play1, ...item.play2]"
								:key="index3">
								<span class="pk-tou" :class="item3.class == 'pk-false' ? 'pk-tou-false' : ''">
									<div v-if="item.status == 3">
										<img class="status-img" v-if="item3.id == item.winner"
											src="../../../assets/img/luck-history/7e4a13754b0f8980a74dc005a35673e.png"
											alt="" srcset="" />
										<img class="status-img" v-else
											src="../../../assets/img/luck-history/8447dfa3b35ada7bba04dd10ef70bfe.png"
											alt="" srcset="" />
									</div>
									<img :class="item3.class" style="object-fit: cover" :src="item3.img" alt="" />
								</span>
								<img class="vs" v-if="index3 + 1 != [...item.play1, ...item.play2].length"
									src="@/assets/new/vs.png" alt="" srcset="" />
							</div>
						</div>

						<div class="room-peo" v-if="item.mode == 4">
							<div class="pk-warp">
								<div v-for="(item4, index4) in [...item.play1, ...item.play2]" :key="index4"
									class="vs-info">
									<span class="pk-tou" :class="item4.class == 'pk-false' ? 'pk-tou-false' : ''">
										<div v-if="item.status == 3">
											<img class="status-img" v-if="item4.id == item.winner"
												src="../../../assets/img/luck-history/7e4a13754b0f8980a74dc005a35673e.png"
												alt="" srcset="" />
											<img class="status-img" v-else
												src="../../../assets/img/luck-history/8447dfa3b35ada7bba04dd10ef70bfe.png"
												alt="" srcset="" />
										</div>
										<img :class="item4.class" style="object-fit: cover" :src="item4.img" alt="" />
									</span>
									<img class="vs" v-if="index4 + 1 != [...item.play1, ...item.play2].length"
										src="@/assets/new/vs.png" alt="" srcset="" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card-footer">
					<div :class="[`two${(item.status + 1) % 2}`, 'three']">
						<div class="box-detail" v-for="(item1, index1) in caculateBoxNum(item.boxInfo)" :key="index1">
							<img :src="item1.current.img_main" alt="" />
							<div class="box-num">{{ item1.num }}</div>
						</div>
					</div>
					<div class="two-top">
						<img src="@/assets/new/money.png" alt="" />
						<span>{{ item.price }}</span>
						<div class="status-condiv">
							<div class="point" :class="[`point_${item.status}`]"></div>
							<div :class="[`text_${item.status}`]">
								{{ ["等待中", "进行中", "已结束"][item.status - 1] }}
							</div>
						</div>
					</div>
				</div>

				<div class="box-info">
					<span class="box-time">{{ item.create_time }}</span>
					<span class="number">编号:{{ item.room_num }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			list: {
				type: Array,
				required: true,
				default: () => [],
			},
		},
		methods: {
			//计算分类 根据盒子计算同一类的数量
			caculateBoxNum(box) {
				const result = box.reduce((total, current) => {
					if (total[current.box_id]) {
						total[current.box_id].num += 1;
					} else {
						total[current.box_id] = {
							num: 1,
							current,
						};
					}
					return total;
				}, {});
				return Object.keys(result).map((box_id) => result[box_id]);
			},
			goLuckyRoom(id) {
				console.log(`/LuckyRoom?id=${id}`);
				this.$router.push({
					path: `/LuckyRoom?id=${id}`,
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.room-box-ul {
		display: grid;
		gap: 10px;
		background-color: #1a1c23;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

		.li {
			cursor: pointer;
			background-color: #2b2c37;
			background-size: 100% 100%;
			max-width: 500px;

			// border-radius: 8px;
			// background-image: url('../../../assets/img/lucky/b825f4845a9b5ef3d3908882b3c3b87.png');
			.roombox-warp {
				border-radius: 5px;
				height: 100%;

				.status-img {
					height: 20px;
				}

				.vs {
					width: 17px;
					height: 12px;
					margin: 0 5px 0 10px;
				}

				.vs-info {
					display: flex;
					align-items: center;
				}

				.box-info {
					height: 40px;
					justify-content: space-between;
					display: flex;
					align-items: center;
					padding: 0 10px;
					font-size: 10px !important;
					color: #909399;
				}

				.card-footer {
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: space-between;

					.status-condiv {
						display: flex;
						flex-direction: row;
						align-items: center;
						color: #fff;
						font-size: 14px;
						margin-left: 20px;

						.point {
							width: 15px;
							height: 15px;
							border-radius: 50%;
							background-color: #fff;
							margin-right: 5px;
						}

						.point_1 {
							background-color: #3088FF;
						}

						.point_2 {
							background-color: #67c23a;
						}

						.point_3 {
							background-color: #999;
						}

						.text_1 {
							color: #3088FF;
						}

						.text_2 {
							color: #67c23a;
						}

						.text_3 {
							color: #999;
						}
					}

					.two-top {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 10px;

						span {
							margin-left: 5px;
							font-size: 14px;
							color: #3088FF;
						}

						img {
							height: 14px;
							width: auto;
						}
					}
				}

				.box-detail {
					position: relative;
					margin-right: 5px;

					.box-num {
						position: absolute;
						top: 0;
						right: -5px;
						width: 15px;
						display: flex;
						font-size: 12px;
						align-items: center;
						justify-content: center;
						height: 15px;
						border-radius: 50%;
						background-color: #3088FF;
						color: #000;
					}
				}

				.one {
					height: 30px;
					display: flex;
					padding: 0 10px;
					align-items: center;
					justify-content: space-between;
					// background-color: #243438;
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;

					.span1 {
						color: #02bf4d;
						font-size: 14px;
						display: flex;
						align-items: center;

						.span-dian {
							width: 8px;
							height: 8px;
							display: inline-block;
							animation: jss684 500ms linear infinite alternate;
							// box-shadow: 0 0  4px rgb(2 191 77 / 20%);
							box-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
							margin-left: 10px;
							margin-right: 10px;
							border-radius: 4px;
							vertical-align: middle;
							background-color: #02bf4d;
						}

						@keyframes jss684 {
							0% {
								box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
							}

							100% {
								box-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
							}
						}
					}

					.span3 {
						color: #c3c3e2;
						font-size: 14px;
					}

					.status2 {
						display: flex;
						align-items: center;

						.span2 {
							color: #ae7bfe;
							font-size: 14px;
							margin-right: 5px;
						}

						.img1 {
							animation: jss163 1000ms linear infinite;
						}

						@keyframes jss163 {
							0% {
								transform: rotate(0deg);
							}

							100% {
								transform: rotate(360deg);
							}
						}
					}
				}

				.two1 {}

				.two2 {
					opacity: 0.5;
				}

				.two3 {}

				.two {
					padding: 40px 0 15px 0;

					.two-bot {
						margin-top: 10px;

						.room-peo {
							// min-height: 124px;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;

							.pk-warp {
								margin-top: 10px;
								display: flex;
								align-items: center;

								span:first-child {
									margin-left: 10px;
								}

								.pk-tou {
									margin: 0 5px;
									width: 40px;
									height: 40px;
									display: flex;
									justify-content: center;
									align-items: center;
									flex-direction: column;
									font-size: 12px;
									//border: 1px dashed #3088FF;
									position: relative;

									.status-img {
										height: 38px;
										text-align: center;
										position: absolute;
										top: -40px;
										left: 50%;
										transform: translateX(-50%);
										color: #909399;
										border-radius: 0;
									}

									img {
										border-radius: 50%;
									}

									.pk-true {
										width: 40px;
										height: 40px;
									}

									.pk-false {
										width: 25px;
										border: 1px dashed #3088FF;
										height: 25px;
									}
								}

								.pk-tou-false {
									border-radius: 50%;
									width: 40px;
									height: 40px;
									border: 1px dashed #3088FF;
								}
							}
						}

						.room-btn {
							margin-top: 20px;
							display: flex;
							justify-content: center;

							span {
								border: 2px solid #3088FF;
								padding: 4px 50px;
								border-radius: 30px;
								color: #3088FF;
							}

							/* span:hover {
                      cursor: pointer;
                    }*/
							.span1:hover {
								cursor: pointer;
								background-color: rgba(233, 177, 14, 0.1);
							}

							.span2:hover {
								cursor: pointer;
								background-color: rgba(132, 132, 146, 0.2);
							}
						}
					}
				}

				.three {
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;
					// background-color: #243438;
					display: flex;
					flex-wrap: nowrap;
					overflow-x: auto;
					margin-top: 10px;
					padding: 0 20px;

					img {
						height: 30px;
						width: auto;
					}
				}

				.three::-webkit-scrollbar {
					/*滚动条整体样式*/
					width: 10px;
					/*高宽分别对应横竖滚动条的尺寸*/
					height: 6px;
				}

				.three::-webkit-scrollbar-thumb {
					/*滚动条里面小方块*/
					border-radius: 10px;
					background-color: #4b575c;
				}

				.three::-webkit-scrollbar-track {
					/*滚动条里面轨道*/
					box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
					// background: #243438;
				}
			}
		}

		li:nth-child(4n + 1) .roombox-warp {
			margin-left: 0;
		}

		li:nth-child(4n) .roombox-warp {
			margin-right: 0;
		}
	}
</style>