<template>
  <div class="ornaOpen">
    <!-- 标题 -->
    <div class="title">
      <div class="left">
        <img src="../assets/img/Dreams@2x.748dad0d.png" alt="" /><span
          >DREAMS</span
        >
      </div>
      <div class="right" @click="goRule()">
        <div class="rule"  >
          <img src="../assets/img/Question.c91820b3.png" alt="" /><span
            >游戏规则</span
          >
        </div>
      </div>
    </div>
    <!-- <div class="tanchuang" v-if="tanchuang" @click="tanchuang = !tanchuang">
      <div class="boxs">
        <div>
          <p>
            1.PC端从左边指针转盘区域选择您想要的目标饰品，手机端从中间指针转盘中选择您想要的目标饰品。
          </p>
          <p>&nbsp;</p>
          <p>
            2.选择好逐梦饰品后，您可以通过左右滑动概率条来自由选择概率，选择概率越大，获得目标幸运饰品的概率越大，概率区间为
            5%～75%；升级成功直接获得该饰品、升级失败将只获得安慰饰品。
          </p>
          <p>&nbsp;</p>
          3.幸运饰品的概率为每次独立事件，并不会累加。
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p style="color: red">
            &nbsp;&nbsp;&nbsp;本活动的最终解释权归IBOX，恶意刷取奖励等行为，一经发现，将分解奖励并封禁平台账号
          </p>
        </div>
      </div>
    </div> -->
    <div class="bbt" v-if="isbot" @click="isbot = !isbot">
      <div class="orans-warp" @click.stop>
        <div class="oran-top">
          <div class="orantop-left">选择饰品</div>
          <!-- 暂不开放 -->
        </div>

        <div class="oran-sels">
          <div class="sel-top">
            <ul>
              <li
                v-for="(item, index) in selList"
                :key="index"
                @click="selOran(item.status, item.id)"
              >
                <div
                  :class="
                    item.state ? 'seltop-warp seltop-warp1' : 'seltop-warp'
                  "
                >
                  <img
                    v-if="item.state"
                    src="../assets/img/duizhan/2s.png"
                    alt=""
                  />
                  <img v-else src="../assets/img/duizhan/1s.png" alt="" />
                  <span>{{ item.name }}</span>
                </div>
              </li>
            </ul>
          </div>

          <div class="class-box">
            <!-- 子类 -->
            <!-- 	<div class="class-list">
							<ul>
								<li v-for="(item, index) in classList" :key="index">
									<span :class="item.state ? '' : 'span2'" @click="selClass(item)">{{ item.name }}</span>
								</li>
							</ul>
						</div> -->
            <div class="sel-bot">
              <!-- <div class="selbot-left">
								<span class="span1">价格从高到低</span>
							</div> -->
              <div class="selbot-right">
                <div class="selbot-rb">
                  <div class="shengjiang" @click="onHandledSort">
                    <span style="font-weight: bold">价格</span>
                    <img
                      src="../assets/img/duizhan/shang.png"
                      v-if="sortType == 'desc'"
                      alt=""
                    />
                    <img
                      src="../assets/img/duizhan/shang.png"
                      v-if="sortType == 'asc'"
                      style="transform: rotate(180deg)"
                      alt=""
                    />
                  </div>
                  <div class="shengjiang1">
                    <span>价格区间</span>
                    <input class="zong" type="text" v-model="MinPrice" />
                    <span>-</span>
                    <input class="zong" type="text" v-model="MaxPrice" />
                    <i
                      slot="suffix"
                      class="el-input__icon el-icon-search input1-i"
                      @click="getList"
                    ></i>
                  </div>
                </div>

                <!-- <el-input placeholder="最低金额" v-model="pirce1" class="input">
									<img src="../assets/img/money.png" slot="prefix" />
								</el-input>
								<span class="span">~</span>
								<el-input placeholder="最高金额" v-model="pirce2" class="input">
									<img src="../assets/img/money.png" slot="prefix" />
								</el-input> -->
                <!-- <div class="pirec-btn">确定</div> -->
                <el-input
                  placeholder="按名称搜索"
                  v-model="searchKey"
                  style="
                    width: calc(100% - 86px);
                    max-width: 280px;
                    margin-right: 12px;
                  "
                  class="input1"
                >
                  <i
                    slot="suffix"
                    class="el-input__icon el-icon-search input1-i"
                    @click="getList"
                  ></i>
                </el-input>
              </div>
            </div>
          </div>
        </div>

        <div class="oran-list">
          <div class="roll-list">
            <ul>
              <li
                v-for="(item, index) in list"
                :key="index"
                @click="goOrnamentOpen(item)"
              >
                <div class="list-warp">
                  <!-- list-bor -->
                  <!-- <span v-if="item.state" class="buy-state">
										<img src="../assets/img/gou.png" />
									</span> -->
                  <div class="list-name" :title="item.itemName">
                    {{ item.itemName }}
                  </div>
                  <div class="list-img">
                    <img :src="item.imageUrl" />
                    <div class="bot">
                      <div class="list-pirce">
                        <div class="pirce-left">
                          <img src="../assets/img/money.png" />
                          <span>{{ item.price }}</span>
                        </div>
                        <span class="ico">{{ item.exteriorName }}</span>
                        <!-- <div class="pirce-right">库存 {{ item.stock }}</div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <el-pagination
          layout="prev, pager, next"
          :total="totalSize"
          :page-size="pageSize"
          @current-change="currentchanges"
        >
        </el-pagination>
      </div>
    </div>
    <div class="oran-warp" v-if="showNav">
      <!-- <div class="extension_index_img">
        <img src="../assets/img/duizhan/zhumeng.png" alt="" />
      </div> -->
      <!-- <div class="oran-top">
				<div class="orantop-left">幸运饰品</div> -->
      <!-- 暂不开放 -->
      <!-- <div @click="goHistory" class="orantop-right">饰品升级记录</div> -->
      <!-- </div> -->

      <div class="oran-sel" style="position: relative">
        <!-- <div class="guize" @click="tanchuang = true">活动规则</div> -->
        <div class="open-line" ref="openLine"></div>
        <div class="open">
          <div class="open-hint">
            <!-- <div class="open-hint2">
							<span>玩法介绍和特别说明</span>
							<i class="el-icon-question"></i>
						</div> -->
            <!-- <div @click="ChangeSoundType" class="open-hint3">
							<img :src="sound.icon" />
						</div> -->
          </div>

          <div class="open-box">
            <!-- <div class="box-one"><img src="../assets/img/left.png" /></div>
						<div class="box-two">
							<span>随机物品</span>
							<img src="../assets/img/box1.png" />
						</div>
						<div class="box-three">{{ value3 }}%</div> -->
            <div class="box-four2">
              <!-- <div class="four-top">幸运饰品</div> -->
              <div class="four-bot" v-if="!obj" @click="isbot = true">
                <img
                  class="img1"
                  src="../assets/img/duizhan/Accessory.ebd36d8d.png"
                  alt=""
                />
                <p>选择饰品</p>
                <!-- <img class="por" src="../assets/img/top.png" /> -->
              </div>
              <div class="four-bot" v-else>
                <!-- <img
                  class="yinpin yinpinvs"
                  v-if="disabled"
                  src="../assets/img/duizhan/yinpin1.png"
                  alt=""
                /> -->
                <div class="img" @click="isbot = true">
                  <img :src="obj.imageUrl" />
                  <!-- <div>
                    <img src="../assets/img/money.png" />
                    <div class="name">{{ obj.price }}</div>
                  </div> -->
                </div>
                <!-- <img class="por" src="../assets/img/top.png" /> -->
              </div>
            </div>
            <div class="box-four1">
              <!-- <div class="four-top">幸运饰品</div> -->
              <div class="four-bot">
                <div class="img1">
                  <img
                    src="../assets/img/duizhan/rotateAnimate.a46ab407.png"
                    class="skin"
                  />
                  <!-- <div class="circle-container">
                    <div class="square-box" id="circle-container">
                      <div
                        class="square"
                        v-for="(angle, index) in angles"
                        :key="index"
                        :style="getSquareStyle(angle)"
                        v-if="index < 1.15 * value3"
                      ></div>
                    </div>
                  </div> -->
                  <div class="svgCircular">
                    <div class="progress">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 400 400"
                        style="transform: rotateY(0deg)"
                      >
                        <defs>
                          <linearGradient
                            id="greenGradient"
                            x1="100%"
                            y1="75%"
                            x2="0%"
                            y2="0%"
                          >
                            <stop offset="0%" stop-color="#7713ff"></stop>
                            <stop offset="33%" stop-color="#ff0f48"></stop>
                            <stop offset="50%" stop-color="#ff6204"></stop>
                            <stop offset="100%" stop-color="#ffb400"></stop>
                          </linearGradient>
                        </defs>
                        <circle
                          cx="200"
                          cy="200"
                          r="192.5"
                          stroke="#1A1C22"
                          stroke-width="15"
                          fill="none"
                        ></circle>
                        <circle
                          class="progress-bar"
                          cx="200"
                          cy="200"
                          r="192.5"
                          stroke="url(#greenGradient)"
                          stroke-width="15"
                          stroke-linecap="butt"
                          fill="url(/img/Gradientbg.2720a6b0.png)"
                          transform="rotate(-90, 200, 200)"
                          stroke-dasharray="1209.5131716320705"
                          :stroke-dashoffset="
                            dashoffset - (value3 - 5) * 12.095131716321
                          "
                        ></circle>
                      </svg>
                      <div class="progress-desc">
                        <div class="valBox">
                          <span class="val">5</span
                          ><span class="symbol">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bos" v-if="!disabled || !animState">
                    {{ value3 }}<span>%</span>
                  </div>
                  <div class="bos3" v-if="disabled && animState && !winFalse">
                    <img src="../assets/img/duizhan/y2.png" alt="" />
                    <div>
                      <span class="luky">升级失败</span>
                      <span>{{ value3 }}%</span>
                    </div>
                  </div>
                  <div class="bos3" v-if="disabled && animState && winFalse">
                    <img src="../assets/img/duizhan/y1.png" alt="" />
                    <div>
                      <span class="luky">升级成功</span>
                      <span>{{ value3 }}%</span>
                    </div>
                  </div>
                  <img
                    class="bos2"
                    :class="disabled ? 'kaijiang' : ''"
                    :style="[
                      { '--bg-qudu': value3 * 3.6 + 'deg' },
                      { '--bg-qudus': value4 + 'deg' },
                    ]"
                    src="../assets/img/duizhan/upgrade__rotary__pointer.83b9d058.png"
                    alt=""
                  />
                </div>
                <!-- <img class="por" src="../assets/img/top.png" /> -->
              </div>
            </div>
            <div class="box-four">
              <!-- <div class="four-top">幸运饰品</div> -->
              <div class="four-bot">
                <div class="img1">
                  <img src="../assets/img/duizhan/random.3d6be635.png" />
                </div>
                <!-- <img class="por" src="../assets/img/top.png" /> -->
              </div>
            </div>
            <!-- <div class="box-five"><img src="../assets/img/right.png" /></div> -->
          </div>

          <div>
            <div class="open-pro">
              <div class="pro-con">
                <el-slider
                  v-model="value3"
                  :show-tooltip="false"
                  :min="0"
                  :max="75"
                  @change="changeNum"
                ></el-slider>
              </div>
              <!-- <img src="../assets/img/duizhan/kedu.png" alt="" /> -->
            </div>
            <!-- <div class="open-text">
              成功率 <span class="green">({{ value3 }}%)</span> / 失败率
              <span class="red">({{ 100 - value3 }}%)</span>
              <img src="../assets/img/money.png" style="width: 20px" />
              {{ ((price * value3) / 100).toFixed(2) }}
            </div> -->
          </div>
          <!-- 电脑端按钮 -->
          <div class="upBtn" @click="upgrade">
            <div class="MoneyCoin">
              <img src="../assets/img/money.png" style="width: 40px" />
              <span class="moneyVal" style="color: rgb(255, 255, 255)">{{
                ((price * value3) / 100).toFixed(2)
              }}</span>
            </div>
            <span>追梦</span>
          </div>

          <!-- <div class="open-btn">
            <el-button
              :disabled="disabled"
              class="open-btnwarp"
              @click="upgrade"
            >
            </el-button>
          </div> -->

          <!-- <div class="open-anim">
						<div v-show="showanim" class="open-line" ref="openLine"></div>
						<div class="open-anim-warp" :style="{ width: openWidth + 'px' }">
							<ul :class="openAnimState ? 'ul1' : ''" ref="ul">
								<li v-for="(item, index) in openAnimList" :key="index" :style="{
		            backgroundImage: 'url(' + item.back + ')',
		          }" :class="item.name != '赠品' ? 'li1' : ''">
									<img :src="item.img" />
								</li>
							</ul>
						</div>
					</div> -->
        </div>
      </div>
      <div class="tips">*追梦概率范围为5%~75%</div>
      <div style="color: white" v-if="false">
        <div class="ulss">
          <div
            class=""
            :class="{ wode: wodes == '1' }"
            @click="
              wodes = '1';
              getluckyhist1();
            "
          >
            最近掉落
          </div>
          <div
            class=""
            :class="{ wode: wodes == '2' }"
            @click="
              wodes = '2';
              getluckyhist();
            "
          >
            我的掉落
          </div>
        </div>
        <div class="uls">
          <div>合成结果</div>
          <div>用户</div>
          <div>饰品图片</div>
          <div>获得饰品外观</div>
          <div>获得饰品价值</div>
          <div>花费</div>
          <div>拉取量</div>
          <div>目标饰品名称</div>
          <div>目标饰品外观</div>
          <div>时间</div>
        </div>
        <div
          v-for="item in tableData"
          :class="item.status == '成功' ? 'urls1 selects' : 'urls1'"
          v-if="item.img"
        >
          <div class="first">
            <span>{{ item.status }}</span>
          </div>
          <div class="flex">
            <img
              :src="item.img"
              style="width: 50px; height: 50px; border-radius: 50%"
              alt=""
            />
            <span style="margin-top: 8px">{{ item.name }}</span>
          </div>
          <div class="flex" v-if="item.status == '成功'">
            <img :src="item.imageUrl" alt="" />
            <span>{{ getFrist(item.skins) }} | {{ getLast(item.skins) }}</span>
          </div>
          <div class="flex" v-else>
            <img
              src="../assets/img/duizhan/jb.png"
              style="width: 70px; height: 100%"
              alt=""
            />
            <!-- <span>{{ tableData["loser_info"] && tableData["loser_info"].itemName }}</span> -->
            <span>凉凉奖励</span>
          </div>
          <div class="first">
            {{
              item.status == "成功"
                ? extractValuesInParentheses(item.skins)
                : "无"
            }}
          </div>
          <div class="first">
            <span>{{ item.status == "成功" ? item.value : "0.01" }}</span>
          </div>
          <div class="first">
            <span>{{ item.price && Number(item.price).toFixed(2) }}</span>
          </div>
          <div>{{ item.probability }}%</div>
          <div class="first" style="width: 350px">
            <span>{{ getFrist(item.skins) }} | {{ getLast(item.skins) }}</span>
          </div>
          <div class="first">
            {{ extractValuesInParentheses(item.skins) }}
          </div>
          <div class="send" style="text-align: center">{{ item.time }}</div>
        </div>
      </div>
    </div>
    <div class="oran-warpv" v-else>
      <!-- <div class="extension_index_img">
        <img src="../assets/img/duizhan/zhumeng.png" alt="" />
      </div> -->
      <!-- <div class="oran-top">
				<div class="orantop-left">幸运饰品</div> -->
      <!-- 暂不开放 -->
      <!-- <div @click="goHistory" class="orantop-right">饰品升级记录</div> -->
      <!-- </div> -->

      <div class="oran-sel" style="position: relative">
        <!-- <div class="guize" @click="tanchuang = true">活动规则</div> -->
        <div class="open-line" ref="openLine">
          <div class="selectEquipment">
            <div class="selectTop">
              <div class="skinBox">
                <img
                  src="../assets/img/duizhan/Accessory.ebd36d8d.png"
                  alt=""
                  class="skin"
                  v-if="!obj" @click="isbot = true"
                />
                  <img class="skin"  v-else :src="obj.imageUrl" />
                <!-- <div class="img"> -->
                  <!-- <div>
                    <div class="name">{{ obj.itemName }}</div>
                  </div>
                  <div>
                    <img src="../assets/img/money.png" />
                    <div class="name">{{ obj.price }}</div>
                  </div> -->
                <!-- </div> -->
              </div>
              <p>{{!obj?'选择饰品': obj.itemName}}</p>
            </div>
          </div>
          <img
            class="arrow _mobile"
            src="../assets/img/duizhan/arrow.912f70f1.gif"
            alt=""
          />
          <div class="selectEquipment random">
            <div class="selectTop">
              <div class="skinBox">
                <img
                  src="../assets/img/duizhan/random.3d6be635.png"
                  alt=""
                  class="skin"
                />
              </div>
              <p>随机饰品</p>
            </div>
          </div>
        </div>
        <div class="open">
          <div class="open-hint 22">
            <!-- <div class="open-hint2">
							<span>玩法介绍和特别说明</span>
							<i class="el-icon-question"></i>
						</div> -->
            <!-- <div @click="ChangeSoundType" class="open-hint3">
							<img :src="sound.icon" />
						</div> -->
          </div>

          <div class="open-box">
            <!-- <div class="box-one"><img src="../assets/img/left.png" /></div>
						<div class="box-two">
							<span>随机物品</span>
							<img src="../assets/img/box1.png" />
						</div>
						<div class="box-three">{{ value3 }}%</div> -->
            <div class="box-four1" @click="isbot = true">
              <!-- <div class="four-top">幸运饰品</div> -->
              <div class="four-bot">
                <div class="img1">
                  <!-- <img
                    src="../assets/img/duizhan/rotateAnimate.a46ab407.png"
                    class="skin"
                  /> -->
                  <div class="svgCircular">
                    <div class="progress">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 400 400"
                        style="transform: rotateY(0deg)"
                      >
                        <defs>
                          <linearGradient
                            id="greenGradient"
                            x1="100%"
                            y1="75%"
                            x2="0%"
                            y2="0%"
                          >
                            <stop offset="0%" stop-color="#7713ff"></stop>
                            <stop offset="33%" stop-color="#ff0f48"></stop>
                            <stop offset="50%" stop-color="#ff6204"></stop>
                            <stop offset="100%" stop-color="#ffb400"></stop>
                          </linearGradient>
                        </defs>
                        <circle
                          cx="200"
                          cy="200"
                          r="192.5"
                          stroke="#1A1C22"
                          stroke-width="15"
                          fill="none"
                        ></circle>
                        <circle
                          class="progress-bar"
                          cx="200"
                          cy="200"
                          r="192.5"
                          stroke="url(#greenGradient)"
                          stroke-width="15"
                          stroke-linecap="butt"
                          fill="url(/img/Gradientbg.2720a6b0.png)"
                          transform="rotate(-90, 200, 200)"
                          stroke-dasharray="1209.5131716320705"
                          :stroke-dashoffset="
                            dashoffset - (value3 - 5) * 12.095131716321
                          "
                        ></circle>
                      </svg>
                      <div class="progress-desc">
                        <div class="valBox">
                          <span class="val">5</span
                          ><span class="symbol">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    class="bos2"
                    :class="disabled ? 'kaijiang' : ''"
                    :style="[
                      { '--bg-qudu': value3 * 3.6 + 'deg' },
                      { '--bg-qudus': value4 + 'deg' },
                    ]"
                    src="../assets/img/duizhan/upgrade__rotary__pointer.83b9d058.png"
                    alt=""
                  />
                </div>
                <div
                  class="bos"
                  :style="!disabled || !animState ? '' : 'opacity: 0;'"
                >
                  {{ value3 }}<span>%</span>
                </div>
                <!-- <div class="img" v-else @click="isbot = true">
                  <img class="bbc" :src="obj.imageUrl" />
                  <div>
                    <div class="name">{{ obj.itemName }}</div>
                  </div>
                  <div>
                    <img src="../assets/img/money.png" />
                    <div class="name">{{ obj.price }}</div>
                  </div>
                </div> -->
                <!-- <img class="por" src="../assets/img/top.png" /> -->
              </div>
            </div>
            <!-- <div class="box-five"><img src="../assets/img/right.png" /></div> -->
          </div>

          <div style="position: relative">
            <div class="bos3" v-if="disabled && animState && !winFalse">
              <img src="../assets/img/duizhan/y2.png" alt="" />
              <div>
                <span class="luky">升级失败</span>
                <span>{{ value3 }}%</span>
              </div>
            </div>
            <div class="bos3" v-if="disabled && animState && winFalse">
              <img src="../assets/img/duizhan/y1.png" alt="" />
              <div>
                <span class="luky">升级成功</span>
                <span>{{ value3 }}%</span>
              </div>
            </div>
            <div class="open-pro">
              <div class="pro-con">
                <el-slider
                  v-model="value3"
                  :show-tooltip="false"
                  :min="0"
                  :max="75"
                  @change="changeNum"
                ></el-slider>
              </div>
              <!-- <img src="../assets/img/duizhan/kedu.png" alt="" /> -->
            </div>
            <!-- <div class="open-text">
              成功率 <span class="green">({{ value3 }}%)</span> / 失败率
              <span class="red">({{ 100 - value3 }}%)</span>
              <img src="../assets/img/money.png" style="width: 20px" />
              {{ ((price * value3) / 100).toFixed(2) }}
            </div> -->
          </div>
          <div class="upBtn" @click="upgrade">
            <div class="MoneyCoin">
              <img src="../assets/img/money.png" style="width: 40px" />
              <span class="moneyVal" style="color: rgb(255, 255, 255)">{{
                ((price * value3) / 100).toFixed(2)
              }}</span>
            </div>
            <span>追梦</span>
          </div>
          <!-- <div class="open-btn">
            <el-button
              :disabled="disabled"
              class="open-btnwarp"
              @click="upgrade"
            >
            </el-button>
          </div> -->
          <div class="tips">*追梦概率范围为5%~75%</div>
          <!-- <div class="open-anim">
						<div v-show="showanim" class="open-line" ref="openLine"></div>
						<div class="open-anim-warp" :style="{ width: openWidth + 'px' }">
							<ul :class="openAnimState ? 'ul1' : ''" ref="ul">
								<li v-for="(item, index) in openAnimList" :key="index" :style="{
		            backgroundImage: 'url(' + item.back + ')',
		          }" :class="item.name != '赠品' ? 'li1' : ''">
									<img :src="item.img" />
								</li>
							</ul>
						</div>
					</div> -->
        </div>
      </div>
      <div class="ulss" v-if="false">
        <div
          class=""
          :class="{ wode: wodes == '1' }"
          @click="
            wodes = '1';
            getluckyhist1();
          "
        >
          最近掉落
        </div>
        <div
          class=""
          :class="{ wode: wodes == '2' }"
          @click="
            wodes = '2';
            getluckyhist();
          "
        >
          我的掉落
        </div>
      </div>
      <div style="color: white" class="bottombox" v-if="false">
        <div class="uls">
          <!-- <div>合成结果</div> -->
          <div>用户</div>
          <div>饰品图片</div>
          <div>获得饰品外观</div>
          <div>获得饰品价值</div>
          <div>花费</div>
          <div>拉取量</div>
          <div>目标饰品名称</div>
          <div>目标饰品外观</div>
          <div>时间</div>
        </div>
        <div
          v-for="item in tableData"
          :class="item.status == '成功' ? 'urls1 selects' : 'urls1'"
        >
          <!-- <div class="first">
						<span>{{item.status}}</span>
					</div> -->
          <div class="flex">
            <img
              :src="item.img"
              style="width: 50px; height: 50px; border-radius: 50%"
              alt=""
            />
            <span style="margin-top: 8px">{{ item.name }}</span>
          </div>
          <div class="flex" v-if="item.status == '成功'">
            <img :src="item.skin_img" alt="" />
            <span>{{ getFrist(item.skins) }} | {{ getLast(item.skins) }}</span>
          </div>
          <div class="flex" v-else>
            <img
              :src="require('../assets/img/duizhan/jb.png')"
              style="width: 70px; height: 100%"
              alt=""
            />
            <span>凉凉奖励</span>
          </div>
          <div class="first">
            {{
              item.status == "成功"
                ? extractValuesInParentheses(item.skins)
                : "无"
            }}
          </div>
          <div class="first">
            <span>{{ item.status == "成功" ? item.value : "0.01" }}</span>
          </div>
          <div class="first">
            <span>{{ item.price && Number(item.price).toFixed(2) }}</span>
          </div>
          <div>{{ item.probability }}%</div>
          <div class="first" style="width: 350px">
            <span>{{ getFrist(item.skins) }} | {{ getLast(item.skins) }}</span>
          </div>
          <div class="first">
            {{ extractValuesInParentheses(item.skins) }}
          </div>
          <div class="send" style="text-align: center">{{ item.time }}</div>
        </div>
      </div>
    </div>

    <div class="win-box" v-if="winState">
      <mywin
        :winList="list4"
        :winState="winState"
        :isis="false"
        @winexchange="winexchange($event)"
        @winget="winget($event)"
        @winX="winX($event)"
      ></mywin>
    </div>

    <audio
      controls
      ref="notify"
      class="audio"
      loop="loop"
      style="display: none"
    >
      <source src="../assets/audio/bgm_zhuanbei.mp3" />
    </audio>
    <audio
      controls
      ref="notifys"
      class="audio"
      loop="loop"
      style="display: none"
    >
      <source src="../assets/audio/succeed.mp3" />
    </audio>
    <navheader :showNav="showNav" :yidongmenu="yidongmenu"></navheader>
  </div>
</template>

<script>
import myslide from "@/components/my_slide1.vue";
import navheader from "@/components/navheader.vue";
import Utils from "../assets/js/util.js";
import mywin from "@/components/my_win.vue";
export default {
  components: {
    myslide,
    mywin,
    navheader,
  },
  props: ["yidongmenu", "showNav"],
  data() {
    return {
      pirce1: "",
      pirce2: "",
      search: "",
      list: [],
      classList: [],
      selList: [],
      listSlide: [],
      classObj: [
        {
          name: "全部",
          flag: 0,
          skin_type_id: "",
          state: true,
        },
      ],

      // 0 未排序  desc反序，ace正序
      sortType: "asc",
      // 关键词
      searchKey: "",
      // 品质
      pzList: [],
      // 外观
      wgList: [],
      // 品质选中
      rarity: "不限",
      // 外观选中
      exterior: "不限",
      // 主分类id
      classId: "1",
      // 小分类id
      subClassId: "all",
      // 推荐
      recommend: 1,
      openWidth: 8610,
      openAnimState: false,
      openAnimList: [],
      disabled: false,
      winFalse: true,
      loading: false,
      pirce1: "",
      openBox: false,
      pirce2: "",
      search: "",
      value3: 5,
      wodes: 1,
      tanchuang: false,
      value4: 0,
      list: [
        {
          name: 1,
        },
        {
          name: 12,
        },
        {
          name: 13,
        },
        {
          name: 14,
        },
        {
          name: 15,
        },
        {
          name: 16,
        },
        {
          name: 17,
        },
        {
          name: 18,
        },
        {
          name: 19,
        },
        {
          name: 111,
        },
        {
          name: 123,
        },
        {
          name: 143,
        },
      ],
      MinPrice: "0",
      MaxPrice: "",
      list4: [],
      winState: false,
      price: "",
      animState: false,
      obj: "",
      noWin: {
        name: "赠品",
        price: "0.01",
        img: require("../assets/img/moneyback.png"),
        imageUrl: require("../assets/img/moneyback.png"),
        class: "img-class",
        back: require("../assets/img/box-skins-white.png"),
      },
      valueState: false,
      sound: {
        maxIcon: require("../assets/img/SOUNDPLUS.png"),
        minIcon: require("../assets/img/SOUNDMINUS.png"),
        status: true, // true： 开启声音 false: 关闭声音
        icon: require("../assets/img/SOUNDPLUS.png"),
      },
      showanim: true, // 是否显示箱子列表
      totalSize: 0,
      page: 1,
      pageSize: 15,
      tableData: [],
      totalSize: 0,
      numSquares: 115, // 调整方块数量
      circleRadius: 125, // 圆的半径，根据你的情况调整
      circleRadiuss: 110, // 圆的半径，根据你的情况调整
      skin_id: this.$route.query.skin_id,
      isbot: false,
      dashoffset: 1149.037513050467,
    };
  },
  watch: {
    searchKey: {
      handler(value) {
        if (value.length) return;
        this.getList();
      },
    },

    // 监听一级分类选中获取二级分类
    classId: {
      handler(value) {
        if (!value) return;
        this.classList = [
          {
            name: "全部",
            id: "all",
          },
        ];
        this.subClassId = "all";
        this.$axios
          .post(
            "/index/Lucky/subclassList",
            this.$qs.stringify({
              type_id: value,
            })
          )
          .then((res) => {
            if (res.data.status) {
              this.classList.push(...res.data.data.list);
              this.subClassId = this.classList[0].id;
            }
          });
      },
    },
  },
  computed: {
    angles() {
      const angles = [];
      const startAngle = -Math.PI / 2; // 从圆的左上方开始
      for (let i = 0; i < this.numSquares; i++) {
        const angle = startAngle + (i / this.numSquares) * 2 * Math.PI;
        angles.push(angle);
      }
      return angles;
    },
  },
  mounted() {
    if (this.showNav) {
      this.openWidth =
        this.openWidth - this.$refs.openLine.getBoundingClientRect().left - 950;
    } else {
      this.openWidth =
        this.openWidth - this.$refs.openLine.getBoundingClientRect().left - 100;
    }

    this.getSkinDetail(this.obj.id);
    this.getluckyhist1(this.page);
    this.getList();
    this.getListClass();
    this.getTagAndPz();
  },
  methods: {
    goRule(){
      this.$router.push({
        path: `/ruleDream`,
      });
    },
    extractValuesInParentheses(input) {
      const regex = /\((.*?)\)/g;
      const matches = [];
      let match;

      while ((match = regex.exec(input)) !== null) {
        matches.push(match[1]);
      }

      return matches[0];
    },
    getFrist(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[0];
    },
    getLast(e) {
      const resultArray = this.splitAndRemoveParentheses(e);
      return resultArray[1];
    },
    splitAndRemoveParentheses(input) {
      const parts = input.split("|");
      const result = parts.map((part) => {
        const indexOfParenthesis = part.indexOf("(");
        if (indexOfParenthesis !== -1) {
          return part.substring(0, indexOfParenthesis).trim();
        }
        return part.trim();
      });
      return result;
    },
    getTagAndPz() {
      this.$axios
        .post("/index/Store/exterior", this.$qs.stringify({}))
        .then((res) => {
          const { exterior, rarity } = res.data.data;
          this.pzList = ["不限"].concat(rarity.map((item) => item.rarityName));
          this.wgList = ["不限"].concat(
            exterior.map((item) => item.exteriorName)
          );
        });
    },
    onHandledSort() {
      if (this.sortType == "desc") {
        this.sortType = "asc";
      } else if (this.sortType == "asc") {
        this.sortType = "desc";
      }
      this.getList();
    },
    //分类
    getListClass() {
      this.$axios.post("/index/Lucky/luckyTypeList").then((res) => {
        let data = res.data;
        if (data.status == 1) {
          this.selList = data.data;
          for (let i = 0; i < this.selList.length; i++) {
            this.selList[i].state = false;
          }
          this.selList[0].state = true;
        }
      });
    },
    //默认推荐列表
    getList() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
        // recommend: this.recommend,
      };
      if (this.sortType) {
        param["sort"] = this.sortType;
      }
      if (this.MinPrice) {
        param["minPrice"] = this.MinPrice;
      }
      if (this.MaxPrice) {
        param["maxPrice"] = this.MaxPrice;
      }
      if (this.searchKey) {
        param["searchKey"] = this.searchKey;
      }

      if (this.classId && this.classId != "all") {
        param["type_id"] = this.classId;
      }
      if (this.subClassId && this.subClassId != "all") {
        param["subclass_id"] = this.subClassId;
      }
      if (this.rarity && this.rarity != "不限") {
        param["rarity"] = this.rarity;
      }
      if (this.exterior && this.exterior != "不限") {
        param["exterior"] = this.exterior;
      }

      if (this.classId == "1") {
        param["recommend"] = 1;
        delete param.type_id;
      }

      this.$axios
        .post("/index/Lucky/skinList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          this.list = data.data.list;
          this.totalSize = data.data.total;
          //分类中小分类
          // let param = {
          // 	type_id: "1",
          // };
          // this.$axios
          // 	.post("/index/Lucky/subclassList", this.$qs.stringify(param))
          // 	.then((res) => {
          // 		let data = res.data;
          // 		if (data.status == 1) {
          // 			// console.log(data)
          // 			// if (data.data.list.length != 0) {
          // 			// 	for (let i = 0; i < data.data.list.length; i++) {
          // 			// 		this.classObj.push(data.data.list[i]);
          // 			// 	}
          // 			// }
          // 			// this.classList = this.classObj;
          // 		}
          // 	});
        });
    },
    //点击分类获取列表
    selOran(status, id) {
      console.log(status, id);
      this.recommend = status == 2 ? 1 : 2;
      this.classId = id;
      for (let i = 0; i < this.selList.length; i++) {
        if (id == this.selList[i].id) {
          this.selList[i].state = true;
        } else {
          this.selList[i].state = false;
        }
      }
      this.page = 1;

      if (status == 2 && id == 1) {
        var param = {
          page: this.page,
          pageSize: this.pageSize,
          recommend: 1,
          rarity: this.rarity != "不限" ? this.rarity : "",
          exterior: this.exterior != "不限" ? this.exterior : "",
        };
        var param1 = {
          type_id: "1",
        };
      }
      if (status == 2 && id == 2) {
        var param = {
          page: this.page,
          pageSize: this.pageSize,
          type_id: id,
          rarity: this.rarity != "不限" ? this.rarity : "",
          exterior: this.exterior != "不限" ? this.exterior : "",
        };
        var param1 = {
          type_id: id,
        };
      }
      if (status == 1) {
        var param = {
          page: this.page,
          pageSize: this.pageSize,
          type_id: id,
          rarity: this.rarity != "不限" ? this.rarity : "",
          exterior: this.exterior != "不限" ? this.exterior : "",
        };
        var param1 = {
          type_id: id,
        };
      }

      // console.log(param, param1);

      //列表数据
      // this.$axios
      //   .post("/index/Lucky/skinList", this.$qs.stringify(param))
      //   .then((res) => {
      //     let data = res.data;
      //     //  console.log(res.data);
      //     if (data.status == "1") {
      //       this.list = data.data.list;
      //     } else {
      //       this.list = [];
      //     }
      //   });
      this.getList();
      this.$forceUpdate();
    },
    //点击小分类
    selClass(item) {
      console.log(item);
      this.subClassId = item.id;
      for (let i = 0; i < this.classList.length; i++) {
        if (item.id == this.classList[i].id) {
          this.classList[i].state = true;
        } else {
          this.classList[i].state = false;
        }
      }
      if (item.flag == 0) {
        if (this.classId == 1) {
          var param = {
            page: this.page,
            pageSize: this.pageSize,
            recommend: 1,
          };
        } else {
          var param = {
            page: this.page,
            pageSize: this.pageSize,
            type_id: item.skin_type_id,
          };
        }
      } else {
        if (this.classId == 1) {
          var param = {
            page: this.page,
            pageSize: this.pageSize,
            recommend: 1,
            subclass_id: item.id,
          };
        } else {
          var param = {
            page: this.page,
            pageSize: this.pageSize,
            type_id: item.skin_type_id,
            subclass_id: item.id,
          };
        }
      }

      //console.log(param);
      //列表数据
      // this.$axios
      //   .post("/index/Lucky/skinList", this.$qs.stringify(param))
      //   .then((res) => {
      //     let data = res.data;
      //     if (data.status == "1") {
      //       this.list = data.data.list;
      //     } else {
      //       this.list = [];
      //     }
      //   });
      this.getList();
    },
    goOrnamentOpen(item) {
      // this.$router.push({
      // 	path: `/OrnamentOpen`,
      // 	query: {
      // 		skin_id: item.id,
      // 		// item: JSON.stringify(item),
      // 	},
      // });
      this.isbot = false;
      this.getSkinDetail(item.id);
      this.wodes = 1;
      this.getluckyhist1();
      this.getList();
    },
    getluckyhist1() {
      let param = {
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Lucky/LuckyHistory2", this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.totalSize = data.data.total;
            this.tableData = data.data.list;
          }
        });
    },
    //获得历史
    goHistory() {
      this.$router.push({
        path: `/OrnamentHistory2`,
      });
    },
    getDeg() {
      var random = this.randomFrom(0, this.value3);
      var random1 = this.randomFrom(this.value3, 100);
      console.log(random, random1);
      if (this.winFalse) {
        return 100 * 3.6 * 5 + random * 3.6;
      }
      return 100 * 3.6 * 5 + random1 * 3.6;
    },
    getSquareStyle(angle) {
      var x = this.circleRadius + this.circleRadius * Math.cos(angle);
      var y = this.circleRadius + this.circleRadius * Math.sin(angle);
      if (!this.showNav) {
        x = this.circleRadiuss + this.circleRadiuss * Math.cos(angle);
        y = this.circleRadiuss + this.circleRadiuss * Math.sin(angle);
      }
      const rotation = angle + Math.PI / 2; // 调整方向

      return {
        top: y - 5 + "px",
        left: x - 5 + "px",
        transform: `rotate(${rotation}rad)`,
      };
    },
    randomFrom(lowerValue, upperValue) {
      return Math.floor(
        Math.random() * (upperValue - lowerValue + 1) + lowerValue
      );
    },
    getSkinDetail(id) {
      let param = {
        skin_id: id,
      };
      this.$axios
        .post("/index/Lucky/skin_info", this.$qs.stringify(param))
        .then((res) => {
          const data = res.data;
          if (data.status == 1 && data.data != undefined) {
            this.obj = data.data[0];
            this.obj.img = this.obj.imageUrl;
            this.obj.back = require("../assets/img/lucky.jpg");
            this.price = this.obj.price;
            this.disruptArr();
            this.getluckyhist();
          }
        });
    },
    // 是否静音 切换
    ChangeSoundType() {
      if (this.sound.status) {
        this.sound.status = false;
        this.sound.icon = this.sound.minIcon;
      } else {
        this.sound.status = true;
        this.sound.icon = this.sound.maxIcon;
      }
    },
    // 打乱数组
    getRandomArr(arr, num) {
      var _arr = arr.concat();
      var n = _arr.length;
      var result = [];

      // 先打乱数组
      while (n-- && num--) {
        var index = Math.floor(Math.random() * n); // 随机位置
        [_arr[index], _arr[n]] = [_arr[n], _arr[index]]; // 交换数据
        result.push(_arr[n]); // 取出当前最后的值，即刚才交换过来的值
      }
      return result;
    },
    //拖动滑动条 鼠标松开
    changeNum() {
      if (this.value3 <= 5) {
        this.value3 = 5;
      }
      this.disruptArr();
    },
    //打乱滚动数据
    disruptArr() {
      if (this.loading) {
        return;
      }
      this.openAnimList = [];
      let numImg = parseInt((50 * this.value3) / 100);
      console.log(numImg);
      if (numImg < 13) {
        numImg = 13;
      }
      for (let i = 0; i < 50; i++) {
        if (i < numImg) {
          this.openAnimList.push(this.obj);
        } else {
          this.openAnimList.push(this.noWin);
        }
      }
      this.openAnimList = this.getRandomArr(this.openAnimList, 50);
      this.openAnimList[0] = this.obj;
    },
    goOrnament(idnex) {
      this.$router.push({
        path: `/Ornament`,
      });
    },
    //点击兑换
    winexchange() {
      let param = {
        player_skins_ids: [this.list4[0].player_skin_id],
      };
      this.$axios
        .post("/index/User/exchangeToMoney2", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.status == "1") {
            //改变动画
            this.openAnimState = false;
            this.winState = false;
            this.showanim = true;
            this.$store.commit("getMoney", res.data.data.total_amount);
            Utils.$emit("money", data.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          }
        });
    },
    //放入背包
    winget() {
      this.showanim = true;
      this.winState = false;
      //改变动画
      this.openAnimState = false;
    },

    //点击升级
    async upgrade(event) {
      if (!this.obj) {
        this.$message({
          message: "请先选择饰品",
          type: "warning",
        });
        return;
      }
      if (this.value3 < 5) {
        this.$message({
          message: "成功率不能低于5%",
          type: "warning",
        });
        return;
      }
      let _this = this;
      var money = parseFloat(this.$parent.money);
      var masonry = parseFloat(this.$parent.masonry);
      var totalPrice = (this.price * this.value3) / 100;
      if (money < totalPrice && money + masonry >= totalPrice) {
        var duihuanmoney = totalPrice - money;
        var params = {
          coin: duihuanmoney,
        };
        var a = await this.$axios.post(
          "index/User/exchange",
          this.$qs.stringify(params)
        );
        if (a.data.msg == "兑换成功") {
          var newMoney = money + duihuanmoney;
          var newMasonry = masonry - duihuanmoney;
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          userInfo.total_amount = newMoney;
          userInfo.masonry = newMasonry;
          //userInfo.masonry = masonry
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
      }
      this.showanim = true;

      this.winState = false;
      this.loading = true;
      this.disabled = true;
      let param = {
        skin_id: this.obj.id,
        probability: this.value3,
      };
      this.animState = false;
      this.$axios
        .post("/index/Lucky/getSkin", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.msg.includes("中奖")) {
            if (this.sound.status) {
              this.playAlarm(); // 播放BGM
            }
            this.openAnimState = true;
            this.list4 = [];
            if (data.status == 1) {
              this.winFalse = true;
              this.value4 = this.getDeg();
              this.$store.commit("getMoney", data.data.total_amount.toFixed(2));
              Utils.$emit("money", data.data.total_amount);
              data.data.back = require("../assets/img/lucky.jpg");
              this.openAnimList[42] = data.data;
              this.$forceUpdate();
              setTimeout(() => {
                this.animState = true;
                this.playAlarm2();
              }, 4000);
              setTimeout(() => {
                this.loading = false;
                this.disabled = false;
                // this.openAnimState = false;
                this.winState = true;
                this.showanim = false;
                this.list4 = {
                  skins_info: [data.data],
                  player_skins_ids: [data.data.player_skin_id],
                };
                this.playAlarm3(); // 播放BGM
                if (this.wodes == 1) {
                  this.getluckyhist1();
                } else {
                  this.getluckyhist();
                }
                // 关闭BGM
              }, 6780);
              //this.playAlarm2();  // 关闭BGM
            } else {
              this.winFalse = false;
              this.value4 = this.getDeg();
              this.$store.commit(
                "getMoney",
                Number(data.data.total_amount.toFixed(2)) - 0.01
              );
              Utils.$emit("money", data.data.total_amount - 0.01);
              this.openAnimList[42] = this.noWin;
              this.$forceUpdate();
              setTimeout(() => {
                this.animState = true;
                this.playAlarm2();
              }, 4000);
              setTimeout(() => {
                this.$store.commit(
                  "getMoney",
                  data.data.total_amount.toFixed(2)
                );
                Utils.$emit("money", data.data.total_amount);
                this.loading = false;
                this.disabled = false;
                //this.openAnimState = false;
                this.list4 = {
                  skins_info: [
                    {
                      price: data.data["skins_info"]["price"],
                      name: data.data["skins_info"]["itemName"],
                      img: data.data["skins_info"]["imageUrl"],
                      ccc: true,
                    },
                  ],
                  player_skins_ids: [data.data.player_skin_id],
                };
                console.log(this.list4);
                this.winState = true;
                this.showanim = false;
                this.playAlarm3(); // 播放BGM
                if (this.wodes == 1) {
                  this.getluckyhist1();
                } else {
                  this.getluckyhist();
                }
                // 关闭BGM
              }, 6780);
              //this.playAlarm2();  // 关闭BGM
            }
          } else {
            this.loading = false;
            this.disabled = false;
            if (data.msg == "账户余额不足") {
              this.$message({
                message: "账户余额不足",
                type: "warning",
              });
              this.playAlarm2(); // 关闭BGM
            } else if (data.msg == "饰品信息不存在") {
              this.$message({
                message: "饰品信息错误，请联系客服",
                type: "warning",
              });
            } else if (data.msg == "缺少玩家信息") {
              this.$message({
                message: "请先登录",
                type: "warning",
              });
              this.$store.commit("getLogin", true);
            } else if (data.msg == "不支持该玩法") {
              this.$message({
                message: "新用户仅限于注册开箱",
                type: "warning",
              });
              this.playAlarm2(); // 关闭BGM
            }
          }
        });
    },
    //子组件修改父组件
    winexchange(msg) {
      this.winState = msg;
    },
    currentchanges(e) {
      this.page = e;
      this.getList();
    },
    winX() {
      //改变动画
      this.openAnimState = false;
      this.winState = false;
      this.showanim = true;
    },
    //音乐 播放
    playAlarm() {
      this.$refs.notify.play();
    },
    //音乐 播放
    playAlarm3() {
      var that = this;
      this.$refs.notifys.play();
      setTimeout(function () {
        that.$refs.notifys.pause();
        that.$refs.notifys.currentTime = 0.0;
      }, 600);
    },
    //音乐 结果暂停
    playAlarm2() {
      this.$refs.notify.pause();
      this.$refs.notify.currentTime = 0.0;
    },
    //获得历史
    goHistory() {
      // console.log('goHistory');
      this.$router.push({
        path: `/OrnamentHistory`,
        query: {
          skin_id: this.obj.id,
        },
      });
    },
    getluckyhist(page) {
      let param = {
        // skin_id: this.obj.id,
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Lucky/userHistory", this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.wodes = 2;
            this.tableData = data.data.list;
          }
        });
    },
    //分页 所有记录
    currentChange(val) {
      this.getluckyhist(val);
    },

    goLucky() {
      this.$router.push({
        path: `/Ornament`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.win-box {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
}

.bos3 {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 99999;
  animation: sss 0.5s linear;
  animation-fill-mode: both;

  @keyframes sss {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 150px;
    height: 50px;
  }

  div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 32px;
    color: white;
    font-family: "微软雅黑";

    span:last-child {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

/deep/.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

/deep/.el-pagination .btn-prev,
.btn-next {
  background: none !important;
}

/deep/.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: none !important;
}

/deep/.el-pager li.active {
  background: none;
  color: white;
  background-color: rgb(48 136 255) !important;
  border-radius: 50%;
  min-width: 28px !important;
  font-family: "微软雅黑";
}

/deep/.el-pager li {
  background: none;
  color: white;
  min-width: 28px !important;
  font-family: "微软雅黑";
}

/deep/.el-pagination button:disabled {
  background: none;
}

.tanchuang {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1200;
  background: rgba(0, 0, 0, 0.641);
  display: flex;
  align-items: center;
  justify-content: center;

  .boxs {
    width: 700px;
    left: calc(50% - 350px);
    top: 15vh;
    background-color: rgba(0, 0, 0, 0.641) !important;
    border-radius: 5px !important;
    border: 1px solid #3a4525;
    box-shadow: 0 17px 57px 0 rgba(0, 0, 0, 0.77);
    color: white;
    padding: 32px;
    line-height: 48px;
    text-align: center;
  }
}

.bbt {
  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1200;
  background: rgba(0, 0, 0, 0.641);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .tanchuang {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1200;
    background: rgba(0, 0, 0, 0.641);
    display: flex;
    align-items: center;
    justify-content: center;

    .boxs {
      width: 80% !important;
      left: calc(50% - 350px);
      top: 15vh;
      background-color: rgba(0, 0, 0, 0.641) !important;
      border-radius: 5px !important;
      border: 1px solid #3a4525;
      box-shadow: 0 17px 57px 0 rgba(0, 0, 0, 0.77);
      color: white;
      padding: 12px !important;
      line-height: 24px !important;
      text-align: center;

      p {
        margin-top: 12px;
      }
    }
  }

  .orans-warp {
    padding: 16px 16px;
    width: 85% !important;
    height: 580px !important;
    margin: auto;
    background: rgba(0, 0, 0, 0.641);
    border: 1px solid #3a4525;
  }

  .wode {
    background: radial-gradient(
      101.95% 157% at 50% -23%,
      rgba(48, 136, 255, 0.0901960784) 0,
      rgba(48, 136, 255, 0.2352941176) 31.63%,
      rgb(48 136 255) 100%
    );
    border-radius: 4px;
    color: white;
    padding: 0 30px;
    height: 45px;
    cursor: pointer;
    line-height: 45px;
    font-size: 16px;
    display: inline-block;
  }

  .ulss {
    display: flex;
    width: 100vw;
    padding: 12px !important;
    font-weight: 700;
    color: #ccc;
    margin: 0 auto;
    margin-top: 32px;
  }

  .bottombox {
    overflow-x: auto;

    .uls {
      display: flex;
      width: 1600px !important;
      justify-content: space-between;
      padding: 24px 32px !important;
      background-color: hsla(0, 0%, 100%, 0.062745098);
      border-bottom: 1px solid rgba(222, 226, 230, 0.1176470588) !important;
      font-weight: 700;
      color: #ccc;
      margin: 0 auto;

      .sec {
      }

      div {
        width: 200px;
        text-align: center;
      }
    }

    .urls1 {
      display: flex;
      width: 1600px;
      margin: 0 auto;
      justify-content: space-between;
      padding: 0 32px;
      background-color: hsla(0, 0%, 100%, 0.062745098);
      border-bottom: 1px solid rgba(222, 226, 230, 0.1176470588) !important;
      font-weight: 700;
      color: #ccc;
      text-align: center;

      // padding: 32px;
      div {
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(169, 187, 205);
        font-weight: 600;
        font-family: "微软雅黑";
        font-size: 14px;
        padding: 0;
      }

      .flex {
        display: flex;
        flex-direction: column;
        font-size: 12px;

        img {
          width: 50%;
        }
      }

      .first {
        display: flex;
        align-items: center;

        span {
          margin-left: 12px;
        }
      }

      .sec {
        width: 200px;

        span {
          margin-left: 16px;
        }
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }

    .urls {
      display: flex;
      width: 1600px;
      overflow-x: auto;
      margin: auto;
      justify-content: space-between;
      // padding: 32px;
      margin-top: 12px;

      div {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(169, 187, 205);
        font-weight: 600;
        padding: 8px 24px;
        background-color: rgb(21, 26, 35);
      }

      .first {
        display: flex;
        align-items: center;

        span {
          margin-left: 12px;
        }
      }

      .sec {
        width: 400px;

        span {
          margin-left: 16px;
        }
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }

    .extension_index_img {
      img {
        width: 70%;
        padding-bottom: 42px;
      }
    }

    .bos3 {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 99999;
      animation: sss 0.5s linear;
      animation-fill-mode: both;

      @keyframes sss {
        0% {
          opacity: 0;
          -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0);
        }

        100% {
          opacity: 1;
          -webkit-transform: translate3d(0, -30%, 0);
          transform: translate3d(0, -30%, 0);
        }
      }

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 150px;
        height: 50px;
      }

      div {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 32px;
        color: white;
        font-family: "微软雅黑";

        span:last-child {
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
}

.orans-warp {
  padding: 16px 16px;
  width: 60%;
  height: 650px;
  margin: auto;
  background: rgba(0, 0, 0, 0.641);
  border: 1px solid #3a4525;

  @media screen and (max-width: 750px) {
    .roll-list {
      height: 44vh;
      overflow-y: auto;
      overflow-x: hidden;

      ul {
        li {
          width: 50% !important;

          .list-name {
            // text-align: center;
            font-size: 12px;
            color: white;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 4px 12px;
          }

          .list-bor {
            position: absolute;
            top: 0;
            z-index: 666;
            width: 100%;
            height: 100%;
            background-color: rgba(233, 177, 14, 0.1);
          }

          .ico {
            font-size: 12px;
            // color: #fafcfa;
            // background-color: rgb(81, 113, 156);
            color: white;
            padding: 8px 8px;
          }

          .buy-state {
            position: absolute;
            top: 0;
            right: 0;

            img {
              width: 15px;
              height: 15px;
            }
          }

          .list-img {
            // background-image: url("../assets/img/box-skins-blue.jpg");
            // background-image: url("../assets/img/backroll_pc.png");
            background: radial-gradient(
                101.95% 157% at 50% -23%,
                rgba(48, 136, 255, 0.0901960784) 0,
                rgba(48, 136, 255, 0.2352941176) 31.63%,
                rgb(48 136 255) 100%
              ),
              #21222b;
            overflow: hidden;
            height: 60px !important;
            display: flex;
            padding: 10px 10px !important;
            align-items: center;
            justify-content: space-between;

            span {
              font-family: "微软雅黑";
              font-size: 10px !important;
            }

            .pirce-left {
              span {
                font-size: 14px !important;
                color: #93e311 !important;
              }
            }

            // height: 130px;
            img {
              width: 70px !important;
            }

            div img {
              width: 15px !important;
            }
          }
        }
      }
    }
  }

  .roll-list {
    margin-top: 20px;

    ul {
      margin: 0 -4px;
      height: 430px;
      overflow-y: auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 240px;

        .list-warp {
          margin: 4px;
          color: white;
          font-size: 12px;
          border-radius: 5px;
          position: relative;
          overflow: hidden;

          .list-name {
            // text-align: center;
            font-size: 12px;
            color: white;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 4px 12px;
          }

          .list-bor {
            position: absolute;
            top: 0;
            z-index: 666;
            width: 100%;
            height: 100%;
            background-color: rgba(233, 177, 14, 0.1);
          }

          .ico {
            font-size: 12px;
            // color: #fafcfa;
            // background-color: rgb(81, 113, 156);
            color: white;
            padding: 8px 8px;
          }

          .buy-state {
            position: absolute;
            top: 0;
            right: 0;

            img {
              width: 15px;
              height: 15px;
            }
          }

          .list-img {
            // background-image: url("../assets/img/box-skins-blue.jpg");
            // background-image: url("../assets/img/backroll_pc.png");
            background: radial-gradient(
                101.95% 157% at 50% -23%,
                rgba(48, 136, 255, 0.0901960784) 0,
                rgba(48, 136, 255, 0.2352941176) 31.63%,
                rgb(48 136 255) 100%
              ),
              #21222b;
            overflow: hidden;
            height: 80px;
            display: flex;
            padding: 10px 20px;
            align-items: center;
            justify-content: space-between;

            // height: 130px;
            img {
              width: 100px;
            }
          }

          .bot {
            // background-color: #2b2c37;

            .list-pirce {
              padding: 0;
              display: flex;
              flex-direction: column;
              text-align: center;
              justify-content: center;

              .pirce-left {
                display: flex;
                align-items: center;
                color: rgb(48 136 255) !important;
                font-weight: 600;

                img {
                  width: auto;
                  height: 15px !important;
                  margin-right: 5px;
                }

                span {
                  font-size: 16px;
                }
              }

              .pirce-right {
                color: #848492;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .oran-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .orantop-left {
      color: white;
      font-size: 16px;
    }

    .orantop-right {
      padding: 6px 22px;
      background-color: #333542;
      border-radius: 5px;
      color: #848492;
      font-size: 15px;
      font-weight: 600;
    }

    .orantop-right:hover {
      cursor: pointer;
      background-color: #3a3f50;
    }
  }

  .shengjiang {
    padding: 4px 12px;
    background-color: #21212a;
    display: flex;
    align-items: center;
    color: white;

    span {
      margin-right: 6px;
      font-size: 14px;
      font-weight: 400 !important;
    }

    img {
      width: 12px;
    }
  }

  .oran-sels {
    // background: url("../assets/95/linebg.png") no-repeat;
    // background-size: 100% 100%;
    // border-top: 1px solid #e9b10e;
    // margin: 20px auto 0 auto;
    // background-color: #2b2c37;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 750px) {
      .sel-top {
        ul {
          // display: grid !important;
          // grid-template-columns: repeat(auto-fit, minmax(75px, 1fr)) !important;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;

          li {
            width: auto !important;
            margin-left: 0 !important;

            span {
              font-size: 12px !important;
              color: white;
              margin-left: 6px;
            }
          }
        }
      }

      .selbot-right {
        flex-direction: column !important;

        .input1 {
          width: auto !important;
          margin-top: 12px;
        }
      }
    }

    .sel-top {
      // border-bottom: 1px solid #e9b10e;
      // background-color: #1a1c24;

      ul {
        display: flex;

        li {
          margin-top: 12px;

          .seltop-warp {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 12px;

            img {
              width: 20px;
              height: auto;
              max-height: 78px;
            }

            span {
              font-size: 16px;
              color: white;
              margin-left: 6px;
            }
          }

          .seltop-warp1 {
            // background-color: #2b2c37;
            border-radius: 0 !important;

            span {
              color: white;
            }
          }
        }

        li:hover {
          cursor: pointer;
        }
      }
    }

    .class-box {
      // background-color: #2b2c37;
      padding: 0 !important;
      width: 100%;

      .class-list {
        margin-top: 15px;

        ul {
          display: flex;

          li {
            margin-right: 10px;

            span {
              border: 1px solid #ffc400;
              border-radius: 5px;
              padding: 5px 16px;
              font-size: 12px;
              color: #fff;
            }

            .span2 {
              border: 1px solid #ffc400;
              color: #fff;
              font-size: 12px;
            }

            span:hover {
              cursor: pointer;
              background-color: #ffc400;
              color: #000;
            }
          }
        }
      }
    }

    .sel-bot {
      display: flex;
      flex-wrap: wrap;

      // &>div {
      // 	margin: 30px 0 10px 0px;

      // 	&:last-child {
      // 		display: flex;
      // 		justify-content: flex-start;
      // 	}
      // }

      .selbot-left {
        flex: 1;
        min-width: 200px;
        min-height: 60px;

        .pz-container,
        .wg-container {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 5px 0;

          .name {
            color: #fff;
            width: 45px;
          }

          .wg-content {
            color: #848492;
            flex: 1;
            white-space: nowrap;
            overflow: auto;

            span {
              padding: 0 10px;
              cursor: pointer;

              &:hover {
                color: #e9b10e;
              }
            }

            .active {
              color: #e9b10e;
            }
          }
        }
      }

      .selbot-right {
        margin-top: 32px !important;
        min-width: 100% !important;
        // margin-left: 10px;
        display: flex;
        // align-items: center;
        justify-content: space-between;

        .span {
          margin: 0 8px;
          color: #848492;
        }

        .pirec-btn {
          margin: 0 10px;
          background-color: #333542;
          border-radius: 5px;
          color: #848492;
          font-size: 15px;
          font-weight: 600;
          padding: 10px 26px;
        }

        .pirec-btn:hover {
          cursor: pointer;
          background-color: #3a3f50;
        }

        .input {
          width: 120px;

          img {
            width: auto;
            height: 18px;
          }
        }

        .input /deep/ .el-input__prefix,
        .input /deep/ .el-input__suffix {
          top: 11px;
        }

        .input1 {
          width: 200px;
        }

        .input1-i:hover {
          cursor: pointer;
        }
      }

      .selbot-rb {
        // margin-left: 10px;
        display: flex;
        color: white;
        width: 350px;

        .shengjiang1 {
          display: flex;
          align-items: center;
          margin-left: 12px;
          border: 1px solid #21212a;
          padding: 0 12px;
          justify-content: center;
        }

        .zong {
          display: block;
          width: 44px;
          background: #3b3027;
          color: #bababa;
          border: none;
          height: 24px;
          text-align: center;
          margin-left: 4px;
          margin-right: 4px;
        }

        .span {
          margin: 0 8px;
          color: #848492;
        }

        .pirec-btn {
          margin: 0 10px;
          background-color: #333542;
          border-radius: 5px;
          color: #848492;
          font-size: 15px;
          font-weight: 600;
          padding: 10px 26px;
        }

        .pirec-btn:hover {
          cursor: pointer;
          background-color: #3a3f50;
        }

        .input {
          width: 120px;

          img {
            width: auto;
            height: 18px;
          }
        }

        .input /deep/ .el-input__prefix,
        .input /deep/ .el-input__suffix {
          top: 11px;
        }

        .input1 {
          width: 200px;
        }

        .input1-i:hover {
          cursor: pointer;
        }
      }

      .selbot-right /deep/ .el-input__inner {
        background-color: transparent;
        border: 1px solid #21212a;
        color: #c3c3e2;
      }
    }
  }
}

.ulss {
  display: flex;
  width: 1660px;
  padding: 24px 32px;
  font-weight: 700;
  color: #ccc;
  margin: 0 auto;
  margin-top: 32px;

  div {
    padding: 0 30px;
    height: 45px;
    cursor: pointer;
    line-height: 45px;
    font-size: 16px;
    display: inline-block;
    background: #000;
  }

  .wode {
    background-image: linear-gradient(0deg, #2c6a04, #4e8718);
    border-radius: 4px;
    color: white;
    padding: 0 30px;
    height: 45px;
    cursor: pointer;
    line-height: 45px;
    font-size: 16px;
    display: inline-block;
  }
}

.uls {
  display: flex;
  width: 1600px;
  justify-content: space-between;
  padding: 24px 32px;
  background-color: hsla(0, 0%, 100%, 0.062745098);
  border-bottom: 1px solid rgba(222, 226, 230, 0.1176470588) !important;
  font-weight: 700;
  color: #ccc;
  margin: 0 auto;

  .sec {
    width: 30%;
  }

  div {
    width: 300px;
    text-align: center;
  }
}

.urls {
  display: flex;
  width: 1600px;
  margin: auto;
  justify-content: space-between;
  // padding: 32px;
  margin-top: 12px;

  div {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(169, 187, 205);
    font-weight: 600;
    padding: 8px 24px;
    background-color: rgb(21, 26, 35);
  }

  .first {
    display: flex;
    align-items: center;

    span {
      margin-left: 12px;
    }
  }

  .sec {
    width: 400px;

    span {
      margin-left: 16px;
    }
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

.urls1 {
  display: flex;
  width: 1600px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 0 32px;
  background-color: hsla(0, 0%, 100%, 0.062745098);
  border-bottom: 1px solid rgba(222, 226, 230, 0.1176470588) !important;
  font-weight: 700;
  color: #ccc;

  // padding: 32px;
  div {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(169, 187, 205);
    font-weight: 600;
    font-family: "微软雅黑";
    font-size: 14px;
    padding: 8px 24px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    font-size: 12px;

    img {
      width: 50%;
    }
  }

  .first {
    display: flex;
    align-items: center;

    span {
      margin-left: 12px;
    }
  }

  .sec {
    width: 400px;

    span {
      margin-left: 16px;
    }
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 767px) {
  .extension_index_img {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70%;
    }
  }

  .oran-sel {
    width: 95%;
    margin: auto;
    background: url("../assets/img/duizhan/mobileBg.24020d77.png") no-repeat;
    background-size: 100% 100%;
    background-position: 50%;
    margin-top: -20px !important;
  }

  .guize {
    font-size: 16px;
    color: rgb(130, 240, 255) !important;
    font-weight: bold;
    z-index: 9;
    text-align: center;
    position: absolute;
    top: 10px !important;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
  }

  .uls {
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: space-between;
    padding: 2px 8px;
    font-size: 10px;
    margin-top: 12px;

    .sec {
      width: 30%;
    }

    div {
      width: 20%;
      text-align: center;
    }
  }

  .urls {
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-top: 12px;
    font-size: 10px;
    background-color: rgb(21, 26, 35);

    div {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(169, 187, 205);
      font-weight: 600;
      padding: 2px 8px;
    }

    div:first-child {
      justify-content: flex-start;
    }

    .first {
      display: flex;
      align-items: center;

      span {
        margin-left: 6px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 10px;
        white-space: nowrap;
      }
    }

    .sec {
      width: 30%;

      img {
        display: none;
      }

      span {
        width: 100%;
        margin-left: 6px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 10px;
        white-space: nowrap;
      }
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}

.selects {
  div {
    color: #93e311 !important;
  }
}

.ornaOpen {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  // background: url("../assets/img/bg.6a2bc676.jpg") no-repeat;
  height: 100%;
  background-position: 50% 0%;
  background-size: 100%;
  background-attachment: fixed;
  background-color: #0e1325;
  // padding:30px 50px;
  padding: 4rem 12rem;
  --mutible: 1.4;
  box-sizing: border-box;
  .extension_index_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // //动画
  .box {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .open-anim {
    padding: 10px;
    // margin-top: 64px;
    background-color: #383856;
    position: relative;
    overflow: hidden;

    // .open-anim-s{
    // 	position: relative;
    // 	overflow: hidden;
    // 	width: 100vw;
    // 	height: 100%;
    // }
    .open-line {
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: #ffbb00;
      top: 0;
      left: 50%;
      right: 0;
      z-index: 66;
    }

    // .open-line::before {
    //   content: "";
    //   background-image: url("../assets/img/pointer.png");
    //   width: 38px;
    //   height: 19px;
    //   position: absolute;
    //   top: -1px;
    //   left: 2px;
    //   transform: translateX(-50%) rotate(180deg);
    //   background-size: 100% 100%;
    // }

    .open-line::after {
      content: "";
      background-image: url("../assets/img/pointer.png");
      width: 38px;
      height: 19px;
      position: absolute;
      bottom: -1px;
      left: -18px;
      // transform: translateX(-50%) rotate(180deg);
      background-size: 100% 100%;
    }

    .open-anim-warp {
      overflow: hidden;
      width: 8610px; // ~"calc(-77vh * 10)" 8610px
      height: 155px;
      position: relative;
    }

    ul {
      width: 100%;
      position: relative;
      left: 0;
      display: flex;

      li {
        background-image: url("../assets/img/box-skins-blue.jpg");
        background-size: 100% 100%;
        max-width: 200px;
        min-width: 200px;
        height: 150px;
        // margin-right: 5px;
        border: 2px solid #92a9b6;
        border-radius: 5px;

        img {
          margin-left: 10px;
          margin-top: 10px;
          width: 180px;
          height: 130px;
        }
      }

      .li1 {
        border: 2px solid #c9a167;
      }
    }

    .ul1 {
      animation: run11 6s;
      animation-timing-function: ease; //动画慢 快 慢
      animation-iteration-count: 1; //播放几次动画
      animation-delay: 0s; //动画运行前等待时间
      animation-fill-mode: forwards; //动画结束 是否保持

      @keyframes run11 {
        0% {
          left: 0;
        }

        100% {
          left: -100%; //~"calc(-77vh * 10)"
        }
      }
    }
  }

  .guize {
    font-size: 16px;
    color: rgb(130, 240, 255) !important;
    font-weight: bold;
    z-index: 9;
    text-align: center;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
  }

  .oran-warpv {
    padding: 16px 0;

    .oran-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;

      .orantop-left {
        color: white;
      }

      .orantop-right {
        padding: 12px 22px;
        background-color: #333542;
        border-radius: 5px;
        color: #848492;
        font-size: 15px;
        font-weight: 600;
      }

      .orantop-right:hover {
        cursor: pointer;
        background-color: #3a3f50;
      }
    }

    .oran-sel {
      width: 95%;
      margin: auto;
      background: url("../assets/img/duizhan/mobileBg.24020d77.png") no-repeat;
      background-size: 100% 100%;
      background-position: 50%;
      margin-top: -100px;

      .sel-top {
        ul {
          margin: 0 -1px;

          li {
            float: left;
            width: 11.11%;

            .seltop-warp {
              background-color: #24252f;
              margin: 0 1px;
              display: flex;
              flex-direction: column;
              align-items: center;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              img {
                width: 60%;
                height: auto;
              }

              span {
                padding-bottom: 10px;
                font-size: 14px;
                color: #848492;
              }
            }

            .seltop-warp1 {
              background-color: #2b2c37;

              span {
                color: #e9b10e;
              }
            }
          }

          li:hover {
            cursor: pointer;
          }
        }
      }

      .sel-bot {
        background-color: #2b2c37;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 26px 16px;

        .selbot-left {
          font-size: 14px;
          color: #e9b10e;
          font-weight: 600;
        }

        .selbot-left:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        .selbot-right {
          display: flex;
          align-items: center;

          .span {
            margin: 0 8px;
            color: #848492;
          }

          .pirec-btn {
            margin: 0 10px;
            background-color: #333542;
            border-radius: 5px;
            color: #848492;
            font-size: 15px;
            font-weight: 600;
            padding: 10px 26px;
          }

          .pirec-btn:hover {
            cursor: pointer;
            background-color: #3a3f50;
          }

          .input {
            width: 120px;

            img {
              width: auto;
              height: 18px;
            }
          }

          .input /deep/ .el-input__prefix,
          .input /deep/ .el-input__suffix {
            top: 11px;
          }

          .input1 {
            width: 200px;
          }

          .input1-i:hover {
            cursor: pointer;
          }
        }

        .selbot-right /deep/ .el-input__inner {
          background-color: #2b2c37;
          border: 1px solid #848492;
          color: #c3c3e2;
        }
      }
    }

    .open {
      // margin-bottom: 100px; //后修改
      margin-top: 16px;
      // background-color: #2b2c37;
      border-radius: 5px;
      padding: 16px;

      .open-hint {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row !important;

        .open-hint1 {
          font-size: 20px;
          color: #c3c3e2;
        }

        .open-hint2 {
          display: flex;
          align-items: center;

          span {
            margin-right: 5px;
            font-size: 14px;
            color: #848492;
          }

          i {
            font-size: 14px;
            color: #848492;
          }

          i:hover {
            cursor: pointer;
          }
        }

        .open-hint3 {
          img {
            width: 30px;
            height: 30px;
          }

          img:hover {
            cursor: pointer;
          }
        }
      }

      .box-four1 {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .four-top {
          color: #e9b10e;
        }

        .four-bot::after {
          content: "";
          width: 19.336rem;
          height: 19.336rem;
          position: static;
          transform: translateX(0);
          display: flex;
          align-items: center;
          justify-content: center;

          background-image: url("../assets/img/duizhan/rotateAnimate.a46ab407.png");
          background-size: 100% 100%;
          background-position: 50% 50%;
          animation: s3601 10s linear infinite;
        }

        // .four-bot::before {
        //   content: "";
        //   width: 231px;
        //   height: 297px;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   margin: auto;
        //   background-image: url("../assets/img/duizhan/xuanzhuan.png");
        //   background-size: 100% 100%;
        //   background-position: 50% 50%;
        //   animation: s360 8s linear infinite;
        // }

        @keyframes s360 {
          0% {
            transform: rotate(0);
          }

          100% {
            transform: rotate(1turn);
          }
        }

        @keyframes s3601 {
          0% {
            transform: rotate(0);
          }

          100% {
            transform: rotate(1turn);
          }
        }

        .four-bot {
          position: relative;
          text-align: center;
          background-image: url("../assets/img/duizhan/hexagon.1aac5db5.png");
          background-size: 100% 100%;
          background-position: 50% 50%;
          width: 24.17rem * 0.8;
          height: 24.17rem * 0.8;
          position: static;
          transform: translateX(0);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
          .img1 {
            // img {
            //   width: 170px;
            // }
          }

          .img {
            // border-bottom: 2px solid #e9b10e;
            padding: 24px;
            position: absolute;
            top: 50px;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            font-family: "微软雅黑";

            img {
              top: 0;
              bottom: 0;
              left: 0;
              width: 50%;
              animation: shangxias 3s infinite;
            }

            div {
              color: white;
              display: flex;
              align-items: center;
              font-size: 12px;
              justify-content: center;
              line-height: 15px;

              img {
                width: 15px;
                animation: none;
              }
            }
          }

          .bos {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            // z-index: 99;
            font-size: 32px;
            font-weight: 600;
            color: white;
            font-family: "微软雅黑";

            span {
              margin-bottom: -25px;
              font-weight: 500;
              line-height: 120px;
              font-size: 20px;
            }

            img {
              width: 16px;
            }
          }

          .circle-container {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;

            .square-box {
              position: relative;
              width: 200px;
              height: 200px;
              margin: 42px auto;
              margin-left: 40px;
              // border: 2px solid black;
              transform: rotate(var(1000deg));
              border-radius: 50%;
            }

            .square {
              position: absolute;
              width: 3px;
              height: 10px;
              background-color: #3088ff;
              border-radius: 2px;
              transform-origin: top left;
            }
          }

          .kaijiang {
            transition: all 4s;
            transform: rotate(var(--bg-qudus)) !important;
          }

          // .bos2 {
          //   position: absolute;
          //   top: 0;
          //   bottom: 0;
          //   right: 0;
          //   left: 0;
          //   margin: auto;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   // z-index: 99;
          //   font-size: 60px;
          //   font-weight: 600;
          //   color: white;
          //   font-family: "微软雅黑";
          //   transform: rotate(var(--bg-qudu));
          //   width: 15px !important;

          //   span {
          //     margin-bottom: -25px;
          //     font-weight: 500;
          //     line-height: 120px;
          //     font-size: 20px;
          //   }

          //   img {
          //     width: 16px;
          //   }
          // }

          @keyframes shangxias {
            0% {
              transform: translateY(10%);
            }

            50% {
              transform: translateY(20%);
            }

            100% {
              transform: translateY(10%);
            }
          }

          .name {
            padding: 8px;
            font-size: 14px;
            color: #c3c3e2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // background-color: #24252f;
          }

          .por {
            position: absolute;
            left: 0;
            top: -25%;
            width: 100%;
          }
        }
      }

      .box-four2 {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;

        .four-top {
          color: #e9b10e;
        }

        @media screen and (max-width: 768px) {
          .four-bot {
            position: relative;
            width: 90% !important;
            text-align: center;
            padding: 0 20px;
            margin-top: 25px;
          }
        }

        .four-bot {
          position: relative;
          text-align: center;
          padding: 0 20px;

          .yinpin {
            position: absolute;
            left: 0;
            right: 0;
            top: -200px;
            margin: auto;
            width: 500px;
            height: 500px;
          }

          .smallfourbot {
            margin: auto;
            width: 267px;
            height: 177px;
          }

          .yinpinvs {
            animation: luan 0.3s infinite;
          }

          .img {
            // background-image: url("../assets/img/ARR.png");
            background-size: 100% 100%;
            // border-bottom: 2px solid #e9b10e;
            height: 100%;
            position: relative;
            width: 100%;

            img {
              width: 45%;
              animation: shangxia 3s infinite;
            }

            div {
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 16px;
              }
            }
          }

          @keyframes shangxia {
            0% {
              transform: translateY(-20%);
            }

            50% {
              transform: translateY(0);
            }

            100% {
              transform: translateY(-20%);
            }
          }

          .name {
            padding: 8px;
            font-size: 14px;
            color: #c3c3e2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // background-color: #24252f;
          }

          .por {
            position: absolute;
            left: 0;
            top: -25%;
            width: 100%;
          }
        }
      }

      .open-box {
        display: flex;
        justify-content: space-around;
        width: 90%;
        margin: auto;
        align-items: center;
        height: auto !important;
        margin-top: 50px;

        .box-one {
          height: 100%;
          display: flex;
          justify-content: center;

          img {
            height: 80%;
          }
        }

        .box-two {
          height: 100%;
          position: relative;
          margin-top: -50px;

          img {
            height: 100%;
          }

          span {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -35px;
            color: #c3c3e2;
          }
        }

        .box-three {
          min-width: 50px;
          text-align: center;
          height: 100%;
          color: #c3c3e2;
          line-height: 200px;
          padding: 0 20px;
          font-weight: 600;
          font-size: 20px;
        }

        .box-four {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 25%;

          .four-top {
            color: #e9b10e;
          }

          @media screen and (max-width: 768px) {
            .four-bot {
              position: relative;
              width: 90% !important;
              text-align: center;
              padding: 0 20px;
              margin-top: 25px;
            }
          }

          .four-bot {
            position: relative;
            text-align: center;
            padding: 0 20px;

            .yinpin {
              position: absolute;
              left: 0;
              right: 0;
              top: -200px;
              margin: auto;
              width: 500px;
              height: 500px;
            }

            .yinpinvs {
              animation: luan 0.3s infinite;
            }

            .img {
              // background-image: url("../assets/img/ARR.png");
              background-size: 100% 100%;
              // border-bottom: 2px solid #e9b10e;
              height: 100%;
              position: relative;
              width: 100%;

              img {
                width: 45%;
                animation: shangxia 3s infinite;
              }

              div {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 16px;
                }
              }
            }

            @keyframes shangxia {
              0% {
                transform: translateY(-20%);
              }

              50% {
                transform: translateY(0);
              }

              100% {
                transform: translateY(-20%);
              }
            }

            .name {
              padding: 8px;
              font-size: 14px;
              color: #c3c3e2;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // background-color: #24252f;
            }

            .por {
              position: absolute;
              left: 0;
              top: -25%;
              width: 100%;
            }
          }
        }

        .box-five {
          height: 100%;
          display: flex;
          justify-content: center;

          img {
            height: 80%;
          }
        }
      }

      .open-text {
        margin-top: 10px;
        text-align: center;
        color: white;
        font-family: "微软雅黑";
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .green {
          color: rgb(40, 235, 108);
        }

        .red {
          color: rgb(235, 39, 39);
        }
      }

      .bos {
        font-size: 32px;
        color: white;
        font-family: "微软雅黑";
        margin-top: 32px;
        font-weight: 600;
        text-align: center;
      }

      .open-pro {
        width: 50%;
        margin-left: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        // .pro-con {
        //   width: 320px;
        // }

        img {
          width: 350px;
          margin-right: 15px;
        }
      }

      .open-btn {
        display: flex;
        justify-content: center;

        .open-btnwarp {
          // background-color: #e9b10e;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // padding: 10px 40px;
          // border-radius: 5px;
          width: 200px;
          height: 45px;
          background: url("../assets/img/duizhan/button.png");
          background-size: 100% 100%;
          margin-top: 12px;

          /deep/ span {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
          }

          img {
            width: 25px;
            height: 25px;
            margin: 0 5px;
            position: relative;
            // top: 5px;
          }
        }

        .open-btnwarp:hover {
          cursor: pointer;
          // background-color: #ffbb00;
        }

        .open-btnwarp:active {
          cursor: pointer;
          // background-color: #949493;
        }
      }
      .upBtn {
        width: 16.15rem;
        height: 4.72rem;
        background: url("../assets/img/duizhan/btn@2X.3fbc3dda.png") no-repeat
          100% 100%;
        border-radius: 0.25rem;
        margin: 0.8rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.29rem;
        font-weight: 700;
        cursor: pointer;
      }

      .upBtn span {
        margin-left: 0.2rem;
      }
    }
  }

  .oran-warp {
    // padding: 16px;

    .oran-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;

      .orantop-left {
        color: white;
      }

      .orantop-right {
        padding: 12px 22px;
        background-color: #333542;
        border-radius: 5px;
        color: #848492;
        font-size: 15px;
        font-weight: 600;
      }

      .orantop-right:hover {
        cursor: pointer;
        background-color: #3a3f50;
      }
    }

    .oran-sel {
      width: 100%;
      // max-width: 1720px;
      height: 892px;
      margin: auto;
      background: url("../assets/img/duizhan/dreambg-cn.016b2461.png") no-repeat;
      background-size: 100%;
      background-position: 50%;
      margin-top: -8rem;

      .sel-top {
        ul {
          margin: 0 -1px;

          li {
            float: left;
            width: 11.11%;

            .seltop-warp {
              background-color: #24252f;
              margin: 0 1px;
              display: flex;
              flex-direction: column;
              align-items: center;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              img {
                width: 60%;
                height: auto;
              }

              span {
                padding-bottom: 10px;
                font-size: 14px;
                color: #848492;
              }
            }

            .seltop-warp1 {
              background-color: #2b2c37;

              span {
                color: #e9b10e;
              }
            }
          }

          li:hover {
            cursor: pointer;
          }
        }
      }

      .sel-bot {
        background-color: #2b2c37;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 26px 16px;

        .selbot-left {
          font-size: 14px;
          color: #e9b10e;
          font-weight: 600;
        }

        .selbot-left:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        .selbot-right {
          display: flex;
          align-items: center;

          .span {
            margin: 0 8px;
            color: #848492;
          }

          .pirec-btn {
            margin: 0 10px;
            background-color: #333542;
            border-radius: 5px;
            color: #848492;
            font-size: 15px;
            font-weight: 600;
            padding: 10px 26px;
          }

          .pirec-btn:hover {
            cursor: pointer;
            background-color: #3a3f50;
          }

          .input {
            width: 120px;

            img {
              width: auto;
              height: 18px;
            }
          }

          .input /deep/ .el-input__prefix,
          .input /deep/ .el-input__suffix {
            top: 11px;
          }

          .input1 {
            width: 200px;
          }

          .input1-i:hover {
            cursor: pointer;
          }
        }

        .selbot-right /deep/ .el-input__inner {
          background-color: #2b2c37;
          border: 1px solid #848492;
          color: #c3c3e2;
        }
      }
    }
    .tips {
      text-align: center;
      font-size: 0.75rem * 1.6;
      margin-top: 3rem;
      color: #979ca7;
    }

    .open {
      // margin-bottom: 100px; //后修改
      margin-top: 16px;
      // background-color: #2b2c37;
      border-radius: 5px;
      padding: 16px;

      .open-hint {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row !important;

        .open-hint1 {
          font-size: 20px;
          color: #c3c3e2;
        }

        .open-hint2 {
          display: flex;
          align-items: center;

          span {
            margin-right: 5px;
            font-size: 14px;
            color: #848492;
          }

          i {
            font-size: 14px;
            color: #848492;
          }

          i:hover {
            cursor: pointer;
          }
        }

        .open-hint3 {
          img {
            width: 30px;
            height: 30px;
          }

          img:hover {
            cursor: pointer;
          }
        }
      }

      .box-four1 {
        width: calc(24.17rem * var(--mutible));
        height: calc(24.17rem * var(--mutible));

        display: flex;
        flex-direction: column;
        align-items: center;
        background: url("../assets/img/duizhan/hexagon.1aac5db5.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        justify-content: center;
        position: relative;
        .four-top {
          color: #e9b10e;
        }

        @media screen and (max-width: 768px) {
          .four-bot {
            position: relative;
            width: 90% !important;
            text-align: center;
            padding: 0 20px;
            margin-top: 25px;
          }
        }
        .skin {
          width: calc(24.17rem * var(--mutible));
          height: calc(24.17rem * var(--mutible));
          animation: s3601 10s linear infinite;
        }
        // .four-bot::after {
        //   content: "";
        //   width: calc(24.17rem * var(--mutible));
        //   height: calc(24.17rem * var(--mutible));
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   transform: translate(-50%, -50%);
        //   margin: auto;
        //   background-image: url("../assets/img/duizhan/xuanzhuan1.png");
        //   background-size: 100% 100%;
        //   background-position: 50% 50%;
        //   animation: s3601 10s linear infinite;
        // }

        // .four-bot::before {
        //   content: "";
        //   width: 300px;
        //   height: 393px;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   margin: auto;
        //   background-image: url("../assets/img/duizhan/xuanzhuan.png");
        //   background-size: 100% 100%;
        //   background-position: 50% 50%;
        //   animation: s360 8s linear infinite;
        // }

        @keyframes s360 {
          0% {
            transform: rotate(0);
          }

          100% {
            transform: rotate(1turn);
          }
        }

        @keyframes s3601 {
          0% {
            transform: rotate(0);
          }

          100% {
            transform: rotate(1turn);
          }
        }

        .four-bot {
          position: relative;
          text-align: center;
          padding: 70px;
          // background-image: url("../assets/img/duizhan/yuanpan.png");
          background-size: 100% 100%;
          background-position: 50% 50%;

          .img {
            // border-bottom: 2px solid #e9b10e;
            height: 100%;
            padding: 24px;
            position: relative;

            img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 50%;
              animation: shangxia 3s infinite;
            }
          }

          .bos {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            // z-index: 99;
            font-size: 60px;
            font-weight: 600;
            color: white;
            font-family: "微软雅黑";

            span {
              margin-bottom: -25px;
              font-weight: 500;
              line-height: 120px;
              font-size: 20px;
            }

            img {
              width: 16px;
            }
          }

          .circle-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .square-box {
              position: relative;
              width: 236px;
              height: 236px;
              // margin: 50px auto;
              // border: 2px solid black;
              transform: rotate(var(1000deg));
              border-radius: 50%;
              // top: 50%;
              // left: 50%;
              // transform: translate(-50%, -50%);
            }

            .square {
              position: absolute;
              width: 3px;
              height: 10px;
              background-color: #3088ff;
              border-radius: 2px;
              transform-origin: top left;
            }
          }

          .kaijiang {
            transition: all 4s;
            transform: rotate(var(--bg-qudus)) !important;
          }

          @keyframes shangxia {
            0% {
              transform: translateY(-20%);
            }

            50% {
              transform: translateY(0);
            }

            100% {
              transform: translateY(-20%);
            }
          }

          .name {
            padding: 8px;
            font-size: 14px;
            color: #c3c3e2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // background-color: #24252f;
          }

          .por {
            position: absolute;
            left: 0;
            top: -25%;
            width: 100%;
          }
        }
      }

      .box-four2 {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        .four-bot {
          width: calc(10.25rem * var(--mutible));
          height: calc(8.67rem * var(--mutible));
          background: url("../assets/img/duizhan/AccessoryBg.bae60c7e.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          p {
            position: absolute;
            color: #fff;
            bottom: -3rem;
            font-weight: 700;
          }
          .img1 {
            width: 50%;
          }
        }
        .four-top {
          color: #e9b10e;
        }

        @media screen and (max-width: 768px) {
          .four-bot {
            position: relative;
            width: 90% !important;
            text-align: center;
            padding: 0 20px;
            margin-top: 25px;
          }
        }

        .four-bot {
          position: relative;
          text-align: center;
          // padding: 0 20px;

          .yinpin {
            position: absolute;
            left: 0;
            right: 0;
            top: -200px;
            margin: auto;
            width: 500px;
            height: 500px;
          }

          .smallfourbot {
            margin: auto;
            width: 267px;
            height: 177px;
          }

          .yinpinvs {
            animation: luan 0.3s infinite;
          }

          .img {
            // background-image: url("../assets/img/ARR.png");
            background-size: 100% 100%;
            // border-bottom: 2px solid #e9b10e;
            // height: 100%;
            position: relative;
            width: 100%;

            img {
              width: 45%;
              animation: shangxia 3s infinite;
            }

            div {
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 16px;
              }
            }
          }

          @keyframes shangxia {
            0% {
              transform: translateY(-20%);
            }

            50% {
              transform: translateY(0);
            }

            100% {
              transform: translateY(-20%);
            }
          }

          .name {
            padding: 8px;
            font-size: 14px;
            color: #c3c3e2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // background-color: #24252f;
          }

          .por {
            position: absolute;
            left: 0;
            top: -25%;
            width: 100%;
          }
        }
      }

      .open-box {
        display: flex;
        justify-content: space-around;
        width: 90%;
        margin: auto;
        align-items: center;
        height: auto !important;
        margin-top: 6rem;

        .box-one {
          height: 100%;
          display: flex;
          justify-content: center;

          img {
            height: 80%;
          }
        }

        .box-two {
          height: 100%;
          position: relative;
          margin-top: -50px;

          img {
            height: 100%;
          }

          span {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -35px;
            color: #c3c3e2;
          }
        }

        .box-three {
          min-width: 50px;
          text-align: center;
          height: 100%;
          color: #c3c3e2;
          line-height: 200px;
          padding: 0 20px;
          font-weight: 600;
          font-size: 20px;
        }

        .box-four {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 25%;
          .four-bot {
            width: calc(10.25rem * var(--mutible));
            height: calc(8.67rem * var(--mutible));
            background: url("../assets/img/duizhan/AccessoryBg.bae60c7e.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .img1 {
              width: 50%;
              img {
                width: 100%;
              }
            }
          }
          .four-top {
            color: #e9b10e;
          }

          @media screen and (max-width: 768px) {
            .four-bot {
              position: relative;
              width: 90% !important;
              text-align: center;
              padding: 0 20px;
              margin-top: 25px;
            }
          }

          .four-bot {
            position: relative;
            text-align: center;
            // padding: 0 20px;

            .yinpin {
              position: absolute;
              left: 0;
              right: 0;
              top: -200px;
              margin: auto;
              width: 500px;
              height: 500px;
            }

            .yinpinvs {
              animation: luan 0.3s infinite;
            }

            .img {
              // background-image: url("../assets/img/ARR.png");
              background-size: 100% 100%;
              // border-bottom: 2px solid #e9b10e;
              height: 100%;
              position: relative;
              width: 100%;

              img {
                width: 45%;
                animation: shangxia 3s infinite;
              }

              div {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 16px;
                }
              }
            }

            @keyframes shangxia {
              0% {
                transform: translateY(-20%);
              }

              50% {
                transform: translateY(0);
              }

              100% {
                transform: translateY(-20%);
              }
            }

            .name {
              padding: 8px;
              font-size: 14px;
              color: #c3c3e2;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // background-color: #24252f;
            }

            .por {
              position: absolute;
              left: 0;
              top: -25%;
              width: 100%;
            }
          }
        }

        .box-five {
          height: 100%;
          display: flex;
          justify-content: center;

          img {
            height: 80%;
          }
        }
      }

      .open-text {
        margin-top: 30px;
        text-align: center;
        color: white;
        font-family: "微软雅黑";
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        .green {
          color: rgb(40, 235, 108);
        }

        .red {
          color: rgb(235, 39, 39);
        }
      }

      .open-pro {
        width: 43.09rem * 1.6;
        height: 2.9rem * 1.6;
        border-radius: 1.45rem * 1.6;
        margin: -1rem auto 0;
        position: relative;
        padding: 0.1rem 0;
        background: hsla(0, 0%, 100%, 0.05);
        margin-top: 4rem;
        .pro-con {
          width: 38.72rem * 1.6;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: url("../assets/img/duizhan/Scale.d7656191.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 2.95rem;
        }
        .el-slider {
          width: 37.6rem * 1.6;
          margin-bottom: 0.83rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .el-slider__runway {
          background: url("../assets/img/duizhan/sliderbg.60417937.png")
            no-repeat cover;
          height: 0.58rem;
          border-radius: 1.4rem;
        }
      }

      .open-btn {
        display: flex;
        justify-content: center;

        .open-btnwarp {
          // background-color: #e9b10e;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // padding: 10px 40px;
          // border-radius: 5px;
          width: 249px;
          height: 57px;
          background: url("../assets/img/duizhan/button.png");
          background-size: 100% 100%;
          margin-top: 32px;

          /deep/ span {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
          }

          img {
            width: 25px;
            height: 25px;
            margin: 0 5px;
            position: relative;
            // top: 5px;
          }
        }

        .open-btnwarp:hover {
          cursor: pointer;
          // background-color: #ffbb00;
        }

        .open-btnwarp:active {
          cursor: pointer;
          // background-color: #949493;
        }
      }
      .upBtn {
        width: 16.15rem * 1.6;
        height: 4.72rem * 1.6;
        background: url("../assets/img/duizhan/btn@2X.3fbc3dda.png") no-repeat;
        background-size: 100% 100%;
        border-radius: 0.25rem;
        margin: 0.8rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.29rem * 1.6;
        font-weight: 700;
        cursor: pointer;
      }
      .MoneyCoin {
        display: flex;
        align-items: center;
      }
      .upBtn span {
        margin-left: 0.2rem;
      }
    }
  }

  .win-box {
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999999999999999;
    display: grid;
    place-items: center;
  }

  .win {
    width: 90% !important;
    // height: 70%;
    // max-height: 800px;
    padding: 32px 0;
    // background-color: #2b2c37;
    position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .win-back {
      width: 100%;
      height: auto;
      position: absolute;
      top: 15%;
      left: 0;
      z-index: -10;
      animation: move 5s linear infinite;

      img {
        width: 100%;
        height: auto;
      }
    }

    @keyframes move {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .win-top {
      width: 100%;
      text-align: center;
      margin-top: 50px;

      img {
        width: 60%;
        max-width: 300px;
        height: auto;
      }
    }

    .win-x {
      position: absolute;
      top: 6%;
      right: 30px;
      color: #e9b10e;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #e9b10e;
      text-align: center;
      line-height: 20px;
      padding: 4px;
      font-weight: bold;
    }

    .win-x:hover {
      cursor: pointer;
    }

    .win-bot {
      margin-top: 50px;
      width: 100%;
      max-width: 450px;

      .win-span1 {
        width: 50%;
        // margin-left: 10%;
        margin: 0 auto;
        padding: 10px 0;
        background-color: #e9b10e;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        span {
          color: #000;
          font-weight: 600;
          font-size: 15px;
        }

        img {
          margin-left: 20px;
          margin-right: 5px;
          width: auto;
          height: 15px;
        }
      }

      .win-span1:hover {
        cursor: pointer;
      }

      .win-span2 {
        margin-top: 14px;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #000000;
        width: 50%;
        font-weight: bold;
        // margin-left: 10%;
        margin: 0 auto;
        border-radius: 5px;
        padding: 10px 0;
        margin-top: 10px;
        background-color: #e9b10e;
      }

      .win-span2:hover {
        cursor: pointer;
      }
    }

    .win-con {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      ul {
        display: flex;
        justify-content: center;
        width: 200px;

        li {
          width: 100%;
          border-radius: 5px;
          overflow: hidden;

          .win-warp {
            width: 100%;
            background-color: #e2c873;
            position: relative;

            .win-img {
              // padding: 50px;
              //background-image: url("../assets/img/box-skins-golden.png");
              background-size: 100% 100%;

              img {
                width: 100%;
                height: auto;
                //width: 25px;
                //height: 25px;
                // border-radius: 50%;
              }
            }

            .img-class {
              display: flex;
              justify-content: center;
              padding: 30px 40px;

              img {
                height: 100px;
                width: auto;
              }
            }

            span {
              position: absolute;
              right: 1px;
              top: 1px;
              background-color: rgba(0, 0, 0, 0.2);
              color: #fff;
              font-size: 12px;
              padding: 2px 4px;
              border-radius: 20px;
              margin-top: 20px;
              min-width: 20px;
              text-align: center;
            }

            .win-text {
              width: 100%;
              padding: 10px 0;
              color: #fd492c;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: center;
            }
          }
        }
      }
    }
  }

  /deep/ .el-button {
    border: none;
  }

  /deep/ .el-slider__button {
    border: 2px solid rgba(48, 136, 255, 1);
  }

  /deep/.el-slider__bar {
    height: 10px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    background: linear-gradient(
      90deg,
      rgba(48, 136, 255, 0.4),
      rgba(48, 136, 255, 1)
    ) !important;
  }

  /deep/ .el-slider__runway {
    background: url("../assets/img/duizhan/sliderbg.60417937.png") no-repeat;
    background-size: 100% 100%;
    height: 0.58rem;
    border-radius: 1.4rem;
  }
  /deep/ .el-slider__bar {
    background: url("../assets/img/duizhan/sliderbg_inner.e74755c2.png")
      no-repeat !important;
    background-size: 37.5rem * 1.6 100% !important;
    height: 0.58rem;
    border-radius: 1.4rem;
  }
  /deep/ .el-slider__button-wrapper {
    top: 50%;
    transform: translate(-60%, -30%);
  }
  /deep/ .el-slider__button {
    background: url("../assets/img/duizhan/dreamSlider.c3ad0340.png") no-repeat !important;
    background-size: cover !important;
    border: 0;
    width: 1.45rem * 1.6;
    height: 2rem * 1.6;
    border-radius: 0;
  }
}

@keyframes luan {
  10% {
    transform: rotate(10deg) scale(0);
  }

  10% {
    transform: rotate(45deg) scale(0.5);
  }

  20% {
    transform: rotate(90deg) scale(0);
  }

  30% {
    transform: rotate(125deg) scale(0.6);
  }

  40% {
    transform: rotate(160deg) scale(0);
  }

  50% {
    transform: rotate(185deg) scale(0.8);
  }

  60% {
    transform: rotate(220deg) scale(0);
  }

  70% {
    transform: rotate(255deg) scale(0.4);
  }

  80% {
    transform: rotate(290deg) scale(0);
  }

  0% {
    transform: rotate(300deg) scale(0.3);
  }

  90% {
    transform: rotate(330deg) scale(0);
  }

  100% {
    transform: rotate(1turn) scale(1);
  }
}
.title {
  height: calc(2.71rem * var(--mutible));
  background: #12161f;
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(1.2rem * var(--mutible));
  position: relative;
  // z-index: 9999;
}

.title .left,
.title .right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title .left {
  background: url("../assets/img/titleBg.02cfd809.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // width: 11.26rem;
  // height: 2.71rem;
  width: calc(11.26rem * var(--mutible));
  height: calc(2.7rem * var(--mutible));
  justify-content: flex-start;
  padding-left: 0.83rem;
  box-sizing: border-box;
}

.title .left img {
  width: calc(1.5rem * var(--mutible));
  margin-right: 0.6rem;
}

.title .left span {
  font-size: calc(0.83rem * var(--mutible));
  font-weight: 700;
  color: #fff;
}

.title .right {
  padding-right: calc(1.2rem * var(--mutible));
  font-size: calc(0.67rem * var(--mutible));
}

.title .right .provably {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 calc(0.8rem * var(--mutible));
  margin-right: calc(0.8rem * var(--mutible));
  cursor: pointer;
}

.title .right .provably:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 2px;
  height: 80%;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.title .right .provably img {
  width: calc(0.88rem * var(--mutible));
  margin-right: calc(0.33rem * var(--mutible));
}

.title .right .rule {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title .right .rule img {
  width: calc(0.71rem * var(--mutible));
  margin-right: 0.33rem;
}

.title .right span {
  color: #7a7a7a;
  cursor: pointer;
}

.title .right span:hover {
  color: #fff;
}
.svgCircular {
  width: calc(13.21rem * var(--mutible));
  height: calc(13.21rem * var(--mutible));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bos2 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  // z-index: 99;
  font-size: 60px;
  font-weight: 600;
  color: white;
  font-family: "微软雅黑";
  transform: rotate(var(--bg-qudu));
  // transform: scale(0.9);
  width: 52%;
  height: 52%;
  span {
    margin-bottom: -25px;
    font-weight: 500;
    line-height: 120px;
    font-size: 20px;
  }

  img {
    width: 16px;
  }
}
@media screen and (max-width: 767px) {
  .ornaOpen {
    padding: 0;
  }
  .title {
    margin: 1rem 0.4rem;
    height: 2.25rem;
    background: #12161f;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .title .left {
    height: 100%;
    img {
      width: 1.5rem;
      margin-right: 0.6rem;
    }
    span {
      font-size: 0.83rem;
    }
  }
  .title .right span {
    font-size: 0.83rem;
  }
  .svgCircular {
    width: 13.21rem * 0.8;
    height: 13.21rem * 0.8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .bos2 {
    width: 60%;
    height: 60%;
  }
  .open-pro {
  }
  /deep/.el-slider__button {
    width: 2.32rem * 0.5 !important;
    height: 3.2rem * 0.5 !important;
  }
  .pro-con {
    width: 26.4rem * 0.7;
    height: 3.19rem * 0.7;
    background: url("../assets/img/duizhan/ScaleMb.a999f5eb.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // height: 2.95rem;
  }
  .upBtn {
    width: 17.44rem !important;
    height: 5.1rem !important;
    margin: 0.5rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.42rem;
    font-weight: 700;
    cursor: pointer;
    background-size: 100% 100% !important;
  }

  .upBtn span {
    margin-left: 0.2rem;
  }
  .MoneyCoin {
    display: flex;
    align-items: center;
  }
  .tips {
    text-align: center;
    font-size: 0.75rem;
    // margin-top: 3rem;
    color: #979ca7;
  }
  .selectEquipment {
    position: absolute;
    top: 0;
    left: 0;
  }
  .selectEquipment .selectTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .selectEquipment .selectTop .skinBox {
    background: url("../assets/img/duizhan/AccessoryBg.bae60c7e.png") no-repeat;
    background-size: 100% 100%;
    width: 10.25rem * 0.7;
    height: 8.67rem * 0.7;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .selectEquipment .selectTop .skinBox .skin {
    width: 4.58rem * 0.7;
    height: 4.58rem * 0.7;
  }
  .selectEquipment .selectTop .skinBox .img{
    img{
      width: 4.58rem * 0.7;
    height: 4.58rem * 0.7;
    }
  }

  .selectEquipment .selectTop p {
    text-align: left;
    font-size: 1.08rem * 0.8;
    font-weight: 700;
    width: auto;
    white-space: normal;
    margin-top: 0.5rem;
    color: #fff;
  }

  .selectEquipment .degreeList {
    position: static;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -1.2rem;
  }

  .selectEquipment .degreeList .degreeItem {
    width: 46%;
    height: 4.8rem;
    background: hsla(0, 0%, 100%, 0.05);
    border-radius: 0.33rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.83rem;
    box-sizing: border-box;
    margin: 0.33rem 1%;
  }

  .selectEquipment .degreeList .degreeItem span {
    color: #fff;
  }

  .selectEquipment .degreeList .active {
    background: rgba(129, 27, 255, 0.3);
    border: 2px solid #811bff;
  }
  .arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 2.75rem;
    height: 2.25rem;
  }
  .random {
    top: 0;
    right: 0;
    left: auto;
    cursor: default;
  }
  .open-box {
    // margin-top: 10rem !important;
  }
}
</style>
