<template>
  <div class="welfare">
    <!-- 电脑端 -->
    <div class="member _windows" v-if="showNav">
      <div class="cent1">
        <div>
          <p>VIP等级奖励</p>
          <p>累计金额</p>
          <p>购买金额</p>
          <p>加送奖励</p>
        </div>
        <div>
          <ul>
            <li
              :class="{ activ: index == 0 }"
              v-for="(item, index) in vipUserList"
              :key="index"
            >
              <div>
                <img class="vipLevel" :src="getVipLevelImg(item.level)" />
              </div>
              <div></div>
              <div>
                <div class="MoneyCoin">
                  <img src="@/assets/img/money.png" alt="" />
                  <span class="moneyVal">{{ item.money }}</span>
                </div>
              </div>
              <div>
                <span v-if="index == 0">/</span>
                <span v-else>购买加送{{ item.addReward }}%</span>
              </div>
            </li>
          </ul>
          <div class="progressBox">
            <div></div>
            <div>
              <div style="width: 0%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="vip-activity _mobile" v-if="!showNav">
      <Back></Back>
      <div class="titles">
        <p class="titletext">我的福利</p>
      </div>
      <div class="vipitem" v-for="(item, index) in vipUserList" :key="index">
        <div class="leftbar">
          <div class="point"></div>
          <div class="line"></div>
        </div>
        <div class="right">
          <img class="vipimg" alt="" />
          <div class="contentbox">
            <img
              v-if="index == 0"
              class="currenticon"
              src="../../../assets/img/vip_icon-wz.e0c7bcf0.png"
              alt=""
            />
            <div class="contenttitle" :class="{ active: index == 0 }">
              <div>购买金额</div>
              <div>升级奖励</div>
              <div>加送奖励</div>
            </div>
            <div class="contenttitle value" :class="{ active: index == 0 }">
              <div class="valuecell">{{ item.money }}</div>
              <div class="valuecell">/</div>
              <div class="valuecell">
                <p>{{ index == 0 ? "/" : `购买加送${item.addReward}%` }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import Back from "@/components/back.vue";
export default {
  props: ["showNav"],
  components: {
    Back,
  },
  data() {
    return {
      show: false,
      vipUserList: [
        {
          id: null,
          userId: null,
          vipConfigId: 1,
          getFlag: 2,
          level: 0,
          money: 0,
          curFlag: true,
          upgradeReward: "/",
          addReward: 0.0,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:14:33",
          needLevelUp: null,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 2,
          getFlag: 2,
          level: 1,
          money: 288,
          curFlag: null,
          upgradeReward: "/",
          addReward: 0.5,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:15:18",
          needLevelUp: 288,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 3,
          getFlag: 2,
          level: 2,
          money: 988,
          curFlag: null,
          upgradeReward: "/",
          addReward: 1.0,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:16:21",
          needLevelUp: null,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 4,
          getFlag: 2,
          level: 3,
          money: 1888,
          curFlag: null,
          upgradeReward: "/",
          addReward: 1.5,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:16:56",
          needLevelUp: null,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 5,
          getFlag: 2,
          level: 4,
          money: 5888,
          curFlag: null,
          upgradeReward: "/",
          addReward: 2.0,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:17:12",
          needLevelUp: null,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 6,
          getFlag: 2,
          level: 5,
          money: 8888,
          curFlag: null,
          upgradeReward: "/",
          addReward: 2.5,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:17:44",
          needLevelUp: null,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 7,
          getFlag: 2,
          level: 6,
          money: 28888,
          curFlag: null,
          upgradeReward: "/",
          addReward: 3.0,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:18:09",
          needLevelUp: null,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 8,
          getFlag: 2,
          level: 7,
          money: 58888,
          curFlag: null,
          upgradeReward: "/",
          addReward: 3.5,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:18:28",
          needLevelUp: null,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 9,
          getFlag: 2,
          level: 8,
          money: 88888,
          curFlag: null,
          upgradeReward: "/",
          addReward: 4.0,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:18:52",
          needLevelUp: null,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 10,
          getFlag: 2,
          level: 9,
          money: 188888,
          curFlag: null,
          upgradeReward: "/",
          addReward: 5.0,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:19:08",
          needLevelUp: null,
        },
        {
          id: null,
          userId: null,
          vipConfigId: 11,
          getFlag: 2,
          level: 10,
          money: 288888,
          curFlag: null,
          upgradeReward: "/",
          addReward: 6.0,
          redReward: 0.0,
          enabled: 1,
          createTime: "2022-03-03 10:19:36",
          needLevelUp: null,
        },
      ],
    };
  },
  methods: {
    getVipLevelImg(level) {
      return require(`@/assets/img/mes/V${level}.png`);
    },
  },
};
</script>
<style scoped lang="less">
.member .alreadyHB {
  color: #959595;
  background-color: #181c27;
}

.member .alreadyHB,
.member .stayHB {
  width: 74px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 5px;
}

.member .stayHB {
  color: #d0a700;
  border: 1px solid #540;
}

.member .progressBox {
  position: absolute;
  left: 0;
  top: 98px;
  background-image: url("../../../assets/img/mes/member_pro.690df65f.png");
  height: 17px;
  width: 972px;
  background-size: 100% 100%;
  z-index: 99;
}

.member .progressBox > div:last-child {
  position: absolute;
  top: 0;
  left: 52px;
  width: 920px;
}

.member .progressBox > div:last-child > div {
  height: 17px;
  width: 10%;
  background-image: url("../../../assets/img/mes/member_pros.b94d5049.png");
  background-size: auto 100%;
}

.member .progressBox > div:first-child {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 52px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAARCAYAAACSGY9uAAAAAXNSR0IArs4c6QAAAQtJREFUSEvdlj1OA0EMhd/blagoIe4o4AhQAAVpOAMRnA1KROAK9PxI4QhJuVqDqOjnoUG7URrEbmicndpj+fM8jx8xsMNN4JFUuPukKIpLSccAdgF8kHxJKd2Z2T3JlFnCA7n7vqQHkoe/NV/SG8kLM1uEBsowAJ4BjDoo6R3ASVigRmavJI86wPyESJqFBarr+orkbVeYNm4JVFXVXlmW1wDGALb6JooSvwRy90cA51EKW7eOVaAvANvrJopyb7gv1MzQDYCzQcxQFMm0dfz7l4sGNLg9lBvs7gcAnno4hdOwi7VVTIaSlM3nX15uYmbz8ECNpVl12xlsB8AnyVlKaWpm041x231n+xtJ4XLpqXfqFQAAAABJRU5ErkJggg==);
  background-size: auto 100%;
}

.member .member {
  width: 1100px;
  margin: 0 auto;
  min-height: 1200px;
  padding: 50px 0;
}

.member .cent1 {
  display: flex;
  margin-top: 65px;
  margin-bottom: 55px;
}

.member .cent1 div {
  flex-shrink: 1;
}

.member .cent1 li,
.member .cent1 p {
  text-align: center;
}

.member .cent1 li .vipLevel {
  width: 80%;
  vertical-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.member .cent1 li .vipNum {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 60%;
  text-shadow: 10px 10px 10px #79541b;
  font-style: italic;
  font-weight: 700;
  font-size: 0.75rem;
  color: #f3e8db;
}

.member .cent1 > div:first-child {
  width: 148px;
  background-color: hsla(0, 0%, 100%, 0.05);
}

.member .cent1 > div:first-child > p {
  height: 68px;
  line-height: 68px;
  color: #fff;
  font-size: 16px;
  border-bottom: 2px solid #181c27;
  border-right: 2px solid #181c27;
}

.member .cent1 > div:first-child > p:nth-child(2) {
  height: 76px;
  line-height: 76px;
}

.member .cent1 > div:first-child > p:last-child {
  height: 96px;
  line-height: 96px;
  border-bottom: none;
}

.member .cent1 > div:nth-child(2) {
  position: relative;
}

.member .cent1 ul {
  width: 1010px;
  background-color: hsla(0, 0%, 100%, 0.05);
  display: flex;
  border-radius: 0 20px 20px 0;
}

.member .cent1 ul > li {
  width: 90px;
  color: #d5dae3;
  font-size: 14px;
  border-right: 2px solid #181c27;
  transition: all 0.3s linear;
}

.member .cent1 ul > .activ {
  background: rgba(19, 214, 226, 0.5);
}

.member .cent1 ul > .activ > div:nth-child(2) {
  background: rgba(1, 163, 225, 0.05);
}

.member .cent1 ul > li:last-child {
  border-right: none;
  border-radius: 0 20px 20px 0;
}

.member .cent1 ul > li:hover {
  background: rgba(65, 201, 134, 0.1);
}

.member .cent1 ul > li:hover > div:nth-child(2) {
  background: rgba(1, 163, 225, 0.05);
}

.member .cent1 ul > li > div {
  height: 68px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  color: #d5dae3;
  font-size: 14px;
  border-bottom: 2px solid #181c27;
  transition: all 0.3s linear;
}

.member .cent1 ul > li > div > span {
  display: block;
  width: 100%;
}

.member .cent1 ul > li > div:nth-child(2) {
  height: 76px;
  line-height: 76px;
  background-color: #181c27;
}

.member .cent1 ul > li > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.member .cent1 ul > li > div:nth-child(3) img {
  margin-right: 5px;
}

.member .cent1 ul > li > div:last-child {
  height: 96px;
  border-bottom: none;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.member .cent1 ul > li > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.member .cent1 ul > li > div:first-child .vip_weiz {
  position: absolute;
  top: -36px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.member .cent1 ul > li .HBbox img {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}

.member .cent1 ul > li .HBbox {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffa22a;
}

.member .getHB {
  width: 74px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  color: #fccb03;
  border-radius: 5px;
  background-color: rgba(255, 146, 39, 0.2);
  margin-top: 5px;
  cursor: pointer;
}

.vip-activity {
  margin-bottom: 2rem;
}

.vip-activity .titles {
  display: flex;
  align-items: center;
  padding: 2.333rem*0.8 0 2.917rem*0.8;
  font-size: 1.083rem*0.8;
  color: #54d2fc;
}

.vip-activity .titles .titleicon {
  margin-right: 0.708rem*0.8;
  width: 1.917rem*0.8;
}

.vip-activity .titles .titletext {
  font-size: 1.667rem*0.8;
  font-weight: 600;
  flex: 1;
  color: #fff;
}

.vip-activity .titles .qsicon {
  width: 1.083rem;
  margin-right: 0.417rem;
}

.vip-activity .vipitem {
  display: flex;
  height: 15.208rem;
}

.vip-activity .vipitem .right {
  flex: 1;
}

.vip-activity .vipitem .right .vipimg {
  width: 6.667rem;
  margin-bottom: 0.583rem;
}

.vip-activity .vipitem .right .contentbox {
  margin-bottom: 1.875rem;
  position: relative;
}

.vip-activity .vipitem .right .contentbox .contenttitle {
  height: 4.125rem;
  display: flex;
  text-align: center;
  font-size: 1.083rem;
  color: #d5dae3;
  align-items: center;
  background: hsla(0, 0%, 100%, 0.08);
  border-radius: 0.833rem 0.833rem 0 0;
}

.vip-activity .vipitem .right .contentbox .contenttitle div {
  width: 33.33%;
}

.vip-activity .vipitem .right .contentbox .value {
  height: 6.375rem;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0 0 0.833rem 0.833rem;
}

.vip-activity .vipitem .right .contentbox .value .price {
  color: #ffa22a;
}

.vip-activity .vipitem .right .contentbox .value .price .priceicon {
  width: 1.25rem;
  margin-right: 0.167rem;
  vertical-align: middle;
}

.vip-activity .vipitem .right .contentbox .value .getbtn {
  width: 4.667rem;
  height: 2.292rem;
  background: #ffa22a;
  border-radius: 1.167rem;
  box-sizing: border-box;
  color: #6b3f06;
  font-size: 1rem;
  line-height: 2.292rem;
  margin: 0.417rem auto 0;
}

.vip-activity .vipitem .right .contentbox .value .got {
  background: #32393f;
  color: #959595;
}

.vip-activity .vipitem .right .contentbox .value .waitget {
  background: none;
  color: #ffa22a;
  border: 0.083rem solid #704500;
}

.vip-activity .vipitem .right .contentbox .currenticon {
  height: 2.5rem;
  position: absolute;
  top: -2.5rem;
  right: 0.166rem;
  z-index: 3;
}

.vip-activity .vipitem .leftbar {
  margin-right: 0.833rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.vip-activity .vipitem .leftbar .point {
  background: #323f4f;
  width: 1.042rem;
  height: 1.042rem;
  border-radius: 50%;
}

.vip-activity .vipitem .leftbar .line {
  width: 0.292rem;
  background: #323f4f;
  flex: 1;
}

.vip-activity .tab-bar {
  height: 80px;
  background: #21303f;
  display: flex;
  font-size: 28px;
  color: #fff;
  padding: 0 10px;
}

.vip-activity .tab-bar .tab {
  width: 169px;
  position: relative;
  margin-right: 80px;
  height: 100%;
  box-sizing: border-box;
  border-bottom: 5px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vip-activity .tab-bar .tab-active {
  color: #ffa22a;
  border-color: #ffa22a;
}

.vip-activity .vip-activity {
  padding: 0 28px 0 20px;
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
}

.title_box {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 28px;
}

.title_box img {
  margin-right: 10px;
  width: 1.5rem;
}

@media screen and (max-width: 992px) {
  .title_box {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 1.1rem;
  }

  .title_box img {
    margin-right: 0.42rem;
  }
  .active{
      background-color: rgb(0, 109, 145) !important;
    }
}
.MoneyCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
._mobile {
  margin: 0 auto;
  padding: 0 1rem;
  min-height: 100%;
}
</style>