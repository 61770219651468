import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Roll from "../components/Roll.vue";
import Index from "../components/Index.vue";
import Lucky from "../components/Lucky.vue";
import Arena from "../components/Arena.vue";
import Dota from "../components/Dota.vue";
import dome from "../components/dome.vue";
import Openbox from "../components/Openbox.vue";
import LuckyRoom from "../components/LuckyRoom.vue";
import Doubt from "../components/Doubt.vue";
import LuckyRule from "../components/LuckyRule.vue";
import LuckyHistory from "../components/LuckyHistory.vue";
import Ornament from "../components/Ornament.vue";
import OrnamentOpen from "../components/OrnamentOpen.vue";
import Bill from "../components/Bill.vue";
import Spread from "../components/Spread.vue";
import Me from "../components/Me.vue";
import Inform from "../components/Inform.vue";
import Vip from "../components/Vip.vue";
import Payment from "../components/Payment.vue";
import ArenaRoom from "../components/ArenaRoom.vue";
import Mes from "../components/Mes.vue";
import Mess from "../components/Mess.vue";
import Abouts from "../components/Abouts.vue";
import Agreement from "../components/Agreement.vue";
import JiaoCheng from "../components/jiaocheng.vue";
import Privacy from "../components/Privacy.vue";
import Clause from "../components/Clause.vue";
import MeYiDong from "../components/me_yidong.vue";
import OrnamentHistory from "../components/OrnamentHistory.vue";
import OrnamentHistory2 from "../components/OrnamentHistory2.vue";
import OpenboxRule from "../components/OpenboxRule.vue";
import SpreadLonger from "../components/SpreadLonger.vue";
import Cdk from "../components/Cdk.vue";
import createRoom from "../components/create_room.vue";
import store from "../store";
import battleEstablish from "../components/battleEstablish";
import share from "../components/share.vue";
import skins from "../views/profile/skins.vue";
import income from "../views/mes-child/income/income.vue";
import welfare from "../views/mes-child/welfare/welfare.vue";
// import recharge from "../views/mes-child/recharge/recharge.vue";
import comeback from "../components/comeback.vue";
import fullReturn from "../components/fullReturn.vue";
import ruleDream from "../components/ruleDream.vue";
import ruleBattle from "../components/ruleBattle.vue";
import recharge from "../components/recharge.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "Index",
    meta: { keepAlive: true },
    children: [
      {
        path: "Index",
        name: "Index",
        meta: { keepAlive: true },
        component: Index,
        children: [
         
        ],
      },
      {
        path: "Lucky",
        name: "Lucky",
        component: Lucky,
        children: [
          
        ],
      },
      {
        path: "JiaoCheng",
        name: "JiaoCheng",
        component: JiaoCheng,
      },
      {
        path: "battleEstablish",
        name: "battleEstablish",
        component: battleEstablish,
      },
      {
        path: "Arena",
        name: "Arena",
        component: Arena,
      },
      {
        path: "createRoom",
        name: "createRoom",
        component: createRoom,
      },
      {
        path: "Mes",
        name: "Mes",
        component: Mes,
      },
      {
        path: "MeYiDong",
        name: "MeYiDong",
        component: MeYiDong,
      },
      {
        path: "Mess",
        name: "Mess",
        component: Mess,
      },

      {
        path: "/Roll",
        name: "Roll",
        component: Roll,
      },
      {
        path: "/Dota",
        name: "Dota",
        component: Dota,
      },
      {
        path: "/dome",
        name: "dome",
        component: dome,
      },
      {
        path: "/Cdk",
        name: "Cdk",
        component: Cdk,
      },
      {
        path: "Openbox",
        name: "Openbox",
        component: Openbox,
      },
      {
        path: "/LuckyRoom",
        name: "LuckyRoom",
        component: LuckyRoom,
      },
      {
        path: "/Doubt",
        name: "Doubt",
        component: Doubt,
      },
      {
        path: "/LuckyRule",
        name: "LuckyRule",
        component: LuckyRule,
      },
      {
        path: "/LuckyHistory",
        name: "LuckyHistory",
        component: LuckyHistory,
      },
      {
        path: "/Ornament",
        name: "Ornament",
        component: Ornament,
      },
      {
        path: "/OrnamentOpen",
        name: "OrnamentOpen",
        component: OrnamentOpen,
      },
      {
        path: "/Bill",
        name: "Bill",
        component: Bill,
      },
      {
        path: "/Spread",
        name: "Spread",
        component: Spread,
      },
      {
        path: "/Inform",
        name: "Inform",
        component: Inform,
      },
      {
        path: "/Vip",
        name: "Vip",
        component: Vip,
      },
      {
        path: "/Me",
        name: "Me",
        component: Me,
      },
      {
        path: "/Payment",
        name: "Payment",
        component: Payment,
      },
      {
        path: "/ArenaRoom",
        name: "ArenaRoom",
        component: ArenaRoom,
      },
      {
        path: "/Abouts",
        name: "Abouts",
        component: Abouts,
      },
      {
        path: "/Agreement",
        name: "Agreement",
        component: Agreement,
      },
      {
        path: "/Privacy",
        name: "Privacy",
        component: Privacy,
      },
      {
        path: "/Clause",
        name: "Clause",
        component: Clause,
      },
      {
        path: "/OrnamentHistory",
        name: "OrnamentHistory",
        component: OrnamentHistory,
      },
      {
        path: "/OrnamentHistory2",
        name: "OrnamentHistory2",
        component: OrnamentHistory2,
      },
      {
        path: "/OpenboxRule",
        name: "OpenboxRule",
        component: OpenboxRule,
      },
      {
        path: "/SpreadLonger",
        name: "SpreadLonger",
        component: SpreadLonger,
      },
      {
        path: "/share",
        name: "share",
        component: share,
      },
      {
        path: "/profile/skins",
        name: "skins",
        component: skins,
      },
      {
        path: "/profile/income",
        name: "income",
        component: income,
      },
      {
        path: "/profile/welfare",
        name: "welfare",
        component: welfare,
      },
      {
        path: "/recharge",
        name: "recharge",
        component: recharge,
      },
      {
        path: "/comeback",
        name: "comeback",
        component: comeback,
      },
      {
        path: "/fullReturn",
        name: "fullReturn",
        component: fullReturn,
      },
      {
        path: "/ruleDream",
        name: "ruleDream",
        component: ruleDream,
      },
      {
        path: "/ruleBattle",
        name: "ruleBattle",
        component: ruleBattle,
      },
      {
        path: "/task-center",
        name: "TaskCenter",
        component: () => import("@/views/task-center/task-center.vue"),
      },
      {
        path: "/change-cdk",
        name: "Cdk",
        component: () => import("@/views/cdk-change/cdk-change.vue"),
      },
    ],
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("@/views/slot-machine/test.vue"),
  },
  //SpreadLonger
  /*{
    path: '/about',
    name: 'About',
    component: function () {
      return import('../views/About.vue')
    }
  }  */
];

const router = new VueRouter({
  routes,
});
function converTime(timeString) {
  return new Date(new Date(timeString).getTime() + 2 * 60 * 60 * 1000);
}
router.beforeEach((to, from, next) => {
  const loginStatus = localStorage.getItem("loginStatus");
  const expireTime = converTime(loginStatus);
  if (expireTime < new Date()) {
    if (localStorage.getItem("userInfo")) {
      localStorage.setItem("userInfo", null);
    }
    store.commit("getLogin", true);
    store.commit("getLogins", false);
    store.commit("loginOut");
  }
  // if(login)
  next();
});
export default router;
