<template>
  <div class="battleHistory">
    <div class="battleHistory">
      <div class="tab">
        <div
          class="tabItem"
          :class="{ active: type === 'all' }"
          @click="changeTab('all')"
        >
          全部记录
        </div>
        <div
          class="tabItem"
          :class="{ active: type === 'my' }"
          @click="changeTab('my')"
        >
          我的记录
        </div>
      </div>
      <div class="title tr _windows">
        <div class="title-inside title-time td">
          <div class="title-inside-text">
            <span>日期</span>
          </div>
          <div class="title-inside-arrow"></div>
        </div>
        <div class="title-inside title-cases td">
          <div class="title-inside-text">箱子</div>
        </div>
        <div class="title-inside title-value td">
          <div class="title-inside-text">
            <span>金额</span>
          </div>
          <div class="title-inside-arrow"></div>
        </div>
        <div class="title-inside title-players td">
          <div class="title-inside-text">
            <span>玩家</span>
          </div>
          <div class="title-inside-arrow"></div>
        </div>
        <div class="title-inside title-actions td">
          <div class="title-inside-text">操作</div>
        </div>
      </div>
      <!---->
      <div class="list _windows">
        <div class="tr" v-for="item in tableData" :key="item">
          <div class="td list-column-time">
            <!-- <div>今天下午</div> -->
            <div>{{ item.update_time }}</div>
          </div>
          <div class="td list-column-cases">
            <div
              class="swiper swiper-initialized swiper-horizontal swiper-ios swiper-backface-hidden"
            >
              <div
                class="swiper-wrapper"
                style="transform: translate3d(0px, 0px, 0px)"
              >
                <div
                  class="swiper-slide list-column-cases-images"
                  style="margin-right: 10px"
                  v-for="(item1, index) in item.boxInfo"
                  :key="item1.id"
                >
                  <div class="mauser-pistol">
                    <div class="list-column-cases-images-dom">
                      <img class="img-go" :src="item1.img_active" /><img
                        class="img-go img-overlay"
                        :src="item1.img_main"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="td list-column-value">
            <div class="MoneyCoin">
              <img src="../assets/img/money.png" alt="" />
              <span class="moneyVal" style="color: rgb(255, 255, 255)">{{
                item.total_price
              }}</span>
            </div>
          </div>
          <div class="td list-column-players">
            <div class="list-column-players-images">
              <div
                v-for="item2 in item.player_info"
                :key="item2.id"
                class="list-column-players-images-show"
                :style="{
                  'background-image': `url(${item2.img})`,
                }"
              >
                <img
                  v-if="item.winner == item2.id"
                  class="winFlag"
                  src="../assets/img/luckRoom/win@2x.a08753c1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="td list-column-actions">
            <div
              class="list-column-actions-text"
              @click="goLuckyRoom(item.battle_id)"
            >
              查看对局
            </div>
          </div>
        </div>
      </div>
      <div class="list-m tr _mobile" v-if="!showNav">
        <div class="time">日期</div>
        <div class="value">金额</div>
        <div class="players">玩家</div>
      </div>
      <div class="list-d _mobile" v-if="!showNav">
        <div class="list-column-m tr" v-for="item in tableData" :key="item" @click="goLuckyRoom(item.battle_id)">
          <div class="top">
            <div class="time">
              <div>{{ item.update_time }}</div>
            </div>
            <div class="value">
              <div class="MoneyCoin">
                <img src="../assets/img/money.png" alt="" />
                <span class="moneyVal" style="color: rgb(255, 255, 255)">{{
                  item.total_price
                }}</span>
              </div>
            </div>
            <div class="players">
              <div
                v-for="item2 in item.player_info"
                :key="item2.id"
                class="list-column-players-images-show"
                :style="{
                  'background-image': `url(${item2.img})`,
                }"
              >
                <img
                  v-if="item.winner == item2.id"
                  class="winFlag"
                  src="../assets/img/luckRoom/win@2x.a08753c1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="swiper swiper-initialized swiper-horizontal swiper-ios">
              <div
                class="swiper-wrapper"
                style="transition-duration: 0ms; transition-delay: 0ms"
              >
                <div
                  class="swiper-slide list-column-cases-images"
                  v-for="(item1, index) in item.boxInfo"
                  :key="item1.id"
                >
                  <div class="mauser-pistol">
                    <div class="list-column-cases-images-dom">
                      <img class="img-go" :src="item1.img_active" /><img
                        class="img-go img-overlay"
                        :src="item1.img_main"
                      />
                    </div>
                  </div>
                  <!---->
                </div>
              </div>
              <!----><!----><!---->
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalSize"
        :page-size="pageSize"
        :current-page="page"
        @current-change="currentChange"
      >
      </el-pagination>
      <!-- <div class="paging" style="">
        <div class="el-pagination">
          <button
            type="button"
            class="btn-prev is-first"
            disabled=""
            aria-label="Go to previous page"
            aria-disabled="true"
          >
            <i class="el-icon"
              ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  fill="currentColor"
                  d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z"
                ></path></svg
            ></i>
          </button>
          <ul class="el-pager">
            <li
              class="is-active number"
              aria-current="true"
              aria-label="page 1"
              tabindex="0"
            >
              1
            </li>
            <li
              class="number"
              aria-current="false"
              aria-label="page 2"
              tabindex="0"
            >
              2
            </li>
            <li
              class="number"
              aria-current="false"
              aria-label="page 3"
              tabindex="0"
            >
              3
            </li>
            <li
              class="number"
              aria-current="false"
              aria-label="page 4"
              tabindex="0"
            >
              4
            </li>
            <li
              class="more btn-quicknext el-icon"
              tabindex="0"
              aria-label="Next 3 pages"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  fill="currentColor"
                  d="M176 416a112 112 0 1 1 0 224 112 112 0 0 1 0-224m336 0a112 112 0 1 1 0 224 112 112 0 0 1 0-224m336 0a112 112 0 1 1 0 224 112 112 0 0 1 0-224"
                ></path>
              </svg>
            </li>
            <li
              class="number"
              aria-current="false"
              aria-label="page 10"
              tabindex="0"
            >
              10
            </li>
          </ul>
          <button
            type="button"
            class="btn-next is-last"
            aria-label="Go to next page"
            aria-disabled="false"
          >
            <i class="el-icon"
              ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  fill="currentColor"
                  d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"
                ></path></svg
            ></i>
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      page: 1,
      pageSize: 10,
      activeName: "one",
      totalSize: 0,
      tableData: [],
      type: "all",
    };
  },
  mounted() {
    this.getBattleHistory();
  },
  methods: {
    // 获取记录
    getBattleHistory() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
        type: this.type,
      };
      this.$axios
        .post("/index/Battle/battleHistory", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.totalSize = data.data.total;
            let result = data.data.list;
            for (let i = 0; i < result.length; i++) {
              result[i].boxInfo = JSON.parse(result[i].boxInfo);
              result[i].price = 0;
              var info = result[i].boxInfo;
              for (let j = 0; j < info.length; j++) {
                result[i].price += Number(info[j].price);
              }
              result[i].price = result[i].price.toFixed(2);
            }
            for (let i = 0; i < result.length; i++) {
              let play = JSON.parse(JSON.stringify(result[i].player_info));
              let mode = result[i].mode;
              for (let j = 0; j < mode - result[i].player_info.length; j++) {
                play.push({
                  img: require("../assets/img/jiapeople.png"),
                  class: "pk-false",
                  id: play.length,
                  border: "1px dashed #3088FF",
                });
              }
              for (let x = 0; x < play.length; x++) {
                if (play[x] && play[x].id) {
                  play[x].class = "pk-true";
                  play[x].img = play[x].img;
                }
              }
              result[i].player_info = play;
              result[i].player_info.forEach((e) => {
                if (!e["name"]) {
                  if (!result[i]["nums"]) {
                    result[i]["nums"] = 1;
                  } else {
                    result[i]["nums"] += 1;
                  }
                }
              });

              if (mode == "3") {
                result[i].play1 = [];
                result[i].play2 = [];
                result[i].play1.push(play[0]);
                result[i].play2.push(play[1]);
                result[i].play2.push(play[2]);
              }
              if (mode == "4") {
                result[i].play1 = [];
                result[i].play2 = [];
                result[i].play1.push(play[0]);
                result[i].play1.push(play[1]);
                result[i].play2.push(play[2]);
                result[i].play2.push(play[3]);
              }
            }
            this.tableData = result;
            console.log(this.tableData, "dhjadja");
          }
        });
    },
    goLuckyRoom(id) {
      this.$router.push({
        path: `/LuckyRoom?id=${id}`,
      });
    },
    changeTab(type) {
      this.type = type;
      this.page = 1;
      this.getBattleHistory();
    },
    currentChange(val) {
      this.page = val;
      this.getBattleHistory();
    },
  },
};
</script>
<style scoped lang="less">
.battleHistory .tab {
  display: flex;
  margin-bottom: 0.33rem * 1.4;
}

.battleHistory .tab .tabItem {
  margin-right: 1rem * 1.4;
  color: #c0c3d1;
  font-size: 0.58rem * 1.4;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0 0.5rem * 1.4 0.5rem * 1.4 0.5rem * 1.4;
}

.battleHistory .tab .active {
  color: #fff;
  position: relative;
}

.battleHistory .tab .active:after {
  display: block;
  content: "";
  position: absolute;
  width: 60%;
  height: 0.13rem * 1.4;
  background-color: #ff31ed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.battleHistory .title {
  display: flex;
  width: 100%;
  height: 1.75rem * 1.4;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  background: #1a1f30;
}
.battleHistory .title:hover {
  background: #212636;
}

.battleHistory .title .title-inside {
  display: flex;
  justify-content: center;
}

.battleHistory .title .title-inside .title-inside-text {
  height: 0.75rem * 1.4;
  font-size: 0.58rem * 1.4;
  font-weight: 400;
  color: #a9abbc;
}

.battleHistory .title .title-inside .title-inside-text > span {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  padding-right: 0.2rem * 1.4;
}

.battleHistory .title .title-inside .title-inside-arrow {
  display: none;
  width: 0.75rem * 1.4;
  height: 0.75rem * 1.4;
  background-image: url(/img/sort.13aa10ac.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}

.battleHistory .title .title-inside .title-inside-arrow-rotate {
  transform: rotate(180deg);
}

.battleHistory .title .title-time {
  width: 12%;
}

.battleHistory .title .title-cases {
  flex: 1;
  justify-content: flex-start;
  padding-left: 1rem * 1.4;
}

.battleHistory .title .title-value {
  width: 10%;
  justify-content: flex-start;
}

.battleHistory .title .title-players {
  width: 18%;
  justify-content: flex-start;
}

.battleHistory .title .title-actions {
  width: 14%;
}

.battleHistory .list .tr {
  width: 100%;
  height: 4.88rem * 1.4;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  margin-top: 0.12rem * 1.4;
  background: #1a1f30;
}
.battleHistory .list .tr:hover {
  background: #212636;
}

.battleHistory .list .tr .td {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.battleHistory .list .tr .list-column-time {
  width: 12%;
  display: flex;
  justify-content: center;
  font-size: 0.58rem * 1.4;
  font-weight: 400;
  color: #a9abbc;
}

.battleHistory .list .tr .list-column-time > div {
  text-align: center;
  width: 100%;
  padding: 0.1rem * 1.4 0;
}

.battleHistory .list .tr .list-column-cases {
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-y: auto;
  flex: 1;
  padding-right: 1rem * 1.4;
}

.battleHistory .list .tr .list-column-cases .swiper {
  margin: 0;
}

.battleHistory .list .tr .list-column-cases .list-column-cases-images {
  width: 3.67rem * 1.4;
  cursor: pointer;
}

.battleHistory
  .list
  .tr
  .list-column-cases
  .list-column-cases-images
  .list-column-cases-images-dom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 3.17rem*1.4;
  height: 3.17rem*1.4;
}

.battleHistory
  .list
  .tr
  .list-column-cases
  .list-column-cases-images
  .list-column-cases-images-dom
  .img-go {
  width: 3.17rem;
  height: 3.17rem ;
  z-index: 2;
}

.battleHistory
  .list
  .tr
  .list-column-cases
  .list-column-cases-images
  .list-column-cases-images-dom
  .img-text {
  width: 1.17rem * 1.4;
  height: 0.72rem * 1.4;
  background: #2e3544;
  border-radius: 0.38rem * 1.4 0.36rem * 1.4 0.36rem * 1.4 0;
  position: absolute;
  top: 0;
  left: -0.1rem * 1.4;
  font-size: 0.5rem * 1.4;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.battleHistory
  .list
  .tr
  .list-column-cases
  .list-column-cases-images
  .list-column-cases-images-dom
  .img-overlay {
  width: 2.17rem * 1.4;
  height: 2.71rem * 1.4;
  position: absolute;
  top: 0.15rem * 1.4;
  left: 0.5rem * 1.4;
  z-index: 1;
}

.battleHistory .list .tr .list-column-value {
  width: 10%;
  justify-content: flex-start;
}

.battleHistory .list .tr .list-column-players {
  width: 18%;
  justify-content: flex-start;
}

.battleHistory .list .tr .list-column-players .list-column-players-images {
  display: flex;
  flex-wrap: wrap;
}

.battleHistory
  .list
  .tr
  .list-column-players
  .list-column-players-images
  .list-column-players-images-show {
  width: 2rem * 1.4;
  height: 2rem * 1.4;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 0.2rem * 1.4;
  position: relative;
}

.battleHistory
  .list
  .tr
  .list-column-players
  .list-column-players-images
  .list-column-players-images-show
  .winFlag {
  width: 100%;
  position: absolute;
  top: -50%;
  right: -20%;
}

.battleHistory .list .tr .list-column-actions {
  width: 14%;
}

.battleHistory .list .tr .list-column-actions .list-column-actions-text {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 4.04rem * 1.4;
  height: 1.51rem * 1.4;
  background: #2b313f;
  border-radius: 0.76rem * 1.4;
  font-size: 0.5rem * 1.4;
  background: rgba(105, 23, 255, 0.2);
  border: 2px solid #6917ff;
  box-sizing: border-box;
  color: #fff;
}

.battleHistory .list .tr .list-column-actions .list-column-actions-text:hover {
  background: rgba(105, 23, 255, 0.5);
}

@media screen and (max-width: 992px) {
  ._windows {
    display: none !important;
  }
  .battleHistory .tab {
    display: flex;
  }

  .battleHistory .tab .tabItem {
    margin-right: 1rem * 0.8;
    color: #c0c3d1;
    font-size: 1rem * 0.8;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding: 0 0.5rem * 0.8 0.5rem * 0.8 0.5rem * 0.8;
  }

  .battleHistory .title {
    display: flex;
    width: 100%;
    height: 1.75rem * 0.8;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    margin-bottom: 0.7rem * 0.8;
  }

  .battleHistory .title .title-inside {
    display: flex;
    justify-content: center;
  }

  .battleHistory .title .title-inside .title-inside-text {
    height: 0.75rem * 0.8;
    font-size: 1rem * 0.8;
    font-weight: 400;
    color: #a9abbc;
  }

  .battleHistory .title .title-inside .title-inside-text > span {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    padding-right: 0.2rem * 0.8;
  }

  .battleHistory .title .title-inside .title-inside-arrow {
    width: 1.61rem * 0.8;
    height: 1.61rem * 0.8;
    background-image: url(/img/sort.13aa10ac.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  .battleHistory .title .title-inside .title-inside-arrow-rotate {
    transform: rotate(180deg);
  }

  .battleHistory .title .title-time {
    width: 18rem * 0.8;
  }

  .battleHistory .list-m {
    width: 29.31rem * 0.8;
    height: 2.84rem * 0.8;
    border-radius: 0.42rem * 0.8 0.42rem * 0.8 0 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(105, 23, 255, 0.3);
    color: #a9abbc;
  }

  .battleHistory .list-d .list-column-m {
    width: 29.31rem * 0.8;
    height: 11.31rem * 0.8;
    margin-top: 0.2rem * 0.8;
    flex-wrap: wrap;
    background: #1a1f30;
  }

  .battleHistory .list-d .list-column-m .top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem * 0.8;
    position: relative;
    flex-direction: row !important;
  }

  .battleHistory .list-d .list-column-m .top .players {
    width: 10rem * 0.8;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .battleHistory
    .list-d
    .list-column-m
    .top
    .players
    .list-column-players-images-show {
    width: 2.07rem * 0.8;
    height: 2.07rem * 0.8;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 0.2rem * 0.8;
    position: relative;
  }

  .battleHistory
    .list-d
    .list-column-m
    .top
    .players
    .list-column-players-images-show
    .winFlag {
    width: 100%;
    position: absolute;
    top: -50%;
    right: -20%;
  }

  .battleHistory .list-d .list-column-m .top .value {
    width: 10rem * 0.8;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .battleHistory .list-d .list-column-m .top .time {
    width: 9rem * 0.8;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #a9abbc;
  }

  .battleHistory .list-d .list-column-m .top .time > div {
    text-align: center;
    width: 10rem * 0.8;
  }

  .battleHistory .list-d .list-column-m .top:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    width: 90%;
    background: hsla(0, 0%, 100%, 0.08);
  }

  .battleHistory .list-d .list-column-m .bottom {
    padding: 0.5rem * 0.8 2rem * 0.8 0 2rem * 0.8;
    overflow-x: auto;
  }

  .battleHistory .list-d .list-column-m .bottom .list-column-cases-images {
    width: 4.83rem * 0.8;
    position: relative;
    .img-go {
      width: 100%;
    }
    .img-overlay {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.MoneyCoin {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
}
</style>