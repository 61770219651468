<template>
  <div class="slot-machine" @click="startRolling">
    <div class="slot-list" ref="slotList">
      <div
        v-for="(item, index) in arrayList"
        :key="index"
        class="slot-item"
        :id="item.id"
        :style="{
          backgroundImage: `url('${item.img}')`,
        }"
      >
        <img :src="item.imageUrl" alt="" srcset="" />
        <!-- <p>{{ item.box_name }}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    delay: {
      type: Number,
      default: 0,
    },
    direction: {
      type: String,
      default: "vertical", //horizontal
    },
  },
  mounted() {
    setTimeout(() => {
      // this.startRolling();
    }, this.delay);
  },
  computed: {
    arrayList() {
      let list = [];
      for (let index = 0; index < 1; index++) {
        list = list.concat(this.list);
      }
      return this.shuffle(list);
    },
  },
  methods: {
    shuffle(arr) {
      return arr.sort(() => Math.random() - 0.5);
    },
    startRolling() {
      // 滚动速度
      let speed = 20;
      // 滚动时间
      let time = 7;
      let count = 0;
      // 随机选取目标位置 由于前面的数组已经打乱了的，所以只需要取最后第2个就是了
      let targetIndex = this.arrayList.length - 3;
      // 计算滚动的最后时长 500 是最后位置调整时长
      const scrollTime = new Date().getTime() + time * 1000 - 5000;
      let slotList = this.$refs.slotList;

      const setup = () => {
        const nowTime = new Date().getTime();
        // 判断是否达到目标位置
        if (nowTime >= scrollTime) {
          // 滚动结束，触发事件
          this.$emit("finish-rolling", this.arrayList[targetIndex + 1]);
          // 达到目的位置进行滚动
          slotList.style.transition = `all 5000ms ease-out`;
          // 在最终停下来的时候的位置
          slotList.style.transform = `translateY(-${targetIndex * 85}px)`;
        } else {
          count += speed;
          slotList.style.transition = `none`;
          slotList.style.transform = `translateY(-${count % 2000}px)`;
          window.requestAnimationFrame(setup);
        }
      };
      setup();
    },
  },
};
</script>

<style lang="less" scoped>
@heightItem: 85px;
.slot-machine {
  width: 73px;
  height: @heightItem * 3;
  overflow: hidden;
  position: relative;
  user-select: none;
}
.slot-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2000px;
}
.slot-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
}
.slot-item {
  height: @heightItem;
  text-align: center;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  img {
    width: 60px;
    margin: auto 0;
  }
  // p {
  //   color: #fff;
  //   font-size: 13px;
  //   height: 30px;
  //   line-height: 30px;
  // }
}
</style>
