<template>
  <div class="layoutView">
    <div class="CDKView routerView">
      <div class="content">
        <div class="card">
          <div class="inputBox">
            <div class="title _mobile">
              <span class="titleName">CDK兑换</span>
            </div>
            <el-input v-model="cdk" placeholder=""></el-input>
            <div class="btn defaultBtn" @click="getcdk">兑换</div>
          </div>
        </div>
        <ul class="list">
          <li class="listItem" v-for="i in cdkList" :key="i.code">
            <div class="itemTitle">
              <span class="cdkName">当日累计充值，不可重复领取</span
              >
              <!-- <span> 总数量：700</span> -->
            </div>
            <div class="subject">
              <img src="../assets/img/redEnvelope@2X.d7ff8ac4.gif" alt="" />
              <div class="describe">
                <div class="msg">参与条件：每日充值{{ i.limitAmount }}币即可领取</div>
                <!-- <div class="endTime">结束时间：2024-09-13 23:59:59</div> -->
              </div>
              <div class="btn"  @click="talk_cdk(i.id)">领取</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
  </div>
</template>
<script>
import navheader from "@/components/navheader.vue";
export default {
  components: {
    navheader
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      cdk: "",
      cdkList:[]
    };
  },
  methods: {
     // 兑换cdk
     getcdk() {
      if (!this.cdk) {
        this.$message({
          message: "请输入cdk口令",
          type: "warning",
        });
        return;
      }
      let param = {
        password: this.cdk,
      };
      this.$axios
        .post("index/Activity/cdk", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.$message({
              message: "CDK成功兑换",
              type: "success",
            });
            this.cdk = "";
          } else {
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        });
    },
    // 领取红包
    talk_cdk(id) {
      let param = {
        id: id,
      };
      this.$axios
        .post("/index/activity/talk_cdk", this.$qs.stringify(param))
        .then((res) => {
          if (res.data.status == 1) {
            this.$message.success(res.data.msg);
            this.getCdkList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 获取红包列表
    getCdkList() {
      this.$axios.get("/index/activity/ckd_list").then((res) => {
        if (res.data.status == 1) {
          this.cdkList = res.data.data;
        }
      });
    },
  },
  mounted() {
    this.getCdkList()
  },
};
</script>
<style scoped lang="less">
.CDKView {
  width: 28.75rem * 1.5;
  color: #fff;
}

.CDKView .title {
  font-size: 0.58rem * 1.5;
  margin-bottom: 0.75rem * 1.5;
  color: #d1d1d1;
}

.CDKView .title .titleName {
  font-size: 0.92rem * 1.5;
  margin-right: 0.58rem * 1.5;
  color: #fff;
  font-weight: 700;
}

.CDKView .content .card {
  width: 100%;
  overflow: hidden;
  padding: 0 0.8rem * 1.5;
  box-sizing: border-box;
  margin-bottom: 0.83rem * 1.5;
}

.CDKView .content .card .inputBox {
  height: 4.46rem * 1.5;
  background-color: rgba(105, 23, 255, 0.5);
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: 6;
}

.CDKView .content .card .inputBox input {
  width: 20rem * 1.5;
  margin-right: 0.67rem * 1.5;
  margin-bottom: 0;
  background-color: hsla(0, 0%, 100%, 0.8);
  color: #000;
}

.CDKView .content .card .inputBox .btn {
  width: 4.3rem * 1.5;
  height: 1.94rem * 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff31ed;
}

.CDKView .content .card .inputBox:after,
.CDKView .content .card .inputBox:before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  border-bottom: 2.23rem * 1.5 solid transparent;
  border-top: 2.23rem * 1.5 solid transparent;
}

.CDKView .content .card .inputBox:before {
  left: -2.21rem * 1.5;
  border-right: 2.23rem * 1.5 solid rgba(105, 23, 255, 0.5);
}

.CDKView .content .card .inputBox:after {
  right: -2.21rem * 1.5;
  border-left: 2.22rem * 1.5 solid rgba(105, 23, 255, 0.5);
}

.CDKView .content .list {
  max-height: 16rem * 1.5;
  overflow: auto;
}

.CDKView .content .list .listItem {
  padding: 1rem * 1.5;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.42rem * 1.5;
  margin-bottom: 0.21rem * 1.5;
  position: relative;
}

.CDKView .content .list .listItem:after {
  content: "";
  display: block;
  height: 50%;
  width: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: #6917ff;
}

.CDKView .content .list .listItem .itemTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.58rem * 1.5;
  color: #d1d1d1;
  margin-bottom: 1.25rem * 1.5;
  padding: 0 1rem * 1.5;
  box-sizing: border-box;
  height: 2rem * 1.5;
  background-color: #6917ff;
  border-radius: 0.42rem * 1.5;
}

.CDKView .content .list .listItem .itemTitle .cdkName {
  font-size: 0.75rem * 1.5;
  color: #fff;
  font-weight: 700;
}

.CDKView .content .list .listItem .subject {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CDKView .content .list .listItem .subject img {
  width: 2.18rem * 1.5;
  margin-right: 0.5rem * 1.5;
}

.CDKView .content .list .listItem .subject .describe {
  flex: 1;
  font-size: 0.75rem * 1.5;
}

.CDKView .content .list .listItem .subject .describe .endTime {
  font-size: 0.58rem * 1.5;
  color: #a9a9a9;
  margin-top: 0.25rem * 1.5;
}

.CDKView .content .list .listItem .subject .btn {
  background: url('../assets/img/GO1.4e0c0e55.png') no-repeat 50% / cover;
  width: 3.25rem * 1.5;
  height: 1.83rem * 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem * 1.5;
  font-weight: 700;
  color: #fff;
}

.CDKView .content .list .listItem .subject .received {
  background: url('../assets/img/GO3.61a78841.png') no-repeat 50% / cover;
  color: #5c5c5c;
}

::-webkit-scrollbar-thumb {
  background-color: #6917ff;
}

@media screen and (max-width: 992px) {
  .CDKView {
    width: 100%;
    padding: 1rem * 0.8 0.5rem * 0.8;
    box-sizing: border-box;
    position: relative;
  }

  .CDKView .content .card {
    width: 100%;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 0.83rem * 0.8;
  }

  .CDKView .content .card .inputBox {
    height: auto;
    background-color: rgba(105, 23, 255, 0.5);
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 0.96rem * 0.8;
    padding: 3rem * 0.8 1rem * 0.8 1rem * 0.8 1rem * 0.8;
  }

  .CDKView .content .card .inputBox .title {
    font-size: 1rem * 0.8;
    margin-bottom: 0.75rem * 0.8;
    color: #d1d1d1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0.5rem * 0.8;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 0 1rem * 0.8;
  }

  .CDKView .content .card .inputBox .title .titleName {
    font-size: 1.2rem * 0.8;
    margin-right: 0.58rem * 0.8;
    color: #fff;
    font-weight: 700;
  }

  .CDKView .content .card .inputBox input {
    width: 20rem * 0.8;
    margin-right: 0.67rem * 0.8;
    margin-bottom: 0;
    background-color: hsla(0, 0%, 100%, 0.8);
    color: #000;
  }

  .CDKView .content .card .inputBox .btn {
    width: 5.3rem * 0.8;
    height: 2.99rem * 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff31ed;
  }

  .CDKView .content .card .inputBox:after,
  .CDKView .content .card .inputBox:before {
    display: none;
  }

  .CDKView .content .list {
    max-height: none;
  }

  .CDKView .content .list .listItem {
    padding: 1.33rem * 0.8;
    background: hsla(0, 0%, 100%, 0.05);
    border-radius: 0.42rem * 0.8;
    margin-bottom: 0.88rem * 0.8;
    position: relative;
  }

  .CDKView .content .list .listItem:after {
    content: "";
    display: block;
    height: 50%;
    width: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  .CDKView .content .list .listItem .itemTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem * 0.8;
    color: #d1d1d1;
    margin-bottom: 1.25rem * 0.8;
    padding: 0 1rem * 0.8;
    box-sizing: border-box;
    height: 3rem * 0.8;
    background-color: #6917ff;
    border-radius: 0.42rem * 0.8;
  }

  .CDKView .content .list .listItem .itemTitle .cdkName {
    font-size: 1.2rem * 0.8;
    color: #fff;
    font-weight: 700;
  }

  .CDKView .content .list .listItem .subject {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .CDKView .content .list .listItem .subject img {
    width: 3.18rem * 0.8;
    margin-right: 0.5rem * 0.8;
  }

  .CDKView .content .list .listItem .subject .describe {
    flex: 1;
    font-size: 1.08rem * 0.8;
  }

  .CDKView .content .list .listItem .subject .describe .endTime {
    font-size: 1rem * 0.8;
    color: #a9a9a9;
    margin-top: 0.25rem * 0.8;
  }

  .CDKView .content .list .listItem .subject .btn {
    background: url('../assets/img/GO1.4e0c0e55.png') no-repeat 50% / cover;
    width: 5.39rem * 0.8;
    height: 3.05rem * 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.08rem * 0.8;
    font-weight: 700;
    color: #fff;
  }

  .CDKView .content .list .listItem .subject .received {
    background: url('../assets/img/GO3.61a78841.png') no-repeat 50% / cover;
    color: #5c5c5c;
  }
}
</style>