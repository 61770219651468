<template>
  <div class="index">
    <div class="swiper-box">
      <swiper ref="mySwiper" :options="swiperOptions" class="swiper-more">
        <swiper-slide v-for="(item, index) in banners" :key="index"
          ><img :src="item" width="100%" style="object-fit: contain"
        /></swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
    <div class="notices">
      <div class="rt">
        <img src="../assets/img/notice.e2f24045.png" alt="" />
        <NoticeScroll
          :speed="10"
          text="盲盒有风险，拆盒需谨慎，最终商品以实际获取为准，切勿上头！"
        />
      </div>
    </div>

    <!-- <CenterNav v-if="!showNav" /> -->
    <div
      class="positionSticky"
      id="positionSticky"
      :class="{ fixed: isFixed }"
      :style="{ top: isFixed ? `${navHeight}px` : '' }"
    >
      <div
        class="positionItem"
        v-for="(v, i) in typeBoxList"
        :key="i"
        @click="scrollToTab(v.id)"
        :class="{ active: v.id === activeTabId }"
      >
        <span>{{ v.type_name }}</span>
      </div>
    </div>
    <div
      class="center"
      :class="{ yidongcenter: !$store.state.mobile }"
      :style="{
        padding: $store.state.mobile ? '0' : '0 4%',
      }"
    >
      <div
        v-for="(v, i) in typeBoxList"
        :key="i"
        class="box-area"
        :id="`box-${v.id}`"
      >
        <div class="box">
          <div class="title">
            <span>{{ v.type_name }}</span>
          </div>

          <div class="lists">
            <div
              @click="openbox(item.id)"
              class="list-item"
              v-for="(item, index) in v.box_list"
              :key="index"
              @mouseenter="mouseenterItem"
              @mouseleave="mouseleaveItem"
            >
              <img class="main-img" :src="item.img_active" alt="" srcset="" />
              <img style="width: 100%" :src="item.img_main" alt="" srcset="" />
              <img
                src="https://181skins.com/assets/cases_bg.74c51cca.png?v=65"
                alt=""
                class="bg-img"
                srcset=""
              />
              <span class="box-name">{{ item.name }}</span>
              <span class="box-price" v-if="![33, 34, 35, 36].includes(item.id)"
                ><img src="../assets/img/money.png" />{{ item.price }}</span
              >
              <span
                class="box-price open"
                v-if="![33, 34, 35, 36].includes(item.id)"
                >打开<img src="../assets/img/open.png"
              /></span>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <myhomebot style="margin-top: 20px"></myhomebot>
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import myslide from "@/components/my_slide1.vue";
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import navheader from "@/components/navheader.vue";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import Swiper2, { Navigation, Pagination, Autoplay } from "swiper";
import { CenterNav, NoticeScroll } from "@/views/common";

Swiper2.use([Navigation, Pagination, Autoplay]);
export default {
  components: {
    myslide,
    myhomebot,
    myinform,
    Swiper,
    SwiperSlide,
    navheader,
    CenterNav,
    NoticeScroll,
  },
  directives: {
    swiper: directive,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      isFixed: false,
      boxAreas: [], // 用于存储每个 box-area 的位置信息
      activeTabId: null,
      centerContent: [
        {
          name: "商城",
          image: require("../assets/img/nav2/b55.png"),
          url: "/Roll",
          type: 1,
        },
        {
          name: "roll房",
          image: require("../assets/img/nav2/b11.png"),
          url: "/Arena",
          type: 1,
        },
        {
          name: "CD-KEY",
          image: require("../assets/img/cdk.png"),
          url: "/Payment?type=cdk",
          type: 1,
        },
        {
          name: "客服",
          image: require("../assets/img/kefu.png"),
          type: 2,
        },
        {
          name: "取回助手",
          image: require("../assets/img/back.svg"),
          type: 3,
        },
      ],
      typeBoxList: [],
      listSlide: [],
      img: "",
      img1: require("../assets/img/1mdpi.png"),
      active: [],
      windowWidth: document.body.clientWidth,
      userState: "",
      banners: [
        // require('../assets/img/c2c011f872ce3307300c5e32be476cb.png'),
        // require('../assets/img/85b2fa8b401791a2a2676cad0d72af1.png'),
        require("../assets/img/9fd0a606e75b34806c4328e4a361a6e.jpg"),
        // require('../assets/img/e2b32fdb5c524d704954e01dccad42c.png')
        // 659cadf3ea0fb00eb42c127b7931047.jpg
      ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        autoplay: {
          delay: 3000, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        loop: false,
      },
      adshow: false,
      adshowimg: require("../assets/img/adshow.png"),
      mouseenterAudio: new Audio(require("../assets/audio/shoot.4e9ed9b8.mp3")),
      navHeight: 0,
      positionSticky: 0,
      boxAreas: [],
    };
  },
  methods: {
    initBoxAreas() {
      this.boxAreas = [];
      // 获取每个盒子的距离顶部的高度
      requestAnimationFrame(() => {
        this.typeBoxList.forEach((item, index) => {
          const area = document.getElementById(`box-${item.id}`);
          this.boxAreas[index] = {
            id: item.id,
            element: area,
            top: area.offsetTop,
          };
        });
      });
    },

    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; // 获取滑动距离
      // 动态获取导航栏高度
      this.navHeight = document.getElementsByClassName("top")[0].offsetHeight;

      if (scrollTop <= this.positionSticky) {
        this.isFixed = false;
      } else {
        this.isFixed = true; // 设置fixed定位
      }
      this.updateActiveTab(scrollTop);
    },
    updateActiveTab(scrollTop) {
      this.boxAreas.forEach((area) => {
        if (scrollTop >= area.top - this.navHeight) {
          this.activeTabId = area.id;
        }
      });
    },
    scrollToTab(id) {
      const element = document.getElementById(`box-${id}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        this.activeTabId = id;
      }
    },
    mouseleaveItem() {
      this.mouseenterAudio.pause();
    },
    bindNav(type, url) {
      if (type == 2) {
        window.open(
          "https://qm.qq.com/cgi-bin/qm/qr?k=s7qihLq_4vvaIaOwNlsGQDq6e3wSxdzM&jump_from=webapi"
        );
      }
      if (type == 1) {
        this.$router.push({
          path: url,
        });
      }
      if (type == 3) {
        this.$emit("backAction", true);
      }
    },
    adClick() {
      this.adshow = false;
      this.$store.commit("getLogin", true);
    },
    mouseenterItem(i, index) {
      this.mouseenterAudio.play();
    },
    //请求背景图片
    getBack() {
      let _this = this;
      _this.$axios.post("/index/Setting/background").then((res) => {
        if (res.data.status == 1) {
          this.img = res.data.data.img;
          if (!this.img) {
            this.img = this.img1;
          }
        }
      });
    },
    // 首页活动模块
    getboxList() {
      let _this = this;
      _this.$axios.post("/index/Box/boxList").then((res) => {
        this.active = res.data.data.active;
        if (res.data.status == 1) {
          _this.typeBoxList = res.data.data.list;
          this.initBoxAreas();
          //console.log(_this.typeBoxList);
        }
      });
    },
    openbox(box_id) {
      this.$router.push({
        path: `/Openbox`,
        query: {
          box_id: box_id,
        },
      });
    },
    getPlayerInfo(pid) {
      this.$axios.post("/index/User/getPlayerInfo", {}).then((res) => {
        if (res.data.data.status == 1) {
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          Utils.$emit("login", true);
          that.selfLogin();
          return;
        }
      });
    },
    selfLogin() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!userInfo) {
        return;
      }
      this.me = userInfo;
      this.money = userInfo.total_amount;
      this.masonry = userInfo.masonry;
      this.loginfalse = false;
      this.regHint = false;
    },
  },
  activated() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      this.$parent.nextLogin();
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // this.initBoxAreas();
    // this.setupScrollListeners();
    requestAnimationFrame(() => {
      this.positionSticky = document.getElementById("positionSticky").offsetTop;
    });
    if (this.$route.query.pid > 0) {
      this.z(this.$route.query.pid);
    }
    this.getboxList();
    this.getBack();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      this.adshow = true;
      return;
    }
    this.userState = userInfo.state;
    // this.$emit('backAction','click')
  },
  created() {
    // this.initBoxAreas();
    // this.setupScrollListeners();
  },
  beforeDestroy() {
    this.removeScrollListeners();
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    positionStickyClass() {
      return {
        positionSticky: true,
        fixed: this.isFixed,
      };
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .lists {
    grid-template-columns: repeat(2, 1fr) !important;
    .list-item {
      height: 310px !important;
      .box-name {
        font-size: 20px !important;
        bottom: 70px !important;
      }
      .box-price {
        font-size: 14px !important;
        bottom: 20px !important;
        padding: 1px 5px !important;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .lists {
    grid-template-columns: repeat(3, 1fr) !important;
    .list-item {
      height: 360px !important;
      .box-name {
        font-size: 20px !important;
        bottom: 90px !important
      }
      .box-price {
        font-size: 14px !important;
        bottom: 40px !important;
        padding: 1px 5px !important;
      }
    }
  }
}

// .notice {
//   display: inline-flex;
//   align-items: center;
//   height: 50px;
//   overflow: hidden;
//   img {
//     margin-right: 10px;
//   }
//   .tit {
//     width: 50px;
//   }
//   .notice_con {
//     width: 93%;
//     padding-right: 20px;
//     white-space: nowrap;
//     overflow: hidden;
//   }
// }

.lists {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1300px;
  width: 100%;
  gap: 20px;
  margin: 0 auto;
  margin-top: 70px;
  .list-item {
    height: 600px;
    overflow: visible;
    position: relative;
    cursor: pointer;
    &:hover {
      .main-img {
        transition: all 200ms;
        transform: translate(-50%, -60%) scale(1.2) rotate(5deg) !important;
      }
      .bg-img {
        opacity: 1 !important;
      }
      // .box-name {
      //   color: #3088ff;
      // }
      .box-price {
        // background-image: linear-gradient(to right, red, #3088ff) !important;
        opacity: 0;
      }
      .open {
        opacity: 1;
      }
    }
    .box-name {
      position: absolute;
      bottom: 150px;
      left: 0;
      width: 100%;
      text-align: center;
      display: block;
      color: #fff !important;
      font-size: 20px;
      -webkit-background-clip: text;
      color: transparent;
      // background-image: linear-gradient(to right, red, #3088ff, green);
      // font-family: "JDZY";
    }

    .box-price {
      position: absolute;
      bottom: 106px;
      left: 50%;
      width: 100%;
      display: block;
      color: #fff;
      font-size: 18px;
      // font-family: "JDZY";
      overflow: visible;
      // background-color: #3088ff;
      width: max-content;
      transform: translateX(-50%);
      padding: 2px 20px;
      background: url("../assets/img/prcBg@2x.99ac8258.png") no-repeat 50% /
        cover;
      // background-image: linear-gradient(to right, red, #3088ff, green);
      // -webkit-background-clip: text;
      // color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;

      img {
        width: 30px;
        height: 30px;
      }
    }
    .open {
      opacity: 0;
      background: url("../assets/img/prcBg2@2x.a9a5140f.png") no-repeat 50% /
        cover;
      padding: 6px 22px;
      // font-weight: normal;
      img {
        margin-left: 10px;
        width: 20px;
        height: 14px;
      }
    }

    & > img {
      position: absolute;
      top: 50%;
      left: 50%;
      &:nth-child(1) {
        width: 50%;
        transform: translate(-50%, -65%);
        z-index: 9;
      }
      &:nth-child(2) {
        width: 100%;
        z-index: 2;
        transform: translate(-50%, -70%);
      }
      &:nth-child(3) {
        width: 100%;
        transform: translate(-50%, -50%);
        z-index: 1;
        transition: all 800ms;
        opacity: 0;
      }
    }
  }
}
.swiper-box {
  overflow: hidden;
}

.index {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  // background: url('../assets/img/bg.jpg') no-repeat 100% 100%;
  // background-image:url('../assets/img/bg.jpg');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: center;
  // background: linear-gradient(
  //   to bottom,
  //   rgba(48, 136, 255, 0.1),
  //   rgba(48, 136, 255, 0.1)
  // );
  // background: url('../assets/img/bg.jpg') no-repeat 100% 100%
  // background: linear-gradient(
  //   to bottom,
  //   rgba(48, 136, 255, 0.1),
  //   rgba(48, 136, 255, 0.1)
  // );

  /* .top::-webkit-scrollbar {
        display: none;
  }*/
  // ::after {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   content: "";
  //   background-color: yellow;
  //   opacity: 0.2;
  //   z-index: 1;
  //   width: 100%;
  //   height: 100%;
  // }

  .yidongcenter {
    // background: url("../assets/img/newback.png");
    background-size: 400% !important;
    background-position: -410px -100px !important;
  }

  .center {
    // background: url("../assets/img/newback.png");
    background-size: 100%;
    min-height: 100%;
    padding-bottom: 40px;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 36px;
    margin-top: -5px;

    .box-area {
      margin-bottom: 100px;
    }

    .box {
      .title {
        margin: 0 auto;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        font-size: 40px;
        margin-bottom: 52px;
        font-family: "JDZY";
        height: 105px;
        max-width: 1100px;
        color: #fff;
        // padding-top: 36px;
        background: url("../assets/img/5.png") no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;

        span {
          // display: table;
          margin-top: 28px;
          // border-bottom: 3px solid #fff;
        }
      }

      ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 10px;
        li {
          position: relative;
          // background: red;

          .bei1 {
            justify-content: center;
            display: flex;
            align-items: center;
            img {
              width: 100%;
            }
            // &:hover {
            background-image: url("https://181skins.com/assets/cases_bg.74c51cca.png?v=65");
            background-size: cover;
            background-position: center bottom;
            // }
          }

          .bei2 {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            top: 30%;

            img {
              width: 60%;
              height: 60%;
            }
          }

          .name {
            display: flex;
            justify-content: center;

            .name-warp {
              display: flex;
              align-items: center;
              // color: #c3c3e2;
              color: #ffffff;
              font-size: 18px;

              // font-weight: 600;
              img {
                width: 25px;
                height: 25px;
                margin-right: 10px;
              }
            }
          }

          .money {
            margin-top: 15px;
            display: flex;
            justify-content: center;

            .money-warp {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;
              border: 2px solid #3088ff;
              // background-color: #8dc0dd;
              // background:linear-gradient(to right, #FF571B 0%, #CE34C1 100%) border-box;
              overflow: hidden;
              border-radius: 20px;
              padding: 6px 16px;
              font-size: 14px;

              //boder颜色渐变
              // border: 2px transparent solid;
              // background-clip: padding-box, border-box;
              // background-origin: padding-box, border-box;
              // background-image: linear-gradient(135deg,rgba(25,30,46,0.8),rgba(255, 255, 255, 0.5)), linear-gradient(135deg, #ff571b, #3088FF);
              // background-color:transparent;

              img {
                width: auto;
                height: 20px;
                margin-right: 5px;
              }
            }
          }
        }
      }

      //动画
      .money-buy {
        display: none;
      }

      .money-kong {
        display: none;
      }

      .box-hover:hover {
        cursor: pointer;
      }

      .box-hover:hover .money-buy {
        display: block;
        position: absolute;
        right: -1px;
        // background-color: #3088FF;
        background-image: linear-gradient(to right, #ff571b, #3088ff);
        border-radius: 20px;
        color: white;
        padding: 7px 16px;
      }

      .box-hover:hover .money-kong {
        display: block;
        margin-left: 60px;
      }

      .box-hover:hover .bei2 {
        // position: absolute;
        // top: 20%;
        // animation: boxhover 1.5s linear 0s infinite alternate;
        transition: all 400ms;
        transform: scale(1.2);
      }
    }
  }
}
// .index::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-image: var(--background-image);
//   background-repeat: no-repeat !important;
//   background-size: cover !important;
//   background-attachment: fixed !important;
//   background-position: center center !important;
//   z-index: 0;
// }
.center-Content {
  display: flex;
  align-content: center;
  color: hsla(0, 0%, 100%, 0.5);
  margin: 0 -12px;
  justify-content: space-between;

  .first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
  }

  img {
    width: 25px;
  }
}

.active {
  // width: 40%;
  margin: 20px auto;
  position: relative;

  // display: flex;
  // float: left;
  .active-img {
    width: calc(33.33333% - 5px);
    position: relative;
    margin-left: 2.5px;
    margin-right: 2.5px;
    float: left;

    :hover {
      // transform: translate3d(0,-10px,0);
      // transition: transform 1s;
      animation: imghover 1s infinite;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  .img-1 {
    position: absolute;
    cursor: pointer;
  }

  .img-2 {
    visibility: hidden;
  }

  .img-1,
  .img-2 {
    // width: inherit;
    width: 100%;
    height: 90px;
    object-fit: cover;
  }

  @keyframes imghover {
    from {
      top: 0px;
    }

    to {
      top: -15px;
    }
  }
}

#mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-more {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .swiper-more {
    width: 100%;
  }
}
.mask_img {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .active {
    width: 98%;
  }

  .mask_img {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .active-img {
    width: calc(50% - 5px) !important;
  }
}

@media screen and (max-width: 567px) {
  .active-img {
    width: 100% !important;
  }

  .box-area {
    margin-bottom: 50px !important;
  }

  .title {
    font-size: 16px !important;
    height: 40px !important;
    margin: 0px 0 !important;
    margin-top: 60px !important;
    margin-bottom: 20px !important;
    background: url("../assets/img/5.png") no-repeat !important;
    background-position: center !important;
    background-size: 500px auto !important;

    span {
      font-weight: bold;
      margin-top: 10px !important;
    }
  }
}
.notices {
  max-width: 1500px;
  width: 100%;
  height: 35px;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 0 50px;
  box-sizing: border-box;
  margin: 16px auto;
  position: relative;
  img {
    width: 20px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.positionSticky {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  border-radius: 0.33rem;
  margin-bottom: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-x: auto;
  z-index: 3;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: var(--window-headerNav-height);
  padding: 0.5rem 0.5rem 0 0.5rem;
  background: #0e1325;
  margin-top: 40px;
  // transition: top 0.5s ease; /* 过渡效果 */
  .positionItem {
    transition: background 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    position: relative;
    background: url("../assets/img/tittleBorder.fb5c5562.png") no-repeat 50% /
      cover;
    width: 14rem;
    height: 2.9rem;
    flex-shrink: 0;
    cursor: pointer;
    color: #929292;
    margin: 0 0.6rem 1rem;
    font-size: 1.2rem;
  }
  .positionItem.active,
  .positionItem:hover {
    color: #fff;
    background: url("../assets/img/tittleActive.fba375fc.png") no-repeat 50% /
      cover;
  }
}
.positionSticky.fixed {
  position: fixed;
  // top: 100px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0px !important;
}
@media screen and (max-width: 992px) {
  .positionSticky {
    width: 90%;
    margin: 0 auto;
    border-radius: 0.33rem;
    margin-bottom: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    z-index: 3;
    top: var(--window-headerNav-height);
    left: 0;
    position: sticky;
    max-height: 8rem;
    overflow-y: auto;

    .positionItem.active {
      font-size: 1.08rem;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      position: relative;
      // width: 7.2rem;
      // height: 2.74rem;
      margin: 0 0.8rem 0.5rem;
      background: url("../assets/img/tittleBorder.4cc2fe40.png") no-repeat;
      font-size: 12px;
      background-position: center center;
      background-size: 100% 100%;
      width: 89.84px;
      height: 34.19px;
    }
    .positionItem {
      font-size: 1.08rem;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      position: relative;
      // width: 5.2rem;
      // height: 2.2rem;
      width: 89.84px;
      height: 34.19px;
      margin: 0 0.8rem 0.5rem;
      background: url("../assets/img/tittleBorder.4cc2fe40.png") no-repeat;
      font-size: 12px;
      background-position: center center;
      background-size: 100% 100%;
    }
    .positionItem:hover {
      background: url("../assets/img/tittleActive.285483c6.png") no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      width: 89.84px;
      height: 34.19px;
      margin: 0 0.8rem 0.5rem;
    }
  }
  .notices {
    width: 96%;
    height: 30px;
    font-size: 12px;
  }
}

</style>
