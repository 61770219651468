<template>
  <div class="skins routerView">
    <Back></Back>
    <div class="skins-tab">
      <div class="skins-tab-left">
        <div
          class="skins-tab-card"
          :class="{ 'skins-tab-card-selected': skinType == 1 }"
          @click="toggleType(1)"
        >
          我的物品
        </div>
        <div
          class="skins-tab-card"
          :class="{ 'skins-tab-card-selected': skinType == 2 }"
          @click="toggleType(2)"
        >
          正在取回
        </div>
        <div
          class="skins-tab-card"
          :class="{ 'skins-tab-card-selected': skinType == 3 }"
          @click="toggleType(3)"
        >
          取回记录
        </div>
      </div>
    </div>
    <div class="skins-condition">
      <div class="skins-condition-left">
        <div class="skins-condition-card condition-time">
          <div class="skins-condition-card-show" @click="toggleTime">
            <div class="skins-condition-card-text">获得时间</div>
            <div class="skins-condition-card-icon card-icon-time"  :class="{'card-icon-selected':time=='asc'}"></div>
          </div>
        </div>
        <div class="skins-condition-card condition-price">
          <div class="skins-condition-card-show" @click="togglePrice">
            <div class="skins-condition-card-text">价格</div>
            <div class="skins-condition-card-icon card-icon-price" :class="{'card-icon-selected':skins_value=='asc'}"></div>
          </div>
        </div>
      </div>
      <div class="skins-condition-right-m _mobile">
        <div class="skins-condition-right-m-num">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAAAXNSR0IArs4c6QAAAbVJREFUOE+l07FrU1EUx/Hv7xpLU0SppdBFUFDxL1BELRmtNOBL8gSV5im4+AdIFyfBxcFNcdGatgqSmFdEjJsgMerg4qaIIIgWUXBJ0SH3SNRK8qg0vN7x3nM+99zDPQqC8pg5d0TqbI5rC1U2uBSUoicSOTO+O5jz+CYdmnG88CWNrUIYfQYmepPNzBBvhZqGf2deH51lWvX6rffrXdKt8KvE2HqBf8+XgecmWpt8p9Vuf3vVaDR+9uZ2wXsSJwYEk2E/gBWgY8ZsXKvMKZ/PjwwNbd9ljofAzpQw3TY5s4NaBQql8iVEANoBbEsDG1z8B/YCU1Ont2az2m0us1fyx0AzBp+EjYKy/7vMjGtrgsmEIIxem9nsUm3+cRBGS4JpwCXiHrRZOTsYWIoeIZ7F1crlQli+8wfSqb6v5nU+vn/7xoBgeVrSomHLoA8CDxxNVHizXq2cGwjsJoZhuMVreNJ7HUJMCh3uqxB7E1fn9w0MrtHXl4L9q/u/p6tjExsAy1eELvSPrC+mBo8Xo5POcbf/2bqaGiwWZw6Ycy8SrXiaGszlzgyPjlsT2IPZiKQMsuu/AJKanMW8qIlyAAAAAElFTkSuQmCC"
          />
          {{ total }}
        </div>
        <div class="skins-condition-right-m-price">
          <div class="MoneyCoin">
            <img src="../../assets/img/money.png" alt="" />
            <span class="moneyVal">{{ total_value }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="skins-list">
      <div
        class="skins-list-card"
        v-for="(item, index) in skinList"
        :key="index"
        @click="check(item.id)"
        :class="{ 'skins-list-card-selected': item.state }"
      >
        <div
          class="skinItem"
          font=""
          :style="{
              'background-image': `url(${getBgColor(item.color)})`,
            }"
        >
          <div class="MoneyCoin moneyCoin">
            <img src="../../assets/img/money.png" alt="" /><span
              class="moneyVal"
              >{{ item.price }}</span
            >
          </div>
          <!----><!----><img class="skinImage" :src="item.img" alt="" />
          <div class="nameBox">
            <!---->
            <p class="degree">{{ item.name }}</p>
            <p class="skinName ellipsis">
              {{ item.exteriorName }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页位置 开始-->
    <el-pagination
      small
      background
      layout="prev, pager, next"
      :total="total"
      :current-page="page"
      :page-size="pageSize"
      @current-change="currentChange"
    >
    </el-pagination>
    <!-- 分页位置 结束-->
    <div class="skins-condition-border _mobile"></div>
    <div class="skins-condition-action _mobile" v-if="skinType==1">
      <div class="skins-condition-action-select">
        <div class="selectAllBox">
          <div
            class="skins-condition-action-select-frame skins-condition-action-select-all"
            v-if="checkedPrice > 0"
            @click="offAll()"
          ></div>
          <div
            class="skins-condition-action-select-frame"
            @click="selAll()"
            v-else
          ></div>
          全选
        </div>
        <div class="skins-condition-action-price">
          已选{{ calculateSelectedSkinsCount() }} 件, 共
          <div class="MoneyCoin">
            <img src="../../assets/img/money.png" alt="" /><span
              class="moneyVal"
              style="color: rgb(255, 255, 255)"
              >{{ checkedPrice }}</span
            >
          </div>
        </div>
      </div>
      <div class="skins-condition-action-button">
        <div class="skins-condition-action-btn btn-green" @click="getPull()">
          取回
        </div>
        <div class="skins-condition-action-btn btn-yellow" @click="coniAll()">
          分解
        </div>
      </div>
    </div>
    <!-- 分解弹窗 -->
    <div class="Confirm mask" v-show="showConfirm">
      <div class="confirmBody">
        <div class="CloseIcon close" @click="showConfirm = false">
          <img src="../../assets/img/close@2x.a042e540.png" alt="" />
        </div>
        <div class="content">
          <p>确认{{confirmType}}？</p>
        </div>
        <div class="btnBox">
          <div class="cancel btn _btnClick1" @click="showConfirm = false">取消</div>
          <div class="confirm btn _btnClick1" @click="startExchange">确认</div>
        </div>
      </div>
    </div>
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
  </div>
</template>
<script >
import Utils from "../../assets/js/util.js";
import Back from "@/components/back.vue";
import navheader from "@/components/navheader.vue";
export default {
  props: ["yidongmenu", "showNav", "openHongbao"],
  components: {
    Back,
    navheader,
  },
  data() {
    return {
      loading: false,
      total: 0,
      total_value: 0,
      skinList: [],
      pageSize: 15,
      exchangeHint: "",
      checkedPrice: 0,
      random: "",
      exchangeType: "diamond",
      changeDecoration: [],
      page: 1,
      changeDecorations: [],
      skinType: 1, //1我的物品 2正在取回 3取回记录
      // 分解弹窗
      showConfirm: false,
      // 弹窗类型
      confirmType:'分解',
      time:'desc',
      skins_value:'desc',
      red: require("../../assets/img/skinbg1.535a460d.png"),
      grey: require("../../assets/img/skinbg4.c3e49d82.png"),
      orange: require("../../assets/img/skinbg0.cadc763a.png"),
      gold: require("../../assets/img/skinbg0.cadc763a.png"),
      blue: require("../../assets/img/skinbg3.14609471.png"),
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getBgColor(value) {
      if (value == undefined) {
        return this.grey;
      }
      switch (value.toUpperCase()) {
        // 灰色
        case "#B2B2B2":
          // return `background: url("../assets/img/skinbg4.c3e49d82.png") no-repeat;`;
          return this.grey;
        // 红色
        case "#EB4B4B":
          // return ` background: url("../assets/img/skinbg1.535a460d.png") no-repeat;`;
          return this.red;
        // 橙色
        case "#CF6A32":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.orange;
        // 金色
        case "#E4AE39":
        case "#FFD700":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.gold;
        // 蓝色
        case "#4B69FF":
          // return `background: url("../assets/img/skinbg3.14609471.png") no-repeat;`;
          return this.blue;
      }
    },
    //兑换多个
    coniAll() {
      if (!Number.parseFloat(this.checkedPrice)) {
        this.$message.error("请选中饰品");
        return;
      }
      var arr = [];
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state) {
          arr.push(this.skinList[i].id);
        }
      }
      this.changeDecoration = arr;
       this.showConfirm= true;
      this.confirmType="分解"
    },
    getPull() {
      if (!Number.parseFloat(this.checkedPrice)) {
        this.$message.error("请选中饰品");
        return;
      }
      var arr = [];
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state) {
          arr.push(this.skinList[i].id);
        }
      }
      this.changeDecoration = arr;
       this.showConfirm= true;
      this.confirmType="取回"
    },
    // 提取多个
    conisAll() {
      if (!Number.parseFloat(this.checkedPrice)) {
        this.$message.error("请选中饰品");
        return;
      }
      var arr = [];
      var arrs = [];
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state) {
          arr.push(this.skinList[i].id);
          arrs.push(this.skinList[i].itemId);
        }
      }
      this.changeDecoration = arr;
      this.changeDecorations = arrs;
     
    },
    openPull(){
      var arr = [];
      var steamId = []
      var randomString = []
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state) {
          arr.push(this.skinList[i].id);
          steamId.push(this.skinList[i].itemId);
          randomString.push(this.randomString());
        }
      }
      this.steamIds = steamId;
      this.randoms = randomString;
      this.changeDecorations = arr;
      console.log(this.steamIds,this.randoms,this.changeDecorations,'ceshi1' )
    },
    //单个选中
    check(id) {
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].id == id) {
          this.skinList[i].state = !this.skinList[i].state;
          if (this.skinList[i].state == true && !this.skinList[i].state1) {
            this.checkedPrice = (
              parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
            ).toFixed(2);
          } else {
            this.checkedPrice = (
              parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
            ).toFixed(2);
          }
        }
      }
      this.$forceUpdate();
    },
    // 统计选中数量
    calculateSelectedSkinsCount() {
      let count = 0;
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state === true) {
          count++;
        }
      }
      return count;
    },
    //选择当前页
    selAll() {
      this.checkedPrice = 0;
      for (let i = 0; i < this.skinList.length; i++) {
        if (!this.skinList[i].state1) {
          this.skinList[i].state = true;
        }
        this.checkedPrice = (
          parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
        ).toFixed(2);
      }
      this.$forceUpdate();
    },
    //取消当前页
    offAll() {
      this.checkedPrice = 0;
      for (let i = 0; i < this.skinList.length; i++) {
        this.skinList[i].state = false;
        // this.checkedPrice = (
        //   parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
        // ).toFixed(2);
      }
      this.$forceUpdate();
      this.tiqu = false;
    },
    /**
     * 兑换成 钻石
     * @param {*} id
     */
    exchangeToMoney(id) {
      let param = {
        player_skins_ids: id,
      };
      this.$axios
        .post("/index/User/exchangeToMoney", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.changeDecoration = [];
            this.showConfirm= false;
          } else {
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: data.msg,
              type: "warning",
            });
          }
        });
    },
    /**
     * 兑换成 金币
     * @param {*} id
     */
    exchangeToMoney2(id) {
      let param = {
        player_skins_ids: id,
      };
      this.$axios
        .post("/index/User/exchangeToMoney2", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$store.commit("getMoney", res.data.data.total_amount);
            Utils.$emit("money", res.data.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.changeDecoration = [];
          } else {
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: data.msg,
              type: "warning",
            });
          }
        });
    },

    //点击取回
    // getPull() {
      
    //   return
    //   for (let i = 0; i < this.skinList.length; i++) {
    //     if (id == this.skinList[i].id) {
    //       this.skinList[i].loading1 = true;
    //       this.skinList[i].loading2 = true;
    //       this.skinList[i].state1 = true;
    //     }
    //   }
    //   this.$forceUpdate();
    //   let param = {
    //     player_skins_id: id,
    //     itemId: steamId,
    //     random: this.random,
    //   };
    //   this.$axios
    //     .post("/index/User/skinToSteam1", this.$qs.stringify(param))
    //     .then((res) => {
    //       // console.log(res.data);
    //       var data = res.data;
    //       if (data.status == "1") {
    //         this.getList(this.page);
    //         this.$message({
    //           showClose: true,
    //           message: data.msg,
    //           type: "success",
    //         });
    //       } else {
    //         var hint = "";
    //         if (data.msg.indexOf("余额不足") != -1) {
    //           hint = "取回错误，请联系客服";
    //         } else if (data.msg.indexOf("取回失败,请充值") != -1) {
    //           hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
    //         } else {
    //           hint = data.msg;
    //         }
    //         this.$message({
    //           showClose: true,
    //           message: hint,
    //           type: "warning",
    //         });
    //         for (let i = 0; i < this.skinList.length; i++) {
    //           if (id == this.skinList[i].id) {
    //             this.skinList[i].loading1 = false;
    //             this.skinList[i].loading2 = false;
    //             this.skinList[i].state1 = false;
    //           }
    //         }
    //         this.$forceUpdate();
    //       }
    //       this.randomString();
    //     });
    // },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      this.random = n;
    },
    //点击兑换
    getExchange(event, id) {
      event.stopPropagation();
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (id == this.skinList[i].id) {
          this.skinList[i].loading1 = true;
          this.skinList[i].loading2 = true;
          this.checkedPrice = this.skinList[i].price;
        }
      }
      this.$forceUpdate();
      this.changeDecoration = [id];
    },
    startExchange() {
      if(this.confirmType=='分解'){
        // 兑换金币
        if (this.exchangeType == "dollar") {
          this.exchangeToMoney2(this.changeDecoration);
        } else {
          // 兑换钻石
          this.exchangeToMoney(this.changeDecoration);
        }
      }else if(this.confirmType=='取回'){
        this.getPulls()
      }
      this.checkedPrice = 0;
    },
    //点击取回
    getPulls() {
      this.openPull()
      let param = {
        player_skins_id: this.changeDecorations,
        itemId: this.steamIds,
        random: this.randoms
      };
      this.$axios
        .post("/index/User/skinToSteam1", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.showConfirm = false
            this.changeDecorations = []
            this.getList(this.page);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          } else {
            var hint = "";
            if (data.msg.indexOf("余额不足") != -1) {
              hint = "取回错误，请联系客服";
            } else if (data.msg.indexOf("取回失败,请充值") != -1) {
              hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
            } else {
              hint = data.msg;
            }
            this.$message({
              showClose: true,
              message: hint,
              type: "warning",
            });
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
                this.skinList[i].state1 = false;
              }
            }
            this.$forceUpdate();
          }
          this.randomString();
        });
    },
    startExchanges() {
      // 兑换
      this.getPulls(this.changeDecoration, this.changeDecorations);
      this.checkedPrice = 0;
    },
    NoExchange() {
      this.changeDecoration.forEach((e) => {
        for (let i = 0; i < this.skinList.length; i++) {
          if (e == this.skinList[i].id) {
            this.skinList[i].loading1 = false;
            this.skinList[i].loading2 = false;
          }
        }
      });
      this.$forceUpdate();
      this.changeDecoration = [];
      this.changeDecorations = [];
    },
    currentChange(val) {
      this.page = val;
      this.getList();
      this.checkedPrice = 0;
    },
    getList() {
      // this.page = page;
      let param = {
        pageSize: this.pageSize,
        page: this.page,
        time:this.time,
        skins_value:this.skins_value
      };
      var url = "/index/User/packageList";
      if (this.skinType == 2) {
        url = "/index/User/backList";
      }
      if (this.skinType == 3) {
        url = "/index/User/processingList";
      }
      this.$axios.post(url, this.$qs.stringify(param)).then((res) => {
        var data = res.data;
        if (data.status == "1") {
          this.total = data.data.total;
          this.total_value = data.data.total_value;
          this.skinList = data.data.skinList;
        } else {
          this.skinList = [];
          this.total = 0;
          this.total_value = 0;
        }
      });
    },
    toggleType(number) {
      console.log(number);
      this.skinType = number;
      // 重置当前页码
      this.page = 1;
      // 重新请求接口
      this.getList();
      this.offAll()
    },
    toggleTime(){
      this.time = this.time == 'asc' ? 'desc' : 'asc';
      this.page = 1;
      this.getList();
    },
    togglePrice(){
      this.skins_value = this.skins_value == 'asc' ? 'desc' : 'asc';
      this.page = 1;
      this.getList();
    }
  },
};
</script>
<style scoped lang="less">
.skins {
  padding: 0.5rem * 0.8 1rem * 0.8;
  max-width: 61.23rem * 0.8;
  border-radius: 0.25rem * 0.8;
  font-size: 0.58rem * 0.8;
  font-weight: 400;
  color: #fff;
  background: #0e1325;
  height: 100%;
}

.skins .BackTop {
  background: none;
}

.skins .BackTop .left {
  padding-left: 0;
}

.skins .skins-tab {
  display: flex;
  justify-content: space-between;
  margin-top: 0.6rem * 0.8;
  margin-left: 0.2rem * 0.8;
}

.skins .skins-tab .skins-tab-left {
  display: flex;
}

.skins .skins-tab .skins-tab-left .skins-tab-card {
  padding: 0 0.5rem * 0.8;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 1.08rem * 0.8;
  font-weight: 400;
  min-width: 5rem * 0.8;
  height: 1.8rem * 0.8;
  flex-wrap: wrap;
  min-width: 3rem * 0.8;
}

.skins .skins-tab .skins-tab-left .skins-tab-card:after {
  bottom: -0.3rem * 0.8;
  width: 80%;
}

.skins .skins-tab .skins-tab-left .skins-tab-card-selected {
  font-weight: 700;
}

.skins .skins-tab-underline {
  width: 100%;
  border-bottom: 0.1rem * 0.8 solid #343a45;
  transform: translateY(-0.25rem * 0.8);
  z-index: 0;
}

.skins .skins-condition {
  margin-left: 0.2rem * 0.8;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem * 0.8;
}

.skins .skins-condition .skins-condition-left {
  display: flex;
}

.skins .skins-condition .skins-condition-left .skins-condition-card {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-right: 0.17rem * 0.8;
  width: 5rem * 0.8;
  height: 2.5rem * 0.8;
  border-radius: 0.25rem * 0.8;
  font-size: 1rem * 0.8;
  font-weight: 400;
  color: #fff;
  background: hsla(0, 0%, 100%, 0.05);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show {
  height: 2.5rem * 0.8 * 0.8;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  font-size: 0.864rem * 0.8;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .skins-condition-card-icon {
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 0.22rem * 0.8;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .card-icon-selected {
  transform: rotate(180deg);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .card-icon-price,
.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .card-icon-time {
  width: 1.06rem * 0.8;
  height: 1.23rem * 0.8;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAAAAXNSR0IArs4c6QAAAYNJREFUSEvtlrsuBVEYhb8VnkCikGhUEoVKR0GCxKXQSfACIqFUEIlQqEThCQiFSqPTuCQavINEQikuhUSy5MjMydjOzOxpThRnuj2z9v/NWv/M3ls06VKTOPw/kO02YAkYAA4knVdJI9qR7RVgLyn+CfRKeoiFVQEdAXOZwrOSTpoBmpd03AKVJmA77FErutLUfgSt6MKc6iuD7Q5gELiV9BQKY6Oz3Q90AheSvtI6PyDb3cB9IngFpiRdZ2ExINubwEYy70zSdAiqrcr7mcJvwGQWVgayvQWsB0l0SXqu3UsdDQGX6TgR/4IVgXIgj0BPGl+2R6vATvBG78BEzVkeyPY2sBbMewFGJd39ii4d2M6FAYvBNjEP9MVA6tEFTc+D3QBjGe0pMFPmpKGjEme1T7W9YB35E1dWm7vD5sSYxymENIwuIsYQVgopBSU/c6OepbAoSBSoAPYBDEu6Lehb/VGVU9AhsJDMNDBe5WwXDUqcLQMjwK6kqxgnhZ93lQKx2m/Yd8of8iKbbwAAAABJRU5ErkJggg==);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .card-icon-rotate {
  width: 0.46rem * 0.8;
  height: 0.29rem * 0.8;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAAAXNSR0IArs4c6QAAAh5JREFUOE+1lE1oE1EUhc+dSdMa0Ej8K0HUlS3uFHSnICgKFUGELMTubJIaDZSC2trM3CQ1VpEKrTZNS81ai4K6iEW3guBKxO7EhYtIocVFCWZM58oEEzLTtB2RvuV7537nvfvzCJu8aJP5qBswTx0se43Fu4PXFv/HlHl6L2BUmGM/LE7VQE9NZgFEAfwSkXhK753+ZxMR0tO5BwT0CSBCFE8lIo9pIPNkl7diLNSAZB2CBpNaZMStCTN7RGnPA7jcwCmyFg3S9bGx1sBPr2WwzQ6k+8lE+BaIZD0j5nybKMZTQM7bdfIxqfUeq6aI05NnRfAcgM8Bm5rvDFydDYVWmpncuDez1Vf+/VKAk43nBBRh4hRzdL5e5ERq4rgC5TUAvwP2jMxAN3PIaNwfyDza4a2oBYCOOvRfV0g9PZzo+VYvck2QSGUPq6CCAHscQW/IpIvMkVL1xTweFKXlLYBDjpt/NlQ6k7kdKTbUwo4aSk90eESdE8h+R/B7mK3ngNJOUVQLfsAWKfhA4ulivrLkiFudXV3P7YMqcwA6HeJPf1/X7ijoOzLNC8yxZSdtzUlmzu4WlQoQHNmgXV8sbTcujcfj5Wa6db+KmyM5f1tZXoFwomkwIf+lI9CzVpetKnIzSN/o6Bb/sm9WgC5buoQeshbu32hOXH124VyuJbiAGRHpBmASoLMWHXYz6a4MaiCrw4SkdGco9t0N3NL8AWK5uhGadozgAAAAAElFTkSuQmCC);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide {
  display: none;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide
  .skins-condition-card-hide-select {
  width: 5.12rem * 0.8;
  height: 1.87rem * 0.8;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide
  .hide-select-first {
  border-radius: 0.25rem * 0.8 0.25rem * 0.8 0 0;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide
  .hide-select-end {
  border-radius: 0 0 0.25rem * 0.8 0.25rem * 0.8;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card:hover
  .card-icon-rotate {
  transform: rotate(180deg);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card:hover
  .skins-condition-card-hide {
  border-radius: 0.5rem * 0.8;
  width: 7.1rem * 0.8;
  background-color: #3b3d47;
  display: block;
  position: relative;
  z-index: 9;
}

.skins .skins-condition .skins-condition-left .condition-time {
  width: 7.1rem * 0.8;
}

.skins .skins-condition .skins-condition-right-m {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 11rem * 0.8;
  margin-right: 0.5rem * 0.8;
}

.skins .skins-condition .skins-condition-right-m .skins-condition-right-m-num {
  font-size: 1rem * 0.8;
  color: #a9abbc;
}

.skins
  .skins-condition
  .skins-condition-right-m
  .skins-condition-right-m-num
  > img {
  width: 1rem * 0.8;
  height: 0.84rem * 0.8;
}

.skins
  .skins-condition
  .skins-condition-right-m
  .skins-condition-right-m-price {
  font-size: 1rem * 0.8;
}

.skins .skins-list {
  margin-top: 0.4rem * 0.8;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  min-height: 25rem * 0.8;
  overflow-y: auto;
}

.skins .skins-list .skins-list-card {
  width: 49%;
  height: 13.5rem * 0.8;
  font-size: 2.05rem * 0.8;
  margin: 0.5rem * 0.8 0;
  box-sizing: border-box;
  border: 0.1rem * 0.8 solid hsla(0, 0%, 100%, 0);
  border-radius: 10%;
}

.skins .skins-list .skins-list-card .nameBox .degree,
.skins .skins-list .skins-list-card .nameBox .skinName {
  font-size: 1rem * 0.8;
}

.skins .skins-condition-border {
  height: 6rem;
}

.skins .skins-condition-action {
  position: fixed;
  // bottom: calc(var(--mobil-tabbar-height) + 0.5rem * 0.8);
  bottom: 85px;
  left: 0.4rem;
  width: 29.4rem *0.88;
  height: 7.08rem * 0.8;
  background: rgba(29, 34, 46, 0.95);
  border-radius: 0.83rem * 0.8 0.83rem * 0.8 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.skins .skins-condition-action .skins-condition-action-select {
  font-size: 1rem * 0.8;
  width: 100%;
  margin-left: 0.92rem * 0.8;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}

.skins .skins-condition-action .skins-condition-action-select .selectAllBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skins
  .skins-condition-action
  .skins-condition-action-select
  .skins-condition-action-select-frame {
  font-size: 1rem * 0.8;
  width: 1.17rem * 0.8;
  height: 1.17rem * 0.8;
  border: 0.09rem * 0.8 solid #82858d;
  border-radius: 20%;
  margin-right: 0.2rem * 0.8;
}

.skins
  .skins-condition-action
  .skins-condition-action-select
  .skins-condition-action-select-all {
  font-size: 1rem * 0.8;
  border: 0;
  width: 1.4rem * 0.8;
  height: 1.4rem * 0.8;
  background-image: url(/img/xuanzhong.7af5e1de.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}

.skins .skins-condition-action .skins-condition-action-price {
  display: flex;
  font-size: 1rem * 0.8;
  margin: 0.2rem * 0.8 0 0.2rem * 0.8 0.92rem * 0.8;
}

.skins .skins-condition-action .skins-condition-action-price .MoneyCoin {
  margin-left: 0.2rem * 0.8;
}

.skins .skins-condition-action .skins-condition-action-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.skins
  .skins-condition-action
  .skins-condition-action-button
  .skins-condition-action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  min-width: 5.77rem * 0.8;
  height: 2.76rem * 0.8;
  border-radius: 1.38rem * 0.8;
  margin-left: 0.29rem * 0.8;
  margin-right: 1rem * 0.8;
  font-size: 1.08rem * 0.8;
  font-weight: 700;
  box-sizing: border-box;
  padding: 0.2rem * 0.8 0.5rem * 0.8;
}

.skins .skins-condition-action .skins-condition-action-button .btn-green {
  background: rgba(105, 23, 255, 0.1);
  border: 2px solid #6917ff;
}

.skins .skins-condition-action .skins-condition-action-button .btn-yellow {
  background: rgba(160, 38, 155, 0.1);
  border: 2px solid #ff31ed;
}
.skins .skins-tab .skins-tab-left .skins-tab-card-selected {
  color: #ff31ed;
  font-weight: 700;
  position: relative;
}
.skins .skins-tab .skins-tab-left .skins-tab-card-selected:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 100%;
  background-color: #ff31ed;
  z-index: 10;
}
.MoneyCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.skinItem {
  width: 100%;
  height: 6.53em;
  overflow: hidden;
  border-radius: 0.42em;
  background: hsla(0, 0%, 100%, 0.05) no-repeat;
  position: relative;
  box-sizing: border-box;
  text-transform: none;
  background-size: cover;
}
.skinItem .moneyCoin {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  color: #fff;
  font-size: 0.5em;
  lign-items: center;
  justify-content: center;
  display: inline-flex;
}
.skinItem .skinImage {
  width: 4.58em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.skinItem .nameBox {
  position: absolute;
  bottom: 0.3em;
  left: 0.4em;
  width: 90%;
}
.skins .skins-list .skins-list-card-selected .skinItem {
  border: 2px solid #ff31ed;
  border-radius: 10%;
}
.skins
  .skins-condition-action
  .skins-condition-action-select
  .skins-condition-action-select-all {
  font-size: 1rem;
  border: 0;
  width: 1.4rem * 0.8;
  height: 1.4rem * 0.8;
  background-image: url("../../assets/img/mes/xuanzhong.7af5e1de.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}
.confirmBody {
  width: 26.46rem*0.8;
  background: #191b29 url('../../assets/img/luckRoom/loginbg.04aa9090.png') no-repeat 50% / cover;
  border-radius: 0.42rem*0.8;
  font-size: 1.25rem*0.8;
  padding: 3rem*0.8 0.5rem*0.8 5rem*0.8;
  display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.confirmBody .content {
  margin-bottom: 2rem*0.8;
}

.confirmBody .content .moneyBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmBody .content .moneyBox span {
  margin-right: 0.33rem*0.8;
}

.confirmBody .content p {
  margin-bottom: 1rem*0.8;
}

.confirmBody .btnBox {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 1rem*0.8;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.17rem*0.8;
  font-weight: 700;
}

.confirmBody .btnBox .btn {
  width: 8.1rem*0.8;
  height: 3.32rem*0.8;
  border-radius: 1.66rem*0.8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmBody .btnBox .cancel {
  background: hsla(0, 0%, 100%, 0.1);
  font-weight: 700;
  color: #a9abbc;
  margin-right: 0.42rem*0.8;
}
.close {
  width: 1.5rem*0.8;
  height: 1.19rem*0.8;
  position: absolute;
  top: 10px;
  right: 10px;
  img{
    width: 100%;
    height: 100%;
  }
}
.mask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .6);
}
.confirmBody .btnBox .confirm{
    background: #6917ff;
    font-weight: 700;
    color: #fff;
}
.skins .skins-condition .skins-condition-left .skins-condition-card .skins-condition-card-show .card-icon-selected{
        transform: rotate(180deg);
    }
</style>