export default {
  data() {
    return {
      // 支付方式
      payMethod: "alipay1",
      // 订单号
      orderNum: "",
      timer: null,
    };
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    /**
     * 支付 -- 创建订单
     * @param {*支付方式} mode  mode = alipay:支付宝  wxpay:微信支付
     */
    createOrder(params) {
      const pay_1 = ["alipay", "wxpay"].includes(this.payMethod);
      const pay_2 = ["alipay1", "wxpay1"].includes(this.payMethod);
      // if (pay_1) {
      //   this.$axios
      //     .post("/index/pay/xinghuopay", {
      //       ...params,
      //       mode: this.payMethod,
      //     })
      //     .then(({ data }) => {
      //       setTimeout(() => {
      //         window.location.href = data.data;
      //       });
      //       // // 刷新订单
      //       // this.refreshOrder();
      //       // this.timer = setInterval(() => {
      //       //   this.refreshOrder();
      //       // }, 1500);
      //       // // 9分钟清空订单
      //       // setTimeout(() => {
      //       //   clearInterval(this.timer);
      //       // }, 60 * 9 * 1000);
      //     });
      // } else if (pay_2) {
      //   this.$axios
      //     .post("/index/pay/sgapi", {
      //       ...params,
      //       mode: this.payMethod,
      //     })
      //     .then(({ data }) => {
      //       if (data.status == 0) {
      //         this.$message({
      //           message: data.msg,
      //           type: "warning",
      //         });
      //       } else if (params.callback) {
      //         params.callback(data.data);
      //       }
      //     });
      // } else {
        this.$axios
          .post("/index/pay/recharge", {
            ...params,
            mode: this.payMethod,
          })
          .then(({ data }) => {
            if (data.status == 0) {
              this.$message({
                message: data.msg,
                type: "warning",
              });
            } else if (params.callback) {
              params.callback(data.data);
            }
          });
      // }
    },
    /**
     * 查询订单状态
     * @returns
     */
    refreshOrder() {
      if (!this.orderNum) return;
      this.$axios
        .get("/index/pay/get_order?out_trade_no=" + this.orderNum)
        .then(({ data }) => {
          console.log(data.data, "支付结果");
          //1: 未支付，2: 待支付，3: 已支付，4:支付失败
          if (data.data.status == 3) {
            this.$YsMessage.success("支付成功");
            clearInterval(this.timer);
          }
          if (data.data.status == 4) {
            this.$YsMessage.error("支付失败");
            clearInterval(this.timer);
          }
        });
    },
  },
};
