<template>
  <div class="homebot" v-if="false">
    <div class="top" v-if="showNav">
      <div class="top-right">
        <img src="../assets/img/weixin_back.png" />
        <img src="../assets/img/zhifubao_back.png" />
      </div>
      <div class="top-left">
        <ul>
          <li v-for="(item, index) in routerList" :key="index">
            <span @click="fun(item.url)">{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="top-bottom">
        <div
          @click="beian()"
          style="display: flex; align-items: center; justify-content: center"
        >
          <!-- 商案号: 桂ICP备2023009974号-1 -->
        </div>
        <div
          @click="beian()"
          style="display: flex; align-items: center; justify-content: center"
        >
          <img
            src="../assets/img/45bf44b1e8983afd2a23ae02b392f4d.jpg"
            alt=""
            srcset=""
            style="width: 20px; height: 20px; margin-right: 3px"
          />
          商案号: 鲁ICP备2023041410号-1
        </div>
        <div>版权所属：红蝌蚪（山东）网络科技有限公司(100034088528)</div>
      </div>
    </div>
    <div class="top" v-else>
      <div class="top-right">
        <img src="../assets/img/weixin_back.png" />
        <img src="../assets/img/zhifubao_back.png" />
      </div>
      <div class="top-left">
        <ul>
          <li v-for="(item, index) in routerList" :key="index">
            <span @click="fun(item.url)">{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="top-bottom">
        <div @click="beian()">商案号: 鲁ICP备2023041410号-1</div>
        <div>版权所属：红蝌蚪（山东）网络科技有限公司(100034088528)</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: document.body.clientWidth,
      showNav: false,
      routerList: [
        {
          name: "关于我们",
          url: "Abouts",
        },
        {
          name: "用户协议",
          url: "Agreement",
        },
        {
          name: "隐私条款",
          url: "Privacy",
        },
        {
          name: "协议与条款",
          url: "Clause",
        },
        {
          name: "常见问题",
          url: "Doubt",
        },
        // {
        // 	name: "营业执照",
        // 	url: "Doubt"
        // },
      ],
      urlList: [],
    };
  },
  mounted() {
    if (this.windowWidth < 1024) {
      this.showNav = false;
      return;
    }
    this.showNav = true;
  },
  methods: {
    fun(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
    goCase() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    beian() {
      window.open("https://beian.miit.gov.cn/#/home", "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.homebot {
  background-color: rgba(0, 122, 255, 0.1);
  border-top: 5px solid rgba(0, 122, 255, 1);
  padding: 24px 24px 24px 24px;

  .top {
    font-size: 14px;
    // color: #848492;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;

    .top-left {
      margin-top: 10px;

      ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;

        li {
          margin-top: 10px;
          padding-right: 10px;
          padding-left: 10px;
          border-right: 1px solid white;

          span {
            white-space: nowrap;
            display: inline-block;
          }
        }

        li:last-child {
          padding-right: 0;
          border-right: none;

          span {
            white-space: nowrap;
            display: inline-block;
          }
        }

        li:hover {
          cursor: pointer;
        }
      }
    }

    .top-right {
      // margin-top: 10px;
      margin-left: 0% !important;

      img {
        margin-right: 10px !important;
        margin-left: 12px;
      }
    }
  }

  .bot {
    margin-top: 24px;
    display: flex;
    font-size: 14px;
    color: #515260;
    overflow-y: hidden;

    .bot-title {
      margin-right: 12px;
    }

    ul {
      display: flex;

      li {
        margin-right: 24px;
      }

      li:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #3088ff;
      }
    }
  }
}

.top-bottom {
  margin-top: 8px;
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 10px;
  div {
    margin-top: 6px;
  }
}

.beian {
  display: flex;
  align-content: center;
  justify-content: center;

  img {
    margin-right: 4px;
  }
}

@media screen and (max-width: 767px) {
  .homebot {
    background-color: rgba(48, 49, 63, 0.6);
    padding: 0 24px 24px 24px;

    .top {
      font-size: 5px;
      // color: #848492;
      color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center !important;

      .top-left {
        margin-top: 10px;

        ul {
          display: flex;
          flex-wrap: wrap;
          margin-left: 10px;
          li {
            margin-top: 10px;
            padding-left: 0;
            padding-right: 8px;
            border-right: 1px solid white;
            font-size: 11px !important;
            -webkit-text-size-adjust: none;

            span {
              white-space: nowrap;
              display: inline-block;
              -webkit-text-size-adjust: none;
              font-size: 11px !important;
            }
          }

          li:last-child {
            padding-right: 0;
            border-right: none;

            span {
              white-space: nowrap;
              display: inline-block;
            }
          }

          li:hover {
            cursor: pointer;
          }
        }
      }

      .top-right {
        // margin-top: 10px;
        margin-left: 0% !important;

        img {
          margin-right: 10px !important;
        }
      }
    }

    .bot {
      margin-top: 24px;
      display: flex;
      font-size: 14px;
      color: #515260;
      overflow-y: hidden;

      .bot-title {
        margin-right: 12px;
      }

      ul {
        display: flex;

        li {
          margin-right: 24px;
        }

        li:hover {
          cursor: pointer;
          text-decoration: underline;
          color: #3088ff;
        }
      }
    }
  }
}
</style>
