import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI, {
	Message
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/text/index.css'

const bus = new Vue();
Vue.prototype.$bus = bus

import Axios from 'axios'
Vue.config.productionTip = false
// step2：把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求,
// 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = Axios

// step3：使每次请求都会带一个 /api 前缀  // https://YYCSGO.com/   
// Axios.defaults.baseURL = 'https://YYCSGO.com/'    // '/api'   https://ahyltt.com.com/
Axios.defaults.baseURL = '/'
Axios.defaults.timeout = 60000;
Axios.defaults.retry = 3;


Axios.interceptors.request.use((config) => {
	// const token = store.state.token
	const token = localStorage.getItem('token');
	if (token) {
		config.headers['token'] = token
	}
	return config
}, config => {
	return Promise.reject(config)
})

Axios.interceptors.response.use((response) => {
	const code = response.data.code || response.data.status
	console.log(code,'状态码')
	if (![1,0].includes(code)) {
		if (code == 401 || response.data.msg=="玩家信息不存在"|| response.data.msg == "用户信息有误" || response.data.msg=="用户不存在" || response.data.msg=="token不存在") {
			if(localStorage.getItem("userInfo")){
				localStorage.setItem("userInfo", null);
			}
			store.commit("getLogin", true);
			store.commit("getLogins", false);
			store.commit("loginOut");
			// Message.error('请先登录')
		}
		return Promise.reject(response)
	}
	return response
}, (error) => {
	console.log("error", error)
	if (error.response) {
		// switch (error.response.status) {
		// 	// case 500:
		// 	// 	Message.error('服务器内部错误，请稍后再试或联系管理员');
		// 	// 	break;
		// 	default:
		// 		Message.error('请求失败，请检查网络连接');
		// }
	} else if (error.request) {
		Message.error('请求超时，请检查网络连接');
	} else {
		Message.error('未知错误：' + error.message);
	}
	return Promise.reject(error);
})

import qs from 'qs';
// Vue.prototype.$ajax = axios // 把axios换成$ajax变量
Vue.prototype.$qs = qs;

//修改网页标题
Vue.directive('title', {
	inserted: function(el, binding) {
		document.title = el.dataset.title
	}
})


Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
	router,
	store,
	render: function(h) {
		return h(App)
	}
}).$mount('#app')