<template>
  <div id="app" v-title data-title="csunbox.cn-最好的饰品体验！" v-cloak>
    <router-view />
    <!-- <div v-else class="not-support">
      <span>Sorry That ！</span>
      <span>Currently not supported for opening on the computer side</span>
      <span>暂不支持电脑端打开</span>
    </div> -->
  </div>
</template>

<script>
import Utils from "./assets/js/util.js";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
      userInfo: {},
    };
  },
  data() {
    return {
      isRouterAlive: true,
      show: false,
    };
  },
  created() {
    let baidu_url_bd_vid = window.location.href;
    let index = baidu_url_bd_vid.indexOf("bd_vid");
    if (index > -1) {
      // 用正则筛选bd_vid=后面的数据
      let reg = /bd_vid=(\S*)/;
      let bd_vid = baidu_url_bd_vid.match(reg)[1];
      let index2 = bd_vid.indexOf("/");
      if (index2 > -1) {
        bd_vid = bd_vid.substring(0, index2);
      }
      window.localStorage.setItem("bd_vid", bd_vid);
    }
    var lastTouchEnd = 0;
    document.addEventListener(
      "touchend",
      function (event) {
        var now = new Date().getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );
    this.$store.commit("setMobile", this.isMobile());
    window.addEventListener("resize", () => {
      this.$store.commit("setMobile", this.isMobile());
    });
    var u = navigator.userAgent;
    var flag;
    var myFunction;
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isIOS) {
      document.body.addEventListener("focusin", () => {
        //软键盘弹起事件
        flag = true;
        clearTimeout(myFunction);
      });
      document.body.addEventListener("focusout", () => {
        //软键盘关闭事件
        flag = false;
        if (!flag) {
          myFunction = setTimeout(function () {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            }); //重点  =======当键盘收起的时候让页面回到原始位置(这里的top可以根据你们个人的需求改变，并不一定要回到页面顶部)
          }, 1);
        } else {
          return;
        }
      });
    } else {
      return;
    }
    window.addEventListener("resize", () => {
      this.checkPcOrMobile();
    });
  },
  mounted() {
    let _this = this;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    _this.userInfo = userInfo;
    _this.getUserInfo();
    setInterval(() => {
      _this.getUserInfo();
    }, 5000);
    Utils.$on("pid", function (pid) {
      _this.userInfo.id = pid;
      _this.getUserInfo();
    });
    setTimeout(() => {
      this.checkPcOrMobile();
    }, 100);
  },
  methods: {
    isMobile() {
      let flag =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      return flag;
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    getUserInfo() {
      if (this.$store.state.token) {
        this.$axios.post("/index/User/getPlayerInfo", {}).then((res) => {
          if (res.data.data.status == 1) {
            Utils.$emit("money", res.data.data.total_amount);
            Utils.$emit("state", res.data.data.state);
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            this.$store.commit("setUser", res.data.data);
          }
        });
      }
    },
    checkPcOrMobile() {
      if (!this.isMobile()) {
        this.show = false;
      } else {
        this.show = true;
      }
      this.$store.commit("setMobile", this.isMobile());
    },
  },
};
</script>

<style lang="less">
body {
}
.el-message {
  z-index: 9999 !important;
  width: max-content !important;
  min-width: 20px !important;
}
::v-deep {
  .el-message {
    z-index: 9999999999999999 !important;
  }
}

.child-box {
  margin: auto;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999999;

  .child-boxs {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 325px;
    margin: auto;
    height: 405px;
    background: url("./assets/img/tanbox.png");
    background-size: 100% 100%;

    .modal-top {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      top: 17px;
      width: 100%;

      div:first-child {
        width: 40px;
      }

      .title {
        font-weight: 600;
        font-size: 30px;
        background: linear-gradient(
          rgba(193, 232, 251, 1) 40%,
          rgba(163, 198, 253, 1)
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
      }

      img {
        width: 32px;
      }
    }

    .child-box_content {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      top: 89px;
      left: 0;
    }
  }
}

.main-color {
  color: #ff31ed !important;
}
.main-bg-color {
  background-color: #6917ff !important;
}
// div:active {
//   background-color: transparent;
//   /* 设置点击时的背景色为透明 */
//   outline: none;
//   /* 取消点击时的默认外边框样式 */
// }

.notices {
  display: flex;
  align-items: center;
  .lt {
    width: 50px;
    height: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(204, 102, 153, 1);
  }
  .rt {
    flex: 1;
  }
}

// /* 滚动条 */
// ::-webkit-scrollbar-thumb:horizontal {
// 	/*水平滚动条的样式*/
// 	width: 4px;
// 	background-color: #cccccc;
// }

// ::-webkit-scrollbar-track-piece {
// 	background-color: #fff;
// 	/*滚动条的背景颜色*/
// 	-webkit-border-radius: 0;
// 	/*滚动条的圆角宽度*/
// }

// ::-webkit-scrollbar {
// 	width: 10px;
// 	/*滚动条的宽度*/
// 	height: 8px;
// 	/*滚动条的高度*/
// }

// ::-webkit-scrollbar-thumb:vertical {
// 	/*垂直滚动条的样式*/
// 	height: 50px;
// 	background-color: #3088FF;
// 	outline: 2px solid #fff;
// 	outline-offset: -2px;
// 	border: 2px solid #fff;
// }

// ::-webkit-scrollbar-thumb:hover {
// 	/*滚动条的hover样式*/
// 	height: 50px;
// 	background-color: #3088FF;
// }

@import "./assets/css/media.less";

//改
div,
blockquote,
body,
html,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

html,
body {
  // overflow: scroll; -webkit-overflow-scrolling: touch;
  // background-color: #000000 !important;
}

html,
body,
#app {
  //  height: 100%;
  height: calc(~"100vh - 0px");
  //  height: calc(~"100vh");
  font-family: "Microsoft YaHei";
  font-family: -apple-system !important;
  font-family: "-apple-system", "HelveticaNeue" !important; // iOS 8 compatible, credit: @MarcoBoschi
  /* 启用滚动回弹效果 */
  // overflow-y: auto;
  // background-color: #0e1325;
  // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}

@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url("./assets/family/YouSheBiaoTiHei.ttf") format("truetype");
}

body::-webkit-scrollbar {
  display: none;
}

img {
  pointer-events: none;
}

.clear {
  clear: both;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

html /deep/ .v-modal {
  top: 60px;
}

//邮箱弹框 样式修改
html /deep/ .el-message-box {
  background-color: #333542;
  border: none;

  .el-message-box__title {
    color: #c3c3e2;
  }
}
[v-cloak] {
  display: none;
}
// ::-webkit-scrollbar-track {
// 	background-color: #1c2127;
// }

// ::-webkit-scrollbar {
// 	width: 10px;
// 	height: 10px;
// }
/* 滚动条整体样式 */
/* 设置滚动条的宽度和高度 */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* 滚动条轨道样式 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  /* 弧度 */
}

/* 鼠标悬停在滚动条上方时的滑块样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* 滚动条角落的空白区域样式 */
::-webkit-scrollbar-corner {
  background-color: transparent;
}

* {
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
  /* Firefox */
}

@media screen and (max-width: 767px) {
  .el-message-box {
    width: 80% !important;
  }
}
.not-support {
  background: #000;
  text-align: center;
  padding: 20px;
  font-size: 26px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
@media screen and (min-width: 992px) {
  .pagesView {
    max-width: 1500px;
    padding: 0 1rem * 1.5;
    min-height: calc(
      100vh - var(--window-headerNav-height) - var(--window-footer-height) -
        var(--window-swiper-height)
    );
    margin: 0 auto;
  }
}
.show {
  background-color: rgba(0, 0, 0, 0.6);
  animation: fadeIn 0.5s;
}
.mask {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupContent {
  position: relative;
  background: url("./assets/img/loginbg.04aa9090.png") no-repeat 50% / cover;
  padding: 1rem * 1.5;
  border-radius: 0.42rem * 1.5;
}

.popupContent .close {
  width: 0.69rem * 1.5;
  position: absolute;
  top: 0.63rem * 1.5;
  right: 0.63rem * 1.5;
  cursor: pointer;
  transition: transform 0.3s linear;
  opacity: 0.6;
}
.popupContent .close img {
  width: 100%;
}

.popupContent .close:hover {
  transform: rotate(90deg);
}

@media screen and (max-width: 992px) {
  .popupContent {
    background: url("./assets/img/loginbg.04aa9090.png") no-repeat 50% / cover;
    padding: 2rem * 1.5;
    border-radius: 0.42rem * 1.5;
  }

  .popupContent .close {
    width: 1.2rem * 1.5;
    position: absolute;
    top: 1rem * 1.5;
    right: 1rem * 1.5;
    cursor: pointer;
    transition: transform 0.3s linear;
    opacity: 0.6;
  }
  .popupContent .close img {
    width: 100%;
    height: 100%;
  }
  .popupContent .close:hover {
    transform: rotate(90deg);
  }
}
.pagesView {
  margin: 0 auto;
  padding: 0 1rem;
  min-height: 100%;
}
.MoneyCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
</style>
