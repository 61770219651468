<template>
  <div class="ranking">
    <div class="battleTop">
      <div class="tab">
        <div
          class="tabItem"
          :class="day == 'today' ? 'active' : ''"
          @click="changeTab('today')"
        >
          今天
        </div>
        <div
          class="tabItem"
          :class="day == 'last' ? 'active' : ''"
          @click="changeTab('last')"
        >
          昨天
        </div>
      </div>
      <div class="list">
        <div class="table">
          <div class="theader">
            <div class="tr">
              <div class="td ranking">排行</div>
              <div class="td players">玩家</div>
              <div class="td value">金额</div>
              <div class="td best">最佳掉落</div>
            </div>
          </div>
          <div class="tbody">
            <div
              class="tr"
              v-for="(item, index) in list1"
              :key="item.id"
              :class="index + 1 <= 3 ? 'top3' : ''"
              :style="{ 'background-image': `url(${getBg(index + 1)})` }"
            >
              <div class="td ranking">
                <img v-if="index + 1 <= 3" class="winBg" :src="winBg" alt="" />
                <div class="rankImg">
                  <img :src="getTop(index + 1)" alt="" /><!---->
                  <span v-if="index + 1 > 3" class="topNum">{{
                    index + 1
                  }}</span>
                </div>
              </div>
              <div class="td players">
                <img :src="item.img" alt="" /><span>{{ item.name }}</span>
              </div>
              <div class="td value">
                <div class="MoneyCoin money">
                  <img src="../assets/img/money.png" alt="" />
                  <span class="moneyVal" style="color: rgb(255, 255, 255)">{{
                    item.total_consume
                  }}</span>
                </div>
              </div>
              <div class="td best">
                <div
                  class="bestItem"
                  v-for="(item2, index2) in item.skins"
                  :key="index2"
                >
                  <div
                    class="SkinCard"
                    :style="{ 'background-image': `url(${getBgColor(item2.color)})` }"
                  >
                    <img :src="item2.img" alt="" class="skinImg" />
                    <div class="MoneyCoin skinMoney">
                      <img src="../assets/img/money.png" alt="" />
                      <span class="moneyVal">{{ item2.price }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="tr top3"
              style="background-image: url('/img/battletop2-bg.bde09e89.png')"
            >
              <div class="td ranking">
                <img
                  class="winBg"
                  src="/img/win00000_iSpt.caa4cbdc.png"
                  alt=""
                />
                <div class="rankImg">
                  <img src="/img/battletop2.af1bd248.png" alt="" />
                </div>
              </div>
              <div class="td players">
                <img
                  src="https://lucsbox.oss-cn-hangzhou.aliyuncs.com/2024/09/01/1725189823233.jpg"
                  alt=""
                /><span>csgo不差钱</span>
              </div>
              <div class="td value">
                <div  class="MoneyCoin money">
                  <svg
                    data-v-0f4c779f=""
                    
                    class="svg-icon"
                    aria-hidden="true"
                    style="color: rgb(255, 49, 237)"
                  >
                    <use data-v-0f4c779f="" xlink:href="#icon-A8"></use></svg
                  ><span
                    
                    class="moneyVal"
                    style="color: rgb(255, 255, 255)"
                    >43424.00</span
                  >
                </div>
              </div>
              <div class="td best">
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg1.535a460d.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5a7abff446072b588ad1ebb2UISrLX07"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">5769.03</span>
                    </div>
                  </div>
                </div>
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg2.8216810b.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5a7abffcaa49f15b4de7ac446yXiuxB1"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">3047.04</span>
                    </div>
                  </div>
                </div>
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg2.8216810b.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5a87527820e3dbde685f0511G8UDTqUi"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">2261.17</span>
                    </div>
                  </div>
                </div>
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg2.8216810b.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5c8a1ec0a824c037c90f3491eJXOcmEB02"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="skinCount">x2</div>
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">2047.56</span>
                    </div>
                  </div>
                </div>
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg3.14609471.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5bb5c1676f049499aadb5a1ecCkPwaYk"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="skinCount">x18</div>
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">1.79</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tr top3"
              style="background-image: url('/img/battletop3-bg.fe6b6912.png')"
            >
              <div class="td ranking">
                <img
                  class="winBg"
                  src="/img/win00000_iSpt.caa4cbdc.png"
                  alt=""
                />
                <div class="rankImg">
                  <img src="/img/battletop3.e72c0f8a.png" alt="" />
                </div>
              </div>
              <div class="td players">
                <img
                  src="https://lucsbox.oss-cn-hangzhou.aliyuncs.com/00/02/rBq_wWHvlByADAc3AAApAQkegxc11138.jpg"
                  alt=""
                /><span>半曲弦铩b1Q</span>
              </div>
              <div class="td value">
                <div  class="MoneyCoin money">
                  <svg
                    data-v-0f4c779f=""
                    
                    class="svg-icon"
                    aria-hidden="true"
                    style="color: rgb(255, 49, 237)"
                  >
                    <use data-v-0f4c779f="" xlink:href="#icon-A8"></use></svg
                  ><span
                    
                    class="moneyVal"
                    style="color: rgb(255, 255, 255)"
                    >14168.20</span
                  >
                </div>
              </div>
              <div class="td best">
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg0.cadc763a.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5c8adfded776cf7361ecae21pqoTGiYr02"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">1353.76</span>
                    </div>
                  </div>
                </div>
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg1.535a460d.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5dd33ced6f04947248b61f672TXhmazm02"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">950.36</span>
                    </div>
                  </div>
                </div>
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg0.cadc763a.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5fc9855aa7f2521b3b6f966aUJUA5H3802"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="skinCount">x2</div>
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">754.48</span>
                    </div>
                  </div>
                </div>
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg1.535a460d.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5fc9ad08143cfa15b14493b3QBlWhAfG02"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">603.99</span>
                    </div>
                  </div>
                </div>
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg0.cadc763a.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5e83fa4ca7f2524f7f0720a7Aot93i8302"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">349.02</span>
                    </div>
                  </div>
                </div>
                <div class="bestItem">
                  <div
                    
                    class="SkinCard"
                    style="background-image: url('/img/skinbg0.cadc763a.png')"
                  >
                    <img
                      
                      src="https://g.fp.ps.netease.com/market/file/5a7ac26d8b74278e3ebd87edee2tzpHM"
                      alt=""
                      style="width: 2.79rem"
                    />
                    <div  class="skinCount">x2</div>
                    <div  class="MoneyCoin skinMoney">
                      <svg
                        data-v-0f4c779f=""
                        
                        class="svg-icon"
                        aria-hidden="true"
                        style="color: rgb(255, 255, 255)"
                      >
                        <use
                          data-v-0f4c779f=""
                          xlink:href="#icon-A8"
                        ></use></svg
                      ><span  class="moneyVal">338.54</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
export default {
  data() {
    return {
      top_default: require("../assets/img/ranking/battletop4.19197c2e.png"),
      top_one: require("../assets/img/ranking/battletop1.f30bbb92.png"),
      top_two: require("../assets/img/ranking/battletop2.af1bd248.png"),
      top_three: require("../assets/img/ranking/battletop3.e72c0f8a.png"),
      top_one_bg: require("../assets/img/ranking/battletop1-bg.22cb61ce.png"),
      top_two_bg: require("../assets/img/ranking/battletop2-bg.bde09e89.png"),
      top_three_bg: require("../assets/img/ranking/battletop3-bg.fe6b6912.png"),
      winBg: require("../assets/img/ranking/win00000_iSpt.caa4cbdc.png"),
      day: "today",
      star: {},
      list1: [],
      red: require("../assets/img/skinbg1.535a460d.png"),
      grey: require("../assets/img/skinbg4.c3e49d82.png"),
      // orange: require("../assets/img/skinbg0.cadc763a.png"),
      gold: require("../assets/img/skinbg0.cadc763a.png"),
      blue: require("../assets/img/skinbg3.14609471.png"),
      purple: require("../assets/img/skinbg2.8216810b.png"),
    };
  },
  methods: {
    // 获取排行榜数据
    getRanking() {
      this.$axios
        .post("/index/Battle/ranking", {
          day: this.day,
        })
        .then((res) => {
          var data = res.data;
          if (data.status == "1") {
            this.list1 = data.data.rank;
            if (this.list1.length == 0) {
              this.list1.push({
                img: "",
                name: "暂无数据",
                total_consume: 0,
              });
            }
            // for (var i = 1; i < 10; i++) {
            //   if (!this.list1[i]) {
            //     this.list1[i] = {
            //       img: "",
            //       name: "暂无数据",
            //       total_consume: 0,
            //     };
            //   }
            // }
            this.star = data.data.star;
            if (this.star) {
              this.star.total_consume = this.star.total_consume.toFixed(2);
            }
          }
        });
    },
    getBg(num) {
      switch (num) {
        case 1:
          return this.top_one_bg;
        case 2:
          return this.top_two_bg;
        case 3:
          return this.top_three_bg;
      }
    },
    getTop(num) {
      switch (num) {
        case 1:
          return this.top_one;
        case 2:
          return this.top_two;
        case 3:
          return this.top_three;
        default:
          return this.top_default;
      }
    },
    getImg(num) {
      switch (num) {
        case 1:
          return this.top_one;
        case 2:
          return this.top_two;
        case 3:
      }
    },
    // 处理背景
    getBgColor(value) {
      // 把字母全部转成大写
      console.log(value,'shishi1')
      if (value == undefined) {
        return this.grey;
      }
      switch (value.toUpperCase()) {
        // 灰色
        case "#B2B2B2":
          // return `background: url("../assets/img/skinbg4.c3e49d82.png") no-repeat;`;
          return this.grey;
        // 红色
        case "#EB4B4B":
          // return ` background: url("../assets/img/skinbg1.535a460d.png") no-repeat;`;
          return this.red;
        // 橙色
        case "#CF6A32":
        case "#D32CE6":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.purple;
        // 金色
        case "#E4AE39":
        case "#FFD700":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.gold;
        // 蓝色
        case "#5E98D9":
        case "#4B69FF":
          // return `background: url("../assets/img/skinbg3.14609471.png") no-repeat;`;
          return this.blue;
      }
    },
    changeTab(value) {
      this.day = value;
      // this.getRanking();
    },
  },
  watch: {
    day: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.getRanking();
      }
    },
  },
  mounted() {
    this.getRanking();
  },
};
</script>
<style scoped lang="less">
.ranking {
  width: 100%;
}
.table {
  display: flex;
  flex-direction: column;
}

.tr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: hsla(0, 0%, 100%, 0.05);
  margin-bottom: 0.13rem * 1.4;
}

.tr:hover {
  background-color: hsla(0, 0%, 100%, 0.08);
}

.td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.38rem * 1.4 1rem * 1.4;
  width: 5rem * 1.4;
}

.battleTop {
  margin-bottom: 3rem * 1.4;
  width: 100%;
}

.battleTop .tab {
  display: flex;
}

.battleTop .tab .tabItem {
  margin-right: 1rem * 1.4;
  color: #c0c3d1;
  font-size: 0.58rem * 1.4;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0 0.5rem * 1.4 0.5rem * 1.4 0.5rem * 1.4;
}

.battleTop .tab .active {
  color: #fff;
  position: relative;
}

.battleTop .tab .active:after {
  display: block;
  content: "";
  position: absolute;
  width: 60%;
  height: 0.13rem * 1.4;
  background-color: #ff31ed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.battleTop .list {
  margin-top: 0.5rem * 1.4;
}

.table .theader {
  color: #a9abbc;
  font-size: 0.58rem * 1.4;
}

.table .theader .tr {
  height: 1.75rem * 1.4;
}

.table .tbody .tr {
  height: 4.88rem * 1.4;
}

.table .td {
  justify-content: flex-start;
}

.table .best {
  flex-grow: 1;
  justify-content: flex-start;
  overflow: hidden;
}

.table .best .bestItem {
  width: 4.67rem * 1.4;
  height: 4.04rem * 1.4;
  margin-right: 0.29rem * 1.4;
  border-radius: 10%;
  overflow: hidden;
  flex-shrink: 0;
}

.table .best .bestItem .skinMoney {
  font-size: 0.5rem * 1.4;
  position: absolute;
  bottom: 0.2rem * 1.4;
  left: 50%;
  transform: translateX(-50%);
}

.table .top3 {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 10.04rem * 1.4 4.84rem * 1.4;
}

.table .ranking {
  justify-content: center;
  position: relative;
}

.table .ranking .winBg {
  width: 100%;
  position: absolute;
}

.table .ranking .rankImg {
  position: relative;
}

.table .ranking .rankImg img {
  width: 2.75rem * 1.4;
}

.table .ranking .rankImg .topNum {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem * 1.4;
  font-weight: 700;
  color: #fff;
}

.table .players {
  flex-basis: auto;
  width: 6rem * 1.4;
  font-size: 0.64rem * 1.4;
  white-space: nowrap;
}

.table .players img {
  width: 2.5rem * 1.4;
  height: 2.5rem * 1.4;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.25rem * 1.4;
}

.table .players span {
  white-space: normal;
  width: calc(100% - 2.5rem * 1.4);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}

.table .value .money {
  font-size: 0.75rem * 1.4;
  font-weight: 700;
}

.noData {
  height: 5rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .battleTop {
    margin-bottom: 3rem * 0.8;
  }

  .battleTop .tab {
    display: flex;
  }

  .battleTop .tab .tabItem {
    margin-right: 1rem * 0.8;
    color: #c0c3d1;
    font-size: 1rem * 0.8;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding: 0 0.5rem * 0.8 0.5rem * 0.8 0.5rem * 0.8;
  }

  .battleTop .list {
    margin-top: 0.5rem * 0.8;
  }

  .table .theader {
    color: #a9abbc;
    font-size: 1rem * 0.8;
  }

  .table .theader .tr {
    height: 2.84rem * 0.8;
    border-radius: 0.42rem * 0.8 0.42rem * 0.8 0 0;
    background: rgba(105, 23, 255, 0.3);
  }

  .table .theader .tr .td {
    justify-content: flex-start;
  }

  .table .tbody .tr {
    height: 6.25rem * 0.8;
  }

  .table .td {
    justify-content: flex-start;
  }

  .table .best {
    display: none;
  }

  .table .top3 {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 12.78rem * 0.8 6.25rem * 0.8;
  }

  .table .ranking {
    justify-content: center;
    width: 25%;
    box-sizing: border-box;
  }

  .table .ranking .rankImg {
    position: relative;
  }

  .table .ranking .rankImg img {
    width: 3.65rem * 0.8;
  }

  .table .ranking .rankImg .topNum {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem * 0.8;
    font-weight: 700;
    color: #fff;
  }

  .table .players {
    flex-basis: auto;
    width: 37.5%;
    box-sizing: border-box;
    font-size: 1rem * 0.8;
    white-space: nowrap;
  }

  .table .players img {
    width: 2.5rem * 0.8;
    height: 2.5rem * 0.8;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.25rem * 0.8;
  }

  .table .value {
    width: 37.5%;
    box-sizing: border-box;
  }

  .table .value .money {
    font-size: 1.08rem * 0.8;
    font-weight: 700;
  }
}
.MoneyCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.SkinCard {
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 100%, 0.05);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 120% 140%;
  position: relative;
  .skinImg {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}
</style>