<template>
  <div class="battleEstablish">
    <div class="battle-establish pagesView routerView">
      <Back></Back>
      <div class="list">
        <div
          class="swiper swiper-initialized swiper-horizontal swiper-backface-hidden"
        >
          <div class="swiper-wrapper">
            <div
              v-for="item in selectBoxList"
              :key="item.id"
              class="swiper-slide list-card swiper-slide-active"
              style="margin-right: 10px"
            >
              <div class="list-card-title">
                {{ item.name }}
                <span
                  class="list-card-title-close"
                  @click="removeSelectBox(item)"
                ></span>
              </div>
              <div class="list-card-price">
                <div data-v-2afe5ab8="" class="MoneyCoin coin">
                  <img src="../assets/img/money.png" alt="" />
                  <span
                    data-v-2afe5ab8=""
                    class="moneyVal"
                    style="color: rgb(255, 255, 255)"
                    >{{ item.price }}</span
                  >
                </div>
              </div>
              <div class="list-card-image">
                <div class="card-dom">
                  <div class="BoxCard">
                    <img
                      class="skinBg"
                      alt=""
                      lazy="loaded"
                      :src="item.img_main"
                    /><img class="skin" :src="item.img_active" alt="" />
                  </div>
                </div>
              </div>
              <div class="list-card-action">
                <div class="list-card-action-icon" @click="del(item, 1)">
                  <i class="el-icon-minus"></i>
                </div>
                <div class="card-number">
                  {{ item.num }}
                </div>
                <div class="list-card-action-icon" @click="add(item, 1)">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
              <!---->
            </div>
            <div
              class="swiper-slide list-card list-card-none"
              style="margin-right: 10px"
              v-for="i in 6 - selectBoxList.length"
              :key="i"
              @click="showPopup"
            >
              <div class="list-card-add"></div>
              <div class="list-card-info">添加箱子</div>
            </div>
          </div>
          <!----><!----><!---->
        </div>
      </div>
      <div class="select">
        <div class="select-title">玩家</div>
        <div class="select-radio">
          <div class="select-radio-choice" v-for="i in 3" :key="i">
            <div
              class="select-radio-choice-img"
              v-if="i != 1"
              :class="i == mode ? 'select-radio-selected' : ''"
              @click="mode = i"
            ></div>
            <div v-if="i != 1">
              {{ i }}
            </div>
          </div>
        </div>
      </div>
      <div class="head">
        <div class="head-left">{{ calculateTotalNumber(selectBoxList) }}</div>
        <div class="head-list _windows">
          <div class="swiper swiper-initialized swiper-horizontal">
            <div
              class="swiper-wrapper"
              style="transition-duration: 0ms; transition-delay: 0ms"
            >
              <div
                class="swiper-slide head-list-card swiper-slide-active"
                style="margin-right: 10px"
                v-for="item in selectBoxList"
                :key="item.id"
              >
                <div class="head-list-card-dom">
                  <div class="bottom-BoxCard">
                    <img
                      class="bottom-skinBg"
                      alt=""
                      lazy="loaded"
                      :src="item.img_main"
                    /><img class="bottom-skin" :src="item.img_active" alt="" />
                  </div>
                </div>
                <!---->
              </div>
            </div>
          </div>
        </div>
        <div class="head-price">
          战斗成本
          <div class="MoneyCoin coin">
            <img src="../assets/img/money.png" alt="" class="money" />
            <span class="moneyVal" style="color: rgb(255, 49, 237)">{{
              calculateTotalPrice(selectBoxList)
            }}</span>
          </div>
        </div>
        <div class="head-right">
          <div class="head-right-button" @click="createRoom"></div>
        </div>
      </div>
      <!---->
      <div class="box-selection" v-show="show">
        <div class="selectionselect">
          <div class="selectiontitle">
            <span class="selectiontitle-left">选择物品</span
            ><span class="selectiontitle-right" @click="show = false"></span>
          </div>
          <div class="selectionmany">
            <div class="selectionmany-left">
              <div class="rowLeft">
                <div class="selectionmany-query">
                  <el-input v-model="name" placeholder=""></el-input>
                  <div class="selectionmany-query-input-icon" @click="search">
                    <img src="../assets/img/lucky/search.b7a10728.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="rowRight">
                <div class="selectionmany-input">
                  <div class="inputBox">
                    <img src="../assets/img/money.png" alt="" />
                    <el-input v-model="min" placeholder="最低价"></el-input>
                  </div>
                  -
                  <div class="inputBox">
                    <img src="../assets/img/money.png" alt="" />
                    <el-input v-model="max" placeholder="最高价"></el-input>
                  </div>
                </div>
                <div class="selectionmany-price">价格</div>
                <div
                  class="selectionmany-price-down"
                  :class="{ 'selectionmany-price-down-change': order == 'asc' }"
                  @click="sortPrice"
                >
                  <i class=""></i>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAcCAYAAAB75n/uAAAAAXNSR0IArs4c6QAAARhJREFUSEvtlrENwkAMRf8XU8BGdBRISFBSsQEVHYxARwkCREPFQgimQEaOkii5+O5Mi5Iukf3f+Z9thXA+IjIGcCjDlyQfnlR6gjRGRF4AhmX8m+TIk/sLQJqCJF25rqCygh6QvjIR6S3qLbId+INBC7bkiuTdKtY7ByIyAbCvt26wJT8A5iSvIcQDEJEZgCOAQZn/ZADQ7yYkBzDEVasAaEm3BtWEpAARcT3otOiiRMCC5CW1rnO5dZvmAq0Kcjl6sNYcpBIAnIOL1ws9GdbWVXcAGbuqzqg46nHzm763xE1AApLahtH2jq6KiF0WJCoeraBScUCS4llAxq6suAvQgHS6yFopoYe/rOstgLXOJYAdyY3nz+4LDh/IcKexlWwAAAAASUVORK5CYII="
                    alt=""
                  />
                </div>
                <div class="selectionmany-price-reset" @click="reset">
                  <img
                    src="../assets/img/lucky/ResetIcon.61e6374c.png"
                    alt=""
                  />
                  重置
                </div>
              </div>
            </div>
          </div>
          <div class="selectionmany-list">
            <div
              class="selectionmany-list-card"
              v-for="(item, index) in boxList"
              :key="item.id"
            >
              <div class="card-img">
                <div class="card-title">{{ item.name }}</div>
                <div class="card-dom">
                  <div class="BoxCard">
                    <img
                      class="skinBg"
                      alt=""
                      lazy="loaded"
                      :src="item.img_main"
                    /><img class="skin" :src="item.img_active" alt="" />
                  </div>
                </div>
                <div class="img-price">
                  <div class="MoneyCoin coin">
                    <img src="../assets/img/money.png" alt="" />
                    <span class="moneyVal" style="color: rgb(255, 49, 237)">{{
                      item.price
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="card-img-selected">
                <div class="card-img-selected-action">
                  <div
                    class="card-img-selected-action-icon"
                    @click="del(item, 2)"
                  >
                    <i class="el-icon-minus"></i>
                  </div>
                  <div class="card-number">
                    {{ item.num }}
                  </div>
                  <div
                    class="card-img-selected-action-icon"
                    @click="add(item, 2)"
                  >
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="selectionmany-confirm">
            <div class="selectionmany-confirm-left">
              <div class="selectionmany-confirm-xz">
                已选:{{ calculateTotalNumber(boxList) }}个
              </div>
              <div class="selectionmany-confirm-line"></div>
              <div class="selectionmany-confirm-xz">
                共值：
                <div class="MoneyCoin coin">
                  <img
                    src="../assets/img/money.png"
                    alt=""
                    style="width: 20px; height: 20px"
                  /><span class="moneyVal" style="color: rgb(255, 49, 237)">{{
                    calculateTotalPrice(boxList)
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="selectionmany-confirm-right"
              @click="closePopup()"
            ></div>
          </div>
        </div>
      </div>
      <!-- <div class="mask animate__animated hidde" style="display: none">
        <div class="popupContent" style="display: none">
          <img class="close" src="/img/close.10e4447a.png" alt="" />
          <div class="detailBox">
            <div class="title"></div>
            <div class="contain">
              <div class="image"></div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Back from "./back.vue";
import Utils from "../assets/js/util.js";
export default {
  components: {
    Back,
  },
  data() {
    return {
      show: false,
      loading: true,
      boxList: [],
      boxList2: [],
      max: "",
      min: "",
      groupedData: [],
      number: 0, //统计箱子数量
      selectBoxList: [], //统计箱子列表
      selectBoxList2: [], //统计箱子列表
      mode: 2,
      loading2: false,
      name: "",
      order: "asc",
    };
  },
  mounted() {
    let that = this;
    that.getBattleBoxList();
    this.$bus.$emit("loading", false);
  },
  methods: {
    sortPrice() {
      this.order = this.order == "asc" ? "desc" : "asc";
      this.getBattleBoxList();
    },
    filterOutEmptyValues(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([_, v]) => v !== null && v !== undefined && v !== ""
        )
      );
    },
    //盲盒选择列表
    getBattleBoxList() {
      let that = this;
      const data = {
        name: that.name,
        order: that.order,
        min: that.min,
        max: that.max,
      };
      that.$axios
        .post("/index/Battle/battleBoxList", that.filterOutEmptyValues(data))
        .then((res) => {
          if (res.data.data != undefined) {
            const data = res.data.data;
            const defaultTypeName = "未分类"; // 设置一个默认的类型名称

            that.groupedData = data.reduce((acc, item) => {
              // 如果 box_type_name 为 null，使用默认名称
              const typeName = item.box_type_name || defaultTypeName;

              let existingGroup = acc.find(
                (group) => group.typeName === typeName
              );
              if (!existingGroup) {
                acc.push({ typeName });
              }
              return acc;
            }, []);
            res.data.data.forEach((element) => {
              element.num = 0;
            });
            that.boxList = res.data.data;
            that.loading = true;
            that.boxList2 = res.data.data;
          } else {
            that.boxList = [];
            that.loading = true;
            that.boxList2 = [];
          }

          // let arr = [];
          // for (let i = 0; i < this.boxList.length / 10; i++) {
          // 	console.log(arr)
          // 	this.boxList2.push(arr);
          // }
          // for (let i = 0; i < this.boxList2.length; i++) {
          // 	this.boxList2[i] = this.boxList.slice(i * 10, (i + 1) * 10);
          // }
        });
    },
    showPopup() {
      if (this.calculateTotalNumber(this.selectBoxList) == 6) {
        return this.$message({
          message: "最多添加6个箱子",
          type: "warning",
        });
      }
      this.show = true;
    },
    closePopup() {
      this.show = false;
      this.selectBoxList = JSON.parse(
        JSON.stringify(this.filterList(this.boxList))
      );
    },
    del(item, type) {
      if (item.num > 0) {
        const data = type == 1 ? this.selectBoxList : this.boxList;
        data.forEach((element, index) => {
          if (element.id == item.id && item.num == 0 && type == 1) {
            data.splice(index, 1);
          } else if (element.id == item.id && item.num != 0) {
            element.num--;
          }
        });
        if (type == 1) {
          this.boxList.forEach((element) => {
            if (element.id == item.id) {
              element.num--;
            }
          });
        }
      }
    },
    add(item, type) {
      const data = type == 1 ? this.selectBoxList : this.boxList;
      const number = this.calculateTotalNumber(data);
      if (number < 6) {
        // item.num++;
        data.forEach((element) => {
          if (element.id == item.id) {
            element.num++;
          }
        });
        if (type == 1) {
          this.boxList.forEach((element) => {
            if (element.id == item.id) {
              element.num++;
            }
          });
        }
      } else {
        return this.$message({
          message: "最多添加6个箱子",
          type: "warning",
        });
      }
    },
    removeSelectBox(item) {
      this.selectBoxList = this.selectBoxList.filter(
        (element) => element.id !== item.id
      );
      // 修改对应的为0
      this.boxList.forEach((element) => {
        if (element.id === item.id) {
          element.num = 0;
          return true;
        }
      });
    },
    calculateTotalPrice(list) {
      return this.filterList(list).reduce((total, box) => {
        return total + box.price * box.num;
      }, 0);
    },

    calculateTotalNumber(list) {
      return list.reduce((total, box) => {
        return total + box.num;
      }, 0);
    },
    // 过滤掉number等于0的
    filterList(list) {
      return list.filter((item) => item.num > 0);
    },
    createRoom() {
      if (!this.$store.state.token) {
        this.$store.commit("getLogin", true);
        return;
      }
      let that = this;
      const boxInfo = [];
      that.selectBoxList.forEach((element) => {
        for (let i = 0; i < element.num; i++) {
          // 创建一个新的对象副本
          let newItem = JSON.parse(JSON.stringify(element));
          // 设置新的对象的 num 为 1
          newItem.num = 1;
          // 设置新的对象的 box_id 为 id
          newItem.box_id = element.id;
          // 将新的对象添加到 boxInfo 数组中
          boxInfo.push(newItem);
        }
      });
      if (that.filterList(that.selectBoxList) == 0) {
        that.$message({
          message: "请选择箱子",
          type: "warning",
        });
        return;
      }
      that.loading2 = true;
      let param = {
        mode: that.mode,

        boxInfo: boxInfo,
      };
      that.$axios
        .post("/index/Battle/createRoom", that.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            that.loading2 = false;
            that.$store.commit(
              "getMoney",
              JSON.parse(localStorage.getItem("userInfo")).total_amount -
                data.data.total_price
            );
            Utils.$emit(
              "money",
              JSON.parse(localStorage.getItem("userInfo")).total_amount -
                data.data.total_price
            );

            that.$router.push({
              path: `/LuckyRoom`,
              query: {
                id: data.data.battle_id,
              },
            });
            that.selectBoxList = [];
            // that.currentBoxNum = 0;
            // that.totalPrice = 0;
          } else {
            that.loading2 = false;
            //创建失败
            that.$message({
              message: data.msg,
              type: "warning",
              center: true,
            });
            that.selectBoxList = [];
            // that.selectList = [];
            // that.currentBoxNum = 0;
            // that.totalPrice = 0;
          }
        });
    },
    search() {
      this.getBattleBoxList();
    },
    // 重置‘
    reset() {
      this.name = "";
      this.min = "";
      this.max = "";
      this.order = "asc";
      this.getBattleBoxList();
    },
    // confirm() {
    //   if (this.number > 0) {
    //     this.$router.push
    //   }
    // }
  },
};
</script>
<style scoped lang="less">
.battleEstablish {
  width: 100vw;
  max-width: 1500px;
  margin: 0 auto;
}
.battle-establish {
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.battle-establish .list {
  margin-top: 1.17rem * 1.5;
  background: hsla(0, 0%, 100%, 0.05);
  height: 15.25rem * 1.5;
  overflow-x: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem * 1.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.battle-establish .list .list-card {
  width: 9.4rem * 1.5;
  height: 12.9rem * 1.5;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.42rem;
  margin-right: 0.58rem * 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.battle-establish
  .list
  .list-card:hover
  .list-card-title
  .list-card-title-close {
  opacity: 90%;
}

.battle-establish .list .list-card:hover .list-card-add {
  background-image: url("../assets/img/lucky/addcase-b@2x.d55a4626.png");
}

.battle-establish .list .list-card .list-card-title {
  text-align: left;
  width: 9.4rem * 1.4;
  height: 0.58rem * 1.4;
  font-size: 0.58rem * 1.4;
  font-weight: 700;
  color: #fff;
  line-height: 1.33rem * 1.4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.8rem * 1.4;
}

.battle-establish .list .list-card .list-card-title .list-card-title-close {
  opacity: 50%;
  cursor: pointer;
  width: 0.6rem * 1.4;
  height: 0.6rem * 1.4;
  background-image: url("../assets/img/lucky/close2.8b460ce4.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: all 0.3s linear;
  position: relative;
  margin-right: 0.5rem * 1.4;
}

.battle-establish
  .list
  .list-card
  .list-card-title
  .list-card-title-close:hover {
  opacity: 90%;
  transform: rotate(90deg);
}

.battle-establish .list .list-card .list-card-price {
  width: 9.4rem * 1.4;
  padding-left: 0.8rem * 1.4;
  display: flex;
  align-items: center;
  transform: translateY(-0.6rem * 1.4);
}

.battle-establish .list .list-card .list-card-price .coin {
  height: 0.46rem * 1.4;
  font-size: 0.58rem * 1.4;
  font-weight: 400;
  color: #fff;
  line-height: 1.33rem * 1.4;
}

// .battle-establish .list .list-card .list-card-price .coin > img {
//   width: 0.67rem*1.4;
//   height: 0.67rem*1.4;
// }

.battle-establish .list .list-card .list-card-image .card-dom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 8.13rem * 1.4;
  height: 7.04rem * 1.4;
}

.battle-establish .list .list-card .list-card-action {
  width: 100%;
  height: 1rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.battle-establish .list .list-card .list-card-action .list-card-action-icon {
  width: 1.17rem * 1.4;
  height: 1.16rem * 1.4;
  background: #6917ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2rem * 1.4;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s linear;
}

.battle-establish
  .list
  .list-card
  .list-card-action
  .list-card-action-icon:hover {
  background: #ff31ed;
}

.battle-establish
  .list
  .list-card
  .list-card-action
  .list-card-action-icon:hover
  .svg-icon {
  color: #fff !important;
}

.battle-establish .list .list-card .list-card-action > .card-number {
  width: 3.37rem * 1.4;
  height: 1.24rem * 1.4;
  line-height: 1.24rem * 1.4;
  background: #12161f;
  border: 0.08px solid #2e3442;
  border-radius: 0.62rem * 1.4;
  color: #fff;
  text-align: center;
}

.battle-establish .list .list-card .list-card-add {
  width: 2.67rem * 1.4;
  height: 2.67rem * 1.4;
  background-image: url("../assets/img/lucky/addcase-a@2x.f93b444a.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s linear;
}

.battle-establish .list .list-card .list-card-info {
  text-align: center;
  height: 0.46rem * 1.4;
  font-size: 0.58rem * 1.4;
  font-weight: 700;
  color: #a9abbc;
  line-height: 1.33rem * 1.4;
  width: 9.4rem * 1.4;
}

.battle-establish .list .list-card-none {
  align-content: center !important;
}

.battle-establish .list .list-card:hover {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  background: hsla(0, 0%, 100%, 0.08);
}

.battle-establish .list .list-card:hover .list-card-info {
  color: #bcbecd !important;
}

.battle-establish .select {
  margin-top: 0.2rem * 1.4;
  height: 3.05rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  padding-left: 1rem * 1.4;
  display: flex;
  align-items: center;
}

.battle-establish .select .select-title {
  font-size: 0.58rem * 1.4;
  font-weight: 700;
  color: #fff;
}

.battle-establish .select .select-radio {
  font-size: 0.67rem * 1.4;
  font-weight: 700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.battle-establish .select .select-radio .select-radio-choice {
  display: flex;
  align-items: center;
  margin: 0 0.8rem * 1.4;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.battle-establish
  .select
  .select-radio
  .select-radio-choice
  .select-radio-choice-img {
  width: 0.75rem * 1.4;
  height: 0.75rem * 1.4;
  background-image: url("../assets/img/lucky/select1.8a5b3c70.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 0 0.2rem * 1.4;
}

.battle-establish
  .select
  .select-radio
  .select-radio-choice
  .select-radio-selected {
  background-image: url("../assets/img/lucky/select2.070f0de8.png") !important;
}

.battle-establish .robot {
  margin-top: 0.2rem;
  min-height: 3.8rem;
  background: hsla(0, 0%, 100%, 0.05);
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.battle-establish .robot .robot-select {
  font-size: 0.58rem;
  font-weight: 700;
  color: #fff;
}

.battle-establish .robot .robot-select-list {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.battle-establish .robot .robot-select-list .robot-avatar {
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}

.battle-establish .robot .robot-select-list .robot-avatar .winningProbability {
  font-size: 0.58rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.1rem;
}

.battle-establish
  .robot
  .robot-select-list
  .robot-avatar
  .winningProbability
  .winVal {
  color: #ff31ed;
}

.battle-establish .robot .robot-select-list .robot-avatar .user-info {
  background: #303547;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  min-width: 4.42rem;
  padding-right: 0.33rem;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.battle-establish
  .robot
  .robot-select-list
  .robot-avatar
  .user-info
  .user-card-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.2rem;
}

.battle-establish .robot .robot-select-list .robot-avatar .user-info img {
  width: 1.17rem;
  height: 1.17rem;
  border-radius: 50%;
  box-sizing: border-box;
}

.battle-establish
  .robot
  .robot-select-list
  .robot-avatar
  .robot-avatar-card-selected {
  border: 2px solid #ff31ed;
  background: rgba(255, 49, 237, 0.2);
}

.battle-establish .head {
  width: 100%;
  height: 4.88rem * 1.4;
  background: rgba(105, 23, 255, 0.38);
  margin-top: 0.2rem * 1.4;
  display: flex;
  align-items: center;
}

.battle-establish .head .head-left {
  width: 2.67rem * 1.4;
  height: 2.67rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.58rem * 1.4 0 1.1rem * 1.4;
  font-size: 1rem * 1.4;
  background: url("../assets/img/lucky/circle@2X.1d7c3d72.png") no-repeat 50% /
    cover;
  color: #fff;
}

.battle-establish .head .head-list {
  width: calc(100% - 22.5rem * 1.4);
  height: 4rem * 1.4;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.battle-establish .head .head-list .head-list-card {
  margin-right: 1rem * 1.4;
  width: 3.54rem * 1.4;
  height: 3.13rem * 1.4;
}

.battle-establish .head .head-list .head-list-card .head-list-card-dom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.battle-establish
  .head
  .head-list
  .head-list-card
  .head-list-card-dom
  .head-list-card-img-go {
  width: 3.17rem * 1.4;
  height: 3.17rem * 1.4;
}

.battle-establish
  .head
  .head-list
  .head-list-card
  .head-list-card-dom
  .head-list-card-img-overlay {
  width: 2.17rem * 1.4;
  height: 2.17rem * 1.4;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 1;
}

.battle-establish .head .head-price {
  height: 0.63rem * 1.4;
  font-size: 0.75rem * 1.4;
  font-weight: 400;
  color: #fff;
  line-height: 1.33rem * 1.4;
  width: 6rem * 1.4;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  .money {
    width: 30px;
    height: 30px;
  }
  .MoneyCoin {
    display: flex;
    align-items: center;
  }
}

.battle-establish .head .head-price .coin {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.battle-establish .head .head-right {
  width: 11rem * 1.4;
  height: 4.88rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.battle-establish .head .head-right .head-right-button {
  width: 9.33rem * 1.4;
  height: 2.77rem * 1.4;
  background: url("../assets/img/lucky/createBattle.002a3164.png") no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}
.box-selection .selectionselect {
  width: 45.82rem * 1.4;
  border-radius: 0.83rem * 1.4;
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 1.25rem * 1.4 1.25rem * 1.4 4rem * 1.4 1.25rem * 1.4;
  text-align: center;
  position: relative;
  background: #181e3e url("../assets/img/lucky/selectPopup.e76a6559.png")
    no-repeat;
  background-size: 100% 100%;
}

.box-selection .selectionselect .selectiontitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-selection .selectionselect .selectiontitle .selectiontitle-left {
  height: 0.75rem * 1.4;
  font-size: 0.75rem * 1.4;
  font-weight: 700;
  color: #fff;
}

.box-selection .selectionselect .selectiontitle .selectiontitle-right {
  cursor: pointer;
  width: 0.84rem * 1.4;
  height: 0.84rem * 1.4;
  background-image: url("../assets/img/luckRoom/close2.8b460ce4.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: all 0.3s linear;
}

.box-selection .selectionselect .selectiontitle .selectiontitle-right:hover {
  transform: rotate(90deg);
}

.box-selection .selectionselect .selectiontype {
  margin-top: 1rem * 1.4;
  display: flex;
  align-content: center;
  align-items: center;
}

.box-selection .selectionselect .selectiontype .selectiontype-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-selection
  .selectionselect
  .selectiontype
  .selectiontype-list
  .selectiontype-list-card {
  cursor: pointer;
  margin-right: 0.33rem * 1.4;
  width: 4.53rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.17rem * 1.4;
  font-size: 0.67rem * 1.4;
  font-weight: 400;
  line-height: 2.26rem * 1.4;
}

.box-selection
  .selectionselect
  .selectiontype
  .selectiontype-list
  .selectiontype-list-card
  > span {
  color: #fff;
}

.box-selection
  .selectionselect
  .selectiontype
  .selectiontype-list
  .selectiontype-list-select {
  background: #6917ff;
}

.box-selection
  .selectionselect
  .selectiontype
  .selectiontype-list
  .selectiontype-list-card:not(.selectiontype-list-select):hover {
  background-color: hsla(0, 0%, 100%, 0.08);
}

.box-selection .selectionselect .selectionmany {
  margin-top: 1rem * 1.4;
}

.box-selection .selectionselect .selectionmany .selectionmany-left {
  display: flex;
  align-items: center;
}

.box-selection .selectionselect .selectionmany .selectionmany-left .rowRight {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  flex-grow: 1;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-input {
  margin-left: 0.5rem * 1.4;
  display: flex;
  align-items: center;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-input
  .inputBox {
  display: flex;
  align-items: center;
  border: 1px solid #6917ff;
  border-radius: 0.17rem * 1.4;
  margin: 0 0.2rem * 1.4;
  padding: 0 0.2rem * 1.4;
  width: 7rem;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-input
  .inputBox
  input {
  width: 3.61rem * 1.4;
  height: 1.42rem * 1.4;
  background: rgba(27, 28, 37, 0);
  color: #fff;
  margin-left: 0.2rem * 1.4;
}
.inputBox img {
  width: 20px;
  height: 20px;
}
.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-price {
  width: 1.46rem * 1.4;
  height: 1.42rem * 1.4;
  line-height: 1.42rem * 1.4;
  font-size: 0.67rem * 1.4;
  font-weight: 400;
  color: #a9abbc;
  margin: 0 0.5rem * 1.4;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-price-down {
  width: 1.63rem * 1.4;
  height: 1.42rem * 1.4;
  background: rgba(32, 32, 40, 0.3);
  border: 1px solid #6917ff;
  border-radius: 0.17rem * 1.4;
  line-height: 1.42rem * 1.4;
  cursor: pointer;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-price-down
  > img {
  width: 0.5rem * 1.4;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-price-down-change {
  transform: rotate(180deg);
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-price-reset {
  width: 3rem * 1.4;
  font-size: 0.58rem * 1.4;
  font-weight: 400;
  color: #a9abbc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem * 1.4;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-price-reset
  img {
  width: 0.67rem * 1.4;
  height: 0.67rem * 1.4;
  margin-right: 0.1rem * 1.4;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-price-reset:hover {
  font-weight: 400;
  color: #fff;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-price-reset:hover
  > div {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAxpJREFUWEfFlzuIlVcUhb+lIL4q352gIFqIWgiSykptBJXBGRS08AFRomhMRCxEi0HFJxLis1BERkEbER+VpBAtLEyjFiHaGbUdJxjNlnU5R/77z9zXeOd64FbnnL3XPXvttdcvmlgRMR5YCSwDfgBmAuPS1QHgNfAIeADclvShibCVI6p3MCKmA78CP6aEn4HRNe7kPQP6HTgq6Z9GQGoCiIgtwElg7BBJnSxScMcog/L+v8AuSRfqgRgEICLGAFeA7tLFnPBP4CnwJu3PABYBC9KLlmNeBzZI+jgUkKrDKfk9YGmhPP8D74DDwFVJ74cKFBGTgfXAPmAaMCqdM/CHwIqhQJQB9AFrU3Jf9P4x4ECzxIoIk/MgsKdQIse6IamnDP4rgFTz8wXU/ufdkm42IlKNF1kD+PnNj5xna5kTlY3E9r+ACYVgXcNNnmNExGrgViFmPzC72B0ZwHFgZ0Lrf35C0i/D+eflOxFxJJXDnHB3nJb0cz6nJDImVhYW9+6sZmveCGTixN+ANcXLOjElxzcAt5vJV6kGsFvSqUaBW9mPiB2AY2Yu9EgyPzCAS8DG9PwGMK1Wq7WStHg2tahb2QBchsuSNmUAz4G56cIzSQuHm6hGN0wCLEJ/JMHysReS5mUAHhyufxWydoCIiK3AWeA/wDyzapqMA5I84ColyBL7KQ2Q/e1Intr7JTAnxXN3eVUUUlKFDyMN4BzgoTZo5hQBjGQJrII/AYeSyOX50C9pYkdImEphIh4AtqduOyPJrdmZNsycigiT0G3ukV5ZHRGieqTuhBSbgMsBG507ktzuX1cnhlFvMilO2iupqs1HehzbExT9xBNJSwa9QGKq+7WdhqQrDTm3XtaBzZI8e6pLUGBpOyyZJTZbsmzrrIJ9kuwZq1Y7TamN6Dpgb8mUOvl9YFVDU5pK0Yot9/NOBRYD83Nrl/7kNWCTJH8nDFrf+mHiJ7bcZonNCdxqdj7+MLlYVwfqbSazam+4rYVPM8+W32znJb2tF7+ihI0OpLLU+zi1030FPAbu+teKn2wKQDMgh3vmuwP4Ai/7SDANa0P9AAAAAElFTkSuQmCC);
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-query {
  width: 15.54rem * 1.4;
  height: 1.67rem * 1.4;
  border: 2px solid #6917ff;
  border-radius: 0.17rem * 1.4;
  display: flex;
  align-items: center;
  background: rgba(105, 23, 255, 0.3);
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-query
  .selectionmany-query-input {
  color: #fff;
  width: 15.54rem * 1.4;
  height: 1.67rem * 1.4;
  margin-left: 0.3rem * 1.4;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-query
  .selectionmany-query-input-icon {
  width: 1.92rem * 1.4;
  height: 1.67rem * 1.4;
  background: #6917ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.box-selection
  .selectionselect
  .selectionmany
  .selectionmany-left
  .selectionmany-query
  .selectionmany-query-input-icon
  > img {
  width: 0.83rem * 1.4;
  height: 0.83rem * 1.4;
}

.box-selection .selectionselect .selectionmany-list {
  flex-wrap: wrap;
  display: flex;
  overflow-y: auto;
  height: 20rem * 1.4;
  margin-top: 0.92rem * 1.4;
  // justify-content: space-around;
}

.box-selection .selectionselect .selectionmany-list::-webkit-scrollbar-thumb {
  background-color: #6917ff;
}

.box-selection .selectionselect .selectionmany-list .selectionmany-list-card {
  cursor: pointer;
  margin-bottom: 1rem;
  margin-right: 0.3rem * 1.4;
  width: 8.32rem * 1.3;
  height: 10.87rem * 1.4;
  border-radius: 0.33rem * 1.4;
}
.BoxCard {
  position: relative;
  .skinBg {
    width: 10rem;
    height: 10rem;
  }
  .skin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8rem;
    height: 8rem;
  }
}
.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img {
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 5%;
  height: 100%;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img
  .card-title {
  padding-top: 0.3rem * 1.4;
  height: 0.79rem * 1.4;
  font-size: 0.67rem * 1.4;
  font-weight: 400;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img
  .card-dom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 8.13rem * 1.4;
  height: 7.04rem * 1.4;
  margin-top: 0.5rem * 1.4;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img
  .img-price {
  padding-top: 0.5rem * 1.4;
  width: 100%;
  display: flex;
  justify-content: center;
}
.MoneyCoin {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
  }
}
.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected-ck {
  background: rgba(0, 0, 0, 0.7) !important;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected-ck
  .card-img-selected-action {
  display: flex !important;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected:hover {
  background: rgba(0, 0, 0, 0.7);
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected:hover
  .card-img-selected-action {
  display: flex;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected {
  background: rgba(40, 42, 51, 0);
  border-radius: 5%;
  height: 100%;
  position: relative;
  z-index: 1;
  top: -10.87rem * 1.4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected
  .card-img-selected-action {
  width: 100%;
  height: 1rem * 1.4;
  display: none;
  align-items: center;
  justify-content: center;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected
  .card-img-selected-action
  .card-img-selected-action-icon {
  width: 1.17rem * 1.4;
  height: 1.16rem * 1.4;
  background: #6917ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2rem * 1.4;
  cursor: pointer;
  transition: background 0.3s linear;
}
.el-icon-minus,
.el-icon-plus {
  color: #fff;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected
  .card-img-selected-action
  .card-img-selected-action-icon:hover {
  background: #ff31ed;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected
  .card-img-selected-action
  .card-img-selected-action-icon:hover
  .svg-icon {
  color: #fff !important;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card
  .card-img-selected
  .card-img-selected-action
  > .card-number {
  width: 3.37rem * 1.4;
  height: 1.24rem * 1.4;
  line-height: 1.24rem * 1.4;
  background: rgba(105, 23, 255, 0.5);
  border: 2px solid #6917ff;
  border-radius: 0.62rem * 1.4;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
}

.box-selection
  .selectionselect
  .selectionmany-list
  .selectionmany-list-card-end {
  margin-right: 0;
}

.box-selection .selectionmany-confirm {
  width: 100%;
  height: 3.5rem * 1.4;
  background: rgba(105, 23, 255, 0.38);
  border-radius: 0 0 0.83rem * 1.4 0.83rem * 1.4;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
}

.box-selection .selectionmany-confirm .selectionmany-confirm-left {
  font-size: 0.58rem * 1.6;
  font-weight: 700;
  color: #fff;
  line-height: 1.33rem * 1.4;
  width: 18rem * 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.box-selection
  .selectionmany-confirm
  .selectionmany-confirm-left
  .selectionmany-confirm-xz {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem * 1.4;
}

.box-selection
  .selectionmany-confirm
  .selectionmany-confirm-left
  .selectionmany-confirm-xz
  .coin {
  font-size: 0.58rem * 1.4;
}

.box-selection .selectionmany-confirm .selectionmany-confirm-right {
  cursor: pointer;
  width: 9.33rem * 1.4;
  height: 2.77rem * 1.4;
  background: url("../assets/img/lucky/createBattle.002a3164.png") no-repeat;
  display: flex;
  margin-right: 1.2rem * 1.4;
  background-size: 100% 100%;
}

.box-selection .el-slider {
  --el-color-primary: #ffc600;
  --el-border-color-light: #333544;
  --el-slider-border-radius: 3px;
  --el-slider-height: 0.21rem * 1.4;
  --el-slider-button-size: 10px;
  --el-color-white: #ffc600;
  --el-slider-button-wrapper-size: 36px;
  --el-slider-button-wrapper-offset: -15px;
}

.box-selection .el-slider .el-slider__button {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAmlJREFUSEu9lk9IVFEUxn/njSBBf4Q06A9WFi1GCHVmzEWLypkKKaFN6bQsahVCEbqyso0tMqRty3LUTWQgmUouWpTzRiqwRZRSlEIZWAj9c96JNzk243ujM6Td5T3nft993zn3fE9YZPl8wWIMwij7EfGCFiH8QnkPvAWGPGJ0DQ8/HM8EI26BBLCHVpTjgGexSwAWQhdxmmKxgXcLcx0Efn+oVkVvA2uWAE4PCzNiyRnT7I+kBtIIfIFQA2gbYOQE/jdZRblgmgM3klvzBBWB6hOC2OyusuVAqIrWj0QHu+wzCbA5zUdRVrsB5XmU3dun2bL+WyL8/vMqXowXMBvPcBdhhjildk3+EASCd4CwG3hNYJJzR19TtO5HWvjTl3xu3t9Jb3Sj68cp0jkS7a+X8vIDW408Y8xN9/PHXhHe52iMNMCOoWLa7u5yI7GsWatEfJWhRlRbF2YcqZzk8snRrKRv6fDS83STM1ekSXyB4APgUGrUMJTeK48pXJsuSya2qa/51Fzai2U5atJnE9ivcnPq4bKSaW41mFndPpl0qt3P87GChWc+2ATfgfzUSG3VBM31L3MiaIl46XnikOnnfyFYFolOt/t55iqRP9iHcHDlipyhTWv3TNAczq4OVyNe7jn1B7tNy6oOb/PEZ9+sxEOLe/J2JBq3IhCKCFq3IqMip2FXODfspnIcdjaJ319dpyIdyzGuRTVsmoOd8+M6Kc1yGA7IxVi0/7rDcJIb/2KZwNnY8ICtwvzKaPpqyDVBbdNfyj4tRbrF0sasTD+VPf23hVJgw1z8I8oowiMD7Y5GB20/cV2/AZLbAzdsLiw7AAAAAElFTkSuQmCC)
    no-repeat 50% / cover;
  border: 0;
  width: 0.5rem * 1.4;
  height: 0.5rem * 1.4;
}
.box-selection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
/deep/.el-input__inner {
  background: transparent !important;
  border: 0 !important;
}
.bottom-BoxCard {
  position: relative;
  // display: flex;
  // align-items: center;
}
.bottom-skinBg {
  width: 100%;
  // height: 6rem;
}
.bottom-skin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
}
@media screen and (max-width: 992px) {
  .battle-establish {
    margin-top: 1rem*0.8;
  }

  .battle-establish .list {
    margin-top: 0.2rem*0.8;
    height: 36.2rem*0.8;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 0.2rem*0.8;
    user-select: none;
    border-radius: 0.83rem*0.8;
  }

  .battle-establish .list .swiper-wrapper {
    flex-wrap: wrap;
  }

  .battle-establish .list .swiper-wrapper .swiper-slide {
    margin-right: 0px !important;
  }

  .battle-establish .list .list-card {
    margin: 0.7rem*0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: calc(94% /2);
    height: 16.83rem*0.8;
    border-radius: 0.83rem*0.8;
  }

  .battle-establish .list .list-card .list-card-title {
    text-align: left;
    width: 13rem*0.8;
    height: 0.58rem*0.8;
    color: rgb(255, 255, 255);
    line-height: 1.33rem*0.8;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.8rem*0.8;
    font-size: 1rem*0.8;
    font-weight: 400;
  }

  .battle-establish .list .list-card .list-card-title .list-card-title-close {
    opacity: 0.5;
    cursor: pointer;
    width: 0.6rem*0.8;
    height: 0.6rem*0.8;
    background-image: url("../assets/img/luckRoom/close2.8b460ce4.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% center;
    transition: 0.3s linear;
    position: relative;
    margin-right: 0.5rem*0.8;
  }

  .battle-establish
    .list
    .list-card
    .list-card-title
    .list-card-title-close:hover {
    opacity: 0.9;
    transform: rotate(90deg);
  }

  .battle-establish .list .list-card .list-card-price {
    width: 13rem*0.8;
    padding-left: 0.8rem*0.8;
    display: inline-flex;
    align-items: center;
    transform: translateY(-0.6rem*0.8);
  }

  .battle-establish .list .list-card .list-card-price .coin {
    height: 0.46rem*0.8;
    font-size: 1.08rem*0.8;
    font-weight: 400;
    color: rgb(255, 255, 255);
    line-height: 1.33rem*0.8;
  }

  .battle-establish .list .list-card .list-card-price .coin > img {
    width: 0.67rem*0.8;
    height: 0.67rem*0.8;
  }

  .battle-establish .list .list-card .list-card-image {
    transform: translateY(-0.4rem*0.8);
  }

  .battle-establish .list .list-card .list-card-image .card-dom {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 10.33rem*0.8;
    height: 8.33rem*0.8;
  }

  .battle-establish .list .list-card .list-card-action {
    width: 100%;
    height: 1rem*0.8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .battle-establish .list .list-card .list-card-action .list-card-action-icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0.2rem*0.8;
    cursor: pointer;
    width: 2.01rem*0.8;
    height: 2rem*0.8;
  }

  .battle-establish .list .list-card .list-card-action > input {
    background: rgb(18, 22, 31);
    border: 1px solid rgb(46, 52, 66);
    color: rgb(255, 255, 255);
    text-align: center;
    width: 5.81rem*0.8;
    height: 2.14rem*0.8;
    border-radius: 1.07rem*0.8;
  }

  .battle-establish .list .list-card .list-card-add {
    width: 5.19rem*0.8;
    height: 5.19rem*0.8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .battle-establish .list .list-card .list-card-info {
    text-align: center;
    height: 0.46rem*0.8;
    font-size: 1.08rem*0.8;
    font-weight: 700;
    color: rgb(169, 171, 188);
    line-height: 1.33rem*0.8;
    width: 9.4rem*0.8;
  }

  .battle-establish .list .list-card-none {
    align-content: center !important;
  }

  .battle-establish .list .list-card:hover {
    user-select: none;
    cursor: pointer;
    background: rgb(32, 37, 50) !important;
  }

  .battle-establish .list .list-card:hover .list-card-info {
    color: rgb(188, 190, 205) !important;
  }

  .battle-establish .select {
    margin-top: 0.2rem*0.8;
    padding-left: 1rem*0.8;
    display: flex;
    align-items: center;
    height: 4.77rem*0.8;
    border-radius: 0.83rem*0.8;
  }

  .battle-establish .select .select-title {
    width: 5.04rem*0.8;
    font-weight: 700;
    font-size: 1.08rem*0.8;
    color: rgb(255, 255, 255);
  }

  .battle-establish .select .select-radio {
    font-size: 1.08rem*0.8;
    font-weight: 700;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .battle-establish .select .select-radio .select-radio-choice {
    display: flex;
    align-items: center;
    margin: 0px 0.8rem*0.8;
    cursor: pointer;
    user-select: none;
  }

  .battle-establish
    .select
    .select-radio
    .select-radio-choice
    .select-radio-choice-img {
    width: 1.4rem*0.8;
    height: 1.4rem*0.8;
    background-image: url("/img/select1.8a5b3c70.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% center;
    margin: 0px 0.2rem*0.8;
  }

  .battle-establish
    .select
    .select-radio
    .select-radio-choice
    .select-radio-selected {
    background-image: url("../assets/img/luckRoom/select2.070f0de8.png") !important;
  }

  .battle-establish .robot {
    margin-top: 0.2rem*0.8;
    min-height: 3.8rem*0.8;
    display: flex;
    align-items: flex-start;
    border-radius: 0.83rem*0.8;
    padding: 1rem*0.8;
  }

  .battle-establish .robot .robot-select,
  .battle-establish .robot {
    font-size: 1.08rem*0.8;
    font-weight: 700;
    color: rgb(255, 255, 255);
  }

  .battle-establish .robot .robot-select-list {
    display: flex;
    flex-wrap: wrap;
    place-content: center flex-start;
    align-items: center;
  }

  .battle-establish .robot .robot-select-list .robot-avatar {
    margin-left: 1.2rem*0.8;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    flex-direction: column;
    margin-bottom: 1rem*0.8;
  }

  .battle-establish .robot .robot-select-list .robot-avatar .user-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-image: initial;
    border-left: none;
    width: 6.48rem*0.8;
    height: 7.21rem*0.8;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.83rem*0.8;
    padding: 0.5rem*0.8;
  }

  .battle-establish
    .robot
    .robot-select-list
    .robot-avatar
    .user-info
    .user-card-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 0.2rem*0.8;
    margin-top: 0.66rem*0.8;
    font-size: 1rem*0.8;
  }

  .battle-establish .robot .robot-select-list .robot-avatar .user-info img {
    width: 3.46rem*0.8;
    height: 3.46rem*0.8;
    border-radius: 50%;
    border: 1px solid transparent;
  }

  .battle-establish
    .robot
    .robot-select-list
    .robot-avatar
    .winningProbability {
    font-size: 1rem*0.8;
  }

  .battle-establish
    .robot
    .robot-select-list
    .robot-avatar
    .robot-avatar-card-selected {
    border: 2px solid rgb(255, 49, 237);
    background: rgba(255, 49, 237, 0.2);
  }

  .battle-establish .head {
    width: 100%;
    margin-top: 0.2rem*0.8;
    display: flex;
    align-items: center;
    height: 6.11rem*0.8;
    margin-bottom: 1rem*0.8;
  }

  .battle-establish .head .head-left {
    width: 4.08rem*0.8;
    height: 4.08rem*0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 1.58rem*0.8 0px 1.1rem*0.8;
  }

  .battle-establish .head .head-list {
    width: calc(100% - 22.5rem*0.8);
    height: 4rem*0.8;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
  }

  .battle-establish .head .head-list .head-list-card {
    margin-right: 1rem*0.8;
    width: 3.54rem*0.8;
    height: 3.13rem*0.8;
  }

  .battle-establish .head .head-list .head-list-card .head-list-card-dom {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .battle-establish
    .head
    .head-list
    .head-list-card
    .head-list-card-dom
    .head-list-card-img-go {
    width: 3.17rem*0.8;
    height: 3.17rem*0.8;
  }

  .battle-establish
    .head
    .head-list
    .head-list-card
    .head-list-card-dom
    .head-list-card-img-overlay {
    width: 2.17rem*0.8;
    height: 2.17rem*0.8;
    position: absolute;
    top: 0.5rem*0.8;
    left: 0.5rem*0.8;
    z-index: 1;
  }

  .battle-establish .head .head-price {
    width: 9.5rem*0.8;
    font-weight: 400;
    color: rgb(255, 255, 255);
    display: flex;
    flex-wrap: wrap;
    place-content: center flex-end;
    align-items: center;
    font-size: 1rem*0.8;
    line-height: 1.74rem*0.8;
    margin-right: 1rem*0.8;
  }

  .battle-establish .head .head-price .coin {
    font-size: 1.26rem*0.8;
    font-weight: 700;
  }

  .battle-establish .head .head-right {
    width: 11rem*0.8;
    height: 6.11rem*0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .battle-establish .head .head-right .head-right-button {
    width: 13.29rem*0.8;
    height: 3.94rem*0.8;
  }

  .box-selection .selectionselect {
    width: 27.7rem*0.8;
    border-radius: 0.83rem*0.8;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px;
    padding: 1.25rem*0.8 1.25rem*0.8 6.5rem*0.8;
    text-align: center;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
  }

  .box-selection .selectionselect .selectiontitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .box-selection .selectionselect .selectiontitle .selectiontitle-left {
    height: 0.75rem*0.8;
    font-size: 1rem*0.8;
    font-weight: 700;
    color: rgb(255, 255, 255);
  }

  .box-selection .selectionselect .selectiontitle .selectiontitle-right {
    cursor: pointer;
    width: 0.84rem*0.8;
    height: 0.84rem*0.8;
    background-image: url("/img/close2.8b460ce4.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% center;
    transition: 0.3s linear;
  }

  .box-selection .selectionselect .selectiontitle .selectiontitle-right:hover {
    transform: rotate(90deg);
  }

  .box-selection .selectionselect .selectiontype {
    margin-top: 1rem*0.8;
    display: flex;
    align-content: center;
    align-items: center;
  }

  .box-selection .selectionselect .selectiontype .selectiontype-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .box-selection
    .selectionselect
    .selectiontype
    .selectiontype-list
    .selectiontype-list-card {
    cursor: pointer;
    margin: 0.3rem*0.8;
    width: 5.53rem*0.8;
    border-radius: 0.17rem*0.8;
    font-size: 1rem*0.8;
    font-weight: 400;
    line-height: 2.26rem*0.8;
  }

  .box-selection .selectionselect .selectionmany {
    margin-top: 1rem*0.8;
  }

  .box-selection .selectionselect .selectionmany .selectionmany-left {
    display: flex;
    align-items: center;
    flex-flow: column-reverse wrap;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-input {
    margin-left: 0.2rem*0.8;
    display: flex;
    align-items: center;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-input
    .inputBox {
    display: flex;
    align-items: center;
    border-radius: 0.17rem*0.8;
    // margin: 0px 0.2rem*0.8;
    // padding: 0px 0.4rem*0.8;
    width: 4rem;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-input
    .inputBox
    input {
    width: 4rem*0.8;
    height: 2.26rem*0.8;
    background: rgba(27, 28, 37, 0);
    color: rgb(255, 255, 255);
    font-size: 1rem*0.8;
    margin-left: 0.4rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-price {
    width: 2.4rem*0.8;
    font-size: 1rem*0.8;
    color: rgb(169, 171, 188);
    margin: 0px 0.5rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-price-down {
    border-radius: 0.17rem*0.8;
    line-height: 1.42rem*0.8;
    width: 2.57rem*0.8;
    height: 2.23rem*0.8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-price-down
    > img {
    width: 0.79rem*0.8;
    height: 0.92rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-price-down-change {
    transform: rotate(180deg);
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-price-reset {
    width: 3.5rem*0.8;
    font-size: 1rem*0.8;
    font-weight: 400;
    color: rgb(169, 171, 188);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0.8rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-price-reset
    img {
    width: 1.12rem*0.8;
    height: 1.12rem*0.8;
    margin-right: 0.1rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-price-reset:hover {
    font-weight: 400;
    color: rgb(255, 255, 255);
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-query {
    width: 23.5rem*0.8;
    height: 3.08rem*0.8;
    border-radius: 0.17rem*0.8;
    display: flex;
    align-items: center;
    padding-left: 1rem*0.8;
    margin-top: 1rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-query
    .selectionmany-query-input {
    color: rgb(255, 255, 255);
    width: 20.5rem*0.8;
    height: 3.08rem*0.8;
    font-size: 1rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-query
    .selectionmany-query-input-icon {
    width: 3.08rem*0.8;
    height: 3.08rem*0.8;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box-selection
    .selectionselect
    .selectionmany
    .selectionmany-left
    .selectionmany-query
    .selectionmany-query-input-icon
    > img {
    width: 1.3rem*0.8;
    height: 1.3rem*0.8;
  }

  .box-selection .selectionselect .selectionmany-list {
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    height: calc(-30rem*0.8 + 90vh);
    margin-top: 0.92rem*0.8;
  }

  .box-selection .selectionselect .selectionmany-list .selectionmany-list-card {
    cursor: pointer;
    margin-bottom: 0.3rem*0.8;
    margin-right: 0.3rem*0.8;
    width: 12.1rem*0.8;
    height: 15.81rem*0.9;
    border-radius: 0.42rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img {
    border-radius: 5%;
    height: 100%;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img
    .card-title {
    padding-top: 0.6rem*0.8;
    height: 2rem*0.8;
    font-size: 1.08rem*0.8;
    font-weight: 400;
    color: rgb(255, 255, 255);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img
    .card-dom {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 11.88rem*0.8;
    height: 10.21rem*0.8;
  }
  .BoxCard .skinBg{
    width: 10rem *0.8;
    height: 10rem *0.8;
  }
  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img
    .img-price {
    padding-top: 0.5rem*0.8;
    width: 100%;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img
    .img-price
    .coin {
    font-size: 1.08rem*0.8;
    font-weight: 700;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img-selected-ck {
    background: rgba(40, 42, 51, 0.88) !important;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img-selected-ck
    .card-img-selected-action {
    display: flex !important;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img-selected:hover {
    background: rgba(40, 42, 51, 0.88);
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img-selected:hover
    .card-img-selected-action {
    display: flex;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img-selected {
    background: rgba(40, 42, 51, 0);
    border-radius: 5%;
    height: 100%;
    position: relative;
    z-index: 1;
    top: -15.87rem*0.8;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img-selected
    .card-img-selected-action {
    width: 100%;
    height: 1rem*0.8;
    display: none;
    align-items: center;
    justify-content: center;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img-selected
    .card-img-selected-action
    .card-img-selected-action-icon {
    width: 1.98rem*0.8;
    height: 1.97rem*0.8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0.2rem*0.8;
    cursor: pointer;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card
    .card-img-selected
    .card-img-selected-action
    > input {
    width: 6.2rem*0.8;
    height: 2.1rem*0.8;
    border-radius: 1.05rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany-list
    .selectionmany-list-card-end {
    margin-right: 0px;
  }

  .box-selection .selectionselect .selectionmany-confirm {
    height: 5.75rem*0.8;
    border-radius: 0px 0px 1.25rem*0.8 1.25rem*0.8;
    position: absolute;
    left: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .box-selection
    .selectionselect
    .selectionmany-confirm
    .selectionmany-confirm-left {
    font-size: 1rem*0.8;
    font-weight: 700;
    color: rgb(255, 255, 255);
    line-height: 1.33rem*0.8;
    width: 18rem*0.8;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .box-selection
    .selectionselect
    .selectionmany-confirm
    .selectionmany-confirm-left
    .selectionmany-confirm-line {
    height: 0.5rem*0.8;
    width: 100%;
  }

  .box-selection
    .selectionselect
    .selectionmany-confirm
    .selectionmany-confirm-left
    .selectionmany-confirm-xz {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 1rem*0.8;
    width: 100%;
  }

  .box-selection
    .selectionselect
    .selectionmany-confirm
    .selectionmany-confirm-left
    .selectionmany-confirm-xz
    .coin {
    font-size: 1rem*0.8;
  }

  .box-selection
    .selectionselect
    .selectionmany-confirm
    .selectionmany-confirm-right {
    margin-right: 1.08rem*0.8;
    width: 13.29rem*0.8;
    height: 3.94rem*0.8;
  }

  .box-selection .el-slider {
    --el-color-primary: #ffc600;
    --el-border-color-light: #333544;
    --el-slider-border-radius: 4px;
    --el-slider-height: 0.21rem*0.8;
    --el-slider-button-size: 10px;
    --el-color-white: #ffc600;
    --el-slider-button-wrapper-size: 46px;
    --el-slider-button-wrapper-offset: -21px;
  }

  .box-selection .el-slider .el-slider__button {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAmlJREFUSEu9lk9IVFEUxn/njSBBf4Q06A9WFi1GCHVmzEWLypkKKaFN6bQsahVCEbqyso0tMqRty3LUTWQgmUouWpTzRiqwRZRSlEIZWAj9c96JNzk243ujM6Td5T3nft993zn3fE9YZPl8wWIMwij7EfGCFiH8QnkPvAWGPGJ0DQ8/HM8EI26BBLCHVpTjgGexSwAWQhdxmmKxgXcLcx0Efn+oVkVvA2uWAE4PCzNiyRnT7I+kBtIIfIFQA2gbYOQE/jdZRblgmgM3klvzBBWB6hOC2OyusuVAqIrWj0QHu+wzCbA5zUdRVrsB5XmU3dun2bL+WyL8/vMqXowXMBvPcBdhhjildk3+EASCd4CwG3hNYJJzR19TtO5HWvjTl3xu3t9Jb3Sj68cp0jkS7a+X8vIDW408Y8xN9/PHXhHe52iMNMCOoWLa7u5yI7GsWatEfJWhRlRbF2YcqZzk8snRrKRv6fDS83STM1ekSXyB4APgUGrUMJTeK48pXJsuSya2qa/51Fzai2U5atJnE9ivcnPq4bKSaW41mFndPpl0qt3P87GChWc+2ATfgfzUSG3VBM31L3MiaIl46XnikOnnfyFYFolOt/t55iqRP9iHcHDlipyhTWv3TNAczq4OVyNe7jn1B7tNy6oOb/PEZ9+sxEOLe/J2JBq3IhCKCFq3IqMip2FXODfspnIcdjaJ319dpyIdyzGuRTVsmoOd8+M6Kc1yGA7IxVi0/7rDcJIb/2KZwNnY8ICtwvzKaPpqyDVBbdNfyj4tRbrF0sasTD+VPf23hVJgw1z8I8oowiMD7Y5GB20/cV2/AZLbAzdsLiw7AAAAAElFTkSuQmCC")
      50% center / cover no-repeat;
    border: 0px;
    width: 1rem*0.8;
    height: 1rem*0.8;
  }

  .popupContent {
    padding: 1rem*0.8;
  }
}
</style>
  