<template>
  <div class="base-header">
    <!-- 电脑端 -->
    <div class="skins">
      <div class="skins-tab">
        <div class="skins-tab-left">
          <div
            class="skins-tab-card"
            :class="{ 'skins-tab-card-selected': skinType == 1 }"
            @click="toggleType(1)"
          >
            我的物品
          </div>
          <div
            class="skins-tab-card"
            :class="{ 'skins-tab-card-selected': skinType == 2 }"
            @click="toggleType(2)"
          >
            正在取回
          </div>
          <div
            class="skins-tab-card"
            :class="{ 'skins-tab-card-selected': skinType == 3 }"
            @click="toggleType(3)"
          >
            取回记录
          </div>
        </div>
        <div class="skins-tab-right _windows">
          <div class="skins-tab-right-num">共{{ total }}件饰品</div>
          <div class="skins-tab-right-line"></div>
          <div class="skins-tab-right-price">
            价值
            <div class="MoneyCoin">
              <img src="../../../assets/img/money.png" alt="" />
              <span class="moneyVal">{{ total_value }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="skins-tab-underline _windows"></div>
      <div class="skins-condition">
        <div class="skins-condition-left">
          <div class="skins-condition-card condition-time">
            <div class="skins-condition-card-show" @click="toggleTime">
              <div class="skins-condition-card-text">获得时间</div>
              <div
                class="skins-condition-card-icon card-icon-time"
                :class="{ 'card-icon-selected': time == 'asc' }"
              ></div>
            </div>
          </div>
          <div class="skins-condition-card condition-price">
            <div class="skins-condition-card-show" @click="togglePrice">
              <div class="skins-condition-card-text">价格</div>
              <div
                class="skins-condition-card-icon card-icon-time"
                :class="{ 'card-icon-selected': skins_value == 'asc' }"
              ></div>
            </div>
          </div>
        </div>
        <!-- 手机端 -->
        <div class="skins-condition-right-m _mobile" v-if="!showNav&&skinType==1">
          <div class="skins-condition-right-m-num">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAAAXNSR0IArs4c6QAAAbVJREFUOE+l07FrU1EUx/Hv7xpLU0SppdBFUFDxL1BELRmtNOBL8gSV5im4+AdIFyfBxcFNcdGatgqSmFdEjJsgMerg4qaIIIgWUXBJ0SH3SNRK8qg0vN7x3nM+99zDPQqC8pg5d0TqbI5rC1U2uBSUoicSOTO+O5jz+CYdmnG88CWNrUIYfQYmepPNzBBvhZqGf2deH51lWvX6rffrXdKt8KvE2HqBf8+XgecmWpt8p9Vuf3vVaDR+9uZ2wXsSJwYEk2E/gBWgY8ZsXKvMKZ/PjwwNbd9ljofAzpQw3TY5s4NaBQql8iVEANoBbEsDG1z8B/YCU1Ont2az2m0us1fyx0AzBp+EjYKy/7vMjGtrgsmEIIxem9nsUm3+cRBGS4JpwCXiHrRZOTsYWIoeIZ7F1crlQli+8wfSqb6v5nU+vn/7xoBgeVrSomHLoA8CDxxNVHizXq2cGwjsJoZhuMVreNJ7HUJMCh3uqxB7E1fn9w0MrtHXl4L9q/u/p6tjExsAy1eELvSPrC+mBo8Xo5POcbf/2bqaGiwWZw6Ycy8SrXiaGszlzgyPjlsT2IPZiKQMsuu/AJKanMW8qIlyAAAAAElFTkSuQmCC"
            />
            1
          </div>
          <div class="skins-condition-right-m-price">
            <div class="MoneyCoin">
              <svg data-v-0f4c779f="" class="svg-icon" aria-hidden="true">
                <use data-v-0f4c779f="" xlink:href="#icon-A8"></use></svg
              ><span class="moneyVal">5.00</span>
            </div>
          </div>
        </div>
        <!-- 电脑端 -->
        <div class="skins-condition-right _windows" v-if="skinType==1">
          <div class="skins-condition-right-select">
            <div
              v-if="checkedPrice > 0"
              @click="offAll()"
              class="skins-condition-right-select-frame skins-condition-right-select-all"
            ></div>
            <div
              class="skins-condition-right-select-frame"
              @click="selAll()"
              v-else
            ></div>
            全选
          </div>
          <div class="skins-condition-right-price">
            已选 {{ calculateSelectedSkinsCount() }} 件, 共
            <div class="MoneyCoin">
              <img src="../../../assets/img/money.png" alt="" />
              <span class="moneyVal" style="color: rgb(255, 49, 237)">{{
                checkedPrice
              }}</span>
            </div>
          </div>
          <div class="skins-condition-right-btn btn-green" @click="openPull()">
            取回
          </div>
          <div class="skins-condition-right-btn btn-yellow" @click="coniAll()">
            分解
          </div>
        </div>
      </div>
      <div class="skins-list">
        <!-- skins-list-card-selected -->
        <div
          class="skins-list-card"
          v-for="(item, index) in skinList"
          :key="index"
          @click="check(item.id)"
          :class="{ 'skins-list-card-selected': item.state }"
        >
          <div
            class="skinItem"
            font=""
            :style="{
              'background-image': `url(${getBgColor(item.color)})`,
            }"
          >
            <div class="MoneyCoin moneyCoin">
              <img src="../../../assets/img/money.png" alt="" />
              <span class="moneyVal">{{ item.price }}</span>
            </div>
            <img class="skinImage" :src="item.img" alt="" />
            <div class="nameBox">
              <p class="degree">{{ item.name }}</p>
              <p class="skinName ellipsis">{{ item.exteriorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="page"
        :page-size="pageSize"
        @current-change="currentChange"
      >
      </el-pagination>
      <!-- <div class="paging" style="">
        <div class="el-pagination">
          <button
            type="button"
            class="btn-prev is-first"
            disabled=""
            aria-label="Go to previous page"
            aria-disabled="true"
          >
            <i class="el-icon"
              ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  fill="currentColor"
                  d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z"
                ></path></svg
            ></i>
          </button>
          <ul class="el-pager">
            <li
              class="is-active number"
              aria-current="true"
              aria-label="page 1"
              tabindex="0"
            >
              1
            </li>
          </ul>
          <button
            type="button"
            class="btn-next is-last"
            disabled=""
            aria-label="Go to next page"
            aria-disabled="true"
          >
            <i class="el-icon"
              ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path
                  fill="currentColor"
                  d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"
                ></path></svg
            ></i>
          </button>
        </div>
      </div> -->
    </div>
    <div class="exchange-decoration" v-if="changeDecoration.length">
      <div class="content">
        <span class="title">确定要回收吗?</span>
        <!-- <div class="types">
					<div @click="exchangeType = 'dollar'" class="btn" :class="{
              'active-btn': exchangeType == 'dollar',
            }">
						<span>金币：</span>
						<img class="dollar" src="../../../assets/img/money.png" />
						<span>{{ checkedPrice }}</span>
					</div>
					<div class="btn" @click="exchangeType = 'diamond'" :class="{
              'active-btn': exchangeType == 'diamond',
            }">
						<span>钻石：</span>
						<img class="diamond" src="../../../assets/img/masonry.png" />
						<span>{{ checkedPrice }}</span>
					</div>
				</div> -->
        <div class="operate">
          <div @click="NoExchange">取消</div>
          <div @click="startExchange">确认</div>
        </div>
      </div>
    </div>
    <div
      class="exchange-decoration"
      v-if="changeDecorations.length"
    >
      <div class="content">
        <span class="title">确定要提取吗?</span>
        <div class="operate">
          <div @click="NoExchange">取消</div>
          <div @click="getPull">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "./../../../assets/js/util.js";
export default {
  name: "base-header",
  props: ["type", "showNav"],
  data() {
    return {
      loading: false,
      total: 0,
      total_value: 0,
      skinList: [],
      pageSize: 15,
      exchangeHint: "",
      checkedPrice: 0,
      random: "",
      exchangeType: "diamond",
      changeDecoration: [],
      page: 1,
      changeDecorations: [],
      steamIds:[],
      randoms: [],
      skinType: 1, //1我的物品 2正在取回 3取回记录
      time: "desc",
      skins_value: "desc",
      red: require("../../../assets/img/skinbg1.535a460d.png"),
      grey: require("../../../assets/img/skinbg4.c3e49d82.png"),
      orange: require("../../../assets/img/skinbg0.cadc763a.png"),
      gold: require("../../../assets/img/skinbg0.cadc763a.png"),
      blue: require("../../../assets/img/skinbg3.14609471.png"),
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getBgColor(value) {
      if (value == undefined) {
        return this.grey;
      }
      switch (value.toUpperCase()) {
        // 灰色
        case "#B2B2B2":
          // return `background: url("../assets/img/skinbg4.c3e49d82.png") no-repeat;`;
          return this.grey;
        // 红色
        case "#EB4B4B":
          // return ` background: url("../assets/img/skinbg1.535a460d.png") no-repeat;`;
          return this.red;
        // 橙色
        case "#CF6A32":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.orange;
        // 金色
        case "#E4AE39":
        case "#FFD700":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.gold;
        // 蓝色
        case "#4B69FF":
          // return `background: url("../assets/img/skinbg3.14609471.png") no-repeat;`;
          return this.blue;
      }
    },
    toggleTime() {
      this.time = this.time == "asc" ? "desc" : "asc";
      this.page = 1;
      this.getList();
    },
    togglePrice() {
      this.skins_value = this.skins_value == "asc" ? "desc" : "asc";
      this.page = 1;
      this.getList();
    },
    //兑换多个
    coniAll() {
      if (!Number.parseFloat(this.checkedPrice)) {
        this.$message.error("请选中饰品");
        return;
      }
      var arr = [];
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state) {
          arr.push(this.skinList[i].id);
        }
      }
      this.changeDecoration = arr;
    },
    // 提取多个
    conisAll() {
      if (!Number.parseFloat(this.checkedPrice)) {
        this.$message.error("请选中饰品");
        return;
      }
      var arr = [];
      var arrs = [];
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state) {
          arr.push(this.skinList[i].id);
          arrs.push(this.skinList[i].itemId);
        }
      }
      this.changeDecoration = arr;
      this.changeDecorations = arrs;
    },
    //单个选中
    check(id) {
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].id == id) {
          this.skinList[i].state = !this.skinList[i].state;
          if (this.skinList[i].state == true && !this.skinList[i].state1) {
            this.checkedPrice = (
              parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
            ).toFixed(2);
          } else {
            this.checkedPrice = (
              parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
            ).toFixed(2);
          }
        }
      }
      this.$forceUpdate();
    },
    // 统计选中数量
    calculateSelectedSkinsCount() {
      let count = 0;
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state === true) {
          count++;
        }
      }
      return count;
    },
    //选择当前页
    selAll() {
      this.checkedPrice = 0;
      for (let i = 0; i < this.skinList.length; i++) {
        if (!this.skinList[i].state1) {
          this.skinList[i].state = true;
        }
        this.checkedPrice = (
          parseFloat(this.checkedPrice) + parseFloat(this.skinList[i].price)
        ).toFixed(2);
      }
      this.$forceUpdate();
    },
    //取消当前页
    offAll() {
      this.checkedPrice = 0;
      for (let i = 0; i < this.skinList.length; i++) {
        this.skinList[i].state = false;
        // this.checkedPrice = (
        //   parseFloat(this.checkedPrice) - parseFloat(this.skinList[i].price)
        // ).toFixed(2);
      }
      this.$forceUpdate();
      this.tiqu = false;
    },
    /**
     * 兑换成 钻石
     * @param {*} id
     */
    exchangeToMoney(id) {
      let param = {
        player_skins_ids: id,
      };
      this.$axios
        .post("/index/User/exchangeToMoney", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.changeDecoration = [];
          } else {
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: data.msg,
              type: "warning",
            });
          }
        });
    },
    /**
     * 兑换成 金币
     * @param {*} id
     */
    exchangeToMoney2(id) {
      let param = {
        player_skins_ids: id,
      };
      this.$axios
        .post("/index/User/exchangeToMoney2", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$store.commit("getMoney", res.data.data.total_amount);
            Utils.$emit("money", res.data.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.changeDecoration = [];
          } else {
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: data.msg,
              type: "warning",
            });
          }
        });
    },
    openPull(){
      if (!Number.parseFloat(this.checkedPrice)) {
        this.$message.error("请选中饰品");
        return;
      }
      var arr = [];
      var steamId = []
      var randomString = []
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state) {
          arr.push(this.skinList[i].id);
          steamId.push(this.skinList[i].itemId);
          randomString.push(this.randomString());
        }
      }
      this.steamIds = steamId;
      this.randoms = randomString;
      this.changeDecorations = arr;
      console.log(this.steamIds,this.randoms,this.changeDecorations,'ceshi1' )
    },
    //点击取回
    getPull() {
      // for (let i = 0; i < this.skinList.length; i++) {
      //   if (id == this.skinList[i].id) {
      //     this.skinList[i].loading1 = true;
      //     this.skinList[i].loading2 = true;
      //     this.skinList[i].state1 = true;
      //   }
      // }
      // this.$forceUpdate();
      let param = {
        player_skins_id: this.changeDecorations,
        itemId: this.steamIds,
        random: this.randoms
      };
      this.$axios
        .post("/index/User/skinToSteam1", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.changeDecorations = []
            this.getList(this.page);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          } else {
            var hint = "";
            if (data.msg.indexOf("余额不足") != -1) {
              hint = "取回错误，请联系客服";
            } else if (data.msg.indexOf("取回失败,请充值") != -1) {
              hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
            } else {
              hint = data.msg;
            }
            this.$message({
              showClose: true,
              message: hint,
              type: "warning",
            });
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
                this.skinList[i].state1 = false;
              }
            }
            this.$forceUpdate();
          }
          this.randomString();
        });
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      // this.random = n;
      return n;
    },
    //点击兑换
    getExchange(event, id) {
      event.stopPropagation();
      for (let i = 0; i < this.skinList.length; i++) {
        if (this.skinList[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.skinList.length; i++) {
        if (id == this.skinList[i].id) {
          this.skinList[i].loading1 = true;
          this.skinList[i].loading2 = true;
          this.checkedPrice = this.skinList[i].price;
        }
      }
      this.$forceUpdate();
      this.changeDecoration = [id];
    },
    startExchange() {
      // 兑换金币
      if (this.exchangeType == "dollar") {
        this.exchangeToMoney2(this.changeDecoration);
      } else {
        // 兑换钻石
        this.exchangeToMoney(this.changeDecoration);
      }
      this.checkedPrice = 0;
    },
    //点击取回
    getPulls(id, steamId) {
      for (let i = 0; i < this.skinList.length; i++) {
        if (id == this.skinList[i].id) {
          this.skinList[i].loading1 = true;
          this.skinList[i].loading2 = true;
          this.skinList[i].state1 = true;
        }
      }
      this.$forceUpdate();
      let param = {
        player_skins_id: id,
        itemId: steamId,
        random: this.random,
      };
      this.$axios
        .post("/index/User/skinToSteam1", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          } else {
            var hint = "";
            if (data.msg.indexOf("余额不足") != -1) {
              hint = "取回错误，请联系客服";
            } else if (data.msg.indexOf("取回失败,请充值") != -1) {
              hint = "为防止机器人恶意注册，进行任意充值即可开箱提取哦~";
            } else {
              hint = data.msg;
            }
            this.$message({
              showClose: true,
              message: hint,
              type: "warning",
            });
            for (let i = 0; i < this.skinList.length; i++) {
              if (id == this.skinList[i].id) {
                this.skinList[i].loading1 = false;
                this.skinList[i].loading2 = false;
                this.skinList[i].state1 = false;
              }
            }
            this.$forceUpdate();
          }
          this.randomString();
        });
    },
    startExchanges() {
      // 兑换
      this.getPulls(this.changeDecoration, this.changeDecorations);
      this.checkedPrice = 0;
    },
    NoExchange() {
      this.changeDecoration.forEach((e) => {
        for (let i = 0; i < this.skinList.length; i++) {
          if (e == this.skinList[i].id) {
            this.skinList[i].loading1 = false;
            this.skinList[i].loading2 = false;
          }
        }
      });
      this.$forceUpdate();
      this.changeDecoration = [];
      this.changeDecorations = [];
    },
    currentChange(val) {
      this.page = val;
      this.getList();
      this.checkedPrice = 0;
    },
    getList() {
      // this.page = page;
      let param = {
        pageSize: this.pageSize,
        page: this.page,
        time: this.time,
        skins_value: this.skins_value,
      };
      var url = "/index/User/packageList";
      if (this.skinType == 2) {
        url = "/index/User/backList";
      }
      if (this.skinType == 3) {
        url = "/index/User/processingList";
      }
      this.$axios.post(url, this.$qs.stringify(param)).then((res) => {
        var data = res.data;
        if (data.status == "1") {
          this.total = data.data.total;
          this.total_value = data.data.total_value;
          this.skinList = data.data.skinList;
        } else {
          this.skinList = [];
          this.total = 0;
          this.total_value = 0;
        }
      });
    },
    toggleType(number) {
      console.log(number);
      this.skinType = number;
      // 重置当前页码
      this.page = 1;
      // 重新请求接口
      this.getList();
      this.offAll()
    },
  },
};
</script>

<style lang="less" scoped>
.base-header {
  font-family: "微软雅黑";

  .exchange-decoration {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999999999;
    background-color: rgba(0, 0, 0, 0.6);
    display: grid;
    place-items: center;

    .content {
      width: 80%;
      max-width: 500px;
      background-color: #1b2330;
      border-radius: 10px;
      color: #fff;
      text-align: center;
      box-sizing: border-box;
      padding: 30px 0px 0 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 20px;
        flex: 1;
        font-weight: bold;
        margin: 0 0 30px 0;
      }

      .types {
        width: 70%;
        margin-bottom: 50px;

        .active-btn {
          background-color: #3088ff;
          color: #000 !important;
          font-weight: bold;
        }

        .btn {
          margin: 10px 0;
          height: 40px;
          line-height: 40px;
          color: #fff;
          cursor: pointer;
          border-radius: 8px;
          border: 1px solid #2b3441;
          display: flex;
          justify-content: center;
          align-items: center;

          .dollar {
            width: 20px;
            height: 20px;
            margin-right: 3px;
          }

          .diamond {
            width: 17px;
            margin-right: 3px;
            height: 17px;
          }
        }
      }

      .operate {
        width: 100%;
        height: 50px;
        font-weight: bold;
        display: flex;
        border-top: 1px solid #000;

        & > div {
          flex: 1;
          display: grid;
          place-items: center;
          cursor: pointer;

          &:first-child {
            border-right: 1px solid #000;
          }
        }
      }
    }
  }
}
.skins {
  padding: 0.5rem * 1.4 1rem * 1.4;
  max-width: 61.23rem * 1.4;
  border-radius: 0.25rem * 1.4;
  font-size: 0.58rem * 1.4;
  font-weight: 400;
  color: #fff;
}

.skins .skins-tab {
  display: flex;
  justify-content: space-between;
  margin-top: 0.6rem * 1.4;
}

.skins .skins-tab .skins-tab-left {
  display: flex;
}

.skins .skins-tab .skins-tab-left .skins-tab-card {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 0.58rem * 1.4;
  font-weight: 400;
  color: #fff;
  min-width: 3.5rem * 1.4;
  height: 1.8rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding: 0 0.33rem * 1.4;
}

.skins .skins-tab .skins-tab-left .skins-tab-card-selected {
  color: #ff31ed;
  font-weight: 700;
}

.skins
  .skins-tab
  .skins-tab-left
  .skins-tab-card-selected[data-v-006d0570]:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 100%;
  background-color: #ff31ed;
  z-index: 10;
}

.skins .skins-tab .skins-tab-right {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-0.22rem * 1.4);
  color: #a9abbc;
}

.skins .skins-tab .skins-tab-right .skins-tab-right-num > span {
  color: #fff;
  padding: 0 0.2rem * 1.4;
}

.skins .skins-tab .skins-tab-right .skins-tab-right-line {
  height: 0.7rem * 1.4;
  opacity: 0.5;
  border-left: 0.1rem * 1.4 solid #666;
  margin: 0 0.3rem * 1.4;
  transform: translateY(0.05rem * 1.4);
}

.skins .skins-tab .skins-tab-right .skins-tab-right-price {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skins .skins-tab .skins-tab-right .skins-tab-right-price .MoneyCoin {
  margin-left: 0.2rem * 1.4;
}

.skins .skins-tab-underline {
  width: 100%;
  border-bottom: 0.1rem * 1.4 solid #343a45;
  transform: translateY(-0.1rem * 1.4);
  z-index: 0;
}

.skins .skins-condition {
  display: flex;
  justify-content: space-between;
  margin-top: 0.6rem * 1.4;
}

.skins .skins-condition .skins-condition-left {
  display: flex;
}

.skins .skins-condition .skins-condition-left .skins-condition-card {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 3.48rem * 1.4;
  height: 1.56rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.17rem * 1.4;
  margin-right: 0.17rem * 1.4;
  padding: 0.2rem * 1.4;
  text-align: center;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show {
  height: 1.56rem * 1.4;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .skins-condition-card-icon {
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 0.22rem * 1.4;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .card-icon-selected {
  transform: rotate(180deg);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .card-icon-price[data-v-006d0570],
.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .card-icon-time {
  width: 0.5rem * 1.4;
  height: 0.58rem * 1.4;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAAAAXNSR0IArs4c6QAAAYNJREFUSEvtlrsuBVEYhb8VnkCikGhUEoVKR0GCxKXQSfACIqFUEIlQqEThCQiFSqPTuCQavINEQikuhUSy5MjMydjOzOxpThRnuj2z9v/NWv/M3ls06VKTOPw/kO02YAkYAA4knVdJI9qR7RVgLyn+CfRKeoiFVQEdAXOZwrOSTpoBmpd03AKVJmA77FErutLUfgSt6MKc6iuD7Q5gELiV9BQKY6Oz3Q90AheSvtI6PyDb3cB9IngFpiRdZ2ExINubwEYy70zSdAiqrcr7mcJvwGQWVgayvQWsB0l0SXqu3UsdDQGX6TgR/4IVgXIgj0BPGl+2R6vATvBG78BEzVkeyPY2sBbMewFGJd39ii4d2M6FAYvBNjEP9MVA6tEFTc+D3QBjGe0pMFPmpKGjEme1T7W9YB35E1dWm7vD5sSYxymENIwuIsYQVgopBSU/c6OepbAoSBSoAPYBDEu6Lehb/VGVU9AhsJDMNDBe5WwXDUqcLQMjwK6kqxgnhZ93lQKx2m/Yd8of8iKbbwAAAABJRU5ErkJggg==);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-show
  .card-icon-rotate {
  width: 0.46rem * 1.4;
  height: 0.29rem * 1.4;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAAAXNSR0IArs4c6QAAAh5JREFUOE+1lE1oE1EUhc+dSdMa0Ej8K0HUlS3uFHSnICgKFUGELMTubJIaDZSC2trM3CQ1VpEKrTZNS81ai4K6iEW3guBKxO7EhYtIocVFCWZM58oEEzLTtB2RvuV7537nvfvzCJu8aJP5qBswTx0se43Fu4PXFv/HlHl6L2BUmGM/LE7VQE9NZgFEAfwSkXhK753+ZxMR0tO5BwT0CSBCFE8lIo9pIPNkl7diLNSAZB2CBpNaZMStCTN7RGnPA7jcwCmyFg3S9bGx1sBPr2WwzQ6k+8lE+BaIZD0j5nybKMZTQM7bdfIxqfUeq6aI05NnRfAcgM8Bm5rvDFydDYVWmpncuDez1Vf+/VKAk43nBBRh4hRzdL5e5ERq4rgC5TUAvwP2jMxAN3PIaNwfyDza4a2oBYCOOvRfV0g9PZzo+VYvck2QSGUPq6CCAHscQW/IpIvMkVL1xTweFKXlLYBDjpt/NlQ6k7kdKTbUwo4aSk90eESdE8h+R/B7mK3ngNJOUVQLfsAWKfhA4ulivrLkiFudXV3P7YMqcwA6HeJPf1/X7ijoOzLNC8yxZSdtzUlmzu4WlQoQHNmgXV8sbTcujcfj5Wa6db+KmyM5f1tZXoFwomkwIf+lI9CzVpetKnIzSN/o6Bb/sm9WgC5buoQeshbu32hOXH124VyuJbiAGRHpBmASoLMWHXYz6a4MaiCrw4SkdGco9t0N3NL8AWK5uhGadozgAAAAAElFTkSuQmCC);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide {
  display: none;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide
  .skins-condition-card-hide-select {
  width: 5.12rem * 1.4;
  height: 1.87rem * 1.4;
  background: #30333d;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide
  .hide-select-selected[data-v-006d0570],
.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide
  .skins-condition-card-hide-select[data-v-006d0570]:hover {
  background: #434652;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide
  .hide-select-first {
  border-radius: 0.25rem * 1.4 0.25rem * 1.4 0 0;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card
  .skins-condition-card-hide
  .hide-select-end {
  border-radius: 0 0 0.25rem * 1.4 0.25rem * 1.4;
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card[data-v-006d0570]:hover {
  background: hsla(0, 0%, 100%, 0.08);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card:hover
  .card-icon-rotate {
  transform: rotate(180deg);
}

.skins
  .skins-condition
  .skins-condition-left
  .skins-condition-card:hover
  .skins-condition-card-hide {
  border-radius: 0.5rem * 1.4;
  width: 5.12rem * 1.4;
  background-color: #3b3d47;
  display: block;
  position: relative;
  z-index: 9;
}

.skins .skins-condition .skins-condition-left .condition-time {
  width: 4.46rem * 1.4;
}

.skins .skins-condition .skins-condition-right {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.58rem * 1.4;
  position: relative;
}

.skins .skins-condition .skins-condition-right .skins-condition-right-select {
  margin-left: 0.92rem * 1.4;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}

.skins
  .skins-condition
  .skins-condition-right
  .skins-condition-right-select
  .skins-condition-right-select-frame {
  width: 0.4rem * 1.4;
  height: 0.4rem * 1.4;
  border: 0.09rem * 1.4 solid #82858d;
  border-radius: 20%;
  margin-right: 0.2rem * 1.4;
}

.skins
  .skins-condition
  .skins-condition-right
  .skins-condition-right-select
  .skins-condition-right-select-all {
  border: 0;
  width: 0.6rem * 1.4;
  height: 0.6rem * 1.4;
  background-image: url(/img/xuanzhong.7af5e1de.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}

.skins .skins-condition .skins-condition-right .skins-condition-right-price {
  margin-left: 0.92rem * 1.4;
  margin-right: 0.62rem * 1.4;
  display: flex;
}

.skins
  .skins-condition
  .skins-condition-right
  .skins-condition-right-price
  .MoneyCoin {
  margin-left: 0.2rem * 1.4;
}

.skins .skins-condition .skins-condition-right .skins-condition-right-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  min-width: 3.17rem * 1.4;
  height: 1.42rem * 1.4;
  border-radius: 0.71rem * 1.4;
  margin-left: 0.29rem * 1.4;
  padding: 0.2rem * 1.4 0.33rem * 1.4;
  box-sizing: border-box;
}

.skins .skins-condition .skins-condition-right .btn-green {
  background: rgba(105, 23, 255, 0.1);
  border: 2px solid #6917ff;
}

.skins .skins-condition .skins-condition-right .btn-yellow {
  background: rgba(160, 38, 155, 0.1);
  border: 2px solid #ff31ed;
}

.skins .skins-list {
  margin-top: 0.4rem * 1.4;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  min-height: 25rem * 1.4;
  overflow-y: auto;
}

.skins .skins-list .skins-list-card {
  font-size: 1.18rem * 1.4;
  margin-bottom: 0.33rem * 1.4;
  box-sizing: border-box;
  border: 0.01rem * 1.4 solid hsla(0, 0%, 100%, 0);
  border-radius: 10%;
  width: 20%;
  padding: 0 0.33rem * 1.4;
}

@media screen and (max-width: 1350px) {
  .skins .skins-list .skins-list-card {
    width: 25%;
  }
}

@media screen and (max-width: 1100px) {
  .skins .skins-list .skins-list-card {
    width: 33.333%;
  }
}

.skins .skins-list .skins-list-card.nameBox .degree {
  font-size: 0.5rem * 1.4;
}

.skins .skins-list .skins-list-card.nameBox .skinName {
  font-size: 0.58rem * 1.4;
}

.skins .skins-list .skins-list-card.skinItem {
  width: 100%;
}

.skins .skins-list .skins-list-card-selected.skinItem {
  border: 2px solid #ff31ed;
  border-radius: 10%;
}

@media screen and (max-width: 992px) {
  .skins {
    padding: 0.5rem * 0.8 1rem * 0.8;
    max-width: 61.23rem * 0.8;
    border-radius: 0.25rem * 0.8;
    font-size: 0.58rem * 0.8;
    font-weight: 400;
    color: #fff;
  }

  .skins.BackTop {
    background: none;
  }

  .skins.BackTop .left {
    padding-left: 0;
  }

  .skins .skins-tab {
    display: flex;
    justify-content: space-between;
    margin-top: 0.6rem * 0.8;
    margin-left: 0.2rem * 0.8;
  }

  .skins .skins-tab .skins-tab-left {
    display: flex;
  }

  .skins .skins-tab .skins-tab-left .skins-tab-card {
    padding: 0 0.5rem * 0.8;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 1.08rem * 0.8;
    font-weight: 400;
    min-width: 5rem * 0.8;
    height: 1.8rem * 0.8;
    flex-wrap: wrap;
  }

  .skins .skins-tab .skins-tab-left .skins-tab-card[data-v-006d0570]:after {
    bottom: -0.3rem * 0.8;
    width: 80%;
  }

  .skins .skins-tab .skins-tab-left .skins-tab-card-selected {
    font-weight: 700;
  }

  .skins .skins-tab-underline {
    width: 100%;
    border-bottom: 0.1rem * 0.8 solid #343a45;
    transform: translateY(-0.25rem * 0.8);
    z-index: 0;
  }

  .skins .skins-condition {
    margin-left: 0.2rem * 0.8;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem * 0.8;
  }

  .skins .skins-condition .skins-condition-left {
    display: flex;
  }

  .skins .skins-condition .skins-condition-left .skins-condition-card {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    margin-right: 0.17rem * 0.8;
    width: 5rem * 0.8;
    height: 2.5rem * 0.8;
    border-radius: 0.25rem * 0.8;
    font-size: 1rem * 0.8;
    font-weight: 400;
    color: #fff;
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-show {
    height: 2.5rem * 0.8;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-show
    .skins-condition-card-icon {
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 0.22rem * 0.8;
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-show
    .card-icon-selected {
    transform: rotate(180deg);
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-show
    .card-icon-price[data-v-006d0570],
  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-show
    .card-icon-time {
    width: 1.06rem * 0.8;
    height: 1.23rem * 0.8;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAAAAXNSR0IArs4c6QAAAYNJREFUSEvtlrsuBVEYhb8VnkCikGhUEoVKR0GCxKXQSfACIqFUEIlQqEThCQiFSqPTuCQavINEQikuhUSy5MjMydjOzOxpThRnuj2z9v/NWv/M3ls06VKTOPw/kO02YAkYAA4knVdJI9qR7RVgLyn+CfRKeoiFVQEdAXOZwrOSTpoBmpd03AKVJmA77FErutLUfgSt6MKc6iuD7Q5gELiV9BQKY6Oz3Q90AheSvtI6PyDb3cB9IngFpiRdZ2ExINubwEYy70zSdAiqrcr7mcJvwGQWVgayvQWsB0l0SXqu3UsdDQGX6TgR/4IVgXIgj0BPGl+2R6vATvBG78BEzVkeyPY2sBbMewFGJd39ii4d2M6FAYvBNjEP9MVA6tEFTc+D3QBjGe0pMFPmpKGjEme1T7W9YB35E1dWm7vD5sSYxymENIwuIsYQVgopBSU/c6OepbAoSBSoAPYBDEu6Lehb/VGVU9AhsJDMNDBe5WwXDUqcLQMjwK6kqxgnhZ93lQKx2m/Yd8of8iKbbwAAAABJRU5ErkJggg==);
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-show
    .card-icon-rotate {
    width: 0.46rem * 0.8;
    height: 0.29rem * 0.8;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAAAXNSR0IArs4c6QAAAh5JREFUOE+1lE1oE1EUhc+dSdMa0Ej8K0HUlS3uFHSnICgKFUGELMTubJIaDZSC2trM3CQ1VpEKrTZNS81ai4K6iEW3guBKxO7EhYtIocVFCWZM58oEEzLTtB2RvuV7537nvfvzCJu8aJP5qBswTx0se43Fu4PXFv/HlHl6L2BUmGM/LE7VQE9NZgFEAfwSkXhK753+ZxMR0tO5BwT0CSBCFE8lIo9pIPNkl7diLNSAZB2CBpNaZMStCTN7RGnPA7jcwCmyFg3S9bGx1sBPr2WwzQ6k+8lE+BaIZD0j5nybKMZTQM7bdfIxqfUeq6aI05NnRfAcgM8Bm5rvDFydDYVWmpncuDez1Vf+/VKAk43nBBRh4hRzdL5e5ERq4rgC5TUAvwP2jMxAN3PIaNwfyDza4a2oBYCOOvRfV0g9PZzo+VYvck2QSGUPq6CCAHscQW/IpIvMkVL1xTweFKXlLYBDjpt/NlQ6k7kdKTbUwo4aSk90eESdE8h+R/B7mK3ngNJOUVQLfsAWKfhA4ulivrLkiFudXV3P7YMqcwA6HeJPf1/X7ijoOzLNC8yxZSdtzUlmzu4WlQoQHNmgXV8sbTcujcfj5Wa6db+KmyM5f1tZXoFwomkwIf+lI9CzVpetKnIzSN/o6Bb/sm9WgC5buoQeshbu32hOXH124VyuJbiAGRHpBmASoLMWHXYz6a4MaiCrw4SkdGco9t0N3NL8AWK5uhGadozgAAAAAElFTkSuQmCC);
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-hide {
    display: none;
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-hide
    .skins-condition-card-hide-select {
    width: 5.12rem * 0.8;
    height: 1.87rem * 0.8;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-hide
    .hide-select-first {
    border-radius: 0.25rem * 0.8 0.25rem * 0.8 0 0;
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card
    .skins-condition-card-hide
    .hide-select-end {
    border-radius: 0 0 0.25rem * 0.8 0.25rem * 0.8;
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card:hover
    .card-icon-rotate {
    transform: rotate(180deg);
  }

  .skins
    .skins-condition
    .skins-condition-left
    .skins-condition-card:hover
    .skins-condition-card-hide {
    border-radius: 0.5rem * 0.8;
    width: 7.1rem * 0.8;
    background-color: #3b3d47;
    display: block;
    position: relative;
    z-index: 9;
  }

  .skins .skins-condition .skins-condition-left .condition-time {
    width: 7.1rem * 0.8;
  }

  .skins .skins-condition .skins-condition-right-m {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 11rem * 0.8;
    margin-right: 0.5rem * 0.8;
  }

  .skins
    .skins-condition
    .skins-condition-right-m
    .skins-condition-right-m-num {
    font-size: 1rem * 0.8;
    color: #a9abbc;
  }

  .skins
    .skins-condition
    .skins-condition-right-m
    .skins-condition-right-m-num
    > img {
    width: 1rem * 0.8;
    height: 0.84rem * 0.8;
  }

  .skins
    .skins-condition
    .skins-condition-right-m
    .skins-condition-right-m-price {
    font-size: 1rem * 0.8;
  }

  .skins .skins-list {
    margin-top: 0.4rem * 0.8;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .skins .skins-list .skins-list-card {
    width: 49%;
    height: 13.5rem * 0.8;
    font-size: 2.05rem * 0.8;
    margin: 0.5rem * 0.8 0;
    box-sizing: border-box;
    border: 0.1rem * 0.8 solid hsla(0, 0%, 100%, 0);
    border-radius: 10%;
  }

  .skins .skins-list .skins-list-card.nameBox .degree,
  .skins .skins-list .skins-list-card.nameBox .skinName {
    font-size: 1rem * 0.8;
  }

  .skins .skins-condition-border {
    height: 6rem * 0.8;
  }

  .skins .skins-condition-action {
    position: fixed;
    bottom: calc(var(--mobil-tabbar-height) + 0.5rem * 0.8);
    left: 1rem * 0.8;
    width: 29.4rem * 0.8;
    height: 7.08rem * 0.8;
    background: rgba(29, 34, 46, 0.95);
    border-radius: 0.83rem * 0.8 0.83rem * 0.8 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .skins .skins-condition-action .skins-condition-action-select {
    font-size: 1rem * 0.8;
    width: 100%;
    margin-left: 0.92rem * 0.8;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  .skins .skins-condition-action .skins-condition-action-select .selectAllBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .skins
    .skins-condition-action
    .skins-condition-action-select
    .skins-condition-action-select-frame {
    font-size: 1rem * 0.8;
    width: 1.17rem * 0.8;
    height: 1.17rem * 0.8;
    border: 0.09rem * 0.8 solid #82858d;
    border-radius: 20%;
    margin-right: 0.2rem * 0.8;
  }

  .skins
    .skins-condition-action
    .skins-condition-action-select
    .skins-condition-action-select-all {
    font-size: 1rem * 0.8;
    border: 0;
    width: 1.4rem * 0.8;
    height: 1.4rem * 0.8;
    background-image: url(/img/xuanzhong.7af5e1de.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .skins .skins-condition-action .skins-condition-action-price {
    display: flex;
    font-size: 1rem * 0.8;
    margin: 0.2rem * 0.8 0 0.2rem * 0.8 0.92rem * 0.8;
  }

  .skins .skins-condition-action .skins-condition-action-price .MoneyCoin {
    margin-left: 0.2rem * 0.8;
  }

  .skins .skins-condition-action .skins-condition-action-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .skins
    .skins-condition-action
    .skins-condition-action-button
    .skins-condition-action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    min-width: 5.77rem * 0.8;
    height: 2.76rem * 0.8;
    border-radius: 1.38rem * 0.8;
    margin-left: 0.29rem * 0.8;
    margin-right: 1rem * 0.8;
    font-size: 1.08rem * 0.8;
    font-weight: 700;
    box-sizing: border-box;
    padding: 0.2rem * 0.8 0.5rem * 0.8;
  }

  .skins .skins-condition-action .skins-condition-action-button .btn-green {
    background: rgba(105, 23, 255, 0.1);
    border: 2px solid #6917ff;
  }

  .skins .skins-condition-action .skins-condition-action-button .btn-yellow {
    background: rgba(160, 38, 155, 0.1);
    border: 2px solid #ff31ed;
  }
}
.MoneyCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.skinItem {
  width: 6.87em;
  height: 6.53em;
  overflow: hidden;
  border-radius: 0.42em;
  background: hsla(0, 0%, 100%, 0.05) no-repeat;
  position: relative;
  box-sizing: border-box;
  text-transform: none;
  background-size: cover;
}

.skinItem:after {
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--color);
}

.skinItem .moneyCoin {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  color: #fff;
  font-size: 0.5em;
}

.skinItem .rollNumber {
  font-size: 0.5em;
  padding: 0.5em;
}

.skinItem .error {
  font-size: 0.6em;
  color: red;
  font-weight: 700;
  position: absolute;
  background-color: #f0f0f0;
  border-radius: 8px 8px 8px 0;
  padding: 0.33em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-block;
  top: 1.5em;
  left: 0;
  z-index: 1;
}

.skinItem .error:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #f0f0f0 transparent;
  bottom: -10px;
  left: 0;
}

.skinItem .skinImage {
  width: 4.58em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skinItem .nameBox {
  position: absolute;
  bottom: 0.3em;
  left: 0.4em;
  width: 90%;
}

.skinItem .nameBox .tips {
  font-size: 0.6em;
  color: #fbc600;
}

.skinItem .nameBox .degree {
  font-size: 0.5em;
  color: #a9abbc;
}

.skinItem .nameBox .skinName {
  font-size: 0.58em;
  color: #fff;
  margin: 0;
}
.skins .skins-tab .skins-tab-left .skins-tab-card-selected:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 100%;
  background-color: #ff31ed;
  z-index: 10;
}
.skins .skins-list .skins-list-card-selected .skinItem {
  border: 2px solid #ff31ed;
  border-radius: 10%;
}
.skins
  .skins-condition
  .skins-condition-right
  .skins-condition-right-select
  .skins-condition-right-select-all {
  border: 0;
  width: 0.6rem * 1.4;
  height: 0.6rem * 1.4;
  background-image: url("../../../assets/img/mes/xuanzhong.7af5e1de.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}
</style>
