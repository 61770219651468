<template>
  <div class="me">
    <div class="informationMobile pagesView routerView">
      <div class="header">
        <div class="avatar">
          <div
            class="pic"
            @click="avatarDialog = true"
            :style="{
              'background-image': `url(${Data.img})`,
            }"
          ></div>
          <div class="nameBox">
            <div class="username">
              <span>{{ Data.name }}</span
              ><span class="edit" @click="editUserName">编辑</span>
            </div>
            <div class="vipLevel">
              <img src="../assets/img/mess/V0.png" alt="" />
            </div>
            <div class="invitationCode">
              邀请码
              <div class="Copy" @click="copyText(valueUrl)">
                {{ Data.invite_code }}
              </div>
            </div>
          </div>
        </div>
        <div class="money">
          <div class="balance">
            <div class="moneyVal">
              余额：
              <div class="MoneyCoin">
                <img src="../assets/img/money.png" alt="" />
                <span class="moneyVal" style="color: rgb(255, 49, 237)">{{
                  Data.total_amount
                }}</span>
              </div>
            </div>
          </div>
          <div class="btnBox">
            <div class="recharge _btnClick1" @click="recharge">充值</div>
            <div
              class="recharge realName _btnClick1"
              @click="realNameDialog = true"
            >
              实名认证
            </div>
          </div>
        </div>
        <div class="linkBox">
          <div class="linkItem" @click="$router.push('/profile/skins')">
            <img
              class="icon"
              src="../assets/img/mess/wupinmobile.fd687c17.png"
              alt=""
            /><span>我的物品</span>
            <i class="el-icon-arrow-right svg-icon svgIcon"></i>
          </div>
          <div class="linkItem" @click="$router.push('/share')">
            <img
              class="icon"
              src="../assets/img/mess/yongjinmobile.0c1a8e4a.png"
              alt=""
            /><span>我的佣金</span
            ><i class="el-icon-arrow-right svg-icon svgIcon"></i>
          </div>
          <div class="linkItem" @click="$router.push('/profile/income')">
            <img
              class="icon"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAjCAYAAAAJ+yOQAAAAAXNSR0IArs4c6QAAA0RJREFUWEfVmFtoXFUUhr//5CYVLCXJzKDFCyqiUOprvVAELwj6XCpYrdUwMw1C64MgfSz0XSSTtKQo+mBFwVrEVn2otxZExbylSJC+1CaO7UsvMcmc36zJREaTFmQ65syGDYd1Zvb+z7/2+vdaSzSN0oC3KuFZxBOGu4Gbm9+3+fmyYArzhVOOVar6ank/xcPwgG9Nu3jU8Lhgs+EOYAPQ02ZgzcvPAxcFZw0Tgi+TGt+8VdW5Oshy3tsMrwJbeoQWDP4f0TUz1i2Yd33704I3R6Z1RKVBP43YhnhekMQf1gLgP1y7hCHFvIs5olLOFVRncPNaMfhvp4V7g9E5MyFzSqWCz2D6Bf1ryeBqQA1/WFRVyvtqAG/MNTiJ190ynLugct5ZInBVxMFkmjX6Vri+lPeVzIMs5n0g8yCzDjDwaWiD12cdaATOG5kH2RESNFzoDJ3sCDHvXJCRiXQ1cre4QJevpevZE0EXUIt5Az89onu15cI2C5yXuGhzZyNTl2FWMI2oYm4H+lnKQ/+0+T3eSWw0DMR33gjlWAEymIrMA/jF5oOkm1NO2YvZauiTmUJ8ZPg8gWHDU8A64FfBsQSO1mAX8AxwS2O9lrCuABkurpmrgo8Nh+d6+KlvjtcttgO3AZ8Yxnvm+brWzR6LHcBdmOMyh2fNib6EIuIF4AFBsN/SWAEyMuIFcwlTIWW8UtWZct57DC8D98mM1eDg2IwmSjkXgSHEg8DbFqOj5/V9Oecd8XuLR7pAraZZ12Ly8iJrh4KxwjSTMzn2InYa7gXG04RDY7/xY3mQ3RavLLp/E+I9p4yNzui73Xm/lJpdFlvaCTKC5nhqRtb18u3sAvsxzwE5R10sKhf6+WzwAvtsXgQ2AicNByen+fD+Aq/h+rm8py3u/jtwzJThqBJ+wAwBDwM3RYAAnyrhpFN2Ch7zUhPh7GJJfMJxNhO2Y54E1rclcJpOeMhdFOwR6X2NGij2jCMW9pjXsvc2Ggv1ur7VUa9xVou+WD3EOQQwhLlZzP+LvWWAkU8W8w65yXa1WCx4UmYg23V3ziMWD/VmsIMxb352vYOR0V5QGrezeQd4P7NdNcFplrtqEX0d0Z9clomsdnr/Am3Ei1n6RffpAAAAAElFTkSuQmCC"
              alt=""
            /><span>收支明细</span
            ><i class="el-icon-arrow-right svg-icon svgIcon"></i>
          </div>
          <div class="linkItem" @click="$router.push('/profile/welfare')">
            <img
              class="icon"
              src="../assets/img/mess/fuli2.f96c4f53.png"
              alt=""
            /><span>我的福利</span
            ><i class="el-icon-arrow-right svg-icon svgIcon"></i>
          </div>
        </div>
        <div class="steamLink">
          <div class="steamTitle">STEAM交易链接</div>
          <div class="top">
            <div class="link">
              <el-input
                v-model="url"
                placeholder="输入您的steam交易链接"
              ></el-input>
              <!-- <input
                type="text"
                placeholder="输入您的steam交易链接"
                field_signature="1318412689"
                form_signature="12160021326808841948"
                alternative_form_signature="17456244145592490337"
                visibility_annotation="true"
              /> -->
            </div>
          </div>
          <div class="tips">
            <p>
              1、交易链接一旦绑定则无法更换,请谨慎操作,同一链接更新token不限制.
            </p>
            <p>2、请勿绑定他人的steam交易链接</p>
          </div>
          <div class="btnBox">
            <div class="give" @click="goUrl(url1)">获取</div>
            <div class="save" @click="confirm">保存地址</div>
          </div>
        </div>
        <div class="logout" @click="nextLogin">
          <i class="el-icon-switch-button"></i>
          退出登录
        </div>
      </div>
      <img src="../assets/img/mess/13mdpi.png" class="informationBg" alt="" />
      <!-- 修改昵称 -->
      <div
        class="mask animate__animated"
        :class="userNameDialog ? 'show' : 'hidde'"
        v-show="userNameDialog"
      >
        <div class="popupContent">
          <div @click="userNameClose" class="close">
            <img src="../assets/img/mess/close.10e4447a.png" alt="" />
          </div>
          <div class="changePopupContent">
            <div class="title">修改昵称</div>
            <el-input
              class="inputBox"
              v-model="userName"
              placeholder="请输入昵称"
            ></el-input>
            <div class="btnBox">
              <div class="btn cancel" @click="userNameClose">取消</div>
              <div class="btn confirm" @click="getName">确认</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 实名认证 -->
      <div
        class="mask animate__animated"
        :class="realNameDialog ? 'show' : 'hidde'"
        v-show="realNameDialog"
      >
        <div class="popupContent">
          <div @click="realNameDialog = false" class="close">
            <img src="../assets/img/mess/close.10e4447a.png" alt="" />
          </div>
          <div class="RealNamePopup">
            <div class="title">实名认证</div>
            <ul class="content">
              <li class="box">
                <div class="name">姓名</div>
                <el-input
                  :disabled="is_realname"
                  v-model="realName"
                  placeholder="请输入真实姓名"
                ></el-input>
              </li>
              <li class="box">
                <div class="name">身份证号</div>
                <el-input
                  :disabled="is_realname"
                  v-model="idCard"
                  placeholder="请输入身份证号码"
                ></el-input>
              </li>
            </ul>
            <div
              class="defaultBtn _btnClick1"
              :disabled="is_realname"
              @click="realNameConfirm"
            >
              {{ !is_realname ? "认证" : "已认证" }}
            </div>
          </div>
        </div>
      </div>
      <!-- 更换头像 -->
      <div
        class="mask animate__animated"
        :class="avatarDialog ? 'show' : 'hidde'"
        v-show="avatarDialog"
      >
        <div class="popupContent">
          <div @click="avatarDialog = false" class="close">
            <img src="../assets/img/mess/close.10e4447a.png" alt="" />
          </div>
          <div class="RealNamePopup avatarPopup">
           
            <div class="avatarBox">
              <div
                v-for="(item, index) in avatarList"
                :key="item"
                @click="changeAvatar(index)"
              >
                <img
                  :src="item.img"
                  :class="{ selectAvatar: index == avatarCurrent }"
                  alt=""
                />
              </div> 
           </div>
            <div class="defaultBtn _btnClick1" @click="avatarConfirm">更换</div>
          </div>
        </div>
      </div>
    </div>
   

    <span :plain="true">{{ urlHint }}</span>

    <!-- 邮箱弹框 -->
    <div class="hide-box">
      <div class=""></div>
    </div>
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import navheader from "@/components/navheader.vue";
export default {
  inject: ["reload"],
  components: {
    navheader,
  },
  props: ["yidongmenu", "showNav"],
  data() {
    return {
      inviteImg: "",
      inviteName: "",
      Data: {},
      site: "",
      time: "",
      url: "",
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://www.bilibili.com/video/BV15D4y1X79w/",
      urlState: true,
      checked1: false,
      checked2: false,
      flag: false,
      urlHint: "",
      valueUrl: "http://www.csunbox.cn",
      tuiguangren: "",
      valueCode: "TRtqaetA",
      tableData1: [],
      value1: 0,
      value2: 0,
      value3: 0,
      dateList: [
        {
          name: "推广统计",
          state: false,
          val: 0,
        },
      ],
      payInfoData: {
        flag: true,
        type: "",
        checked2: false,
        checked1: false,
      }, // 充值提示
      is_invite: false,
      userNameDialog: false,
      userName: "",
      realNameDialog: false,
      realName: "",
      idCard: "",
      is_realname: false,
      avatarDialog: false,
      avatar: "",
      avatarList: [
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/1.png",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/2.png",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/3.png",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/4.png",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/5.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/6.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/7.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/8.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/9.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/10.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/11.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/12.jpg",
        },
      ],
      avatarCurrent: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    avatarConfirm() {
      var _this = this;
      let param = {
        imageUrl: this.avatarList[this.avatarCurrent].img,
      };
      this.$axios
        .post("/index/User/editHeadImages", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "修改成功",
              type: "success",
            });

            this.getList();
            this.avatarDialog = false
          } else {
            this.$message({
              message: "修改失败",
              type: "info",
            });
          }
        });
    },
    changeAvatar(index) {
      this.avatarCurrent = index;
    },
    recharge(){
      this.$router.push({
          path: `/recharge`,
        });
    },
    // 实名
    realNameConfirm() {
      let param = {
        idcard: this.idCard,
        name: this.realName,
      };
      this.$axios
        .post("/index/user/realname", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.is_realname = true;
            this.realNameDialog = false;
          }
        });
    },
    // 关闭昵称弹框
    userNameClose() {
      this.userNameDialog = false;
    },
    // 编辑昵称
    editUserName() {
      this.userNameDialog = true;
    },
    //挑战推广详情
    goLonger() {
      this.$router.push({
        path: `/SpreadLonger`,
      });
    },
    tuiguang() {
      if (this.is_invite) {
        return this.$message({
          message: "您已绑定推荐人",
          type: "error",
        });
      }
      let param = {
        invite_code: this.tuiguangren,
      };
      this.$axios
        .post("index/User/bindInviter", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
            this.is_invite = true;
            this.getList();
          } else {
            this.$message({
              message: "绑定失败，" + data.msg,
              type: "info",
            });
          }
        });
    },

    //复制
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    toNav(index, e) {
      if (index == 0) {
        this.$router.push({
          path: `/Dota`,
          query: {
            type: e,
          },
        });
      } else if (index == 1) {
        this.$router.push({
          path: `/Bill`,
          query: {
            type: e,
          },
        });
      } else if (index == 2) {
        this.$router.push({
          path: `/MeYiDong`,
        });
      } else if (index == 3) {
        this.$router.push({
          path: `/task-center`,
          query: {
            type: e,
          },
        });
      } else if (index == 99) {
        this.$router.push({
          path: `/Payment`,
        });
      }
    },
    handleChange(file, fileList) {
      let _this = this;
      let formData = new FormData();
      formData.append("player_id", _this.$store.state.id);
      formData.append("file", file.raw);
      console.log(file);
      //--------------
      _this
        .$axios({
          url: "/index/User/editHeadImage",
          method: "post",
          dataType: "json",
          data: formData,
          // processData: false,
          // contentType: false,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // headers:{'Content-Type':'application/x-www-form-urlencoded'}
        })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 1) {
            _this.Data.img = res.data.data;
            Utils.$emit("img", _this.Data.img);
            _this.reload();
          }
        });
      // this.fileList = fileList.slice(-3);
    },
    //跟换头像
    getImg(response, file, fileList) {
      return;
    },
    //更换昵称
    getName() {
      let param = {
        name: this.userName,
      };
      this.$axios
        .post("/index/User/editNickname", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.Data.name = this.userName;
            this.userNameClose();
          } else {
            this.$message({
              message: "修改失败",
              type: "info",
            });
          }
        });
    },
    //邮箱绑定
    openEmail() {
      this.$prompt("请输入邮箱", "邮箱绑定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern:
          /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: "邮箱格式不正确",
      })
        .then(({ value }) => {
          // console.log(value);
          let param = {
            email: value,
          };
          this.$axios
            .post("/index/User/bindEmail", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              // console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "绑定成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "绑定失败",
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    //修改密码
    openPass() {
      this.$prompt("请输入密码", "密码修改", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let param = {
            password: value,
          };
          this.$axios
            .post("/index/User/editPass", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              // console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "修改失败",
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //绑定上级
    openTop() {
      this.$prompt("请输入邀请码", "绑定上级", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let param = {
            invite_code: value,
          };
          this.$axios
            .post("index/User/bindInviter", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "绑定成功",
                  type: "success",
                });
                this.reload();
              } else {
                this.$message({
                  message: "绑定失败，" + data.msg,
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //个人中心信息
    getList() {
      let param = {};
      this.$axios
        .post("/index/User/playerInfo", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.status == "1") {
            this.Data = data.data;
            this.is_realname = data.data.is_realname;
            this.url = this.Data.tradeUrl;
            if (this.url) {
              this.flag = true;
            }
            if (data.data.myInviter.img && data.data.myInviter.name) {
              this.tuiguangren = this.Data.myInviter;
              this.is_invite = true;
              this.inviteImg = data.data.myInviter.img;
              this.inviteName = data.data.myInviter.name;
            }
            this.site = this.Data.last_login_info.position;
            this.time = this.Data.last_login_info.time;
          }
        });
      this.$axios
        .post("index/Invite/inviteInfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.valueUrl = data.data.invite_url;
            this.valueCode = data.data.invite_code;
          }
        });
      this.$axios
        .post("index/Invite/offline", {
          days: 7,
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            //console.log(data.data.people_num)
            if (data.data.people_num) {
              this.value1 = data.data.people_num;
            }
            if (data.data.ratio) {
              this.value2 = data.data.ratio || 0;
            }
            if (data.data.invite_commission) {
              this.value3 = data.data.invite_commission;
            }
            this.tableData1 = data.data.list;
            for (let i = 0; i < this.tableData1.length; i++) {
              if (this.tableData1[i].status == 1) {
                this.tableData1[i].state = "已结算";
              } else {
                this.tableData1[i].state = "未结算";
              }
            }
          }
        });
    },
    //复制用户ID 和 steamid
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    //steam教程
    goUrl(url) {
      window.open(url, "_blank");
    },
    edit() {
      if (this.flag) {
        this.$message({
          message: "steam链接只能修改一次，请联系客服修改",
          type: "error",
        });
        return;
      }
      this.urlState = false;
    },
    off() {
      this.urlState = true;
    },
    confirm() {
      //console.log(this.url);
      let param = {
        tradeUrl: this.url,
      };
      this.$axios
        .post("/index/User/bindSteam", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.urlState = true;
            this.flag = true;
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "error",
            });
            this.urlState = true;
            // this.url = '';
          }
        });
    },
    //退出登录
    nextLogin() {
      localStorage.setItem("userInfo", null);
      this.loginfalse = true;
      this.drawer = false;
      this.$store.commit("getId", {
        name: "",
        id: "",
        img: "",
        money: "",
      });
      this.$store.commit("loginOut");
      this.$router.push({
        path: `/Index`,
      });
    },
  },
};
</script>

<style lang="less" scoped>


// 新代码
.header {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 0.42rem * 0.8;
  margin-bottom: 0.46rem * 0.8;
  color: #fff;
}

.header .avatar {
  padding: 2rem * 0.8 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.header .avatar .pic {
  width: 5.83rem * 0.8;
  height: 5.84rem * 0.8;
  border-radius: 50%;
  border: 2px solid #6917ff;
  margin-right: 1.21rem * 0.8;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-shrink: 0;
}

.header .avatar .pic .upload {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.header .avatar .nameBox {
  flex-grow: 1;
}

.header .avatar .nameBox .username {
  font-size: 1.46rem * 0.8;
  font-weight: 700;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.header .avatar .nameBox .username .edit {
  color: #ff31ed;
  font-size: 1.2rem * 0.8;
  cursor: pointer;
}

.header .avatar .nameBox .vipLevel {
  width: 8.66rem * 0.8;
  height: 0.51rem * 0.8;
  background: #d1d3de;
  border-radius: 0.14rem * 0.8;
  margin: 0.75rem * 0.8 0;
  margin-left: 1rem * 0.8;
  position: relative;
}

.header .avatar .nameBox .vipLevel img {
  width: 2rem * 0.8;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -1.5rem * 0.8;
  height: auto;
  border: 0;
}

.header .avatar .nameBox .invitationCode {
  font-size: 1rem * 0.8;
  color: #a9abbc;
  display: flex;
}

.header .avatar .nameBox .invitationCode .Copy {
  margin-left: 0.5rem * 0.8;
}

.header .money {
  width: 100%;
  padding: 0.8rem * 0.8 1.25rem * 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.42rem * 0.8;
  margin-bottom: 0.38rem * 0.8;
  box-sizing: border-box;
}

.header .money .balance {
  margin-bottom: 0.66rem * 0.8;
}

.header .money .balance .moneyVal {
  font-size: 1.25rem * 0.8;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.header .money .btnBox .recharge,
.header .money .btnBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .money .btnBox .recharge {
  width: 8.33rem * 0.8;
  height: 2.99rem * 0.8;
  background: #ff31ed;
  border-radius: 0.64rem * 0.8;
  cursor: pointer;
  font-size: 1.08rem * 0.8;
  font-weight: 700;
  color: #fff;
  margin-right: 1.33rem * 0.8;
}

.header .money .btnBox .recharge img {
  width: 1.21rem * 0.8;
  margin-right: 0.42rem * 0.8;
}

.header .money .btnBox .realName {
  background-color: #6917ff;
}

.header .linkBox {
  font-size: 1.08rem * 0.8;
  margin: 0.5rem * 0.8 0 1.7rem * 0.8 0;
  width: 100%;
}

.header .linkBox .linkItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.3rem * 0.8 0.5rem * 0.8;
  position: relative;
  border-bottom: 2px solid #1d222e;
}

.header .linkBox .linkItem .icon {
  width: 1.42rem * 0.8;
  margin-right: 0.38rem * 0.8;
}

.header .linkBox .linkItem .svgIcon {
  color: #383c48;
  font-size: 2rem * 0.8;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 1.8rem * 0.8;
}

.header .steamLink {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1.83rem * 0.8 1.54rem * 0.8;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.42rem * 0.8;
  font-size: 1rem * 0.8;
}

.header .steamLink .steamTitle {
  font-size: 1rem * 0.8;
  margin-bottom: 0.9rem * 0.8;
  color: #ccc;
}

.header .steamLink .top {
  display: flex;
  font-size: 1rem * 0.8;
  align-items: center;
  margin-bottom: 0.75rem * 0.8;
}

.header .steamLink .top .link {
  display: flex;
  align-items: center;
  flex: 1;
  height: 3.15rem * 0.8;
  background: hsla(0, 0%, 100%, 0.08);
  border-radius: 0.33rem * 0.8;
}

.header .steamLink .top input {
  flex: 1;
  height: 100%;
  padding: 0.5rem * 0.8 0.8rem * 0.8;
  color: #fff;
  box-sizing: border-box;
  font-size: 1rem * 0.8;
  margin-bottom: 0;
}

.header .steamLink .tips {
  font-size: 1rem * 0.8;
  color: #a9abbc;
  padding: 0 0.25rem * 0.8;
}

.header .steamLink .tips p {
  margin-bottom: 0.3rem * 0.8;
}

.header .steamLink .btnBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.7rem * 0.8;
}

.header .steamLink .btnBox .give {
  width: 8.83rem * 0.7;
  height: 3.13rem * 0.8;
  background: rgba(105, 23, 255, 0.3);
  border-radius: 0.64rem * 0.8;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .steamLink .btnBox .save {
  width: 15.92rem * 0.8;
  height: 3.13rem * 0.8;
  background: rgba(105, 23, 255, 0.2);
  border: 2px solid #6917ff;
  border-radius: 1.56rem * 0.8;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .steamLink .btnBox .save img {
  width: 0.96rem * 0.8;
  margin-right: 0.63rem * 0.8;
}

.header .logout {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a9abbc;
  font-size: 1.08rem * 0.8;
  height: 3.5rem * 0.8;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.33rem * 0.8;
  margin: 1rem * 0.8 0;
}

.header .logout .guanji {
  margin-right: 0.5rem * 0.8;
  font-size: 1.25rem * 0.8;
}

.changePopupContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 20rem * 0.8;
  padding: 0 1.5rem * 0.8;
  box-sizing: border-box;
}

.changePopupContent /deep/.el-input__inner {
  width: 100%;
  height: 1.83rem * 0.8;
  padding: 0.2rem * 0.8 0.52rem * 0.8;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.17rem * 0.8;
  margin-bottom: 0.4rem * 0.8;
  color: #bbb;
  box-sizing: border-box;
  border: none;
}

.changePopupContent .title {
  font-size: 1.8rem * 0.8;
  font-weight: 700;
  margin-bottom: 1.2rem * 0.8;
}

.changePopupContent .tips {
  font-size: 1rem * 0.8;
  color: #979ca7;
}

.changePopupContent .btnBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.changePopupContent .btnBox .btn {
  margin-top: 1.2rem * 0.8;
  min-width: 8rem * 0.8;
  height: 2.58rem * 0.8;
  padding: 0.2rem * 0.8 0.33rem * 0.8;
  box-sizing: border-box;
  background-color: #fbc600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #07090e;
  font-size: 1.2rem * 0.8;
  font-weight: 700;
  border-radius: 0.42rem;
}

.changePopupContent .btnBox .cancel {
  background-color: #faf6f6;
}

.informationBg {
  position: fixed;
  top: var(--window-headerNav-height);
  left: 0;
  width: 100vw;
  z-index: -888;
}
.me {
  background: #0e1325;
}
.pagesView {
  margin: 0 auto;
  // padding: 0 1rem;
  min-height: 100%;
  background: url(../assets/img/mess/13mdpi.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  position: relative;
  z-index: 999;
}

.pagesView .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1rem 1.5rem 1rem;
  box-sizing: border-box;
}

.pagesView .header .left {
  display: flex;
  align-items: center;
}

.pagesView .header .left .logo {
  width: 2.5rem;
}
.MoneyCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.show {
  background-color: rgba(0, 0, 0, 0.6);
  animation: fadeIn 0.5s;
}
.mask {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 992px) {
  .popupContent {
    background: url("../assets/img/mess/loginbg.04aa9090.png") no-repeat 50% /
      cover;
    padding: 2rem;
    border-radius: 0.42rem;
    color: #fff;
    position: relative;
  }

  .popupContent .close {
    width: 1.2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    transition: transform 0.3s linear;
    opacity: 0.6;
    img {
      width: 100%;
    }
  }

  .popupContent .close:hover {
    transform: rotate(90deg);
  }
  .RealNamePopup {
    width: 16rem;
    padding: 1rem;
  }
  .RealNamePopup /deep/.el-input__inner {
    font-size: 0.8rem;
    height: 2.99rem * 0.8;
    padding: 0.4rem 1rem;
    margin-bottom: 0.8rem;
    background: hsla(0, 0%, 100%, 0.05);
    color: #bbb;
    box-sizing: border-box;
    border: none;
  }
  .RealNamePopup .title {
    font-size: 1.2rem * 0.8;
    font-weight: 700;
    margin-bottom: 1.66rem;
    text-align: center;
  }

  .RealNamePopup .defaultBtn {
    font-size: 1.2rem * 0.8;
    height: 2.8rem * 0.8;
    min-width: 5rem;
    padding: 0.2rem 0.33rem;
    box-sizing: border-box;
    background-color: #6917ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    border-radius: 0.42rem;
    cursor: pointer;
  }
  .RealNamePopup .box {
    margin-bottom: 0.66rem;
  }

  .RealNamePopup .box .name {
    margin-bottom: 0.33rem;
  }
  .RealNamePopup .avatarBox {
    // cursor: pointer;
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    div {
      width: calc(100%/4 - 12px);
      height: auto;
      margin: 4px;
      img {
        width: 100%;
        // height: 100%;
        border-radius: 50%;
      }
      .selectAvatar{
        box-shadow: 0 0 6px 0 rgba(71, 201, 243, 0.64);
        border: 2px solid #3088ff;
      }
    }
  }
}

</style>