<template>
  <div class="income informationView">
    <div
      v-if="!showNav"
      class="BackTop _mobile"
      style="background-color: rgba(255, 255, 255, 0.05)"
    >
      <div class="left"  @click="$router.go(-1)">
        <div class="backBox">
          <i class="el-icon-arrow-left svg-icon backSvg"></i>
          <span>收支明细</span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="income-condition">
      <div class="income-condition-card condition-time" @click="toggleDay()">
        <div class="income-condition-card-show"  >
          <div class="income-condition-card-text">日期</div>
          <div class="income-condition-card-icon card-icon-time" :class="{'card-icon-selected':dayType=='asc'}"></div>
        </div>
      </div>
      <div class="income-condition-card condition-use">
        <div class="income-condition-card-show">
          <div class="income-condition-card-text">用途</div>
          <div class="income-condition-card-icon card-icon-rotate"></div>
        </div>
        <div class="income-condition-card-hide">
          <div
            class="income-condition-card-hide-select"
            v-for="(item, key) in type"
            :key="item"
            :class="{ itemActive: currentFilterType == key }"
            @click="filterType(key)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="income-list _windows" v-if="showNav">
      <div class="table">
        <div class="theader">
          <div class="tr">
            <div class="td td-time">日期</div>
            <div class="td undefined">状态</div>
            <div class="td undefined">用途</div>
            <div class="td td-time">金额</div>
          </div>
        </div>
        <div class="tbody">
          <div class="tr" v-for="(item, index) in tableData" :key="index">
            <div class="td td-time">
              <div>
                <div class="td-msg">{{ item.create_time }}</div>
              </div>
            </div>
            <div class="td">{{ item.pay }}</div>
            <div class="td">{{ item.state }}</div>
            <div class="td td-time">
              <div class="td-box">
                <div class="MoneyCoin">
                  <img src="@/assets/img/money.png" alt="" />
                  <span class="moneyVal"
                    >{{ item.pay == "收入" ? "+" : "-" }}{{ item.amount }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="listBox _mobile" v-if="!showNav">
      <div class="income-list-mb" v-for="(item, index) in tableData" :key="index">
        <div class="income-list-mb-order">
          <span>订单ID：{{ item.id }}</span>
        </div>
        <div class="income-list-mb-content">
          <div
            class="income-list-mb-content-middle income-list-mb-content-left"
          >
            <div class="income-list-mb-content-left-top">{{ item.pay }}</div>
            <div class="income-list-mb-content-left-lower">{{item.state}}</div>
          </div>
          <div
            class="income-list-mb-content-middle income-list-mb-content-right"
          >
            <div class="income-list-mb-content-right-top">
              <div class="MoneyCoin">
                <img src="../../../assets/img/money.png" alt="">
                <span class="moneyVal">{{ item.amount }}</span>
              </div>
            </div>
            <div class="income-list-mb-content-right-lower">
              <div class="MoneyCoin">
                <img src="../../../assets/img/money.png" alt="">
                <span class="moneyVal">{{ item.amount }}</span>
              </div>
            </div>
          </div>
          <div class="income-list-mb-line"></div>
          <div class="income-list-mb-date">
            <div class="">{{ item.create_time }}</div>
          </div>
        </div>
      </div>
    </div>
    <!---->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :current-page="page"
      :page-size="pageSize"
      @current-change="currentChange"
    >
    </el-pagination>
    <navheader
    v-if="!showNav"
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
  </div>
</template>
<script >
import navheader from "@/components/navheader.vue";
export default {
  props: ["yidongmenu", "showNav", "openHongbao"],
  components: {
    navheader,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      type: {
        1: "皮肤兑换商城币",
        2: "福利赠送",
        3: "充值",
        4: "对战失败",
        5: "对战存在多个平局赢家平分输家的钱",
        7: "幸运饰品失败",
        8: "cdk兑换",
        10: "下级充值推广佣金",
        "-1": "购买盲盒",
        "-2": "加入对战房间",
        "-3": "购买幸运饰品",
        "-4": "商城购买饰品",
      },
      currentFilterType: 1,
      dayType:'desc'
    };
  },
  mounted() {
    this.getBalanceList();
    // this.getRechargeList();
  },
  methods: {
    toggleDay(){
      console.log(this.dayType,'fdksofksfo');
      if(this.dayType == 'desc'){
        this.dayType = 'asc'
      }else{
        this.dayType = 'desc'
      }
      this.$forceUpdate()
      this.getBalanceList();
    },
    //获取收支明细
    getBalanceList() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
        type:this.currentFilterType,
        day:this.dayType
      };
      this.$axios
        .post("index/User/balanceDetail", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.total = data.data.total;
            this.tableData = data.data.list;

            for (let i = 0; i < this.tableData.length; i++) {
              if (Number(this.tableData[i].amount) >= 0) {
                this.tableData[i].pay = "收入";
              } else {
                this.tableData[i].pay = "支出";
              }
            }
            for (let i = 0; i < this.tableData.length; i++) {
              this.tableData[i].state =
                this.type[this.tableData[i].type] || "未知类型";
            }
          }else{
            this.totalSize = 0;
            this.tableData = [];
          }
        });
    },
    // 获取充值记录
    getRechargeList() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("index/User/recharge", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.totalSize = data.data.total;
            this.tableData = data.data.list;
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].mode == "zhifubao") {
                this.tableData[i].modeName = "支付宝";
              } else if (this.tableData[i].mode == "jdpay") {
                this.tableData[i].modeName = "支付宝";
              } else {
                this.tableData[i].modeName = "微信";
              }

              if (this.tableData[i].status == 1) {
                this.tableData[i].statusName = "未支付";
              } else if (this.tableData[i].status == 2) {
                this.tableData[i].statusName = "待支付";
              } else if (this.tableData[i].status == 3) {
                this.tableData[i].statusName = "支付成功";
              } else {
                this.tableData[i].statusName = "支付失败";
              }
            }
          }
        });
    },
    currentChange(val) {
      this.page = val;
      this.getBalanceList();
    },
    filterType(key) {
      this.currentFilterType = key;
      this.getBalanceList();
    },
  },
};
</script>
<style scoped lang="less">
.income {
  padding: 0.5rem * 1.4 1rem * 1.4;
  max-width: 61.23rem * 1.4;
  min-height: 31rem * 1.4;
  border-radius: 0.25rem * 1.4;
  font-size: 0.58rem * 1.4;
  font-weight: 400;
  color: #fff;
}

.income .income-condition {
  display: flex;
}

.income .income-condition .income-condition-card {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 11rem;
  height: 1.56rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.17rem * 1.4;
  margin-right: 0.17rem * 1.4;
  position: relative;
}

.income .income-condition .income-condition-card .income-condition-card-show {
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.income
  .income-condition
  .income-condition-card
  .income-condition-card-show
  .income-condition-card-icon {
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 0.42rem * 1.4;
  transition: transform 0.2s linear;
}

.income
  .income-condition
  .income-condition-card
  .income-condition-card-show
  .card-icon-time-selected {
  transform: rotate(180deg);
}

.income
  .income-condition
  .income-condition-card
  .income-condition-card-show
  .card-icon-time {
  width: 0.5rem * 1.4;
  height: 0.58rem * 1.4;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAAAAXNSR0IArs4c6QAAAYNJREFUSEvtlrsuBVEYhb8VnkCikGhUEoVKR0GCxKXQSfACIqFUEIlQqEThCQiFSqPTuCQavINEQikuhUSy5MjMydjOzOxpThRnuj2z9v/NWv/M3ls06VKTOPw/kO02YAkYAA4knVdJI9qR7RVgLyn+CfRKeoiFVQEdAXOZwrOSTpoBmpd03AKVJmA77FErutLUfgSt6MKc6iuD7Q5gELiV9BQKY6Oz3Q90AheSvtI6PyDb3cB9IngFpiRdZ2ExINubwEYy70zSdAiqrcr7mcJvwGQWVgayvQWsB0l0SXqu3UsdDQGX6TgR/4IVgXIgj0BPGl+2R6vATvBG78BEzVkeyPY2sBbMewFGJd39ii4d2M6FAYvBNjEP9MVA6tEFTc+D3QBjGe0pMFPmpKGjEme1T7W9YB35E1dWm7vD5sSYxymENIwuIsYQVgopBSU/c6OepbAoSBSoAPYBDEu6Lehb/VGVU9AhsJDMNDBe5WwXDUqcLQMjwK6kqxgnhZ93lQKx2m/Yd8of8iKbbwAAAABJRU5ErkJggg==);
}

.income
  .income-condition
  .income-condition-card
  .income-condition-card-show
  .card-icon-rotate {
  width: 0.46rem * 1.4;
  height: 0.29rem * 1.4;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAAAXNSR0IArs4c6QAAAh5JREFUOE+1lE1oE1EUhc+dSdMa0Ej8K0HUlS3uFHSnICgKFUGELMTubJIaDZSC2trM3CQ1VpEKrTZNS81ai4K6iEW3guBKxO7EhYtIocVFCWZM58oEEzLTtB2RvuV7537nvfvzCJu8aJP5qBswTx0se43Fu4PXFv/HlHl6L2BUmGM/LE7VQE9NZgFEAfwSkXhK753+ZxMR0tO5BwT0CSBCFE8lIo9pIPNkl7diLNSAZB2CBpNaZMStCTN7RGnPA7jcwCmyFg3S9bGx1sBPr2WwzQ6k+8lE+BaIZD0j5nybKMZTQM7bdfIxqfUeq6aI05NnRfAcgM8Bm5rvDFydDYVWmpncuDez1Vf+/VKAk43nBBRh4hRzdL5e5ERq4rgC5TUAvwP2jMxAN3PIaNwfyDza4a2oBYCOOvRfV0g9PZzo+VYvck2QSGUPq6CCAHscQW/IpIvMkVL1xTweFKXlLYBDjpt/NlQ6k7kdKTbUwo4aSk90eESdE8h+R/B7mK3ngNJOUVQLfsAWKfhA4ulivrLkiFudXV3P7YMqcwA6HeJPf1/X7ijoOzLNC8yxZSdtzUlmzu4WlQoQHNmgXV8sbTcujcfj5Wa6db+KmyM5f1tZXoFwomkwIf+lI9CzVpetKnIzSN/o6Bb/sm9WgC5buoQeshbu32hOXH124VyuJbiAGRHpBmASoLMWHXYz6a4MaiCrw4SkdGco9t0N3NL8AWK5uhGadozgAAAAAElFTkSuQmCC);
}

.income .income-condition .income-condition-card .income-condition-card-hide {
  display: none;
  text-align: center;
  max-height: 15rem * 1.4;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 0.1rem;
}

.income
  .income-condition
  .income-condition-card
  .income-condition-card-hide
  .income-condition-card-hide-select {
  width: 100%;
  height: 1.87rem * 1.4;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0 0.33rem * 1.4;
  box-sizing: border-box;
  transition: all 0.3s linear;
}

.income
  .income-condition
  .income-condition-card
  .income-condition-card-hide
  .income-condition-card-hide-select:hover,
.income
  .income-condition
  .income-condition-card
  .income-condition-card-hide
  .itemActive,
.income .income-condition .income-condition-card:hover {
  background: hsla(0, 0%, 100%, 0.08);
}

.income .income-condition .income-condition-card:hover .card-icon-rotate {
  transform: rotate(180deg);
}

.income
  .income-condition
  .income-condition-card:hover
  .income-condition-card-hide {
  border-radius: 0.5rem * 1.4;
  width: 11rem;
  background-color: #3b3d47;
  display: block;
  position: relative;
  z-index: 9;
}

.income .income-condition .income-condition-time {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAAAAXNSR0IArs4c6QAAAYNJREFUSEvtlrsuBVEYhb8VnkCikGhUEoVKR0GCxKXQSfACIqFUEIlQqEThCQiFSqPTuCQavINEQikuhUSy5MjMydjOzOxpThRnuj2z9v/NWv/M3ls06VKTOPw/kO02YAkYAA4knVdJI9qR7RVgLyn+CfRKeoiFVQEdAXOZwrOSTpoBmpd03AKVJmA77FErutLUfgSt6MKc6iuD7Q5gELiV9BQKY6Oz3Q90AheSvtI6PyDb3cB9IngFpiRdZ2ExINubwEYy70zSdAiqrcr7mcJvwGQWVgayvQWsB0l0SXqu3UsdDQGX6TgR/4IVgXIgj0BPGl+2R6vATvBG78BEzVkeyPY2sBbMewFGJd39ii4d2M6FAYvBNjEP9MVA6tEFTc+D3QBjGe0pMFPmpKGjEme1T7W9YB35E1dWm7vD5sSYxymENIwuIsYQVgopBSU/c6OepbAoSBSoAPYBDEu6Lehb/VGVU9AhsJDMNDBe5WwXDUqcLQMjwK6kqxgnhZ93lQKx2m/Yd8of8iKbbwAAAABJRU5ErkJggg==);
}

.income .income-condition .income-condition-state,
.income .income-condition .income-condition-use {
  width: 0.46rem * 1.4;
  height: 0.29rem * 1.4;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAAAXNSR0IArs4c6QAAAh5JREFUOE+1lE1oE1EUhc+dSdMa0Ej8K0HUlS3uFHSnICgKFUGELMTubJIaDZSC2trM3CQ1VpEKrTZNS81ai4K6iEW3guBKxO7EhYtIocVFCWZM58oEEzLTtB2RvuV7537nvfvzCJu8aJP5qBswTx0se43Fu4PXFv/HlHl6L2BUmGM/LE7VQE9NZgFEAfwSkXhK753+ZxMR0tO5BwT0CSBCFE8lIo9pIPNkl7diLNSAZB2CBpNaZMStCTN7RGnPA7jcwCmyFg3S9bGx1sBPr2WwzQ6k+8lE+BaIZD0j5nybKMZTQM7bdfIxqfUeq6aI05NnRfAcgM8Bm5rvDFydDYVWmpncuDez1Vf+/VKAk43nBBRh4hRzdL5e5ERq4rgC5TUAvwP2jMxAN3PIaNwfyDza4a2oBYCOOvRfV0g9PZzo+VYvck2QSGUPq6CCAHscQW/IpIvMkVL1xTweFKXlLYBDjpt/NlQ6k7kdKTbUwo4aSk90eESdE8h+R/B7mK3ngNJOUVQLfsAWKfhA4ulivrLkiFudXV3P7YMqcwA6HeJPf1/X7ijoOzLNC8yxZSdtzUlmzu4WlQoQHNmgXV8sbTcujcfj5Wa6db+KmyM5f1tZXoFwomkwIf+lI9CzVpetKnIzSN/o6Bb/sm9WgC5buoQeshbu32hOXH124VyuJbiAGRHpBmASoLMWHXYz6a4MaiCrw4SkdGco9t0N3NL8AWK5uhGadozgAAAAAElFTkSuQmCC);
}

.income .income-list {
  margin-top: 0.33rem * 1.4;
}

.income .income-list .td {
  width: 25%;
  justify-content: center;
}

.income .income-list .tr {
  height: 2.28rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
}

.income .income-list .theader .tr {
  background: hsla(0, 0%, 100%, 0.1);
  border-radius: 0.17rem * 1.4 0.17rem * 1.4 0 0;
}

.income .income-list .tbody .tr {
  background: hsla(0, 0%, 100%, 0.05);
}

.income .income-list .tbody .tr .td {
  color: #cbcddc;
  font-size: 0.58rem * 1.4;
}

.income .income-list .tbody .tr .td .td-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 3rem * 1.4;
}

.income .income-list .tbody .tr .td .td-msg {
  text-align: center;
}

@media screen and (max-width: 992px) {
  .BackTop {
    height: 3.25rem*0.8;
    border-radius: 0.42rem*0.8;
    display: flex;
        align-items: center;
        padding: 0 0.4rem;
  }

  .BackTop .left {
    padding-left: 0.83rem*0.8;
    box-sizing: border-box;
    cursor: pointer;
  }

  .BackTop .left .backBox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem*0.8;
    font-weight: 700;
    color: #a9abbc;
  }

  .BackTop .left .backBox .backSvg {
    width: 1.77rem*0.8;
    height: 1.77rem*0.8;
    border-radius: 0.25rem*0.8;
    margin-right: 0.52rem*0.8;
    background: transparent;
    border: 2px solid hsla(0, 0%, 100%, .2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .BackTop .left .backBox:hover {
    color: #fff;
  }

  .BackTop .left .backBox:hover .backSvg {
    color: #a9abbc;
    border: 0.08px solid #a9abbc;
  }

  .BackTop .right {
    padding-right: 1.2rem*0.8;
    font-size: 1rem*0.8;
  }

  .BackTop .right .rule img {
    width: 1.3rem*0.8;
    margin-right: 0.33rem*0.8;
  }

  .BackTop .right span {
    color: #7a7a7a;
    cursor: pointer;
  }

  .BackTop .right span:hover {
    color: #fff;
  }
  .income {
    padding: 0.5rem 1rem;
    max-width: 61.23rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    box-sizing: border-box;
    background: #0E1325;
  }

  .income .BackTop {
    background: none;
  }

  .income .BackTop .left {
    padding-left: 0;
  }

  .income .income-condition {
    display: flex;
    margin-top: 0.5rem;
  }

  .income .income-condition .income-condition-card {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    height: 3.5rem*0.8;
    min-width: 6rem*0.8;
    font-size: 1.08rem*0.8;
    border-radius: 0.17rem*0.8;
    margin-right: 0.17rem*0.8;
    width: 3.48rem*0.8;
  }

  .income .income-condition .income-condition-card .income-condition-card-show {
    height: 100%;
    font-size: 1.08rem*0.8;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .income
    .income-condition
    .income-condition-card
    .income-condition-card-show
    .income-condition-card-text {
    font-size: 1.08rem*0.8;
  }

  .income
    .income-condition
    .income-condition-card
    .income-condition-card-show
    .income-condition-card-icon {
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 0.42rem*0.8;
  }

  .income
    .income-condition
    .income-condition-card
    .income-condition-card-show
    .card-icon-time-selected {
    transform: rotate(180deg);
  }

  .income
    .income-condition
    .income-condition-card
    .income-condition-card-show
    .card-icon-time {
    width: 1.06rem*0.8;
    height: 1.23rem*0.8;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAAAAXNSR0IArs4c6QAAAYNJREFUSEvtlrsuBVEYhb8VnkCikGhUEoVKR0GCxKXQSfACIqFUEIlQqEThCQiFSqPTuCQavINEQikuhUSy5MjMydjOzOxpThRnuj2z9v/NWv/M3ls06VKTOPw/kO02YAkYAA4knVdJI9qR7RVgLyn+CfRKeoiFVQEdAXOZwrOSTpoBmpd03AKVJmA77FErutLUfgSt6MKc6iuD7Q5gELiV9BQKY6Oz3Q90AheSvtI6PyDb3cB9IngFpiRdZ2ExINubwEYy70zSdAiqrcr7mcJvwGQWVgayvQWsB0l0SXqu3UsdDQGX6TgR/4IVgXIgj0BPGl+2R6vATvBG78BEzVkeyPY2sBbMewFGJd39ii4d2M6FAYvBNjEP9MVA6tEFTc+D3QBjGe0pMFPmpKGjEme1T7W9YB35E1dWm7vD5sSYxymENIwuIsYQVgopBSU/c6OepbAoSBSoAPYBDEu6Lehb/VGVU9AhsJDMNDBe5WwXDUqcLQMjwK6kqxgnhZ93lQKx2m/Yd8of8iKbbwAAAABJRU5ErkJggg==);
  }

  .income
    .income-condition
    .income-condition-card
    .income-condition-card-show
    .card-icon-rotate {
    width: 1.2rem*0.8;
    height: 0.7rem*0.8;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAAAXNSR0IArs4c6QAAAh5JREFUOE+1lE1oE1EUhc+dSdMa0Ej8K0HUlS3uFHSnICgKFUGELMTubJIaDZSC2trM3CQ1VpEKrTZNS81ai4K6iEW3guBKxO7EhYtIocVFCWZM58oEEzLTtB2RvuV7537nvfvzCJu8aJP5qBswTx0se43Fu4PXFv/HlHl6L2BUmGM/LE7VQE9NZgFEAfwSkXhK753+ZxMR0tO5BwT0CSBCFE8lIo9pIPNkl7diLNSAZB2CBpNaZMStCTN7RGnPA7jcwCmyFg3S9bGx1sBPr2WwzQ6k+8lE+BaIZD0j5nybKMZTQM7bdfIxqfUeq6aI05NnRfAcgM8Bm5rvDFydDYVWmpncuDez1Vf+/VKAk43nBBRh4hRzdL5e5ERq4rgC5TUAvwP2jMxAN3PIaNwfyDza4a2oBYCOOvRfV0g9PZzo+VYvck2QSGUPq6CCAHscQW/IpIvMkVL1xTweFKXlLYBDjpt/NlQ6k7kdKTbUwo4aSk90eESdE8h+R/B7mK3ngNJOUVQLfsAWKfhA4ulivrLkiFudXV3P7YMqcwA6HeJPf1/X7ijoOzLNC8yxZSdtzUlmzu4WlQoQHNmgXV8sbTcujcfj5Wa6db+KmyM5f1tZXoFwomkwIf+lI9CzVpetKnIzSN/o6Bb/sm9WgC5buoQeshbu32hOXH124VyuJbiAGRHpBmASoLMWHXYz6a4MaiCrw4SkdGco9t0N3NL8AWK5uhGadozgAAAAAElFTkSuQmCC);
  }

  .income .income-condition .income-condition-card .income-condition-card-hide {
    display: none;
    margin-top: 0.2rem*0.8;
  }

  .income
    .income-condition
    .income-condition-card
    .income-condition-card-hide
    .income-condition-card-hide-select {
    width: 100%;
    height: auto;
    padding: 1rem*0.8 0.5rem*0.8;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .income
    .income-condition
    .income-condition-card
    .income-condition-card-hide
    .hide-select-first {
    border-radius: 0.25rem*0.8 0.25rem*0.8 0 0;
  }

  .income
    .income-condition
    .income-condition-card
    .income-condition-card-hide
    .hide-select-end {
    border-radius: 0 0 0.25rem*0.8 0.25rem*0.8;
  }

  .income .income-condition .income-condition-card:hover .card-icon-rotate {
    transform: rotate(180deg);
  }

  .income
    .income-condition
    .income-condition-card:hover
    .income-condition-card-hide {
    border-radius: 0.5rem*0.8;
    min-width: 12rem*0.8;
    max-height: 20rem*0.8;
    background-color: #3b3d47;
    display: block;
    position: relative;
    z-index: 9;
  }

  .income .income-condition .income-condition-time {
    width: 1.06rem*0.8;
    height: 1.23rem*0.8;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAAAAXNSR0IArs4c6QAAAYNJREFUSEvtlrsuBVEYhb8VnkCikGhUEoVKR0GCxKXQSfACIqFUEIlQqEThCQiFSqPTuCQavINEQikuhUSy5MjMydjOzOxpThRnuj2z9v/NWv/M3ls06VKTOPw/kO02YAkYAA4knVdJI9qR7RVgLyn+CfRKeoiFVQEdAXOZwrOSTpoBmpd03AKVJmA77FErutLUfgSt6MKc6iuD7Q5gELiV9BQKY6Oz3Q90AheSvtI6PyDb3cB9IngFpiRdZ2ExINubwEYy70zSdAiqrcr7mcJvwGQWVgayvQWsB0l0SXqu3UsdDQGX6TgR/4IVgXIgj0BPGl+2R6vATvBG78BEzVkeyPY2sBbMewFGJd39ii4d2M6FAYvBNjEP9MVA6tEFTc+D3QBjGe0pMFPmpKGjEme1T7W9YB35E1dWm7vD5sSYxymENIwuIsYQVgopBSU/c6OepbAoSBSoAPYBDEu6Lehb/VGVU9AhsJDMNDBe5WwXDUqcLQMjwK6kqxgnhZ93lQKx2m/Yd8of8iKbbwAAAABJRU5ErkJggg==);
  }

  .income .income-condition .income-condition-state,
  .income .income-condition .income-condition-use {
    width: 1.06rem*0.8;
    height: 1.23rem*0.8;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAAAXNSR0IArs4c6QAAAh5JREFUOE+1lE1oE1EUhc+dSdMa0Ej8K0HUlS3uFHSnICgKFUGELMTubJIaDZSC2trM3CQ1VpEKrTZNS81ai4K6iEW3guBKxO7EhYtIocVFCWZM58oEEzLTtB2RvuV7537nvfvzCJu8aJP5qBswTx0se43Fu4PXFv/HlHl6L2BUmGM/LE7VQE9NZgFEAfwSkXhK753+ZxMR0tO5BwT0CSBCFE8lIo9pIPNkl7diLNSAZB2CBpNaZMStCTN7RGnPA7jcwCmyFg3S9bGx1sBPr2WwzQ6k+8lE+BaIZD0j5nybKMZTQM7bdfIxqfUeq6aI05NnRfAcgM8Bm5rvDFydDYVWmpncuDez1Vf+/VKAk43nBBRh4hRzdL5e5ERq4rgC5TUAvwP2jMxAN3PIaNwfyDza4a2oBYCOOvRfV0g9PZzo+VYvck2QSGUPq6CCAHscQW/IpIvMkVL1xTweFKXlLYBDjpt/NlQ6k7kdKTbUwo4aSk90eESdE8h+R/B7mK3ngNJOUVQLfsAWKfhA4ulivrLkiFudXV3P7YMqcwA6HeJPf1/X7ijoOzLNC8yxZSdtzUlmzu4WlQoQHNmgXV8sbTcujcfj5Wa6db+KmyM5f1tZXoFwomkwIf+lI9CzVpetKnIzSN/o6Bb/sm9WgC5buoQeshbu32hOXH124VyuJbiAGRHpBmASoLMWHXYz6a4MaiCrw4SkdGco9t0N3NL8AWK5uhGadozgAAAAAElFTkSuQmCC);
  }

  .income .income-list {
    margin-top: 0.33rem*0.8;
    font-size: 1.08rem*0.8;
  }

  .income .income-list .tr {
    height: 3.26rem*0.8;
    background: hsla(0, 0%, 100%, 0.05);
  }

  .income .income-list .theader .tr {
    background: hsla(0, 0%, 100%, 0.1);
    border-radius: 0.17rem*0.8 0.17rem*0.8 0 0;
  }

  .income .income-list .theader .tr .td {
    width: 5rem*0.8;
    justify-content: center;
  }

  .income .income-list .theader .tr .td-time {
    width: 8rem*0.8;
  }

  .income .income-list .tbody .tr {
    background: hsla(0, 0%, 100%, 0.05);
  }

  .income .income-list .tbody .tr .td {
    color: #cbcddc;
    font-size: 1.08rem*0.8;
    width: 6rem*0.8;
  }

  .income .income-list .tbody .tr .td .td-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .income .income-list .tbody .tr .td .td-msg {
    text-align: center;
  }

  .income .income-list .tbody .tr .td-time {
    width: 8rem*0.8;
    display: flex;
    justify-content: flex-start;
  }

  .income .listBox .income-list-mb {
    width: 22.4rem;
    height: 11.88rem*0.8;
    background: hsla(0, 0%, 100%, 0.05);
    border-radius: 0.83rem*0.8;
    margin: 0.5rem*0.8 0;
  }

  .income .listBox .income-list-mb .income-list-mb-order {
    width: 22.4rem;
    height: 3.38rem*0.8;
    background: hsla(0, 0%, 100%, 0.05);
    border-radius: 0.83rem*0.8 0.83rem*0.8 0 0;
    display: flex;
    align-items: center;
  }

  .income .listBox .income-list-mb .income-list-mb-order > span {
    font-weight: 400;
    color: #717382;
    font-size: 1rem*0.8;
    line-height: 1.46rem*0.8;
    margin-left: 1.4rem*0.8;
  }

  .income .listBox .income-list-mb .income-list-mb-content {
    display: flex;
    flex-wrap: wrap;
  }

  .income
    .listBox
    .income-list-mb
    .income-list-mb-content
    .income-list-mb-content-middle {
    width: 10.7rem;
    height: 5.5rem*0.8;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }

  .income
    .listBox
    .income-list-mb
    .income-list-mb-content
    .income-list-mb-content-left {
    transform: translateX(1.4rem*0.8);
  }

  .income
    .listBox
    .income-list-mb
    .income-list-mb-content
    .income-list-mb-content-left
    .income-list-mb-content-left-top {
    width: 14.625rem*0.8;
    font-size: 1.25rem*0.8;
    font-weight: 400;
    color: #fff;
  }

  .income
    .listBox
    .income-list-mb
    .income-list-mb-content
    .income-list-mb-content-left
    .income-list-mb-content-left-lower {
    width: 14.625rem*0.8;
    font-size: 1rem*0.8;
    font-weight: 400;
    color: #bec2d3;
    padding-top: 0.5rem*0.8;
  }

  .income
    .listBox
    .income-list-mb
    .income-list-mb-content
    .income-list-mb-content-right
    .income-list-mb-content-right-top {
    font-size: 1.25rem*0.8;
    display: flex;
    align-items: center;
    width: 14.625rem*0.8;
    margin-left: 4rem*0.8;
  }

  .income
    .listBox
    .income-list-mb
    .income-list-mb-content
    .income-list-mb-content-right
    .income-list-mb-content-right-lower {
    margin-left: 4rem*0.8;
    width: 14.625rem*0.8;
    font-size: 1rem*0.8;
    display: flex;
    align-items: center;
    padding-top: 0.5rem*0.8;
  }

  .income
    .listBox
    .income-list-mb
    .income-list-mb-content
    .income-list-mb-line {
    transform: translateX(1.4rem*0.8);
    border-bottom: 1px solid #272936;
    width: 27.04rem*0.8;
    height: 0.04rem*0.8;
    font-size: 0.61rem*0.8;
  }

  .income
    .listBox
    .income-list-mb
    .income-list-mb-content
    .income-list-mb-date {
    padding-left: 1.5rem*0.8;
    width: 100%;
    height: 3rem*0.8;
    line-height: 3rem*0.8;
    font-size: 1rem*0.8;
    font-weight: 400;
    color: #717382;
  }
  .card-icon-selected{
        transform: rotate(180deg);
    }
}
.table {
  display: flex;
  flex-direction: column;
}

.tr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: hsla(0, 0%, 100%, 0.05);
  margin-bottom: 0.13rem;
}

.tr:hover {
  background-color: hsla(0, 0%, 100%, 0.08);
}

.td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.38rem 1rem;
  width: 5rem;
}
.MoneyCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.card-icon-selected{
    transform: rotate(180deg);
}
</style>