<template>
  <div class="fullReturn pagesView routerView">
    <Back></Back>
    <div
      class="contentBox"
      :style="{
        'background-image': `url(${showNav ? pcBg : mobileBg})`,
      }"
    >
      <div class="rule btn" @click="showRule = true">活动规则</div>
      <div class="table">
        <div class="item">
          <div class="amount">500</div>
          <div class="pct">5%</div>
        </div>
        <div class="item">
          <div class="amount">2000</div>
          <div class="pct">8%</div>
        </div>
        <div class="item">
          <div class="amount">6000</div>
          <div class="pct">12%</div>
        </div>
        <div class="item">
          <div class="amount">10000</div>
          <div class="pct">15%</div>
        </div>
      </div>
      <div class="btnBox">
        当前满返金额：{{ fullReturnValue }}金
        <div class="fullBtn btn" @click="getFullReturn"></div>
      </div>
    </div>
    <div
      class="mask animate__animated"
      :class="{ show: showRule }"
      v-show="showRule"
    >
      <div class="popupContent" v-show="showRule">
        <div class="close" @click="showRule = false">
          <img src="../assets/img/close.10e4447a.png" alt="" />
        </div>

        <div class="ruleContent">
          <div class="ruleTitle">天天满返活动规则</div>
          <div class="text">
            <p>
              1.当天充值金额次日起7天内领取有效，未领取充值金额最多可累计7天。超过期限未领取的部分自动失效。
            </p>
            <p>2.领取后则以此操作后重新计算充值金额。</p>
            <p>
              3.活动有效金额为：9月10日0点起消费有效，活动最后可领取时间为9月20日23点59分前。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import Back from "@/components/back.vue";
export default {
  components: { Back },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      fullReturnValue: 0,
      showRule: false,
      pcBg: "https://lucsbox.oss-cn-hangzhou.aliyuncs.com/2024/05/27/171680356374538SE8.jpg",
      mobileBg:
        "https://lucsbox.oss-cn-hangzhou.aliyuncs.com/2024/05/27/1716803570168Hh63C.jpg",
    };
  },
  methods: {
    // https://csunbox.cn/index/activity/ddmf
    getFullReturn() {
      this.$axios.post("/index/activity/ddmf", {}).then((res) => {
        var data = res.data;
        if (data.status == "1") {
          this.$message({
            showClose: true,
            message: data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: data.msg,
            type: "error",
          });
        }
      });
    },
    getDmf_value() {
      this.$axios.post("/index/activity/ddmf_value", {}).then((res) => {
        var data = res.data;
        // console.log(data);
        this.fullReturnValue = data.data.coin;
        // if (data.status == "1") {
        //   console.log(data);
        //   this.fullReturnValue = data.data;
        // }
      });
    },
  },
  mounted() {
    this.getDmf_value();
  },
};
</script>
<style scoped lang="less">
.fullReturn {
  overflow: hidden;
  color: #fff;
}

.contentBox {
  margin-top: 0.33rem * 1.5;
  margin-bottom: 3rem * 1.5;
  //   background: no-repeat 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 62.5rem * 1.5;
  height: 25rem * 1.5;
  position: relative;
}

@media screen and (max-width: 1450px) {
  .contentBox {
    transform: scale(0.8) translate(-5rem * 1.5, -2rem * 1.5);
  }
}

@media screen and (max-width: 1250px) {
  .contentBox {
    transform: scale(0.7) translate(-10rem * 1.5, -5rem * 1.5);
  }
}

@media screen and (max-width: 1100px) {
  .contentBox {
    transform: scale(0.6) translate(-15rem * 1.5, -7rem * 1.5);
  }
}

.contentBox .rule {
  position: absolute;
  right: 3rem * 1.5;
  top: 1rem * 1.5;
  font-size: 1.2em;
  font-weight: 700;
}

.contentBox .rule:hover {
  color: #ff31ed;
}

.contentBox .table {
  display: flex;
  width: 25rem * 1.5;
  position: absolute;
  left: 48%;
  bottom: 6.7rem * 1.5;
  box-sizing: border-box;
  padding-left: 3rem * 1.5;
}

.contentBox .table .item {
  flex-grow: 1;
}

.contentBox .table .item .amount,
.contentBox .table .item .pct {
  height: 2.7rem * 1.5;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.contentBox .table .item .amount {
  font-size: 0.75rem * 1.5;
}

.contentBox .table .item .pct {
  font-size: 1rem * 1.5;
  color: #ffcb23;
}

.contentBox .btnBox {
  font-weight: 700;
  font-size: 1.25rem * 1.5;
  color: #ffc800;
  position: absolute;
  left: 58%;
  bottom: 0.63rem * 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contentBox .btnBox .fullBtn {
  background: url("../assets/img/fullBtn.8d5a64b1.png") no-repeat;
  background-size: contain;
  width: 10.93rem * 1.5;
  height: 3.28rem * 1.5;
  margin-top: 0.33rem * 1.5;
}

@media screen and (max-width: 992px) {
  .contentBox {
    width: 100%;
    height: 55.48rem * 0.8;
    background-repeat: no-repeat;
    background-size: contain;
    // background: no-repeat 50%/100% 100%;
    transform: translateX(0);
  }
  .contentBox .rule {
    position: absolute;
    right: 3rem * 0.8;
    top: 1rem * 0.8;
    font-size: 1.2em * 0.8;
    font-weight: 700;
  }
  .contentBox .table {
    display: flex;
    width: 24rem * 0.8;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 18.54rem * 0.8;
    box-sizing: border-box;
    padding-left: 3.5rem * 0.8;
  }

  .contentBox .table .item {
    flex-grow: 1;
  }

  .contentBox .table .item .amount,
  .contentBox .table .item .pct {
    height: 2.7rem * 0.8;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .contentBox .table .item .amount {
    font-size: 1rem * 0.8;
  }

  .contentBox .table .item .pct {
    font-size: 1.17rem * 0.8;
    color: #ffcb23;
  }

  .contentBox .btnBox {
    font-weight: 700;
    font-size: 1.34rem * 0.8;
    color: #ffc800;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 25rem * 0.8;
    bottom: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .contentBox .btnBox .fullBtn {
    background: url("../assets/img/fullBtn.8d5a64b1.png") no-repeat;
    background-size: contain;
    width: 13.02rem * 0.8;
    height: 3.91rem * 0.8;
    margin-top: 1.25rem * 0.8;
  }
}
.ruleContent {
  width: 19.81rem * 0.8;
  box-sizing: border-box;
  padding: 0 1.5rem * 0.8 2rem * 0.8;
}

.ruleContent .ruleTitle {
  font-size: 0.83rem * 0.8;
  text-align: center;
  margin-bottom: 1.75rem * 0.8;
}

.ruleContent .text {
  line-height: 1.8em;
  font-size: 0.58rem * 0.8;
}

@media screen and (max-width: 992px) {
  .ruleContent {
    width: 23rem * 0.8;
    box-sizing: border-box;
    padding: 0 1.5rem * 0.8 2rem * 0.8;
  }

  .ruleContent .ruleTitle {
    font-size: 1.3rem * 0.8;
    text-align: center;
    margin-bottom: 1.75rem * 0.8;
  }

  .ruleContent .text {
    line-height: 1.8em;
    font-size: 1rem * 0.8;
  }
}
</style>