<template>
  <div class="slide" v-if="list.length">
    <ul class="slide-ul">
      <swiper ref="mySwiper" :options="swiperOptions" class="swiper-more">
        <swiper-slide
          v-for="(item1, index1) in list"
          :key="index1"
          @click="getBox(item1.box_id)"
          ><li
            :style="{
              backgroundImage: 'url(' + getColorPz(item1.color) + ')',
            }"
          >
            <div class="slide-warp">
              <div class="top-right">
                <img src="../assets/img/money.png" alt="" />
                {{ item1.price }}
              </div>
              <div class="left">
                <img :src="item1.imageUrl" />
              </div>
              <div class="right">
                <h4 class="r-2">{{ item1.skin_name }}</h4>
              </div>
            </div>
            <div class="slide-warp1">
              <div class="left">
                <img :src="item1.player_img" />
              </div>
              <div class="right">
                <h4 class="r-2">{{ item1.player_name }}</h4>
              </div>
              <div class="time">{{ timeAgo(item1.create_time) }}获得</div>
            </div>
          </li>
        </swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper>
    </ul>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <!-- <swiper ref="mySwiper" :options="swiperOptions" class="swiper-more">
        <swiper-slide v-for="(item, index) in listSlide" :key="index"
          ><img :src="item.img" width="100%" style="object-fit: contain"
        /></swiper-slide>
        <div class="swiper-pagination"></div>
      </swiper> -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import Swiper2, { Navigation, Pagination, Autoplay } from "swiper";
Swiper2.use([Navigation, Pagination, Autoplay]);
import moment from "moment";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 6, // 显示6个幻灯片
        spaceBetween: 10, // 幻灯片之间的间距
        loop: false, // 不循环
        navigation: {
          nextEl: ".swiper-button-next", // 下一个按钮的选择器
          prevEl: ".swiper-button-prev", // 上一个按钮的选择器
        },
        breakpoints: {
          // 响应式配置
          1900: {
            slidesPerView: 6,
          },
          1280: {
            slidesPerView: 5,
          },
          900: {
            slidesPerView: 4,
          },
          600: {
            slidesPerView: 3,
          },
          320: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  props: ["showNav", "box_id",'list'],
  methods: {
    timeAgo(item) {
      const now = moment(); // 获取当前时间
      const time = moment(item, "YYYY-MM-DD HH:mm:ss"); // 解析时间字符串，指定格式

      const diffInMinutes = now.diff(time, "minutes"); // 计算分钟差
      if (diffInMinutes < 60) {
        return `${diffInMinutes}分钟前`;
      }

      const diffInHours = now.diff(time, "hours"); // 计算小时差
      if (diffInHours < 24) {
        return `${diffInHours}小时前`;
      }

      const diffInDays = now.diff(time, "days"); // 计算天数差
      return `${diffInDays}天前`;
    },
    getRandomElementsFromArray(array, numElements) {
      if (numElements > array.length) {
        throw new Error(
          "Number of elements to get is greater than array length."
        );
      }

      const shuffledArray = array.slice(); // Clone the array to avoid modifying the original
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [
          shuffledArray[j],
          shuffledArray[i],
        ];
      }

      return shuffledArray.slice(0, numElements);
    },

    getColorPz(color) {
      // 金色
      if (color == "#FFA500") {
        return require("../assets/img/articlebg0.fa1c808d.png");
      }
      // 红色
      if (color == "#FF4500") {
        return require("../assets/img/articlebg1.ffcfea89.png");
      }
      // 蓝色
      if (color == "#4169E1") {
        return require("../assets/img/articlebg3.d4bee110.png");
      }
    },
    transferColorToRgb(color) {
      if (typeof color !== "string" && !(color instanceof String))
        return console.error("请输入16进制字符串形式的颜色值");
      color = color.charAt(0) === "#" ? color.substring(1) : color;
      if (color.length !== 6 && color.length !== 3)
        return console.error("请输入正确的颜色值");
      if (color.length === 3) {
        color = color.replace(/(\w)(\w)(\w)/, "$1$1$2$2$3$3");
      }
      var reg = /\w{2}/g;
      var colors = color.match(reg);
      for (var i = 0; i < colors.length; i++) {
        colors[i] = parseInt(colors[i], 16).toString();
      }
      return "rgb(" + colors.join() + ")";
    },
    getBox(box_id) {
      this.$router.push({
        path: `/Openbox`,
        query: {
          box_id: box_id,
        },
      });
    },
  },
  activated() {
    // var that = this;
    // that.getList();
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="less" scoped>
.slide /deep/ .el-carousel__indicators--outside {
  display: none;
}

::v-deep {
  .el-carousel__container {
    background-color: #10151b;
  }
}

.slide {
  position: relative;
  height: 180px;
  width: 100%;
  overflow: hidden;

  // padding: 0 0;
  .slide-ul {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    transition: transform 0.5s ease; /* 平滑过渡效果 */

    // animation: run57 30s;
    // // 匀速滚动
    // animation-timing-function: linear;
    // // 反向
    // animation-direction: alternate;
    // animation-iteration-count: infinite; //播放几次动画
    // animation-delay: 0s; //动画运行前等待时间
    // animation-fill-mode: forwards !important; //动画结束 是否保持

    // @keyframes run57 {
    // 	0% {
    // 		transform: translate3d(0px, 0px, 0);
    // 	}

    // 	100% {
    // 		//50
    // 		transform: translate3d(-2400px, 0px, 0);
    // 	}
    // }
    .scrollAnimal {
      animation: scrollFirstChild 2s;

      @keyframes scrollFirstChild {
        0% {
          width: 0;
          opacity: 0;
        }

        100% {
          width: 140px;
          opacity: 1;
        }
      }
    }

    li:hover {
      top: -180px;
    }
    li:first-child {
      margin-left: 0;
    }
    li {
      position: relative;
      top: 0;
      transition: all 0.5s;
      // background-image: linear-gradient(rgba(43, 44, 55, 0.5),
      // 		rgba(173, 200, 203, 0.5));
      background-size: 100% 100%;
      margin-left: 20px;
      width: 100%;
      height: 180px;

      .slide-warp {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 180px;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        .top-right {
          color: #fff;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-end;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .left {
          width: 60%;
          // height: 100%;
          // margin-right: 10px;

          //flex: 1 1 auto;
          img {
            height: auto;
            width: 100%;
          }
        }

        .right {
          //flex: 2 1 auto;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          h4 {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: white;
            font-weight: 400;
            font-size: 12px;
          }

          h5 {
            color: #848492;
            white-space: nowrap;
            font-weight: 200;
            font-size: 12px;

            span {
              color: #adc8cb;
              font-size: 12px;
            }
          }

          h6 {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              color: #848492;
              font-size: 12px;
            }
          }
        }
      }

      .slide-warp2 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 180px;
        overflow: hidden;

        .left {
          margin-right: 10px;

          //flex: 1 1 auto;
          img {
            height: 70px;
            width: auto;
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: white;
            font-weight: 400;
            font-size: 12px;
          }

          h5 {
            color: #848492;
            white-space: nowrap;
            font-weight: 200;
            font-size: 12px;

            span {
              color: #adc8cb;
              font-size: 12px;
            }
          }

          h6 {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              color: #848492;
              font-size: 12px;
            }
          }
        }
      }

      .slide-warp1 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 180px;
        overflow: hidden;
        background: url("../assets/img/userBg.00ea4aa1.png") no-repeat 50% /
          cover;
        .left {
          //flex: 1 1 auto;
          // background: url("../assets/img/avatarCircle.90f12993.png") no-repeat;
          padding: 0.3rem;
          background-size: 100% 100%;
          img {
            border-radius: 50%;
            height: 50px;
            width: 50px;
            // width: auto;
          }
        }

        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          h4 {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #ffc600;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
          }
        }
        .time {
          font-size: 12px;
          color: #fff;
        }
      }

      .line {
        position: absolute;
        bottom: 4px;
        width: 100%;
        height: 2px;
        background-color: #acc7ca;
      }

      .line1 {
        background-color: #b868b3;
      }

      .line2 {
        background-color: #f1a921;
      }

      // background-color: rgba(65, 105, 161, 0.4);
      // border-bottom: 2px solid #fff;
    }

    .li1 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(185, 105, 212, 0.5)
      );
    }

    .li2 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(241, 169, 32, 0.5)
      );
    }
  }

  .slide-ul:hover {
    cursor: pointer;
  }
}

.ul-line {
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: -1;
}

/deep/ .el-carousel__item.is-animating {
  transition: transform 0.4s ease-in-out;
}


.swiper-container {
  height: 180px !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: #6917ff !important;
}
@media screen and (max-width: 767px) {
  .swiper-container{
    height: 92px !important;
  }
  .slide {
    position: relative;
    height: 92px;
    width: 100%;
    overflow: hidden;

    // padding: 0 0;
    .slide-ul {
      position: absolute;
      display: flex;
      flex-wrap: nowrap;
      .scrollAnimal {
        animation: scrollFirstChild1 2s;

        @keyframes scrollFirstChild1 {
          0% {
            width: 0;
            opacity: 0;
          }

          100% {
            width: 98px;
            opacity: 1;
          }
        }
      }

      li {
        position: relative;
        top: 0;
        transition: all 0.5s;
        // background-image: linear-gradient(rgba(43, 44, 55, 0.5),
        // 		rgba(173, 200, 203, 0.5));
        background-size: 100% 100%;
        margin-left: 2px;
        height: 100%;

        .slide-warp2 {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 98px;
          height: 100%;
          overflow: hidden;

          .left {
            margin-right: 0;

            //flex: 1 1 auto;
            img {
              height: 45px;
              width: auto;
            }
          }

          .right {
            //flex: 2 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            h4 {
              width: 78px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: white;
              font-weight: 400;
              font-size: 8px;
            }
          }
        }

        .line {
          position: absolute;
          bottom: 4px;
          width: 100%;
          height: 2px;
          background-color: #acc7ca;
        }

        .line1 {
          background-color: #b868b3;
        }

        .line2 {
          background-color: #f1a921;
        }

        // background-color: rgba(65, 105, 161, 0.4);
        // border-bottom: 2px solid #fff;
      }

      .li1 {
        background-image: linear-gradient(
          rgba(43, 44, 55, 0.5),
          rgba(185, 105, 212, 0.5)
        );
      }

      .li2 {
        background-image: linear-gradient(
          rgba(43, 44, 55, 0.5),
          rgba(241, 169, 32, 0.5)
        );
      }
    }

    .slide-ul:hover {
      cursor: pointer;
    }
  }
  .slide {
    .slide-ul {
      overflow: hidden;
      li {
        // width: 50% !important;
        .slide-warp {
          height: 100%;
          padding: 5px 0 !important;
          .right{
            width: 90%;
            margin: 0 auto;
          }
          .left {
            height: 45px;
            margin-right: 5px !important;
            //flex: 1 1 auto;
            img {
              margin-top: 0px !important;
              height: 50px !important;
              width: auto;
            }
          }
        }
      }
    }
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 26px;
  }
}
</style>
