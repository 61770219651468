<template>
  <div class="roll">
    <div class="box" v-if="showDh">
      <div class="boxx">
        <div class="queren">确认兑换</div>
        <div class="queren" style="font-size: 12px">
          {{ buyCart && buyCart[0].itemName }}
        </div>
        <div class="center">
          <img
            :src="buyCart && buyCart[0].imageUrl"
            style="width: 150px"
            alt=""
          />
          <div>
            <img
              src="../assets/img/masonry.png"
              style="width: 20px"
              slot="prefix"
            />
            <span style="margin-left: 6px">{{
              buyCart && buyCart[0].price
            }}</span>
          </div>
        </div>
        <div class="tbutton">
          <div @click="quxiao()">取消</div>
          <div @click="queren()">确认</div>
        </div>
      </div>
    </div>
    <div class="roll-warp">
      <!-- <div class="roll-hint">
				选择你心仪的商品（您的可用金币数量为<span>
					{{ $store.state.money }} </span>)
			</div> -->

      <!-- <div class="buy-btn-phone">
				<span @click="buyCarPhone">我的购物车</span>
			</div> -->
      <div class="oran-sel">
        <div class="sel-top">
          <ul>
            <li
              v-for="(item, index) in selList"
              :key="index"
              @click="selOran(item.status, item.id)"
            >
              <div
                :class="
                  item.id == classId
                    ? 'seltop-warp seltop-warp1'
                    : 'seltop-warp'
                "
              >
                <img :src="item.img" />
                <span>{{ item.name }}</span>
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="class-box">
					<div class="class-list" v-if="classList.length">
						<ul>
							<li v-for="(item, index) in classList" :key="index">
								<span :class="item.id == subClassId ? 'active' : 'span2'"
									@click="setSubClassId(item)">{{ item.name }}</span>
							</li>
						</ul>
					</div>
				</div> -->
      </div>
      <!-- <div class="roll-pirce">
        <el-input
          placeholder="按名称搜索"
          v-model="searchKey"
          style="width: calc(100% - 86px); margin-right: 12px; max-width: 280px"
          class="input1"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search input1-i"
            @click="getList(page)"
          ></i>
        </el-input>
        <div class="sort-block" @click="listSort">
          <div style="color: #fff; font-size: 14px; font-weight: bold">价格</div>
          <div class="sort-icon">
            <img src="../assets/img/s1.png" v-show="priceSort == 'desc'" alt="" />
            <img src="../assets/img/s2.png" v-show="priceSort == 'asc'" alt="" />
            <img src="../assets/img/s3.png" v-show="priceSort == 0" alt="" />
          </div>
        </div>
      </div> -->
      <div class="dreams-many">
        <div class="flexBox">
          <div class="min inputBox">
            <el-input v-model="input1" placeholder="最低价"></el-input>
            <!-- <input
              type="text"
              placeholder="最低价"
              field_signature="1318412689"
              form_signature="17811488755166338769"
              alternative_form_signature="6061755047663452562"
              visibility_annotation="true"
            /> -->
          </div>
          <span>~</span>
          <div class="max inputBox">
            <el-input v-model="input2" placeholder="最高价"></el-input>
          </div>
          <div class="priceSort" @click="listSort">
            <span>Price</span>
            <img
              :class="priceSort == 0 ? 'asc' : 'desc'"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAcCAYAAAB75n/uAAAAAXNSR0IArs4c6QAAARhJREFUSEvtlrENwkAMRf8XU8BGdBRISFBSsQEVHYxARwkCREPFQgimQEaOkii5+O5Mi5Iukf3f+Z9thXA+IjIGcCjDlyQfnlR6gjRGRF4AhmX8m+TIk/sLQJqCJF25rqCygh6QvjIR6S3qLbId+INBC7bkiuTdKtY7ByIyAbCvt26wJT8A5iSvIcQDEJEZgCOAQZn/ZADQ7yYkBzDEVasAaEm3BtWEpAARcT3otOiiRMCC5CW1rnO5dZvmAq0Kcjl6sNYcpBIAnIOL1ws9GdbWVXcAGbuqzqg46nHzm763xE1AApLahtH2jq6KiF0WJCoeraBScUCS4llAxq6suAvQgHS6yFopoYe/rOstgLXOJYAdyY3nz+4LDh/IcKexlWwAAAAASUVORK5CYII="
              alt=""
            />
          </div>
        </div>
        <div class="dreams-many-left">
          <div class="dreams-many-query">
            <el-input
              v-model="searchKey"
              placeholder="输入装备关键字"
            ></el-input>
            <!-- <input
              class="dreams-many-query-input"
              placeholder="输入装备关键字"
              field_signature="1318412689"
              form_signature="17811488755166338769"
              alternative_form_signature="6061755047663452562"
              visibility_annotation="true"
            /> -->
            <div class="dreams-many-query-input-icon" @click="getList(page)">
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="roll-list">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="buyState(item.id)"
            :style="{ '--color': item.rarityColor }"
          >
            <div class="list-warp">
              <!-- list-bor -->
              <!-- <div :class="item.state ? '' : ''"></div>
              <span class="ico">{{ item.exteriorName }}</span> -->
              <!-- <span v-if="item.state" class="buy-state">
								<img src="../assets/img/gou.png" />
							</span> -->
              <div
                class="list-img"
                :style="{
                  'background-image': `url(${getBgColor(item.rarityColor)})`,
                }"
              >
                <img :src="item.imageUrl" />
                <div class="bot">
                  <div class="list-exteriorName" :title="item.exteriorName">
                    {{ item.exteriorName }}
                  </div>
                  <div class="list-name" :title="item.shortName">
                    {{ item.shortName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="list-pirce">
              <div class="pirce-left">
                <img src="../assets/img/masonry.png" />
                <span>{{ item.price }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
      <div class="roll-page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalSize"
          :page-size="pageSize"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- <div :class="buyCarState ? 'roll-right roll-right1' : 'roll-right'">
			<div class="off">
				<span @click="offAll">清空购物车</span><span class="buy-btn-phone1" @click="offBuyCar">继续选购</span>
			</div>
			<div class="shopping-box">
				<div class="num">{{ total_num }} 饰品</div>
				<div class="shopping-cart">
					<ul>
						<li v-for="(item, index) in buyCart" :key="index">
							<div class="cart-warp">
								<div class="cart-top">
									<div class="cart-img">
										<img :src="item.imageUrl" />
									</div>
									<div class="cart-name">
										<h5>{{ item.itemName }}</h5>
										<h6>
											<img src="../assets/img/masonry.png" /><span>{{
                        item.price
                      }}</span>
										</h6>
									</div>
									<div></div>
								</div>
								<div class="cart-bot">
									<span class="sub" @click="subBuy(item.id)">-</span>
									<span class="num">{{ item.num }}</span>
									<span class="add" @click="addBuy(item.id)">+</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="buy">
				支付 <span>{{ total_price }}</span>
			</div>
			<div class="buy-btn" @click="goBuy">
				<span @click="goBuy">立即购买</span>
				立即购买

				<el-button
          class="el-btn"
          :disabled="loading"
          @click="goBuy"
          :style="{ 'background-color': loading ? '#007aff' : '#007aff' }"
          ><i v-if="loading" class="el-icon-loading"></i>立即购买</el-button
        >
			</div>
		</div> -->
    <div class="clear"></div>
    <div class="myhomebot-mag"></div>
    <myhomebot></myhomebot>
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>

    <!-- 底部悬浮 -->
    <!-- <div class="go-mall-car" @click="buyCarPhone" v-if="total_num && $store.state.mobile">
			去结算：{{ total_price }}
		</div> -->
  </div>
</template>

<script>
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import Utils from "./../assets/js/util.js";
import navheader from "@/components/navheader.vue";
export default {
  components: {
    myhomebot,
    myinform,
    navheader,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      loading: false,
      buyCarState: false,
      input1: "",
      input2: "",
      totalSize: 0,
      page: 1,
      pageSize: 24,
      list: [],
      buyCart: [],
      total_num: 0,
      total_price: 0,
      priceSort: 0,

      searchKey: "",
      // 品质
      pzList: [],
      // 外观
      wgList: [],
      // 品质选中
      rarity: "不限",
      // 外观选中
      exterior: "不限",
      // 主分类id
      classId: "",
      // 小分类id
      subClassId: "all",
      // 推荐
      recommend: 1,
      // 二级分类
      classList: [],
      // 一级分类
      selList: [],

      showSure: false,
      showDh: false,
      red: require("../assets/img/skinbg1.535a460d.png"),
      grey: require("../assets/img/skinbg4.c3e49d82.png"),
      orange: require("../assets/img/skinbg0.cadc763a.png"),
      gold: require("../assets/img/skinbg0.cadc763a.png"),
      blue: require("../assets/img/skinbg3.14609471.png"),
    };
  },
  watch: {
    searchKey: {
      handler(value) {
        if (value.length) return;
        this.getList(this.page);
      },
    },

    // 监听一级分类选中获取二级分类
    classId: {
      handler(value) {
        if (!value) return;
        this.classList = [
          {
            name: "全部",
            id: "all",
          },
        ];
        this.subClassId = "all";

        this.$axios
          .post(
            "/index/Store/subclassList",
            this.$qs.stringify({
              type_id: value,
            })
          )
          .then((res) => {
            if (res.data.status) {
              this.classList.push(...res.data.data.list);
              this.subClassId = this.classList[0].id;
            }
          });
      },
    },
  },
  mounted() {
    this.getListClass();
    this.getList(this.page);
    this.getTagAndPz();
  },
  methods: {
    getBgColor(value) {
      // 把字母全部转成大写
      switch (value.toUpperCase()) {
        // 灰色
        case "#B2B2B2":
          // return `background: url("../assets/img/skinbg4.c3e49d82.png") no-repeat;`;
          return this.grey;
        // 红色
        case "#EB4B4B":
          // return ` background: url("../assets/img/skinbg1.535a460d.png") no-repeat;`;
          return this.red;
        // 橙色
        case "#CF6A32":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.orange;
        // 金色
        case "#E4AE39":
        case "#FFD700":
          // return `background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;`;
          return this.gold;
        // 蓝色
        case "#4B69FF":
          // return `background: url("../assets/img/skinbg3.14609471.png") no-repeat;`;
          return this.blue;
      }
    },
    quxiao() {
      this.showDh = false;
    },
    queren() {
      if (this.buyCart && this.buyCart.length) {
        this.loading = true;
        let param = {
          skin_info: this.buyCart,
        };
        this.$axios
          .post("index/Store/buynew", this.$qs.stringify(param))
          .then((res) => {
            let data = res.data;
            this.showDh = false;
            if (data.status == 1) {
              this.loading = false;
              this.buyCart = [];
              for (let i = 0; i < this.list.length; i++) {
                this.list[i].state = false;
              }
              this.total_num = 0;
              this.total_price = 0;
              this.$store.commit("getMoney", data.data.total_amount);
              Utils.$emit("masonry", data.data.total_amount);
              this.$message({
                message: data.msg,
                type: "success",
              });
              this.showSure = false;
            } else {
              this.loading = false;

              let msg = res.data.msg;
              if (msg.indexOf("余额不足") != -1) {
                this.$message({
                  message: "余额不足，请先充值",
                  type: "warning",
                });
                return;
              }
              if (msg.indexOf("商品库存不足") != -1) {
                this.$message({
                  message: "商品库存不足",
                  type: "warning",
                });
                return;
              }
              if (msg.indexOf("请联系管理员") != -1) {
                this.showSure = false;
                this.$message({
                  message: "活动用户不支持购买饰品,请联系管理员",
                  type: "warning",
                });
                return;
              }
              this.showSure = false;
              this.$message({
                message: "请先登录",
                type: "warning",
              });
              this.$store.commit("getLogin", true);
            }
          });
      } else {
        this.$message.error("购物车为空");
      }
    },
    buyState(id) {
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].state = !this.list[i].state;
          // if (this.list[i].state == true) {
          this.buyCart[0] = this.list[i];
          this.showDh = true;
          for (let j = 0; j < this.buyCart.length; j++) {
            if (id == this.buyCart[j].id) {
              this.buyCart[j].num = 1;
            }
          }
          // }
          //  else {
          // 	for (let j = 0; j < this.buyCart.length; j++) {
          // 		if (id == this.buyCart[j].id) {
          // 			this.buyCart[0] = null;
          // 		}
          // 	}
          // }
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    setSubClassId(item) {
      this.subClassId = item.id;
      this.page = 1;
      this.getList();
    },
    selOran(status, id) {
      this.recommend = status == 2 ? 1 : 2;
      this.classId = id;
      this.getList();
    },
    getListClass() {
      this.$axios.post("/index/Store/storeTypeList").then((res) => {
        let data = res.data;
        if (data.status == 1) {
          this.selList = data.data;
          this.classId = this.selList[0].id;
        }
      });
    },
    getTagAndPz() {
      this.$axios
        .post("/index/Store/exterior", this.$qs.stringify({}))
        .then((res) => {
          const { exterior, rarity } = res.data.data;
          this.pzList = ["不限"].concat(rarity.map((item) => item.rarityName));
          this.wgList = ["不限"].concat(
            exterior.map((item) => item.exteriorName)
          );
        });
    },
    //分页
    currentChange(val) {
      this.getList(val);
    },
    //商城列表
    getList(val) {
      let param = {
        page: val,
        pageSize: this.pageSize,
      };
      if (this.priceSort) {
        param["order"] = this.priceSort;
      }
      if (this.searchKey) {
        param["searchKey"] = this.searchKey;
      }
      if (this.input1) {
        param["minPrice"] = this.input1;
      }
      if (this.input2) {
        param["maxPrice"] = this.input2;
      }
      if (this.classId && this.classId != "all") {
        param["type_id"] = this.classId;
      }
      if (this.subClassId && this.subClassId != "all") {
        param["subclass_id"] = this.subClassId;
      }
      if (this.rarity && this.rarity != "不限") {
        param["rarity"] = this.rarity;
      }
      if (this.exterior && this.exterior != "不限") {
        param["exterior"] = this.exterior;
      }
      if (this.classId == "1") {
        param["recommend"] = 1;
        delete param.type_id;
      }

      this.$axios
        .post("index/Store/skinList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.totalSize = data.data.total;
            this.list = data.data.list;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            for (let i = 0; i < this.buyCart.length; i++) {
              for (let j = 0; j < this.list.length; j++) {
                if (this.buyCart[i].id == this.list[j].id) {
                  this.list[j].state = true;
                }
              }
            }
          }
        });
    },
    //手机端
    buyCarPhone() {
      this.buyCarState = !this.buyCarState;
    },
    offBuyCar() {
      this.buyCarState = false;
    },
    //取消全部
    offAll() {
      this.buyCart = [];
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
      }
      this.buyNum();
    },
    //加一
    addBuy(id) {
      for (let i = 0; i < this.buyCart.length; i++) {
        if (this.buyCart[i].id == id) {
          this.buyCart[i].num = this.buyCart[i].num + 1;
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    //减一
    subBuy(id) {
      for (let i = 0; i < this.buyCart.length; i++) {
        if (this.buyCart[i].id == id) {
          this.buyCart[i].num = this.buyCart[i].num - 1;
        }
        if (this.buyCart[i].num == 0) {
          this.buyCart.splice(i, 1);
          for (let j = 0; j < this.list.length; j++) {
            if (this.list[j].id == id) {
              this.list[j].state = false;
            }
          }
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    //购物车数量和价格
    buyNum() {
      this.total_num = 0;
      this.total_price = 0;
      for (let i = 0; i < this.buyCart.length; i++) {
        this.total_num += this.buyCart[i].num;
        this.total_price += Number(this.buyCart[i].price) * this.buyCart[i].num;
      }
      this.total_price = this.total_price.toFixed(2);
    },
    //立即购买
    goBuy() {
      this.loading = true;
      let param = {
        skin_info: this.buyCart,
      };
      this.$axios
        .post("index/Store/buynew", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.loading = false;
            this.buyCart = [];
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            this.total_num = 0;
            this.total_price = 0;
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.showSure = false;
          } else {
            this.loading = false;

            let msg = res.data.msg;
            if (msg.indexOf("余额不足") != -1) {
              this.$message({
                message: "余额不足，请先充值",
                type: "warning",
              });
              return;
            }
            if (msg.indexOf("商品库存不足") != -1) {
              this.$message({
                message: "商品库存不足",
                type: "warning",
              });
              return;
            }
            if (msg.indexOf("请联系管理员") != -1) {
              this.showSure = false;
              this.$message({
                message: "活动用户不支持购买饰品,请联系管理员",
                type: "warning",
              });
              return;
            }
            this.showSure = false;
            this.$message({
              message: "请先登录",
              type: "warning",
            });
            this.$store.commit("getLogin", true);
          }
        });
    },
    //数据排序
    listSort() {
      if (this.priceSort == 0) {
        this.priceSort = "desc";
      } else if (this.priceSort == "desc") {
        this.priceSort = "asc";
      } else if (this.priceSort == "asc") {
        this.priceSort = 0;
      }
      this.getList();
    },
    //筛选
    screen() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
        minPrice: this.input1,
        maxPrice: this.input2,
      };
      this.$axios
        .post("index/Store/skinList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.totalSize = data.data.total;
            this.list = data.data.list;
            this.priceSort = true;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            for (let i = 0; i < this.buyCart.length; i++) {
              for (let j = 0; j < this.list.length; j++) {
                if (this.buyCart[i].id == this.list[j].id) {
                  this.list[j].state = true;
                }
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-input__inner {
    background-color: #2b2c37;
    border: 1px solid #007aff !important;
    color: #c3c3e2;
  }
}

.box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.6);

  .boxx {
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 300px;
    height: 300px;
    // background: rgba(15, 16, 20, 1);
    background: url("../assets/img/bbk.png");
    background-size: 100% 100%;
    padding: 26px 0px;

    .queren {
    }

    .center {
      img {
        margin: 12px 0;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .tbutton {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 24px;

      div {
        background: #007aff;
        padding: 6px 32px;
        color: black;
      }
    }
  }
}

.oran-sel {
  // background: url("../assets/95/linebg.png") no-repeat;
  // background-size: 100% 100%;
  // border-top: 1px solid #007aff;
  // margin: 20px auto 0 auto;
  // background-color: #2b2c37;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 750px) {
    .sel-top {
      ul {
        // display: grid !important;
        // grid-template-columns: repeat(auto-fit, minmax(75px, 1fr)) !important;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
          width: 22% !important;
          margin-left: 0 !important;
        }
      }
    }
  }

  .sel-top {
    // border-bottom: 1px solid #007aff;
    // background-color: #1a1c24;

    ul {
      display: flex;

      li {
        margin-top: 12px;
        margin-left: 12px;

        .seltop-warp {
          width: 75px;
          height: 75px;
          // background-image: url("../assets/img/sc3.png");
          border-radius: 2px;
          border: 1px solid #007aff;
          background-size: 75px;
          background-color: rgba(0, 122, 255, 0.2);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 60%;
            height: auto;
            max-height: 78px;
          }

          span {
            font-size: 12px;
            color: #878787;
          }
        }

        .seltop-warp1 {
          // background-color: #2b2c37;
          // background-image: url('../assets/img/sc4.png');
          background-color: #007aff;
          background-size: 75px;
          border-radius: 0 !important;

          span {
            color: white;
          }
        }
      }

      li:hover {
        cursor: pointer;
      }
    }
  }

  .class-box {
    background-color: #2b2c37;
    padding: 0 20px 0px 20px;

    .class-list {
      margin-top: 15px;

      ul {
        display: flex;

        li {
          margin-right: 10px;

          span {
            border: 1px solid #ffc400;
            border-radius: 5px;
            padding: 5px 16px;
            font-size: 12px;
            color: #fff;
          }

          .span2 {
            border: 1px solid #ffc400;
            color: #fff;
            font-size: 12px;
          }

          span:hover {
            cursor: pointer;
            background-color: #ffc400;
            color: #000;
          }
        }
      }
    }
  }

  .sel-bot {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin: 30px 0 10px 0px;

      &:last-child {
        display: flex;
        justify-content: flex-start;
      }
    }

    .selbot-left {
      flex: 1;
      min-width: 200px;
      min-height: 60px;

      .pz-container,
      .wg-container {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 5px 0;

        .name {
          color: #fff;
          width: 45px;
        }

        .wg-content {
          color: #848492;
          flex: 1;
          white-space: nowrap;
          overflow: auto;

          span {
            padding: 0 10px;
            cursor: pointer;

            &:hover {
              color: #007aff;
            }
          }

          .active {
            color: #007aff;
          }
        }
      }
    }

    .selbot-right {
      max-width: 400px;
      // margin-left: 10px;
      display: flex;
      align-items: center;

      .span {
        margin: 0 8px;
        color: #848492;
      }

      .pirec-btn {
        margin: 0 10px;
        background-color: #333542;
        border-radius: 5px;
        color: #848492;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 26px;
      }

      .pirec-btn:hover {
        cursor: pointer;
        background-color: #3a3f50;
      }

      .input {
        width: 120px;

        img {
          width: auto;
          height: 18px;
        }
      }

      .input /deep/ .el-input__prefix,
      .input /deep/ .el-input__suffix {
        top: 11px;
      }

      .input1 {
        width: 200px;
      }

      .input1-i:hover {
        cursor: pointer;
      }
    }

    .selbot-right /deep/ .el-input__inner {
      background-color: #2b2c37;
      border: 1px solid #007aff;
      color: #c3c3e2;
    }
  }
}

.buy-sure {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  color: #fff;

  .sure {
    position: absolute;
    width: 80%;
    height: 20%;
    max-width: 500px;
    max-height: 500px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #2b2c37;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 20px;
      text-align: center;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        padding: 8px 30px;
        font-size: 14px;
        border: 1px solid #007aff;
        cursor: pointer;
      }

      .sure-btn {
        background-color: #007aff;
        margin-left: 20px;
        color: #000;
      }
    }
  }
}

.class-list {
  margin: 10px 0;
  height: 50px;
  background-color: #2b2c37;

  ul {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;

    li {
      margin-right: 10px;
      line-height: 50px;

      span {
        background-color: #007aff;
        border-radius: 5px;
        padding: 8px 16px;
        font-size: 14px;
      }

      .span2 {
        border: 1px solid #848492;
        background-color: #2b2c37;
        color: #848492;
        font-size: 14px;
      }

      span:hover {
        cursor: pointer;
        background-color: #007aff;
        color: #000;
      }
    }
  }
}

.go-mall-car {
  position: fixed;
  bottom: 20px;
  left: 50%;
  background-color: #007aff;
  color: #000000;
  padding: 8px 0;
  font-weight: bold;
  font-family: ninefont !important;
  width: calc(100% - 80px);
  max-width: 600px;
  transform: translate(-50%, 0);
  border-radius: 50px;
  box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
    0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  font-size: 18px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roll {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #0e1325;

  .myhomebot-mag {
    margin-top: 120px;
  }

  .roll-warp {
    height: 100%;
    padding: 16px 16px 16px 16px;
    max-width: 1500px;
    margin: 0 auto;
    .roll-hint {
      font-size: 16px;
      color: #848493;

      span {
        color: #007aff;
      }
    }

    .roll-pirce {
      display: flex;
      flex-direction: row;
      align-items: center;
      // background-color: #2b2c37;
      padding: 20px 0 0;

      .sort-block {
        background-color: #007aff;
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        color: #333;
        display: flex;
        font-size: 15px;
        align-items: center;
        // margin-right: 10px;
        cursor: pointer;
        user-select: none;

        .sort-icon {
          display: flex;
          flex-direction: column;
          margin-left: 10px;

          img {
            width: 14px;
          }

          .icon {
            color: #333;
            font-size: 10px !important;
          }

          .active-icon {
            color: #fff !important;
          }
        }
      }

      .left {
        flex: 1;
        // min-width: 200px;
        min-height: 60px;

        .shengjiang {
          padding: 8px 14px;
          border-radius: 4px;
          background-color: #007aff;
          display: flex;
          align-items: center;

          span {
            margin-right: 6px;
          }

          img {
            width: 14px;
          }
        }

        .pz-container,
        .wg-container {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 5px 0;
          width: 100%;

          .name {
            color: #fff;
            width: 45px;
          }

          .wg-content {
            color: #848492;
            // flex: 1;
            white-space: nowrap;
            overflow: auto;

            span {
              padding: 0 10px;
              cursor: pointer;

              &:hover {
                color: #007aff;
              }
            }

            .active {
              color: #007aff;
            }
          }
        }
      }

      .right {
        max-width: 400px;
        display: flex;
        align-items: center;
        // margin-left: 10px;

        span {
          margin-left: 5px;
          margin-right: 5px;
          color: #007aff;
        }

        .screen {
          height: 40px;
          line-height: 40px;
          margin-left: 20px;
          background-color: #007aff;
          padding: 0 30px;
          font-weight: 600;
          color: #fff;
          border-radius: 5px;
        }

        .screen:hover {
          cursor: pointer;
        }

        .input {
          width: 100px;

          img {
            width: auto;
            height: 15px;
          }
        }

        .input /deep/ .el-input__prefix,
        .el-input__suffix {
          top: 12.5px;
        }

        .input /deep/ .el-input__inner {
          background-color: #000;
          color: white;
          border: 1px solid #893e8a;
        }
      }
    }

    .roll-list {
      margin-top: 20px;

      ul {
        margin: 0 -4px;

        li {
          width: 14.285%;
          float: left;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 1rem;
          .list-warp {
            margin: 4px;
            width: 6.87em * 1.8;
            height: 6.53em * 1.8;
            overflow: hidden;
            background: hsla(0, 0%, 100%, 0.05) no-repeat 50% / 8em 8em;
            // border: 1px solid rgba(0, 122, 255, 1);
            border-radius: 20px;
            position: relative;
            overflow: hidden;

            .list-bor {
              position: absolute;
              top: 0;
              z-index: 666;
              width: 100%;
              height: 100%;
              background-color: rgba(233, 177, 14, 0.1);
            }

            .ico {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 12px;
              // color: #fafcfa;
              // background-color: rgb(81, 113, 156);
              color: #ccc;
              padding: 8px 8px;
            }

            .buy-state {
              position: absolute;
              top: 0;
              right: 0;

              img {
                width: 15px;
                height: 15px;
              }
            }

            .list-img {
              // background-image: url("../assets/img/1700642832210.jpg");
              overflow: hidden;
              padding: 20px 0;
              display: flex;
              align-items: center;
              flex-direction: column;
              cursor: pointer;
              position: relative;
              background: hsla(0, 0%, 100%, 0.05) no-repeat;
              background-repeat: no-repeat;
              background-size: 8em * 1.6 8em * 1.6 !important;

              .bot {
                position: absolute;
                bottom: 1em;
                left: 1em;
                width: 90%;
                .list-exteriorName {
                  font-size: 0.5em;
                  color: #a9abbc;
                }
                .list-name {
                  font-size: 0.58em;
                  color: #fff;
                }
              }
              // height: 130px;
              img {
                margin: 32px 0;
                width: 4.58em * 1.6 !important;
                // height: 40% !important;
                // max-height: 40% !important;
              }
            }
            .grey {
              background: url("../assets/img/skinbg4.c3e49d82.png") no-repeat;
            }
            .red {
              background: url("../assets/img/skinbg1.535a460d.png") no-repeat;
            }
            .orange {
              background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;
            }
            .blue {
              background: url("../assets/img/skinbg3.14609471.png") no-repeat;
            }
            .gold {
              background: url("../assets/img/skinbg0.cadc763a.png") no-repeat;
            }

            @media screen and (max-width: 767px) {
              .list-img {
                background-image: url("../assets/img/1700642832210.jpg");
                // background-image: url("https://oss.wwmanghe.com/prod/lucky/f1d5f606-2ba9-4ce6-b4dc-ab44553e3c1a.png?x-oss-process=image/format,webp");
                background-size: 100% 100%;
                padding: 0 20px;
                overflow: hidden;
                padding-top: 32px !important;
                display: flex;
                align-items: center;
                flex-direction: column;

                // height: 130px;
                img {
                  margin: 24px 0;
                  width: auto !important;
                  height: 90px !important;
                  max-height: 90px !important;
                }
              }
            }

            .bot {
              // background-color: #2b2c37;
              .list-name {
                // text-align: center;
                font-size: 14px;
                color: #c3c3e2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          .list-warp::after {
            display: block;
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: var(--color);
          }
        }
      }
    }

    .roll-page {
      margin: 10px 0 0px -10px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .roll-page
      /deep/
      .el-pagination.is-background
      .el-pager
      li:not(.disabled).active {
      background-color: #007aff;
      color: #fff;
    }

    .roll-page /deep/ .el-pagination.is-background .btn-next,
    /deep/ .el-pagination.is-background .btn-prev,
    /deep/ .el-pagination.is-background .el-pager li {
      background-color: #333542;
      color: #848492;
    }
  }
  .list-pirce {
    background: #6917ff;
    border-radius: 0.17rem;
    color: #fff;
    width: 90%;
    font-weight: 700;
    cursor: pointer;
    margin-top: 0.33rem;
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 0 auto;

    .pirce-left {
      display: flex;
      align-items: center;
      line-height: 3rem;
      img {
        width: auto;
        height: 15px !important;
        margin: 0 !important;
        margin-right: 5px !important;
      }

      span {
        color: white !important;
        font-size: 16px;
      }
    }

    .pirce-right {
      color: #848492;
      font-size: 14px;
    }
  }

  .roll-right {
    position: fixed;
    width: 250px;
    height: 100%;
    top: 60px;
    right: 0px;
    z-index: 888;
    background-color: #30313f;
    text-align: center;
    border-left: 1px solid #30313f;

    .off {
      padding: 16px;
      background-color: #fff;

      span {
        background-color: #30313f;
        border-radius: 5px;
        padding: 8px 16px;
        color: #848492;
        font-size: 14px;
        font-weight: 600;
      }

      span:hover {
        cursor: pointer;
      }

      .buy-btn-phone1 {
        display: none;
        margin-left: 10px;
        padding: 8px 16px;
        background-color: #007aff;
        border-radius: 5px;
        color: #1a1c24;
        font-size: 15px;
        font-weight: 600;
      }
    }

    .shopping-box {
      height: calc(~"100vh - 300px");
      padding: 16px 16px 16px 8px;

      .num {
        text-align: center;
        padding: 0 0 16px 0;
        color: #007aff;
      }

      .shopping-cart {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        ul li {
          margin-top: 10px;
          margin-right: 10px;
          padding: 8px;
          background-color: #2b2c37;

          .cart-warp {
            .cart-top {
              display: flex;

              .cart-img {
                min-width: 70px;
                max-width: 70px;
                min-height: 55px;
                max-height: 55px;
                background-image: url("../assets/img/box-skins-blue.jpg");
                background-size: 100% 100%;
                text-align: center;

                img {
                  width: 100%;
                  height: auto;
                }
              }

              .cart-name {
                margin-left: 10px;

                h5 {
                  font-size: 14px;
                  color: #c3c3e2;
                }

                h6 {
                  display: flex;
                  align-items: center;

                  img {
                    width: auto;
                    height: 15px;
                  }

                  span {
                    margin-left: 5px;
                    color: #007aff;
                    font-size: 16px;
                  }
                }
              }
            }

            .cart-bot {
              display: flex;
              align-items: center;

              span {
                color: #c3c3e2;
              }

              .num {
                background-color: #444659;
                padding: 3px 12px;
                font-size: 15px;
              }

              .sub,
              .add {
                font-size: 24px;
                padding: 0 12px;
              }

              .sub:hover,
              .add:hover {
                cursor: pointer;
              }
            }
          }
        }

        img {
          width: 50px;
          height: 50px;
        }
      }

      /*滚动条样式*/
      .shopping-cart::-webkit-scrollbar {
        width: 4px;
        /*height: 4px;*/
      }

      .shopping-cart::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }

      .shopping-cart::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .buy {
      background-color: #2b2c37;
      padding: 16px;
      text-align: center;
      font-size: 14px;
      color: #c3c3e2;

      span {
        margin-left: 10px;
        color: #007aff;
      }
    }

    .buy-btn {
      margin-top: 20px;
      text-align: center;

      .el-btn {
        color: #1a1c24;
        font-size: 15px;
        font-weight: 600;
      }

      span {
        color: #1a1c24;
        background-color: #007aff;
        padding: 8px 80px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
      }

      span:hover {
        cursor: pointer;
        background-color: #3088ff;
      }
    }
  }

  .roll-right1 {
    display: block;
    z-index: 88;
  }

  .buy-btn-phone {
    display: none;
    margin-top: 20px;

    span {
      padding: 8px 22px;
      background-color: #007aff;
      border-radius: 5px;
      color: #1a1c24;
      font-size: 15px;
      font-weight: 600;
    }
  }
}
.dreams-many {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.05);
  padding: 1rem;
}

.dreams-many .flexBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.dreams-many .flexBox span {
  margin: 0 0.33rem;
}

.dreams-many .flexBox .inputBox {
  width: 4rem * 1.6;
}
/deep/.el-input__inner {
  border: transparent !important;
}
.dreams-many .flexBox .inputBox input {
  width: 100%;
  height: 1.83rem;
  padding: 0.2rem 0.52rem;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.17rem;
  color: #bbb;
  box-sizing: border-box;
}

.dreams-many .flexBox .inputBox input:focus {
  border: 1px solid #fbc600;
}

@media screen and (max-width: 992px) {
  .dreams-many .flexBox .inputBox input {
    font-size: 1rem;
    height: 3.08rem;
    padding: 0.4rem 1rem;
  }
}

.dreams-many .priceSort {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff;
  }
}

.dreams-many .priceSort img {
  width: 0.5rem * 1.4;
  padding: 0.5rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border: 1px solid #333544;
  border-radius: 0.17rem;
  margin-left: 0.33rem;
  margin-right: 0.5rem;
}

.dreams-many .priceSort .asc {
  transform: rotate(0);
}

.dreams-many .priceSort .desc {
  transform: rotate(180deg);
}

.dreams-many .dreams-many-left {
  display: flex;
  align-items: center;
}

.dreams-many .dreams-many-left .dreams-many-query {
  width: 15.54rem * 1.4;
  background: rgba(25, 27, 41, 0.3);
  border: 1px solid #333544;
  border-radius: 0.17rem * 1.4;
  display: flex;
  align-items: center;
}

.dreams-many .dreams-many-left .dreams-many-query .dreams-many-query-input {
  color: #fff;
  width: 15.54rem * 1.4;
  height: 1.67rem * 1.4;
  margin-left: 0.3rem * 1.4;
  font-size: 0.58rem * 1.4;
}

.dreams-many
  .dreams-many-left
  .dreams-many-query
  .dreams-many-query-input-icon {
  width: 1.92rem * 1.4;
  height: 1.67rem * 1.4;
  background: rgba(25, 27, 41, 0.3);
  border: 1px solid #333544;
  border-radius: 0.17rem * 1.4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dreams-many
  .dreams-many-left
  .dreams-many-query
  .dreams-many-query-input-icon
  > div {
  width: 0.83rem * 1.4;
  height: 0.83rem * 1.4;
  background-image: url("../assets/img/lucky/search.b7a10728.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
</style>
