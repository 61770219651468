<template>
  <div class="dota">
    <div v-if="bianjiopens" class="bbbc">
      <div class="boxs">
        <div class="ul">
          <div v-for="(item, index) in touxiang">
            <div @click="changeToux(index)">
              <img
                :src="item.img"
                :class="{ selectToux: index == selectToux }"
                alt=""
              />
            </div>
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="boxs_bottom">
          <span @click="bianjiopens = false">取消</span>
          <span @click="changePhoto">确认</span>
        </div>
      </div>
    </div>
    <div class="dota-warp">
      <!-- <img class="topimg" src="../assets/img/my/home_cell_line_1.png" alt="" /> -->
      <!-- 内容区域 -->
      <div class="header">
        <div class="avatar">
          <div class="pic" :style="{ 'background-image': `url(${Data.img})` }">
            <div class="upload" @click="bianjiopen"></div>
          </div>
          <div class="nameBox">
            <div class="username">
              <span>{{ Data.name }}</span
              ><span class="edit">编辑</span>
            </div>
            <div class="vipLevel">
              <img src="/img/V0@2x.510fc35b.png" alt="" />
            </div>
            <div class="invitationCode" @click="copyText(valueUrl)">
              邀请码
              <div class="Copy">
                {{ Data.invite_code }}
              </div>
            </div>
          </div>
        </div>
        <div class="money">
          <div class="balance">
            <div class="balanceTitle">余额:</div>
            <div class="moneyVal">
              <div class="MoneyCoin">
                <img src="../assets/img/money.png" alt="" />
                <span class="moneyVal" style="color: rgb(255, 49, 237)">{{
                  $store.state.user.total_amount
                }}</span>
              </div>
            </div>
          </div>
          <div class="btnBox">
            <div class="recharge _btnClick1" @click="recharge()">充值</div>
            <div class="recharge realName" @click="realNameFn()">
              实名认证
            </div>
            <div class="recharge realName" @click="openHongbao">
              CDK
            </div>
          </div>
        </div>
        <div class="steamLink">
          <div class="top">
            <div class="link">
              <el-input v-model="trading" placeholder=""></el-input>
              <div class="save" @click="confirm">保存</div>
            </div>
            <div class="give" @click="goUrl(url1)">获取</div>
          </div>
          <div class="tips">
            <p>
              1、交易链接一旦绑定则无法更换,请谨慎操作,同一链接更新token不限制.
            </p>
            <p>2、请勿绑定他人的steam交易链接</p>
          </div>
        </div>
      </div>
      <div class="inBody">
        <!-- 左侧边栏 -->
        <div class="leftNav">
          <div class="tabBox">
            <div
              v-for="(item, index) in tabItems"
              :key="index"
              class="tabItem _tabBtn"
              :class="{ active: item.active }"
              @click="selectTab(item)"
            >
              <img
                :src="item.active ? item.activeImage : item.inactiveImage"
                alt=""
              />
              <span>{{ item.label }}</span>
            </div>
            <!-- <div class="setAudio openAudio">
              <span>已启用音效</span
              ><img class="audioImg" src="/img/audioOpen.8c7e5fd0.png" alt="" />
            </div> -->
          </div>
          <div class="logout" @click="nextLogin()">
            <i class="el-icon-switch-button"></i>
            退出登录
          </div>
        </div>
        <div class="skins informationView">
          <!-- 手机端 -->
          <div
            v-if="!showNav"
            class="BackTop _mobile"
            style="background-color: rgba(255, 255, 255, 0.05)"
          >
            <div class="left">
              <div class="backBox">
                <svg
                  data-v-0f4c779f=""
                  class="svg-icon backSvg"
                  aria-hidden="true"
                >
                  <use
                    data-v-0f4c779f=""
                    xlink:href="#icon-arrow-left"
                  ></use></svg
                ><span>我的物品</span>
              </div>
            </div>
            <div class="right"><!----><!----></div>
          </div>
          <!-- 我的物品 -->
          <MyKucun
            v-show="leftNavCurrent == '1'"
            :type="0"
            :showNav="showNav"
            ref="MyKucun0"
          ></MyKucun>
          <!-- 我的福利 -->
          <MyWelfare
            v-show="leftNavCurrent == '2'"
            :showNav="showNav"
          ></MyWelfare>
          <!-- 收支明细 -->
          <Income v-show="leftNavCurrent == '4'" :showNav="showNav"></Income>
          <div class="skins-condition-border _mobile" v-if="!showNav"></div>
          <!-- 手机端 -->
          <div class="skins-condition-action _mobile" v-if="!showNav">
            <div class="skins-condition-action-select">
              <div class="selectAllBox">
                <div class="skins-condition-action-select-frame"></div>
                全选
              </div>
              <div class="skins-condition-action-price">
                已选 0 件, 共
                <div class="MoneyCoin">
                  <svg
                    data-v-0f4c779f=""
                    class="svg-icon"
                    aria-hidden="true"
                    style="color: rgb(255, 255, 255)"
                  >
                    <use data-v-0f4c779f="" xlink:href="#icon-A8"></use></svg
                  ><span class="moneyVal" style="color: rgb(255, 255, 255)"
                    >0.00</span
                  >
                </div>
              </div>
            </div>
            <div class="skins-condition-action-button">
              <div class="skins-condition-action-btn btn-green">取回</div>
              <div class="skins-condition-action-btn btn-yellow">分解</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 内容区域 结束-->

      <!-- <div class="flexbox">
        <div class="flex-left">
          <div class="fl-top">
            <div class="touxiang">
              <img :src="Data.img" alt="" />
              <div class="bianji" @click="bianjiopen">编辑头像</div>
            </div>
            <div>
              <span>{{ Data.name }}</span>
              <span>Lv.{{ Data.grade }}</span>
            </div>
          </div>
          <div class="fl-center">
            <div class="moneny">
              <img src="../assets/img/money.png" alt="" />
              <div>
                <span>金币余额</span>
                <span>{{ $store.state.user.total_amount }}</span>
              </div>
            </div>
            <div class="moneny">
              <img src="../assets/img/masonry.png" alt="" />
              <div>
                <span>钻石余额</span>
                <span>{{ $store.state.user.masonry }}</span>
              </div>
            </div>
          </div>
          <div class="fl-button">
            <span @click="menuClick(4)">充值</span>
            <span @click="menuClick(6)">CDK兑换</span>
          </div>
          <div class="fl-group">
            <div
              :class="{ select: item.select }"
              v-for="(item, index) in menu"
              v-if="item.is"
              @click="menuClick(index)"
            >
              <img :src="item.img" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div class="fl-tuichu" @click="nextLogin">退出登录</div>
        </div>
        <div class="flex-right">
          <MyKucun
            v-show="menu[1]['select']"
            :type="1"
            ref="MyKucun1"
          ></MyKucun>
          <MyKucun
            v-show="menu[2]['select']"
            :type="2"
            ref="MyKucun2"
          ></MyKucun>
          <MyJiLu v-show="menu[3]['select']"></MyJiLu>
          <MyChongZhi v-show="menu[4]['select']"></MyChongZhi>
          <MySheZhi v-show="menu[5]['select']" :Data="Data"></MySheZhi>
          <MyCdk v-show="menu[6]['select']"></MyCdk>
          <MyTuiGuang v-show="menu[7]['select']"></MyTuiGuang>
        </div>
      </div> -->
    </div>
    <myhomebot></myhomebot>
    <!-- <div class="extend1" v-if="datalist.passWord">
          <span
            >短信验证<input
              type="text"
              placeholder="请输入短信验证码"
              class="long"
              v-model="dlist.passWordCode"
          /></span>
          <span @click="getCode(0)">{{ btnText }}</span>
        </div> -->
    <div
      class="mask animate__animated"
      :class="realNameDialog ? 'show' : 'hidde'"
      v-show="realNameDialog"
    >
      <div class="popupContent" style="">
        <div class="close" @click="realNameDialog = false">
          <img class="" src="../assets/img/mess/close.10e4447a.png" alt="" />
        </div>
        <div class="RealNamePopup">
          <div class="title">实名认证</div>
          <ul class="content">
            <li class="box">
              <div class="name">姓名</div>
              <el-input
                :disabled="is_realname"
                v-model="realName"
                placeholder="请输入真实姓名"
              ></el-input>
            </li>
            <li class="box">
              <div class="name">身份证号</div>
              <el-input
                :disabled="is_realname"
                v-model="idCard"
                placeholder="请输入身份证号码"
              ></el-input>
            </li>
          </ul>
          <div class="defaultBtn _btnClick1" @click="realNameConfirm">
            {{ !is_realname ? "认证" : "已认证" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import navheader from "@/components/navheader.vue";
import myhomebot from "@/components/my_homebot.vue";
import {
  MyKucun,
  MyJiLu,
  MyChongZhi,
  MySheZhi,
  MyCdk,
  MyTuiGuang,
  MyWelfare,
  Income,
} from "@/views/mes-child";
export default {
  components: {
    myhomebot,
    navheader,
    MyChongZhi,
    MyKucun,
    MyJiLu,
    MySheZhi,
    MyCdk,
    MyTuiGuang,
    MyWelfare,
    Income,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      tabItems: [
        {
          label: "我的物品",
          activeImage: require("../assets/img/mes/wupin2.81e985bc.png"),
          inactiveImage:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAAAXNSR0IArs4c6QAAAbVJREFUOE+l07FrU1EUx/Hv7xpLU0SppdBFUFDxL1BELRmtNOBL8gSV5im4+AdIFyfBxcFNcdGatgqSmFdEjJsgMerg4qaIIIgWUXBJ0SH3SNRK8qg0vN7x3nM+99zDPQqC8pg5d0TqbI5rC1U2uBSUoicSOTO+O5jz+CYdmnG88CWNrUIYfQYmepPNzBBvhZqGf2deH51lWvX6rffrXdKt8KvE2HqBf8+XgecmWpt8p9Vuf3vVaDR+9uZ2wXsSJwYEk2E/gBWgY8ZsXKvMKZ/PjwwNbd9ljofAzpQw3TY5s4NaBQql8iVEANoBbEsDG1z8B/YCU1Ont2az2m0us1fyx0AzBp+EjYKy/7vMjGtrgsmEIIxem9nsUm3+cRBGS4JpwCXiHrRZOTsYWIoeIZ7F1crlQli+8wfSqb6v5nU+vn/7xoBgeVrSomHLoA8CDxxNVHizXq2cGwjsJoZhuMVreNJ7HUJMCh3uqxB7E1fn9w0MrtHXl4L9q/u/p6tjExsAy1eELvSPrC+mBo8Xo5POcbf/2bqaGiwWZw6Ycy8SrXiaGszlzgyPjlsT2IPZiKQMsuu/AJKanMW8qIlyAAAAAElFTkSuQmCC",
          active: true,
        },
        {
          label: "我的福利",
          activeImage: require("../assets/img/mes/fuli2.f96c4f53.png"),
          inactiveImage:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAAAXNSR0IArs4c6QAAAlVJREFUOE/tlD9ok1EUxX/nS2JUFCy6CKWTCKKIm9gOFgQdOkjSL0XamiAiCKLUUgcXFRcHS62I4CBqYqvQ/NNRRahDK25aKg46FcGloFAojUm+K1+ShibUFuzqm96fc89779xzrwA6OzuDLbva8pKOmfFBXul0Ljf+3T/724hG+1rNCT6VOGxmb3/Oz0UmJydL8gNOuv3tAQWmMD4j9gE/ylbueZkZm16NsIafAHZjfEHsL1u5w8dXCF33TLsnb8rDBhyYw/TIsG2CwVwmda+7O37Qx2WzqZmoG79oMIJYEJz1oM1Bo445HZnM4yphJBY/IjRtHoP5bPJOJBLfq4By/s1g30B7qi+tzg2bdTwnms0++RrpTlyWw4hh7fl06r26unpbNm8Nxg2NAhnzvNeVUEdhwXWhnQYVPQWtwDxmN8ysWNlznOP+J4UNLC2WUoq6iU8I/0tWjWkY/l6Nq/rEpvUyuBprzPiES4jniIdrZXW9M/PsnNApRWOJRcyGc5nUtfWC1rSRG7+JNOQTFsDmQLMbIQQ7AGpTxE0MIS7VBC+ClStSGoZsEyjYJGsJ029UBYECQMhPnIy7jbbBu1ouhO4XiwWFQgsWDO94AOptInxWKvw6XyxuVygUtkC4eEE4t+q2WWlsE1fyE8nhZYKImxiT6FtJaMZ4PpPsr2N6EkMybjcYe7lS/hOycQ1rzWFZ8KibSCLiTcWYymWSiXpSas1h1W4D9grTu3qBih7BoYYsw0cZfi+sVbkdBZ1osE0sFguUbMsLv2P/Y7W8CbAYTafT5T80/2Jq6WCgeAAAAABJRU5ErkJggg==",
          active: false,
        },
        {
          label: "我的佣金",
          activeImage:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAjNJREFUOE+FU01rE1EUPfclcWzRhQtBBRHEKgqKKGrxBygRWjNfCH7MBAShuHbTLkQQF250V+gqHyDoy0wKRUpXLsWKUlERmiKC1KUupKkx8V2ZzETSZGZ8u3fOfed+nPsICSefz2sjO3Y/AnCDmToEzGWoOS2l/BP3hAbBgu3eFMBtMG+C6PxWnl+AoTH4cb1Wlf3ckJBhOV9AdCCp0ghf9WX5SLqQ7a4CGEsX4g++rBxPFdJt5yGB7qQKMT/wa5WZ9IqM63tZiHUiGmo7etjeQHPPkpTfE4UMoziOjKoCdOg/rb1TEMV5WVrpxf3LatjuJDOeEUEbEOnZnenHmdFksDVfqywGeFdo0nROZAW9BDA6XAl/CjE6GlPlBimc87zyx66QbruvCDgb1w4Dd6OM9+J5Xq7LyjgVLPeCICzFzoTxufP7x8mAy27btQLCwbg4xbhIulUsEbEb09IaKXHJ80qNbtW6c5iyeB5nBDOXybCdRh+pALwB42m7pc0uLMw1+xNMTNwazWmtKRCuADgNQIQ8r5Fhu7+ALU4F90XuqJl6vRoNOgy/bF87lkH2PoA8gO29JMxokW45Kn75eFMxzJ69BcvJC4IH0MjgGJiZSbfddQL2JQz7J1Q7/FMi9x6EnQnOfgtaqwEwY4VCcDbiplJivGDYOkB+UhADX6M92p8sxEawkMKw3dcATiUEcoQnfeK3viyf6ZKmeXWMRa6CcLsjS1MaCalgVZZJtR3Pe9L4C7NjwtPa1kFOAAAAAElFTkSuQmCC",
          inactiveImage:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAAAXNSR0IArs4c6QAAAjNJREFUOE+FU01rE1EUPfclcWzRhQtBBRHEKgqKKGrxBygRWjNfCH7MBAShuHbTLkQQF250V+gqHyDoy0wKRUpXLsWKUlERmiKC1KUupKkx8V2ZzETSZGZ8u3fOfed+nPsICSefz2sjO3Y/AnCDmToEzGWoOS2l/BP3hAbBgu3eFMBtMG+C6PxWnl+AoTH4cb1Wlf3ckJBhOV9AdCCp0ghf9WX5SLqQ7a4CGEsX4g++rBxPFdJt5yGB7qQKMT/wa5WZ9IqM63tZiHUiGmo7etjeQHPPkpTfE4UMoziOjKoCdOg/rb1TEMV5WVrpxf3LatjuJDOeEUEbEOnZnenHmdFksDVfqywGeFdo0nROZAW9BDA6XAl/CjE6GlPlBimc87zyx66QbruvCDgb1w4Dd6OM9+J5Xq7LyjgVLPeCICzFzoTxufP7x8mAy27btQLCwbg4xbhIulUsEbEb09IaKXHJ80qNbtW6c5iyeB5nBDOXybCdRh+pALwB42m7pc0uLMw1+xNMTNwazWmtKRCuADgNQIQ8r5Fhu7+ALU4F90XuqJl6vRoNOgy/bF87lkH2PoA8gO29JMxokW45Kn75eFMxzJ69BcvJC4IH0MjgGJiZSbfddQL2JQz7J1Q7/FMi9x6EnQnOfgtaqwEwY4VCcDbiplJivGDYOkB+UhADX6M92p8sxEawkMKw3dcATiUEcoQnfeK3viyf6ZKmeXWMRa6CcLsjS1MaCalgVZZJtR3Pe9L4C7NjwtPa1kFOAAAAAElFTkSuQmCC",
          active: false,
        },
        {
          label: "收支明细",
          activeImage:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAjCAYAAAAJ+yOQAAAAAXNSR0IArs4c6QAAA0RJREFUWEfVmFtoXFUUhr//5CYVLCXJzKDFCyqiUOprvVAELwj6XCpYrdUwMw1C64MgfSz0XSSTtKQo+mBFwVrEVn2otxZExbylSJC+1CaO7UsvMcmc36zJREaTFmQ65syGDYd1Zvb+z7/2+vdaSzSN0oC3KuFZxBOGu4Gbm9+3+fmyYArzhVOOVar6ank/xcPwgG9Nu3jU8Lhgs+EOYAPQ02ZgzcvPAxcFZw0Tgi+TGt+8VdW5Oshy3tsMrwJbeoQWDP4f0TUz1i2Yd33704I3R6Z1RKVBP43YhnhekMQf1gLgP1y7hCHFvIs5olLOFVRncPNaMfhvp4V7g9E5MyFzSqWCz2D6Bf1ryeBqQA1/WFRVyvtqAG/MNTiJ190ynLugct5ZInBVxMFkmjX6Vri+lPeVzIMs5n0g8yCzDjDwaWiD12cdaATOG5kH2RESNFzoDJ3sCDHvXJCRiXQ1cre4QJevpevZE0EXUIt5Az89onu15cI2C5yXuGhzZyNTl2FWMI2oYm4H+lnKQ/+0+T3eSWw0DMR33gjlWAEymIrMA/jF5oOkm1NO2YvZauiTmUJ8ZPg8gWHDU8A64FfBsQSO1mAX8AxwS2O9lrCuABkurpmrgo8Nh+d6+KlvjtcttgO3AZ8Yxnvm+brWzR6LHcBdmOMyh2fNib6EIuIF4AFBsN/SWAEyMuIFcwlTIWW8UtWZct57DC8D98mM1eDg2IwmSjkXgSHEg8DbFqOj5/V9Oecd8XuLR7pAraZZ12Ly8iJrh4KxwjSTMzn2InYa7gXG04RDY7/xY3mQ3RavLLp/E+I9p4yNzui73Xm/lJpdFlvaCTKC5nhqRtb18u3sAvsxzwE5R10sKhf6+WzwAvtsXgQ2AicNByen+fD+Aq/h+rm8py3u/jtwzJThqBJ+wAwBDwM3RYAAnyrhpFN2Ch7zUhPh7GJJfMJxNhO2Y54E1rclcJpOeMhdFOwR6X2NGij2jCMW9pjXsvc2Ggv1ur7VUa9xVou+WD3EOQQwhLlZzP+LvWWAkU8W8w65yXa1WCx4UmYg23V3ziMWD/VmsIMxb352vYOR0V5QGrezeQd4P7NdNcFplrtqEX0d0Z9clomsdnr/Am3Ei1n6RffpAAAAAElFTkSuQmCC",
          inactiveImage:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAAAXNSR0IArs4c6QAAAPxJREFUOE+11LEuREEYxfH/mbuCikqWV5B4AFGgEBHFvdeuCusxVBKPolKIVa2IgncQnkAk3oCEmSMbFBKJuzGmnMz8cuabfJ8Aqs7epqQj8AKoNdxrvvwGugUO+6fHA5XbO2VwcQaE5siPJ1NKcUt1p3eHmP8j9nHd3Kvu9mKGdF950hB0lnSfiMpyfzorWHV3D7KC2Z/83+CTIQnmgEegANqGB5lxxMxv5fn2y5IqiM92uJTSeozFZAg+N2kZF7OST0YCk72REi+tQtdEr6bARJAujBcFbVB/JBC4sXmVWLO5kjwGWgEGxlNCS03AzJ2Su5dzThvbtXLPw3ey4G4ZHTj98QAAAABJRU5ErkJggg==",
          active: false,
        },
      ],
      bianjiopens: false,
      touxiang: [
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/1.png",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/2.png",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/3.png",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/4.png",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/5.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/6.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/7.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/8.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/9.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/10.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/11.jpg",
        },
        {
          img: "https://d7skins.oss-cn-beijing.aliyuncs.com/touxiang/12.jpg",
        },
      ],
      selectToux: 0,
      menu: [
        {
          name: "我的库存",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: true,
        },
        {
          name: "已取回",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "正在取回",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "账户记录",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "充值中心",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "账号设置",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "CDK",
          img: require("../assets/img/my/icon11.png"),
          is: false,
          select: false,
        },
        {
          name: "邀请奖励",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
      ],
      inviteImg: "",
      inviteName: "",
      Data: {},
      site: "",
      time: "",
      url: "",
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://www.bilibili.com/video/BV15D4y1X79w/",
      urlState: true,
      checked1: false,
      checked2: false,
      flag: false,
      urlHint: "",
      timer: 0,
      valueUrl: "",
      valueCode: "",
      trading: "",
      tradingCode: null,
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://www.bilibili.com/video/BV15D4y1X79w/",
      leftNavCurrent: "1",
      realNameDialog: false,
      realName: "",
      idCard: "",
      is_realname: 0,
    };
  },
  watch: {
    $route: function () {
      if (this.$route.query.type == "2") {
        this.leftNavCurrent = "2";
        this.$forceUpdate();
      }
      if (this.$route.query.type == "充值") {
        this.menuClick(4);
        this.$route.query.type = null;
      }
    },
  },
  methods: {
    selectTab(item) {
      // 清除所有选项的激活状态
      this.tabItems.forEach((tab) => (tab.active = false));

      // 设置点击的选项为激活状态
      item.active = true;

      // 根据不同的选项执行不同的逻辑
      switch (item.label) {
        case "我的物品":
          this.leftNavCurrent = "1";
          break;
        case "我的福利":
          this.leftNavCurrent = "2";
          break;
        case "我的佣金":
          this.goShare();
          break;
        case "收支明细":
          this.leftNavCurrent = "4";
          break;
        default:
          break;
      }
    },
    // 实名
    realNameConfirm() {
      if (this.realName == "") {
        this.$message({
          showClose: true,
          message: "请输入姓名",
          type: "error",
        });
        return;
      }
      if (this.idCard == "") {
        this.$message({
          showClose: true,
          message: "请输入身份证号",
          type: "error",
        });
        return;
      }
     
      let param = {
        idcard: this.idCard,
        name: this.realName,
      };
      this.$axios
        .post("/index/user/realname", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.is_realname = true;
            this.realNameDialog = false;
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          }
        });
    },
    recharge(){
      this.$router.push({
          path: `/recharge`,
        });
    },
    openHongbao(){
      this.$emit('openHongbao')
    },
    realNameFn(){
      if(this.is_realname==1){
        this.$message({
          showClose: true,
          message: "已实名",
          type: "warning",
        });
        return;
      }
      this.realNameDialog = true;
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
    // 绑定steam
    confirm() {
      //console.log(this.url);
      console.log(this.dlist);
      let param = {
        tradeUrl: this.trading,
        code: this.tradingCode,
      };
      this.$axios
        .post("/index/User/bindSteam", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.urlState = true;
            this.flag = true;
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "error",
            });
            this.urlState = true;
            // this.url = '';
          }
        });
    },
    changePhoto() {
      var _this = this;
      let param = {
        imageUrl: this.touxiang[this.selectToux].img,
      };
      this.$axios
        .post("/index/User/editHeadImages", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            _this.Data.img = res.data.data;
            Utils.$emit("img", _this.Data.img);
            this.bianjiopens = false;
          } else {
            this.$message({
              message: "修改失败",
              type: "info",
            });
          }
        });
    },
    changeToux(index) {
      this.selectToux = index;
    },
    bianjiopen() {
      this.bianjiopens = true;
    },
    //退出登录
    nextLogin() {
      console.log("退出登录");
      localStorage.setItem("userInfo", null);
      this.loginfalse = true;
      this.drawer = false;
      this.$store.commit("getId", {
        name: "",
        id: "",
        img: "",
        money: "",
      });
      this.$store.commit("loginOut");
      this.$router.push({
        path: `/Index`,
      });
    },
    menuClick(index) {
      var that = this;
      this.menu.forEach((e) => {
        e.select = false;
      });
      this.menu[index]["select"] = true;
      if (index == 0) {
        this.$refs.MyKucun0.getList();
      }
      if (index == 1) {
        this.$refs.MyKucun1.getList();
      }
      if (index == 2) {
        this.$refs.MyKucun2.getList();
      }
    },
    //个人中心信息
    getList() {
      let param = {};
      this.$axios
        .post("/index/User/playerInfo", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.Data = data.data;
            this.is_realname = data.data.is_realname;
            console.log(this.Data);
            this.url = this.Data.tradeUrl;
            this.trading = this.Data.tradeUrl;
            if (this.url) {
              this.flag = true;
            }
            this.site = this.Data.last_login_info.position;
            this.time = this.Data.last_login_info.time;
            if (data.data.myInviter) {
              this.inviteImg = data.data.myInviter.img;
              this.inviteName = data.data.myInviter.name;
            }
          }
        });
    },
    getInvite() {
      this.$axios
        .post("index/Invite/inviteInfo", this.$qs.stringify({}))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.valueUrl = data.data.invite_url;
            this.valueCode = data.data.invite_code;
          }
        });
    },
    goShare() {
      this.$router.push({
        path: `/Share`,
      });
    },
    copyText(text) {
      console.log(text, "dhuajdka");
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
  },
  mounted() {
    this.getInvite();
    this.getList();
    // setInterval(() => {
    //   this.getList();
    // }, 5000);
    if (this.$route.query.type == "2") {
      this.tabItems[0].active = false;
      this.tabItems[1].active = true;
      this.leftNavCurrent = "2";
    }
    if (this.$route.query.type == "充值") {
      this.menuClick(4);
    }
    if (this.$route.query.type == "推广") {
      this.menuClick(6);
    }
  },
};
</script>

<style lang="less" scoped>
.bbbc {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;

  .boxs {
    padding: 20px;
    width: 1000px;
    background-color: rgba(40, 43, 43, 0.7843137255) !important;
    border-radius: 10px;
    border: 1px solid rgba(69, 102, 51, 0.5019607843) !important;

    .ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .selectToux {
        cursor: pointer;
        border: 2px solid #7aba0b;
      }

      div {
        cursor: pointer;
        margin-top: 12px;
        width: 130px;

        img {
          width: 122px;
          height: 122px;
        }
      }
    }
  }

  .boxs_bottom {
    display: flex;
    justify-content: flex-end;

    span:first-child {
      border-radius: 4px;
      padding: 14px 40px;
      color: #fff;
      margin: 0 10px;
      background-color: hsla(0, 0%, 100%, 0.082);
    }

    span:last-child {
      border-radius: 4px;
      padding: 14px 40px;
      color: #fff;
      margin: 0 10px;
      background-color: #7aba0b;
    }
  }
}

.topimg {
  width: 100%;
}

.dota-warp {
  width: 1500px;
  margin: 12px auto;
  padding: 10px 0;
  min-height: 100vh;

  .flexbox {
    margin-top: 32px;
    display: flex;
    // align-items: center;
    justify-content: space-between;

    .flex-left {
      width: 240px;
      height: 717px;
      background-image: url(https://oss.88888csgo.com/web/user_bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 40px 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .fl-top {
        width: 100%;
        display: flex;

        .touxiang {
          position: relative;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          margin-top: 0;
          margin-left: 0;

          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }

          .bianji {
            position: absolute;
            left: 0;
            right: 0;
            width: 70px;
            font-size: 12px;
            height: 70px;
            border-radius: 50%;
            color: white;
            background: rgba(0, 0, 0, 0.5);
            margin-top: 0;
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
          }
        }

        .touxiang:hover {
          .bianji {
            display: flex !important;
          }
        }

        div {
          width: 100%;
          margin-left: 16px;
          display: flex;
          align-items: center;
          // margin-top: 12px;
          justify-content: space-between;

          span {
            font-size: 14px;
            font-family: "微软雅黑";
            font-weight: 700;
            color: #fff;
            line-height: 21px;
            opacity: 0.8;
          }
        }
      }

      .fl-center {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .moneny {
          display: flex;
          width: 110px;
          height: 55px;
          background: rgba(71, 201, 243, 0.14);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            color: white;
            font-size: 11px !important;
          }

          span:first-child {
            font-size: 11px !important;
            font-weight: 400;
            color: #3088ff;
            line-height: 16px;
            margin-bottom: 2px;
          }

          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .fl-button {
        margin-top: 32px;
        display: flex;
        align-items: center;

        span:last-child {
          margin-left: 12px;
        }

        span {
          width: 100px;
          height: 39px;
          font-size: 15px;
          background-repeat: no-repeat;
          font-weight: 400;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 25px;
          background-image: url("../assets/img/my/button.png");
          background-size: 100% 100%;
        }
      }

      .fl-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
        width: 100%;

        div {
          display: flex;
          width: 100%;
          align-items: center;
          height: 56px;
          color: white;
          justify-content: center;

          img {
            width: 20px;
          }

          span {
            width: 68px;
            margin-left: 20px;
          }
        }

        div:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        .select:hover {
          background: rgba(105, 224, 255, 0.1);
        }

        .select {
          color: #3088ff !important;
          border-left: 2px solid #3088ff;
        }
      }

      .fl-tuichu {
        width: 238px;
        height: 42px;
        background: rgba(71, 201, 243, 0.14);
        border-radius: 4px;
        font-size: 15px;
        font-weight: 400;
        color: #adb8ba;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
      }

      .fl-tuichu:hover {
        background: rgba(71, 201, 243, 0.28);
      }
    }

    .flex-right {
      width: 1000px;
    }
  }
}

//分页
.roll-page {
  margin: 10px 0 0px -10px;
  // margin-bottom: 50px;
  padding-bottom: 50px;
}

.roll-page
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #3088ff;
  color: #1a1c24;
}

.roll-page /deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #333542;
  color: #848492;
}
.header {
  display: flex;
  justify-content: flex-start;
  background: #171c3c;
  border-radius: 0.42rem * 1.4;
  margin-bottom: 0.46rem * 1.4;
  padding: 0.66rem * 1.4 0;
  flex-wrap: wrap;
}

.header > div {
  border-right: 1px solid #1e222a;
}

.header > div:last-child {
  border: 0;
}

.header .avatar {
  width: 13.33rem * 1.4;
  padding: 0 1.3rem * 1.4;
  display: flex;
  align-items: center;
}

.header .avatar .pic {
  width: 3.34rem * 1.4;
  height: 3.35rem * 1.4;
  border-radius: 50%;
  border: 2px solid #6917ff;
  margin: 0.63rem * 1.4;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-shrink: 0;
}

.header .avatar .pic .upload {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.header .avatar .nameBox {
  flex-grow: 1;
}

.header .avatar .nameBox .username {
  font-size: 0.83rem * 1.4;
  font-weight: 700;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.header .avatar .nameBox .username .edit {
  color: #ff31ed;
  font-size: 0.62rem * 1.4;
  cursor: pointer;
}

.header .avatar .nameBox .vipLevel {
  width: 4.96rem * 1.4;
  height: 0.29rem * 1.4;
  background: #d1d3de;
  border-radius: 0.14rem * 1.4;
  margin: 0.5rem * 1.4 0 0.5rem * 1.4 0.3rem * 1.4;
  position: relative;
}

.header .avatar .nameBox .vipLevel img {
  width: 1rem * 1.4;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -0.5rem * 1.4;
}

.header .avatar .nameBox .invitationCode {
  font-size: 0.5rem * 1.4;
  color: #a9abbc;
  display: flex;
  cursor: pointer;
}

.header .avatar .nameBox .invitationCode .Copy {
  margin-left: 0.3rem * 1.4;
}

.header .money {
  padding: 0 1.25rem * 1.4;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.header .money .balance {
  display: flex;
  align-items: center;
}

.header .money .balance .balanceTitle {
  font-size: 0.5rem * 1.4;
  color: #a9abbc;
  margin-bottom: 0.3rem * 1.4;
  margin-right: 0.33rem * 1.4;
}

.header .money .balance .moneyVal {
  font-size: 0.83rem * 1.4;
}

.header .money .btnBox {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0.33rem * 1.4;
}

.header .money .btnBox .recharge {
  width: 8.33rem ;
  height: 2.08rem * 1.4;
  background: #ff31ed;
  cursor: pointer;
  border-radius: 0.25rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.83rem * 1.4;
  font-weight: 700;
  color: #fff;
  margin-right: 1.33rem * 1.4;
}

.header .money .btnBox .realName {
  background: #6917ff;
}

.header .steamLink {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.25rem * 1.4 1.25rem * 1.4;
  flex: 1;
}

.header .steamLink .top {
  display: flex;
  font-size: 0.58rem * 1.4;
  align-items: center;
  margin-bottom: 0.75rem * 1.4;
}

.header .steamLink .top .link {
  display: flex;
  align-items: center;
  flex: 1;
  height: 1.75rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.88rem * 1.4;
  margin-right: 0.83rem * 1.4;
  padding-right: 0.25rem * 1.4;
  // border-radius: 0.238rem 0rem 0rem 0.238rem;
}

.header .steamLink .top input {
  flex: 1;
  height: 100%;
  padding: 0.5rem * 1.4 0.8rem * 1.4;
  color: #fff;
  box-sizing: border-box;
}

.header .steamLink .top .give,
.header .steamLink .top .save {
  width: 3.04rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  border-radius: 0.25rem * 1.4;
}

.header .steamLink .top .save {
  font-size: 0.58rem * 1.4;
  font-weight: 700;
  background: rgba(105, 23, 255, 0.2);
  border: 2px solid #6917ff;
  height: 1.5rem * 1.4;
}

.header .steamLink .top .give {
  height: 1.75rem * 1.4;
  font-size: 0.58rem * 1.4;
  font-weight: 700;
  background: rgba(105, 23, 255, 0.3);
}

.header .steamLink .tips {
  font-size: 0.5rem * 1.4;
  color: #a9abbc;
  padding: 0 0.25rem * 1.4;
}

.header .steamLink .tips p {
  margin-bottom: 0.1rem * 1.4;
}
.MoneyCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
.extend1 {
  width: calc(100%-140px);
  background: rgba(71, 201, 243, 0.3);
  border-radius: 4px;
  margin-top: 10px;
  padding: 25px 70px;
  font-size: 11px;
  font-weight: 400;
  color: #adb8ba;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span:first-child {
    font-size: 16px;
  }

  span:last-child {
    display: block;
    width: 90px;
    height: 28px;
    font-size: 13px;
    background-repeat: no-repeat;
    background-size: 100%;
    line-height: 30px;
    background-image: url("../assets/img/my/button.png");
    font-weight: 400;
    color: #fff;
    margin-left: 12px;
    text-align: center;
    cursor: pointer;
  }

  input {
    margin-left: 30px;
    width: 200px;
    height: 40px;
    padding-left: 0.8rem;
    color: #fff;
    outline: none;
    opacity: 0.9;
    background: rgba(23, 188, 241, 0.2);
    border-radius: 4px;
    border: 0;
  }
}
.inBody {
  background-color: #171c3c;
  padding: 0.54rem * 1.4;
  border-radius: 0.42rem * 1.4;
  display: flex;
}
.leftNav {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.33rem * 1.4;
}

.leftNav .tabBox {
  display: flex;
  margin-bottom: 0.46rem * 1.4;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 0.17rem * 1.4;
  overflow: hidden;
  width: 100%;
}

.leftNav .tabBox .tabItem {
  min-width: 6rem * 1.4;
  padding: 0.2rem * 1.4 0.33rem * 1.4;
  box-sizing: border-box;
  height: 2.29rem * 1.4;
  font-size: 0.58rem * 1.4;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftNav .tabBox .tabItem img {
  margin-right: 0.33rem * 1.4;
  width: 0.83rem * 1.4;
}

.leftNav .tabBox .active {
  background-color: #6917ff;
  background-image: url("../assets/img/mes/tabBg.08eb2622.png");
  background-repeat: no-repeat;
  background-position: 50% / 100% 100%;
  background-size: cover;
}

.leftNav .tabBox .setAudio {
  height: 1.84rem * 1.4;
  background: rgba(129, 27, 255, 0.3);
  border: 2px solid #811bff;
  border-radius: 0.17rem * 1.4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.67rem * 1.4;
  margin-top: 0.4rem * 1.4;
  cursor: pointer;
  box-sizing: border-box;
}

.leftNav .tabBox .setAudio .audioImg {
  width: 1.06rem * 1.4;
}

.leftNav .tabBox .closeAudio {
  background: hsla(0, 0%, 100%, 0.1);
  opacity: 0.5;
  border: 0;
}

.leftNav .logout {
  height: 2rem * 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a9abbc;
  font-size: 0.75rem * 1.4;
  padding: 0 1rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.17rem * 1.4;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease;
}

.leftNav .logout .guanji {
  margin-right: 0.5rem * 1.4;
  font-size: 0.75rem * 1.4;
}

.leftNav .logout:hover {
  background: hsla(0, 0%, 100%, 0.08);
  color: #fff;
}

.informationView {
  padding: 0.54rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.42rem * 1.4;
  min-height: 10rem * 1.4;
  flex-shrink: 0;
  width: 80%;
  box-sizing: border-box;
}

.changePopupContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 15rem * 1.4;
  padding: 0 1.5rem * 1.4;
  box-sizing: border-box;
}

.changePopupContent .inputBox {
  width: 100%;
  height: 1.83rem * 1.4;
  padding: 0.2rem * 1.4 0.52rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 0.17rem * 1.4;
  margin-bottom: 0.4rem * 1.4;
  color: #bbb;
  box-sizing: border-box;
}

.changePopupContent .inputBox:focus {
  border: 1px solid #6917ff;
}

.changePopupContent .title {
  font-size: 1.2rem * 1.4;
  font-weight: 700;
  margin-bottom: 0.66rem * 1.4;
}

.changePopupContent .tips {
  font-size: 0.5rem * 1.4;
  color: #979ca7;
}

.changePopupContent .btnBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.changePopupContent .btnBox .btn {
  margin: 0.66rem * 1.4 0;
  min-width: 5rem * 1.4;
  height: 1.8rem * 1.4;
  padding: 0.2rem * 1.4 0.33rem * 1.4;
  box-sizing: border-box;
  background-color: #6917ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.68rem * 1.4;
  font-weight: 700;
  border-radius: 0.42rem * 1.4;
}

.changePopupContent .btnBox .cancel {
  background-color: #faf6f6;
  color: #07090e;
}
.dota {
  background: #0e1325;
}
.show {
  background-color: rgba(0, 0, 0, 0.6);
  animation: fadeIn 0.5s;
}
.mask {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupContent {
  position: relative;
  background: url(../assets/img/mess/loginbg.04aa9090.png) no-repeat 50% / cover;
  padding: 1rem * 1.4;
  border-radius: 0.42rem * 1.4;
  color: #fff;
}

.popupContent .close {
  width: 0.69rem * 1.4;
  position: absolute;
  top: 0.63rem * 1.4;
  right: 0.63rem * 1.4;
  cursor: pointer;
  transition: transform 0.3s linear;
  opacity: 0.6;
  img {
    width: 100%;
  }
}

.popupContent .close:hover {
  transform: rotate(90deg);
}
.RealNamePopup {
  width: 15rem * 1.4;
  padding: 1rem * 1.4;
}

.RealNamePopup .title {
  font-size: 1.2rem * 1.4;
  font-weight: 700;
  margin-bottom: 1.66rem * 1.4;
  text-align: center;
}

.RealNamePopup .box {
  margin-bottom: 0.66rem * 1.4;
}

/deep/.el-input__inner {
  width: 100%;
  height: 1.83rem * 1.4;
  line-height: 1.83rem * 1.4;
  padding: 0.2rem * 1.4 0.52rem * 1.4;
  background: hsla(0, 0%, 100%, 0.05);
  border-radius: 10rem;
  // margin-bottom: .4rem*1.4;
  color: #bbb;
  box-sizing: border-box;
  border: none;
}
.RealNamePopup .box .name {
  margin-bottom: 0.33rem * 1.4;
}
.defaultBtn {
  min-width: 5rem * 1.4;
  height: 1.8rem * 1.4;
  padding: 0.2rem * 1.4 0.33rem * 1.4;
  box-sizing: border-box;
  background-color: #6917ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.68rem * 1.4;
  font-weight: 700;
  border-radius: 0.42rem * 1.4;
  cursor: pointer;
}
</style>
