<template>
  <div class="base-header">
    <div class="nav2">
      <ul>
        <li
          v-for="(item, index) in menu"
          :key="index"
          @click="goMenu(item.id, item.path)"
          :class="{
            activeli: $route.name == item.paths,
          }"
        >
          <img
            v-if="$route.name == item.paths"
            :src="item.img1"
            style="width: 20px"
            alt=""
            srcset=""
          />
          <img v-else :src="item.img2" style="width: 20px" alt="" srcset="" />
          <span
            :class="{
              active: $route.name == item.paths,
            }"
            style="margin-left: 3px"
            >{{ item.value }}</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "base-header",
  data() {
    return {
      menu: [
        {
          id: 1,
          img2: require("../../../assets/img/tabbar/home_sel.png"),
          img1: require("../../../assets/img/tabbar/home.png"),
          selState: true,
          path: "Index",
          paths: "Index",
          child: ["Index", "Openbox"],
          value: "盲盒",
        },
        {
          id: 3,
          img2: require("../../../assets/img/tabbar/exchange_sel.png"),
          img1: require("../../../assets/img/tabbar/exchange.png"),
          selState: false,
          path: "Arena",
          paths: "Arena",
          child: ["Arena", "ArenaRoom"],
          value: "Roll房",
        },
        {
          id: 4,
          img2: require("../../../assets/img/tabbar/percentage_sel.png"),
          img1: require("../../../assets/img/tabbar/percentage.png"),
          selState: false,
          path: "OrnamentOpen",
          paths: "OrnamentOpen",
          child: ["Ornament", "OrnamentOpen", "OrnamentHistory", "OrnamentHistory2"],
          value: "追梦",
        },
        {
          id: 2,
          img2: require("../../../assets/img/tabbar/classify_sel.png"),
          img1: require("../../../assets/img/tabbar/classify.png"),
          selState: false,
          path: "Lucky",
          paths: "Lucky",
          child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
          value: "竞技场",
        }, 
        {
          id: 5,
          img2: require("../../../assets/img/tabbar/exchange_sel.png"),
          img1: require("../../../assets/img/tabbar/exchange.png"),
          selState: false,
          path: "Roll",
          paths: "Roll",
          child: ["Roll"],
          value: "交易市场",
        },
        // {
        //   id: 6,
        //   img2: require("../../../assets/img/tabbar/1701393682126.png"),
        //   img1: require("../../../assets/img/tabbar/1701393710847.png"),
        //   selState: false,
        //   path: "task-center",
        //   paths: "TaskCenter",
        //   child: ["task-center"],
        //   value: "福利中心",
        // },
        // {
        // 	id: 6,
        // 	img1: require("@/assets/img/nav2/b6.png"),
        // 	img2: require("@/assets/img/nav2/b66.png"),
        // 	selState: false,
        // 	path: "Mes",
        // 	child: ["Mes"],
        // 	value: "活动推广",
        // },
        // {
        // 	id: 7,
        // 	img1: require("@/assets/img/nav2/b8.png"),
        // 	img2: require("@/assets/img/nav2/b88.png"),
        // 	selState: false,
        // 	path: "Mes",
        // 	child: ["Mes"],
        // 	value: "饰品背包",
        // },
      ],
    };
  },
  mounted() {
    console.log(this.$route);
  },
  methods: {
    goMenu(id, path) {
      this.$emit("goMenu", id, path);
    },
  },
};
</script>

<style lang="less" scoped>
.base-header {
  padding-left: 20px;
  height: 75px;

  .nav2 {
    height: 100%;

    ul {
      display: flex;
      height: 100%;

      .active {
        color: #fff;
      }

      li {
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6vw;
        text-align: center;
        cursor: pointer;
        color: #a7a7a7;
        font-size: 18px;
        line-height: 38px;
        margin-left: 10px;
        white-space: nowrap;
        // font-weight: bold;
        position: relative;
        // &:first-child {
        //   &::before {
        //     content: "";
        //     position: absolute;
        //     top: 50%;
        //     left: 0;
        //     height: 50px;
        //     width: 1px;
        //     background-color: #272f43;
        //     transform: translate(-50%, -50%);
        //   }
        // }

        // &::after {
        //   content: "";
        //   position: absolute;
        //   top: 50%;
        //   right: 0;
        //   height: 50px;
        //   width: 1px;
        //   background-color: #272f43;
        //   transform: translate(-50%, -50%);
        // }
      }
    }
  }
}
</style>
