<template>
  <div
    class="base-slider-pc"
    :class="{
      'small-screen': small_screen,
    }"
    :style="{
      right: getSliderRight,
    }"
  >
    <!-- <div class="item" @click="gotTo('/task-center', '/task-center', '签到中心')">
      <img src="@/assets/img/1700481608251.png" style="width: 28px; height: auto" />
      <span>签到</span>
    </div> -->

    <!-- <div class="item qqq-money_container" @click="$emit('changeZhuanshi')" v-if="showNav">
      <div class="qqq-money"></div>
      <span>福利</span>
    </div> -->
    <div
      class="item qqq-money_container"
      @click="$router.push('/Mes?type=2')"
      v-if="showNav"
    >
      <div class="qqq-money"></div>
      <span>福利</span>
    </div>
    <div
      class="item helper_container"
      @click="gotTo('/Payment', 'Mes', '充值')"
      v-if="showNav"
    >
      <div class="helper"></div>
      <span>充值</span>
    </div>
    <!-- <div class="item bb-group_container" @click="gotTo('/Dota', 'Mes', '背包')">
      <div class="helper"></div>
      <span>背包</span>
    </div> -->
    <div class="item lucky-money_container" @click="openHongbao">
      <div class="lucky-money"></div>
      <span>红包</span>
    </div>
    <div
      class="item back-top_container"
      style="margin-bottom: 10px !important"
      @click="qqGroup"
    >
      <img class="back-top" src="@/assets/img/1701395045966.png" />
      <span>客服</span>
    </div>
    <!-- <div class="item back-top_container" @click="backTop">
      <img class="back-top" src="@/assets/img/1700481608264.png" />
      <span>顶置</span>
    </div> -->
    <div class="switch" @click="changeSlider">
      <img
        src="@/assets/img/folded@2X.1e4b640b.png"
        alt=""
        class="el-icon-arrow-left"
        v-if="status"
      />
      <img
        src="@/assets/img/expanded@2X.6844ea00.png"
        class="el-icon-arrow-right"
        alt=""
        v-else
      />
      <!-- <i :class="[status ? 'el-icon-arrow-left' : 'el-icon-arrow-right']"></i> -->
    </div>
   
  </div>
</template>

<script>
export default {
  props: ["showNav"],
  data() {
    return {
      // 小屏
      small_screen: true,
      // 状态 0展开 1折叠
      status: 0,
     
    };
  },
  computed: {
    getSliderRight() {
      if (this.small_screen) {
        if (this.status) {
          return "-65px !important";
        } else {
          return "-10px !important";
        }
      } else {
        if (this.status) {
          return "-70px !important";
        } else {
          return "0px !important";
        }
      }
    },
  },
  created() {
    this.checkScreen();
    window.addEventListener("resize", () => {
      this.checkScreen();
    });
    setTimeout(() => {
      this.status = 1;
    }, 1000);
  },
  methods: {
    changeSlider() {
      this.status = this.status ? 0 : 1;
    },
    checkScreen() {
      if (window.innerWidth < 600) {
        this.small_screen = true;
        return;
      }
      this.small_screen = false;
    },
    backTop() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    },
    gotTo(path, pathpc, pe) {
      if (this.showNav) {
        this.$router.push({
          path: pathpc,
          query: {
            type: pe,
          },
        });
      } else {
        this.$router.push({
          path,
        });
      }
    },
    qqGroup() {
      window.open(
        "https://qm.qq.com/cgi-bin/qm/qr?k=QZcWH82_vqJEZ86YttZHaSCLjE2X2Qtx"
      );
    },
    qqsGroup() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
      if (isAndroid) {
        window.open("mqqwpa://im/chat?chat_type=wpa&uin=365265258");
        return;
      }
      if (isIOS) {
        window.open(
          "mqqapi://card/show_pslcard?src_type=internal&source=sharecard&version=1&uin=365265258"
        );
        return;
      }
      window.open(
        "https://wpa.qq.com/msgrd?v=3&uin=365265258&site=qq&menu=yes&jumpflag=1"
      );
    },
    openHongbao() {
      if(this.showNav){
        this.$emit("openHongbao");
      }else{
        this.$router.push({
          path: "/Cdk",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
div {
  -webkit-tab-highlight-color: transparent;
}

.small-screen {
  transform: scale(0.8) !important;
  right: -10px !important;
  transform: translateY(-50%) !important;
}

.base-slider-pc {
  position: fixed;
  right: 0;
  // bottom: 10vh;
  top: 50%;
  width: 70px;
  z-index: 600;
  // border-image: linear-gradient(
  //   180deg,
  //   rgba(48, 136, 255, 0.4784) 3%,
  //   rgba(42, 42, 42, 0.24) 100%
  // );
  background-color: rgba(51, 31, 88, 0.96);
  // min-height: 200px;
  padding: 20px 0;
  transition: all 200ms ease-in-out;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transform: scale(1.1) translateY(-50%);
  box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
    0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  .switch {
    // background-color: #0f1928;
    height: 70px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: -24px;
    top: 50%;
    transform: translateY(calc(-50% + 35px));
    text-align: center;
    i {
      color: #fff;
      font-size: 25px;
    }
  }

  .lucky-money_container {
    .lucky-money {
      width: 28px;
      height: 28.55px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/1700481608335.gif");
    }

    &:hover {
      .lucky-money {
        background-image: url("../../../assets/img/1700481608335.gif");
      }
    }
  }
  .qqq-money_container {
    .qqq-money {
      width: 28.39px;
      height: 28.55px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/1700481608317.png");
    }

    &:hover {
      .qqq-money {
        background-image: url("../../../assets/img/1700481608317.png");
      }
    }
  }

  .chat-group_container {
    .chat-group {
      width: 28.39px;
      height: 28.55px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/1700481608317.png");
    }

    &:hover {
      .chat-group {
        background-image: url("../../../assets/img/1700481608317.png");
      }
    }
  }

  .back-top_container {
    margin-bottom: 0 !important;

    .back-top {
      width: 26px;
      height: 26px;
    }

    &:hover {
      .back-top {
      }
    }
  }

  .bb-group_container {
    .helper {
      width: 30px;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/1700481608277.png");
    }
    &:hover {
      .helper {
        background-image: url("../../../assets/img/1700481608277.png") !important;
      }
    }
  }

  .helper_container {
    .helper {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../../assets/img/1700481608291.png");
    }

    &:hover {
      .helper {
        background-image: url("../../../assets/img/1700481608291.png");
      }
    }
  }

  .item {
    color: #fff;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;

    span {
      margin-top: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

</style>
